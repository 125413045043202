import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as config from '../../config'
import {
    Button,
    Header,
    Icon,
    Image,
    Segment,
    Form
} from 'semantic-ui-react';

import TableList from "../../components/tablePerPage";
import Wrap from "../../components/wrap";
import { getStaffList } from "./service";



const StaffList = (props) => {
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({ class: '', course: '', status: "Active", type: "All" });
    const [search, setSearch] = useState({ name: null });
    const [per_page, setPerPage] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [sort_page, setSortPage] = useState(null);
    const [last_page, setLastPage] = useState(1);
    const [data, setData] = useState([]);
    const [column, setColumn] = useState([
        {
            Header: "No.",
            accessor: "",
            style: { textAlign: "center" },
            sortable: false,
            Cell: (cellInfo) => cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
            width: 50,
        }, {
            Header: "Image",
            accessor: "imageURL",
            style: { textAlign: "center" },
            sortable: false,
            Cell: (cellInfo) => (
                <Image
                    size="mini"
                    verticalAlign="middle"
                    src={
                        cellInfo.original.imageURL ?
                            cellInfo.original.imageURL + "?time=" + new Date()
                            : '../storage/imgs/dfusers.png'
                    }
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                            '../storage/imgs/dfusers.png';
                    }}
                />
            ),
            width: 150,
        }, {
            Header: "Name",
            accessor: "name",
            sortable: true,
            width: 280,
            Cell: (cellInfo) => (
                <Wrap>
                    <span
                        className="bold"
                        style={{ cursor: "pointer" }}
                        onClick={() => props.history.push({
                            pathname: '/Staff/profile',
                            // search: '?query=abc',
                            state: { id: cellInfo.original.id }
                        })
                        }
                    >
                        {cellInfo.original.title} {cellInfo.original.firstName} {cellInfo.original.lastName}
                    </span>
                </Wrap>
            ),
        }, {
            Header: "Phone",
            accessor: "phone",
            sortable: false,
        }, {
            Header: "Employee type",
            accessor: "employee_type",
            sortable: false,
            style: { textAlign: "center" },
            width: 100,
        }, {
            Header: "Action",
            accessor: "id",
            style: { textAlign: "center" },
            sortable: false,
            Cell: (cellInfo) => (
                <Wrap>
                    <Icon
                        link
                        bordered
                        // color="green"
                        style={{ color: config.colorTheme, boxShadow: '0 0 0 0.1em ' + config.colorTheme + ' inset' }}
                        name="user"
                        onClick={() => props.history.push({
                            pathname: '/Staff/profile',
                            // search: '?query=abc',
                            state: { id: cellInfo.original.id }
                        })
                        }
                    />
                </Wrap>
            ),
            width: 100,
        },
    ]);
    const timeoutRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        getData();
    }, [per_page, current_page, sort_page, values])

    const getData = () => {
        setLoading(true)
        let page = current_page + 1;
        let qString = "?_perpage=" + per_page + "&page=" + page;
        if (values.status) qString = qString + "&status=" + values.status;
        if (values.type && values.type !== "All") qString = qString + "&type=" + values.type;
        if (search.name) qString = qString + "&name=" + search.name;

        let sort = sort_page;
        if(sort !== null){
            var sortName = sort[0].id;
            var sortType = sort[0].desc === false ? 'asc' : 'desc';
            if(sortName === "name"){
                sortName = "firstName";
            }
            qString = qString + "&sortName=" + sortName + "&sortType=" + sortType;
        }

        getStaffList(qString).then(res => {
            if (res && res.status === 200) {
                setPerPage(per_page)
                setColumn(column)
                setData(res.data.data)
                setCurrentPage(res.data.current_page - 1)
                setLastPage(res.data.last_page)
            }
            setLoading(false)
        })
    }


    const handleInputChange = e => {
        const { name, value } = e.target
        setSearch({ ...values, [name]: value })

        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            getData();
        }, 2000);
    }
    const handleSelectChange = (e, data) => {
        const { name, value } = data
        setValues({ ...values, [name]: value })
    }


    return (
        <Segment basic textAlign='left'>
            <Header as='h2' >Staff/Nanny</Header>
            <Segment className="contentSegment" textAlign="left">
                <Form>
                    <Form.Group>
                        <Form.Input
                            width="4"
                            icon="search"
                            size="small"
                            placeholder="Search"
                            name="name"
                            onChange={handleInputChange}
                        />
                        <Form.Dropdown
                            width="4"
                            icon={null}
                            fluid
                            floating
                            style={{ width: "100%", marginRight: "30px" }}
                            name='type'
                            trigger={
                                <Button
                                    basic
                                    icon
                                    fluid
                                    size="small"
                                    labelPosition="right"
                                    style={{ textAlign: "left" }}
                                >
                                    <Icon name="angle down" />
                                 Employee type : <b>{values.type}</b>
                                </Button>
                            }
                            options={[
                                { key: "a", text: "All", value: "All" },
                                { key: "s", text: "Staff", value: "Staff" },
                                { key: "n", text: "Nanny", value: "Nanny" }
                            ]}
                            defaultValue={values.type}
                            selectOnBlur={false}
                            onChange={handleSelectChange}
                        />
                        <Form.Dropdown
                            width="4"
                            icon={null}
                            fluid
                            floating
                            style={{ width: "100%", marginRight: "30px" }}
                            name='status'
                            trigger={
                                <Button
                                    basic
                                    icon
                                    fluid
                                    size="small"
                                    labelPosition="right"
                                    style={{ textAlign: "left" }}
                                >
                                    <Icon name="angle down" />
                            Status : <b>{values.status}</b>
                                </Button>
                            }
                            options={config.options_active}
                            defaultValue={values.status}
                            selectOnBlur={false}
                            onChange={handleSelectChange}
                        />
                    </Form.Group>
                </Form>


                <TableList
                    loading={loading}
                    data={data}
                    columns={column}
                    pageSize={per_page}
                    page={current_page}
                    pages={last_page}
                    onPageChange={page => setCurrentPage(page)}
                    onPageSizeChange={value => setPerPage(value)}
                    onSortedChange={(value) => setSortPage(value)}
                />
            </Segment>
        </Segment>
    );
}
export default withRouter(StaffList);
