import React, { Component } from "react";
import { render } from "react-dom";
import ReactTable from "react-table";
// import "react-table/react-table.css";

class Apptest extends Component {
  // constructor() {
  //   super();
  // }

  render() {
    const data = [
      {
        task: "Demo 1",
        status: "pending",
      },
      {
        task: "Demo 2",
        status: "completed",
      },
    ];

    const columns = [
      {
        Header: "Task Name",
        accessor: "task",
      },
      {
        Header: "Status",
        accessor: "status",
        // Cell: (row) => {
        //   row.styles["color"] = "#fff";
        //   row.styles["background-color"] =
        //     row.value == "pending" ? "red" : "green";
        //   return row.value.toUpperCase();
        // },
        // Cell: (cellInfo) => {
        //   cellInfo.styles["color"] = "red";
        //   cellInfo.styles["backgroundColor"] =
        //     cellInfo.original.status == "pending" ? "red" : "green";
        //   return cellInfo.original.status.toUpperCase();
        // },
        Cell: (row) => {
          style: {
            row.styles["backgroundColor"] = "blue";
          }
          return row.value.toUpperCase();
        },
      },
    ];

    return (
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={5}
        className="-striped -highlight"
        // getRowProps={(row) => ({
        //   style: {
        //     backgroundColor: "blue",
        //   },
        // })}
      />
    );
  }
}
// render(<Apptest />, document.getElementById("root"));
export default Apptest;
