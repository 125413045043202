import React, { useState, useRef } from "react";
import {

  Header,
  Icon,
  Segment,
  Card,
  Breadcrumb,
  Button
} from 'semantic-ui-react';
import QrReader from 'react-qr-reader'
import QrScanner from 'react-qr-scanner';

import { getDataFromQR, postCheckIn, postCheckOut } from "./service";
import Wrap from "../../components/wrap";
import ErrorModal from "./errormodal";
import StatusModal from "./statusmodal";
import Checkinout from "./checkinoutmodal";


const SchoolAttendances = (props) => {

  const [result, setResult] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [status, setStatus] = useState(null);
  const [errormsg, setErrormsg] = useState(null)
  const [success, setSuccess] = useState(null)
  const [qrString, setQrString] = useState(null)
  const [legacyMode, setLegacyMode] = useState(false);

  const timeoutRef = useRef(null);
  const reader = useRef(null);

  const getData = data => {
    setLoading(true);
    getDataFromQR(data).then(res => {
      if (res && res.status === 200) {
        setResult("found");
        setData(res.data);
      } else if (res && res.status === 404) {
        setResult(res.data.error && res.data.error.errorsmessage);
      }
      setLoading(false);
    })
  }
  const handleScan = data => {
    if (data) {
      setScanning(false);
      setLoading(true);
      setQrString(data);
      getData(data);
      // getData('?student=$2y$10$V84nTj7nvfXqHLeB1sigo.MFziszSK7V8RYn/PNidoxVpOLVlBWse');
      // getData('?guardian=$2y$10$eitdChyPmzY2pyEuKKsWNeE4YFQV9YqQ9OTMAqHp9mMR7N/Nvq7KC')
    }
  }
  const handleError = err => {
    if (err)
      setResult(err.message)
  }

  const saveAttendance = (values) => {
    setLoading(true);
    if (status === 'Check In') {
      var formData = new FormData();
      if (values.other_guardian) {
        if (typeof values.other_guardian.photo === "object")
          formData.append("photo", values.other_guardian.photo[0]);
        else formData.append("photo", values.other_guardian.photo);

        if (values.other_guardian.photo_other) {
          if (typeof values.other_guardian.photo_other === "object")
            formData.append("photo_other", values.other_guardian.photo_other[0]);
          else formData.append("photo_other", values.other_guardian.photo_other);
        }


        formData.append("name", values.other_guardian.name);
        formData.append("phone", values.other_guardian.phone);
        formData.append("relationship", values.other_guardian.relationship);
      } else {
        formData.append("guardian_id", values.guardian_id);
      }
      formData.append("type", values.type);
      formData.append("student_id", values.student_id);
      postCheckIn(formData).then(res => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (values.type === 'student') {
            setResult(null)
          } else {
            getData(qrString);
          }
          setSuccess(true)
        } else if (res && res.status === 404) {
          setErrormsg(res.data.error.errorsmessage)
        }
        setLoading(false);
      })
    } else {
      var formData = new FormData();
      if (values.other_guardian) {
        if (typeof values.other_guardian.photo === "object")
          formData.append("photo", values.other_guardian.photo[0]);
        else formData.append("photo", values.other_guardian.photo);

        if (values.other_guardian.photo_other) {
          if (typeof values.other_guardian.photo_other === "object")
            formData.append("photo_other", values.other_guardian.photo_other[0]);
          else formData.append("photo_other", values.other_guardian.photo_other);
        }

        formData.append("name", values.other_guardian.name);
        formData.append("phone", values.other_guardian.phone);
        formData.append("relationship", values.other_guardian.relationship);
      } else {
        formData.append("guardian_id", values.guardian_id);
      }
      formData.append("type", values.type);
      formData.append("student_id", values.student_id);
      postCheckOut(formData).then(res => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (values.type === 'student') {
            setResult(null)
          } else {
            getData(qrString);
          }
          setSuccess(true)
        } else if (res && res.status === 404) {
          setErrormsg(res.data.error.errorsmessage)
        }
        setLoading(false);
      })
    }
  }

  const onCheckIn = e => {
    setScanning(true);
    setStatus("Check In")
  }
  const onCheckOut = e => {
    setScanning(true);
    setStatus("Check Out")
  }
  const onClosePopup = e => {
    setErrormsg(null)
    setSuccess(null)
  }

  const onAddOther = data => {
    setErrormsg(null)
    setSuccess(null)
    setResult(null)
  }

  const openImageDialog = () => {
    setLegacyMode(true)
    if (legacyMode)
      reader.current.openImageDialog();

  }
  return (
    <Segment basic textAlign='left'>
      <StatusModal open={loading || success || errormsg} loading={loading} text={status} errormsg={errormsg} onClose={onClosePopup} />
      <Header as='h2' >School Attendances</Header>
      <Breadcrumb>
        <Breadcrumb.Section
          onClick={() => props.history.push("/dashboard")}
          style={{ color: "grey" }}
        >
          Dashboard
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>School Attendances</Breadcrumb.Section>
      </Breadcrumb>
      <br /> <br />  <br />
      {
        data && result === 'found'
        && <Checkinout
          open={result === 'found'}
          data={data}
          status={status}
          onClose={() => setResult(null)}
          onSubmit={saveAttendance}
        />
      }
      {
        result !== 'found'
        && <ErrorModal open={result} error={result} onClose={() => setResult(null)} />
      }
      {
        scanning
          ? <Wrap>
            {/* <Icon bordered color='teal' name='redo' onClick={() => setScanning(false)} /> */}
            <Button floated='left' basic compact
              icon='camera' onClick={openImageDialog} content={legacyMode ? 'Submit a QR Code' : 'Take a photos'} />

            <br /><br />
            {legacyMode ? <QrScanner
              delay={100}
              ref={reader}
              facingMode
              legacyMode
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%', height: 500, textAlign: 'center' }}
              resolution={500}
              className="reader-container"
            /> : <QrReader
              delay={100}
              // legacyMode={legacyMode}
              // facingMode={facingMode}
              facingMode={"environment"}
              // chooseDeviceId={"cameraId"}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%', height: 500, textAlign: 'center' }}
              resolution={500}
              className="reader-container"
            />

            }
            {/* <QrReader
              delay={100}
              // facingMod="rear"
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%', textAlign: 'center' }}
              resolution={2500}
            /> */}
          </Wrap>
          : <Card.Group centered itemsPerRow={2} >
            <Card onClick={onCheckIn}>
              <Card.Content>
                <Header as='h3' icon>
                  <Icon name='qrcode' style={{ color: 'green' }} />
                  Check In
                </Header>
              </Card.Content>
            </Card>
            <Card onClick={onCheckOut}>
              <Card.Content>
                <Header as='h3' icon>
                  <Icon name='qrcode' style={{ color: 'red' }} />
                  Check Out
                </Header>
              </Card.Content>
            </Card>
          </Card.Group>
      }

    </Segment>
  );
}
export default SchoolAttendances;
