import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
} from "semantic-ui-react";
import {
  getStaffProfile,
  updateEmployee,
  updateImageProfile,
} from "../User/service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import StaffForm from "./form";

const StaffProfile = (props) => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [staff, setStaff] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [showPopupImage, setShowPopupImage] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [role, setRole] = useState(
    JSON.parse(sessionStorage.getItem("kidz_roles"))
  );
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  const state = {
    optionsUserType: [
      { key: "r", text: "Teacher", value: "Teacher" },
      { key: "t", text: "TA", value: "TA" },
      { key: "n", text: "Nanny", value: "Nanny" },
      { key: "u", text: "Staff", value: "Staff" },
    ],
  };

  useEffect(() => {
    if (!staff) props.history.push("/management/staff");

    setLoading(true);
    if (staff) {
      getData();
    }
  }, [staff]);

  const getData = () => {
    getStaffProfile(props.location.state.id).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    });
  };

  const updateData = (value) => {
    setLoading(true);
    setErrormsg(null);
    updateEmployee(value).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopup(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  const updateImage = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageProfile(formData, data.id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopupImage(false);
      }
      setLoading(false);
    });
  };

  const onEditProfile = () => {
    setShowPopup(true);
  };
  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">Staff Profile</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => props.history.push("/management/Staff")}
                style={{ color: "grey" }}
              >
                Staff
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Staff Profile</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column>
            {checkPermissionMenu("Personnel", ["manage-staff"]) && (
              <Button
                basic
                color="green"
                content="Upload Image"
                floated="right"
                style={{ marginBottom: "14px" }}
                onClick={() => setShowPopupImage(true)}
              />
            )}
            {checkPermissionMenu("Personnel", ["manage-staff"]) && (
              <Button
                basic
                color="green"
                content="Edit profile"
                floated="right"
                style={{ marginBottom: "14px" }}
                onClick={onEditProfile}
              />
            )}
            {/* <Button basic color='green'
              content='Upload Image' floated='right' style={{ marginBottom: '14px' }}
              onClick={() => setShowPopupImage(true)} />
            <Button basic color='green'
              content='Edit profile' floated='right' style={{ marginBottom: '14px' }}
              onClick={() => setShowPopup(true)} /> */}
          </Grid.Column>
        </Grid>
        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "100vh", padding: "20px" }}
        >
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Wrap>
              <Grid columns="2" doubling>
                <Grid.Column width="4">
                  <Image
                    verticalAlign="middle"
                    src={
                      data && data.imageURL
                        ? data.imageURL + "?time=" + new Date()
                        : "../storage/imgs/dfusers.png"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/dfusers.png";
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  columns="2"
                  width="12"
                  style={{ minHeight: "30vh" }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h2" style={{ color: config.colorTheme2 }}>
                          <Header.Content>
                            {data.title && data.title}{" "}
                            {data.firstName && data.firstName}&nbsp;&nbsp;
                            {data.lastName && data.lastName}
                            <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.title_th && data.title_th}{" "}
                              {data.firstName_th && data.firstName_th}
                              &nbsp;&nbsp;{data.lastName_th && data.lastName_th}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2">
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Passport or ID No.
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.idnumber}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Nationality
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.nationality}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Native Language
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.nativelanguage}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Mobile Phone
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.phone}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            E-mail
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {" "}
                            {data.email}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Date of birth
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.dateOfBirth}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Age
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {moment().diff(
                              moment(data.dateOfBirth, "DD-MM-YYYY"),
                              "years"
                            )}{" "}
                            Year old
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Gender
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.gender}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Address
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.address}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Wrap>
          )}
        </Segment>
      </Segment>
      <ModalBasic
        open={showPopup}
        content="Staff"
        close={() => setShowPopup(false)}
      >
        <StaffForm
          onSubmit={updateData}
          onClose={() => setShowPopup(false)}
          loading={loading}
          initialValues={data}
          errormsg={errormsg}
          state={state}
        />
      </ModalBasic>
      <ModalBasic
        closeIcon={true}
        open={showPopupImage}
        content="Profile Image"
        close={() => setShowPopupImage(false)}
      >
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              {data && data.imageURL && (
                <Image
                  size="medium"
                  name="photo"
                  src={data.imageURL}
                  centered
                />
              )}
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Form.Input
                type="file"
                name="photo"
                onChange={(e) => setPhoto(e.target.files)}
              />
              <br />
              <br />
              <Button
                floated="left"
                content="Upload"
                fluid
                style={{
                  backgroundColor: config.colorTheme,
                  color: "#ffffff",
                  minWidth: "30%",
                }}
                loading={loading}
                onClick={updateImage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </ModalBasic>
    </Wrap>
  );
};

export default withRouter(StaffProfile);
