import React, { useState, useEffect } from "react";
import {
  Button,
  Header,
  Icon,
  Segment,
  Form,
  Grid,
  Dimmer,
  Loader,
  Table,
  List,
  Checkbox,
  Label,
  Dropdown,
  Image,
  TableBody,
} from "semantic-ui-react";
import * as config from "../../config";
import moment from "moment";
import { getBookProfile, updateStatus } from "./service";
import Wrap from "../../components/wrap";
import TableList from "../../components/table";
import ModalBasic from "../../components/modal";
import FormActive from "./formactive";

let Stock = (props) => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [bookProfiles, setBookProfiles] = useState(props.bookProfiles);
  const [data, setData] = useState([]);
  const [errormsg, setErrormsg] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [showPopupDefective, setShowPopupDefective] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showdamage, setShowdamage] = useState(true);

  useEffect(() => {
    setLoadingPage(true);
    if (bookProfiles) {
      getData();
    }
  }, [bookProfiles]);

  const getData = () => {
    setLoadingPage(true);
    getBookProfile(bookProfiles).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        if (
          res.data.damage_detail == null ||
          res.data.damage_detail == "" ||
          res.data.creator == null ||
          res.data.creator == ""
        ) {
          // console.log(res.data.damage_detail);
          if (res.data.status == "Inactive") {
            setShowdamage(showdamage);
          } else if (res.data.status == "Active") {
            console.log();
            setShowdamage(!showdamage);
          }
        }
        if (
          res.data.damage_detail != null ||
          res.data.damage_detail != "" ||
          res.data.creator != null ||
          res.data.creator != ""
        ) {
          // console.log(res.data.damage_detail);
          if (res.data.status == "Inactive") {
            setShowdamage(showdamage);
          } else if (res.data.status == "Active") {
            console.log();
            setShowdamage(!showdamage);
          }
        }
      }
      setLoadingPage(false);
    });
  };
  // console.log(data);

  const onEditDefective = (values) => {
    setShowPopupDefective(true);
    setCurrentData(values);
  };
  const onCloseDefective = () => {
    setShowPopupDefective(false);
    setCurrentData(null);
  };

  const onEditActive = (values) => {
    setShowPopup(true);
    setCurrentData(values);
  };
  const onClose = () => {
    setShowPopup(false);
    setCurrentData(null);
  };
  const updateData = (values) => {
    setLoadingPage(true);
    setErrormsg(null);
    let data = values;
    updateStatus(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopup(false);
      }
      setCurrentData(null);
      setLoadingPage(false);
    });
  };

  return (
    <Wrap>
      {loadingPage ? (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      ) : (
        <Wrap>
          <Table celled padded style={{ borderRadius: 0, fontSize: "95%" }}>
            <Table.Header
              style={{
                boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
                textAlign: "center",
              }}
            >
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  QR Code
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Book code
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Brand
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Price
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Brrowing Status
                </Table.HeaderCell>
                {/* <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Defictive</Table.HeaderCell> */}
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  {/* Active / Inactive */}
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row style={{ textAlign: "center" }}>
                <Table.Cell>
                  <Image
                    centered
                    bordered
                    // src={
                    //   "https://chart.apis.google.com/chart?cht=qr&chs=200x150&chl=?book=" +
                    //   data.id
                    // }
                    // Replace Google API deprecated
                    src={
                      "https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=?book=" +
                      data.id
                    }
                  />
                </Table.Cell>
                <Table.Cell>{data.book_code}</Table.Cell>
                <Table.Cell>{data.brand}</Table.Cell>
                <Table.Cell>{data.price}</Table.Cell>
                <Table.Cell>
                  {data.borrow_status == "Available" && (
                    <span style={{ color: "#33CC33" }}>Available</span>
                  )}
                  {data.borrow_status == "Unavailable" && (
                    <span style={{ color: "red" }}>Unavailable</span>
                  )}
                  {data.borrow_status == "Reserved" && (
                    <span style={{ color: "#FFCC33" }}>Reserved</span>
                  )}
                </Table.Cell>
                {/* <Table.Cell>
                                    {showdamage ?
                                        <Icon
                                            link
                                            style={{ color: 'red', fontSize: '29px' }}
                                            name="warning circle"
                                            onClick={() => onEditDefective(data)}
                                        />
                                        : ""
                                    }
                                </Table.Cell> */}
                <Table.Cell>
                  {/* <Button
                    basic
                    color="green"
                    content={data.status}
                    floated="center"
                    style={{ marginBottom: "14px" }}
                    onClick={() => onEditActive(data)}
                  /> */}
                  {data.status}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Wrap>
      )}

      <ModalBasic open={showPopup} size="large" close={() => onClose()}>
        <FormActive
          initialValues={currentData}
          onClose={() => onClose()}
          onSubmit={updateData}
        />
      </ModalBasic>

      <ModalBasic
        open={showPopupDefective}
        size="small"
        close={() => onCloseDefective()}
      >
        <Header
          as="h2"
          textAlign="left"
          floated="right"
          onClick={onCloseDefective}
          style={{ cursor: "pointer" }}
        >
          X
        </Header>
        <br />
        <br />
        <br />
        <br />
        <Grid>
          <Grid.Row columns="3">
            <Grid.Column>
              Date: <b>{data.date}</b>
            </Grid.Column>
            <Grid.Column>
              Status: <b>{data.damage_status}</b>
            </Grid.Column>
            <Grid.Column>
              Creator: <b>{data.creator}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              Detail: <b>{data.damage_detail}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {/* <Image
                                width={'25%'}
                                verticalAlign="middle"
                                src={
                                    data.imageURL ?
                                        data.imageURL + "?time=" + new Date()
                                        : '../storage/imgs/dfkid.png'
                                }
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                        '../storage/imgs/dfkid.png';
                                }}
                            /> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ModalBasic>
    </Wrap>
  );
};

export default Stock;
