import React, { useState, useEffect } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { Menu, Image, Icon, Accordion, List } from "semantic-ui-react";
import * as config from "../config";

const Menulist = (props) => {
  const [userData, setUserData] = useState(props.userData);
  const [role, setRole] = useState(
    JSON.parse(sessionStorage.getItem("kidz_roles"))
  );
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );
  const pathname =
    props.history && props.history.location
      ? props.history.location.pathname
      : window.location.pathname;
  const [openAccordion, setOpenAccordion] = useState(false);
  const [openApplication, setOpenApplication] = useState(false);
  const [openLibrary, setOpenLibrary] = useState(false);
  const [openAsset, setOpenAsset] = useState(false);

  useEffect(() => {
    if (pathname.indexOf("management") > 0) setOpenAccordion(true);
    if (pathname.indexOf("application") > 0) setOpenApplication(true);
    if (pathname.indexOf("library") > 0) setOpenLibrary(true);
    if (pathname.indexOf("Asset") > 0) setOpenAsset(true);
  }, []);

  const checkPermissionGroup = (group) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    if (userpermissionGroup) return true;
    else return false;
  };

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  return (
    <div style={{ background: config.colorMenu }}>
      <Image
        circular
        style={{ maxHeight: "100px" }}
        centered
        spaced
        size="tiny"
        src={
          userData && userData.imageURL
            ? userData.imageURL
            : "../storage/imgs/dfusers.png"
        }
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "../storage/imgs/dfusers.png";
        }}
      />
      <h4 style={{ marginTop: "10px", marginBottom: "10px" }} className="bold">
        {userData.firstName} {userData.lastName}{" "}
      </h4>
      <h5 style={{ marginTop: "0px", marginBottom: "0px" }} className="bold">
        {userData.employee_type}
      </h5>
      <h5 style={{ marginTop: "0px", marginBottom: "0px" }} className="bold">
        {role && role.toString()}
      </h5>
      <br />
      <br />
      <Menu
        secondary
        fluid
        vertical
        style={{ textAlign: "left", fontSize: "99%" }}
      >
        <Menu.Item
          className="subMenu"
          as={NavLink}
          to="/dashboard"
          activeClassName="active"
          exact
          active={
            pathname.indexOf("/") > 0 || pathname.indexOf("dashboard") > 0
          }
        >
          Dashboard
        </Menu.Item>

        {checkPermissionMenu("Attendance", ["class-attendance"]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/classAttendance"
            activeClassName="active"
            exact
            active={pathname.indexOf("classAttendance") > 0}
          >
            Class Attendance
          </Menu.Item>
        )}

        {/*
                <Menu.Item
                 className="subMenu"
                 as={NavLink}
                 to="/classattendance"
                 activeClassName="active"
                 exact
               >
                 Class Attendance
               </Menu.Item>*/}
        {checkPermissionMenu("Personnel", [
          "manage-student",
          "view-student",
        ]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/student"
            activeClassName="active"
            exact
            active={
              pathname.indexOf("student") > 0 ||
              pathname.indexOf("student/profile") > 0
            }
          >
            Student
          </Menu.Item>
        )}

        {checkPermissionMenu("Personnel", ["import-student"]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/import"
            activeClassName="active"
            exact
            active={pathname.indexOf("import") > 0}
          >
            Import Students
          </Menu.Item>
        )}

        {/* {checkPermissionMenu("Personnel", [
          "register-student",
        ]) &&
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/register"
            activeClassName="active"
            exact
            active={
              pathname.indexOf("import") > 0
            }
          >
            Register
          </Menu.Item>
        } */}
        {checkPermissionMenu("Personnel", ["manage-parent", "view-parent"]) && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/parent"
            activeClassName="active"
            exact
            active={
              pathname.indexOf("parent") > 0 ||
              pathname.indexOf("parent/profile") > 0
            }
          >
            Guardian
          </Menu.Item>
        )}
        {checkPermissionGroup("Report") && (
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/report"
            activeClassName="active"
            exact
            active={pathname.indexOf("report") > 0}
          >
            Report
          </Menu.Item>
        )}

        <Accordion>
          <Accordion.Title
            active={openAccordion}
            index={0}
            onClick={() => setOpenAccordion(!openAccordion)}
          >
            <Menu.Item
              className="subMenu"
              style={{ margin: 0 }}
              as={NavLink}
              to="/management"
              activeClassName="active"
              exact
              // active={
              //   pathname.indexOf("management") > 0
              // }
            >
              Management
            </Menu.Item>
          </Accordion.Title>
          <Accordion.Content active={openAccordion} style={{ padding: 0 }}>
            <List>
              {checkPermissionMenu("Academic", [
                "manage-course",
                "manage-registration",
                "manage-class",
              ]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/course"
                  active={
                    pathname.indexOf("management/course") > 0 ||
                    pathname.indexOf("course/profile") > 0
                  }
                >
                  Course
                </List.Item>
              )}
              {checkPermissionMenu("Academic", ["manage-course"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/term"
                  active={pathname.indexOf("management/term") > 0}
                >
                  Term
                </List.Item>
              )}
              {checkPermissionMenu("Personnel", [
                "manage-teacher",
                "view-teacher",
              ]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/teacher"
                  active={
                    pathname.indexOf("management/teacher") > 0 ||
                    pathname.indexOf("teacher/profile") > 0
                  }
                >
                  Teacher/TA
                </List.Item>
              )}
              {checkPermissionMenu("Personnel", [
                "manage-staff",
                "view-staff",
              ]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/staff"
                  active={
                    pathname.indexOf("management/staff") > 0 ||
                    pathname.indexOf("staff/profile") > 0
                  }
                >
                  Staff/Nanny
                </List.Item>
              )}
              {checkPermissionMenu("Management", ["manage-user"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/user"
                  active={pathname.indexOf("management/user") > 0}
                >
                  User
                </List.Item>
              )}
              {checkPermissionMenu("Management", ["manage-role"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/management/role"
                  active={pathname.indexOf("management/role") > 0}
                >
                  Role & Permission
                </List.Item>
              )}
            </List>
          </Accordion.Content>
        </Accordion>

        <Accordion>
          <Accordion.Title
            active={openApplication}
            index={0}
            onClick={() => setOpenApplication(!openApplication)}
          >
            <Menu.Item
              className="subMenu"
              style={{ margin: 0 }}
              as={NavLink}
              to="/application"
              activeClassName="active"
              exact
            >
              Application
            </Menu.Item>
          </Accordion.Title>
          <Accordion.Content active={openApplication} style={{ padding: 0 }}>
            <List>
              {checkPermissionMenu("Application", ["visit-form"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/application/visit"
                  active={pathname.indexOf("application/visit") > 0}
                >
                  Visit Form
                </List.Item>
              )}
              {checkPermissionMenu("Application", ["application-form"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/application/application"
                  active={pathname.indexOf("application/application") > 0}
                >
                  Application Form
                </List.Item>
              )}
              {checkPermissionMenu("Application", ["register-form"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/application/registation"
                  active={pathname.indexOf("application/registation") > 0}
                >
                  Register Form
                </List.Item>
              )}
            </List>
          </Accordion.Content>
        </Accordion>

        <Accordion>
          <Accordion.Title
            active={openLibrary}
            index={0}
            onClick={() => setOpenLibrary(!openLibrary)}
          >
            <Menu.Item
              className="subMenu"
              style={{ margin: 0 }}
              as={NavLink}
              to="/library"
              activeClassName="active"
              exact
            >
              Library
            </Menu.Item>
          </Accordion.Title>
          <Accordion.Content active={openLibrary} style={{ padding: 0 }}>
            <List>
              {checkPermissionMenu("Library", ["book"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/library/books"
                  active={pathname.indexOf("library/books") > 0}
                >
                  Book
                </List.Item>
              )}
              {checkPermissionMenu("Library", ["history"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/library/history"
                  active={pathname.indexOf("library/history") > 0}
                >
                  History
                </List.Item>
              )}
            </List>
          </Accordion.Content>
        </Accordion>

        <Accordion>
          <Accordion.Title
            active={openAsset}
            index={0}
            onClick={() => setOpenAsset(!openAsset)}
          >
            <Menu.Item
              className="subMenu"
              style={{ margin: 0 }}
              as={NavLink}
              to="/asset"
              activeClassName="active"
              exact
            >
              Asset
            </Menu.Item>
          </Accordion.Title>
          <Accordion.Content active={openAsset} style={{ padding: 0 }}>
            <List>
              {checkPermissionMenu("Asset", ["item"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/asset/items"
                  active={pathname.indexOf("asset/items") > 0}
                >
                  Item
                </List.Item>
              )}
              {checkPermissionMenu("Asset", ["item"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/asset/booking_item"
                  active={pathname.indexOf("asset/booking_item") > 0}
                >
                  Booking Item
                </List.Item>
              )}
              {checkPermissionMenu("Asset", ["asset-history"]) && (
                <List.Item
                  className="accordionMenu"
                  as={Link}
                  to="/asset/history"
                  active={pathname.indexOf("asset/history") > 0}
                >
                  History
                </List.Item>
              )}
            </List>
          </Accordion.Content>
        </Accordion>
      </Menu>
    </div>
  );
};
export default Menulist;
