import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
    Button,
    Icon,
    Segment,
    Table
} from 'semantic-ui-react';
import ModalBasic from "../../components/modal";
import Headerwithmenu from "../../components/headerwithmenu";
import TermForm from "./termForm";
import { getTerm, updateTerm, postTerm, deleteTerm } from './service';
import moment from "moment";
import Confirmmodal from "../../components/confirmmodal";




const Termlist = (props) => {
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [errormsg, setErrormsg] = useState(null)
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);


    useEffect(() => {
        setLoading(true);
        getData();
    }, [])

    const getData = () => {

        setLoading(true);
        getTerm().then(res => {
            if (res && res.status === 200) {
                setData(res.data);
            }
            setLoading(false)
        })
    }

    const onAddTerm = () => {
        setOpen(true);
    }


    const onEdit = (values) => {
        setOpen(true);
        setCurrentData(values)
    }

    const updateData = (values) => {
        setLoading(true);
        let data = values;
        if (values.id) {
            updateTerm(values).then(res => {
                if (res && res.status === 200) {
                    getData();
                    setOpen(false);
                }
                setCurrentData(null)
                setLoading(false);
            })
        } else {
            postTerm(values).then(res => {
                if (res && res.status === 201) {
                    getData();
                    setOpen(false);

                }
                setCurrentData(null)
                setLoading(false);
            })
        }
    }

    const onClickDelete = (values) => {
        setOpenConfirmDelete(true)
        setCurrentData(values)
    }

    const deleteData = () => {
        // let { currentData } = this.state;
        setLoading(true)
        if (currentData && currentData.id)
            deleteTerm(currentData.id).then(res => {
                if (res && res.status === 200) {
                    getData();
                    setOpenConfirmDelete(false)
                }
                setCurrentData(null)
                setLoading(false)
            });
    }

    const onClose = () => {
        setOpen(false);
        setCurrentData(null)
    }
    return (
        <Segment basic textAlign='left'>
            <Headerwithmenu name='Term' >
                <Button color='green'
                    content='Add Term' floated='right'
                    onClick={onAddTerm}
                />
            </Headerwithmenu>
            <Segment className="contentSegment" textAlign="left" style={{ padding: '30px' }}>
                <Table celled padded style={{ borderRadius: 0, fontSize: '95%' }} >
                    <Table.Header style={{
                        boxShadow: '0 0.5px 5px 0 rgb(0 0 0 / 15%)',
                        textAlign: 'center'
                    }}>
                        <Table.Row>
                            <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Name</Table.HeaderCell>
                            <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Period</Table.HeaderCell>
                            <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Description</Table.HeaderCell>
                            <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data.map(item =>
                            <Table.Row>
                                <Table.Cell >{item.name}</Table.Cell>
                                <Table.Cell width='4' ><p>{moment(item.start, 'DD-MM-YYYY').format('D MMM YYYY')} - {moment(item.end, 'DD-MM-YYYY').format('D MMM YYYY')}</p></Table.Cell>
                                <Table.Cell >{item.desc}</Table.Cell>
                                <Table.Cell width='2' textAlign='center'>
                                    <Icon
                                        link
                                        name="edit"
                                        onClick={() => onEdit(item)}
                                    />
                                    &nbsp; &nbsp;
                                    {item.numOfClass === 0 && <Icon
                                        link
                                        color="red"
                                        name="trash alternate"
                                        onClick={() => onClickDelete(item)}
                                    />
                                    }

                                </Table.Cell>
                            </Table.Row>
                        )}

                    </Table.Body>
                </Table>
            </Segment>
            <ModalBasic open={open} content='Term' close={() => onClose()}>
                <TermForm onSubmit={updateData} onClose={() => onClose()}
                    loading={loading}
                    errormsg={errormsg}
                    initialValues={currentData}
                />
            </ModalBasic>
            <Confirmmodal
                loading={loading}
                open={openConfirmDelete}
                title="Term"
                onClose={() => setOpenConfirmDelete(false)}
                onComfirm={deleteData}
            />
        </Segment>
    );
}
export default withRouter(Termlist);
