import FormAlert from "./formalert";
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import * as config from '../config'
import {
    Header,
    Image,
    Segment,
    Grid,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Button,
    Icon,
    Form,
    Label,
    FormGroup,
    Card
} from 'semantic-ui-react';
import ModalBasic from "../components/modal";

import Wrap from "../components/wrap";
import { getApplication } from "./service";



const AlertLibrary = (props) => {
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({ class: '', course: '', status: 'Active', type: "All" });
    const [per_page, setPerPage] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [AfterForm, setAfterForm] = useState([]);


    useEffect(() => {
        setLoading(true);
        getData();
    }, [per_page, current_page, values])


    const getData = () => {
        getApplication().then(res => {
            if (res && res.status === 200) {
                // console.log(res.data);
                setAfterForm(res.data)
            }
            setLoading(false)
        })
    }


    return (
        <Wrap>
            <Header as='h2' >Library</Header>
            <Card.Group itemsPerRow="3" style={{ padding: "20px" }}>
                <Card
                    raised
                    style={{ background: "#ffffff" }}
                >
                    <Header as="h5" style={{ textAlign: "Left", paddingLeft: "20px", paddingTop: "20px" }}>
                        New Booking
                    </Header>

                    <Grid columns='4'>
                        <Grid.Column style={{ paddingLeft: "40px", color: '#aaa' }}>
                            Apply date<br></br>
                        </Grid.Column>
                        <Grid.Column style={{ color: '#aaa' }}>
                            Guardian<br></br>
                        </Grid.Column>
                        <Grid.Column style={{ color: '#aaa' }}>
                            Phone<br></br>
                        </Grid.Column>
                        <Grid.Column style={{ color: '#aaa' }}>
                            view<br></br>
                        </Grid.Column>
                    </Grid>
                </Card>

                <Card
                    raised
                    style={{ background: "#ffffff" }}
                >
                    <Header as="h5" style={{ textAlign: "Left", paddingLeft: "20px", paddingTop: "20px" }}>
                        <p style={{ color: "red" }}>Return Overdue</p>
                    </Header>
                    <Grid columns='4'>
                        <Grid.Column style={{ paddingLeft: "40px", color: '#aaa' }}>
                            Apply date<br></br>
                        </Grid.Column>
                        <Grid.Column style={{ color: '#aaa' }}>
                            Guardian<br></br>
                        </Grid.Column>
                        <Grid.Column style={{ color: '#aaa' }}>
                            Phone<br></br>
                        </Grid.Column>
                        <Grid.Column style={{ color: '#aaa' }}>
                            view<br></br>
                        </Grid.Column>
                    </Grid>
                </Card>

            </Card.Group>

        </Wrap>
    );
}
export default withRouter(AlertLibrary);
