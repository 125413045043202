import React, { useState, useEffect } from "react";
import * as config from '../../config';

import {
  Header,
  Icon,
  Segment,
  Card
} from 'semantic-ui-react';

const Mainapplicationmenu = (props) => {

  const [role, setRole] = useState(JSON.parse(sessionStorage.getItem("kidz_roles")));
  const [allPermistion, setAllPermistion] = useState(JSON.parse(sessionStorage.getItem("kidz_permission")));


  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (userpermissionGroup && userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  return (
    <Segment basic textAlign='left'>
      <Header as='h2' >Application</Header>
      <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>

        {checkPermissionMenu("Application", [
          "visit-form",
        ]) &&
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/application/visit")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="university"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Visit Form
              </Header>
            </Card.Content>
          </Card>
        }
        {checkPermissionMenu("Application", [
          "application-form",
        ]) &&
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/application/application")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="address book outline"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Application Form
              </Header>
            </Card.Content>
          </Card>
        }
        {checkPermissionMenu("Application", [
          "register-form",
        ]) &&
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/application/registation")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="user circle outline"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Registration Form
              </Header>
            </Card.Content>
          </Card>
        }
      </Card.Group>
      <br />
    </Segment>
  );
}
export default Mainapplicationmenu;
