import React, { useState, useEffect } from "react";
import * as config from "../../config";
import { Header, Icon, Segment, Card } from "semantic-ui-react";

const Mainmenu = (props) => {
  const [role, setRole] = useState(
    JSON.parse(sessionStorage.getItem("kidz_roles"))
  );
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Report</Header>
      <Segment basic className="contentSegment" textAlign="left">
        <Header as="h3">School Attendance</Header>
        <Card.Group itemsPerRow="3" doubling style={{ padding: "20px" }}>
          {checkPermissionMenu("Report", ["attendance-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/attendance")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="users"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  School Attendance Report
                </Header>
              </Card.Content>
            </Card>
          )}
          {checkPermissionMenu("Report", ["attendance-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/classattendance")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="users"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  Class Attendance Report
                </Header>
              </Card.Content>
            </Card>
          )}
          {checkPermissionMenu("Report", ["absent-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/absent")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="user x"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  Absent Report
                </Header>
              </Card.Content>
            </Card>
          )}
        </Card.Group>

        <Header as="h3">School Visit</Header>
        <Card.Group itemsPerRow="3" doubling style={{ padding: "20px" }}>
          {checkPermissionMenu("Report", ["visit-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/visit")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="university"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  Visit Report
                </Header>
              </Card.Content>
            </Card>
          )}
          {checkPermissionMenu("Report", ["application-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/application")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="address book outline"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  Application Report
                </Header>
              </Card.Content>
            </Card>
          )}
          {checkPermissionMenu("Report", ["registation-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/registation")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="user circle outline"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  Registration Report
                </Header>
              </Card.Content>
            </Card>
          )}
          {checkPermissionMenu("Report", ["consent-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/consent")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="check circle outline"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  Consent Report
                </Header>
              </Card.Content>
            </Card>
          )}
        </Card.Group>
        <Header as="h3">Kitchen</Header>
        <Card.Group itemsPerRow="3" doubling style={{ padding: "20px" }}>
          {checkPermissionMenu("Report", ["kitchen-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/kitchen")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="food"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  Kitchen Report
                </Header>
              </Card.Content>
            </Card>
          )}
        </Card.Group>
        <Header as="h3">Logs</Header>
        <Card.Group itemsPerRow="3" doubling style={{ padding: "20px" }}>
          {checkPermissionMenu("Report", ["login-report"]) && (
            <Card
              raised
              style={{ background: "#ffffff" }}
              onClick={() => props.history.push("/report/login")}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name="key"
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  Login Report
                </Header>
              </Card.Content>
            </Card>
          )}
        </Card.Group>
      </Segment>
    </Segment>
  );
};
export default Mainmenu;
