import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from '../../config';
import { connect } from "react-redux";
import {
    Header,
    Image,
    Segment,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Icon,
    Label,
    FormGroup
} from 'semantic-ui-react';
import renderDateField from "../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";

let FormEditSibling = (props) => {
    // const Id = props.location.search.split("=");

    const { loading, pristine, submitting, onSubmit, handleSubmit, initialValue, onClose, errormsg, start_card, end_card, studentId } = props;

    return (

        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <br></br><br></br>
                    <Field
                        width="3"
                        name="name1"
                        type="text"
                        required={false}
                        component={renderField}
                        label="Name"
                    />
                    <Field
                        width="1"
                        name="age1"
                        type="number"
                        required={false}
                        component={renderField}
                        label="Age"
                    />
                </Form.Group>
                <Form.Group>
                    <br></br><br></br>
                    <Field
                        width="3"
                        name="name2"
                        type="text"
                        required={false}
                        component={renderField}
                        label="Name"
                    />
                    <Field
                        width="1"
                        name="age2"
                        type="number"
                        required={false}
                        component={renderField}
                        label="Age"
                    />
                </Form.Group>
                <Form.Group>
                    <br></br><br></br>
                    <Field
                        width="3"
                        name="name3"
                        type="text"
                        required={false}
                        component={renderField}
                        label="Name"
                    />
                    <Field
                        width="1"
                        name="age3"
                        type="number"
                        required={false}
                        component={renderField}
                        label="Age"
                    />
                </Form.Group>
                <Form.Group>
                    <br></br><br></br>
                    <Field
                        width="3"
                        name="name4"
                        type="text"
                        required={false}
                        component={renderField}
                        label="Name"
                    />
                    <Field
                        width="1"
                        name="age4"
                        type="number"
                        required={false}
                        component={renderField}
                        label="Age"
                    />
                </Form.Group>
                <br></br>
                <Button floated='left' content='Save'
                    style={{ backgroundColor: config.colorTheme, color: "#ffff", minWidth: '5%' }}
                />
                <br></br><br></br>
            </Form>
        </>


    );

}




FormEditSibling = reduxForm({
    // a unique name for the form
    form: "FormStudentDetail2",
    enableReinitialize: true,
})(FormEditSibling);

const selector = formValueSelector("class");
FormEditSibling = connect(state => {
    const start_card = selector(state, "start_card");
    const end_card = selector(state, "end_card");
    return {
        start_card: start_card,
        end_card: end_card
    };
})(FormEditSibling);

export default withRouter(FormEditSibling);



