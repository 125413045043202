import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
  Form,
} from "semantic-ui-react";
import {
  getStudentProfile,
  updateStudent,
  postGuardian,
  removeGuardian,
  getParent,
  updateGuardian,
  updateImageProfile,
  updateQR, //Todo
  // getAuthenImage,
  // getAuthenImageGaurdian,
} from "./service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import ParentForm from "./parentform";
import StudentForm from "./form";
import Confirmmodal from "../../components/confirmmodal";
import TableClassAttendance from "./classattendance";
import TableAttendance from "./attendance";
import HealthCondition from "./healthcondition";
import StudentDocument from "./studentdocument";

// import QRCode from "qrcode.react";

const StudentProfile = (props) => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  const [showPopupImage, setShowPopupImage] = useState(false);
  const [showPopupQR, setShowPopupQR] = useState(false); //Todo
  const [student, setStudent] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState(null);
  const [parentOption, setParentOption] = useState([]);
  const [errormsg, setErrormsg] = useState(null);
  const [lastpage, setLastpage] = useState(
    props && props.location.state && props.location.state.page
  );
  const [photo, setPhoto] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  const timeoutRef = useRef(null);
  // const [authimgurl, setImage] = useState(null); //authen image view
  // const [authimgurlGuardian, setImageGuardian] = useState([]); //authen image view for Guardian

  const [role, setRole] = useState(
    JSON.parse(sessionStorage.getItem("kidz_roles"))
  );
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  useEffect(() => {
    if (props.location && !props.location.state) props.history.push("/student");

    setLoadingPage(true);
    if (student) {
      getData();
    }
  }, [student]);

  const getData = () => {
    setLoadingPage(true);
    getStudentProfile(student).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        // console.log(`getStudentProfile`, res.data); //Test

        //Cancel: Move to use inside getStudentProfile query --------------------
        // //Todo: get auth guardian's image
        // let option = [];
        // if (res.data.guardians.length > 0) {
        //   const result = res.data.guardians.map((e) => {
        //     getAuthenImageGaurdian(e.guardian_id).then((res) => {
        //       if (res && res.status === 200) {
        //         e["guardian"]["g_auth_url"] = res.data;
        //         option.push(e);

        //         // console.log(`Guardian url response=`, res.data); //Test
        //       } else {
        //         console.log(`Error get auth guardian's image`, e.guardian_id);
        //       }
        //     });
        //   });
        // }
        //---------------------------------------------------------------------
      }

      setLoadingPage(false);
    });

    //Cancel: Move to use inside getStudentProfile query --------------------
    // //Todo: get auth image view for student
    // getAuthenImage(student).then((res) => {
    //   if (res && res.status === 200) {
    //     // setData(res.data);
    //     // console.log(res.data); //Test
    //     setImage(res.data);
    //   }
    // });
    //---------------------------------------------------------------------
  };
  const onSearchQuery = ({ name, searchQuery }) => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      setLoading(true);
      getParent("?" + name + "=" + searchQuery + "&status=Active").then(
        (res) => {
          if (res && res.status === 200) {
            let option = [];
            res.data.map((item) => {
              item["key"] = item["value"] = item.idnumber;
              item[
                "text"
              ] = `${item.idnumber} ${item.firstName} ${item.lastName}`;
              option.push(item);
            });

            setParentOption(option);
          }
          setLoading(false);
        }
      );
    }, 1500);
  };
  const updateProfile = (values) => {
    setErrormsg(null);
    setLoading(true);
    updateStudent(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopupProfile(false);
      } else if (res && res.status === 409) {
        console.log(`Duplicate`, res);
        // setErrormsg(
        //   "Duplicated student. Please check [ID No.] or [firstName] or [lastName]"
        // );
        setErrormsg("Duplicated student, already registered!!");
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };
  const updateImage = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageProfile(formData, data.id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopupImage(false);
      }
      setLoading(false);
    });
  };
  const insertParent = (value) => {
    setErrormsg(null);
    setLoading(true);
    value["student_id"] = data.id;
    postGuardian(value).then((res) => {
      if (res && res.status === 201) {
        getData();
        setShowPopup(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };
  const updateParent = (value) => {
    setErrormsg(null);
    setLoading(true);
    value["student_id"] = data.id;
    value["guardian_id"] = value.id;
    value["relationship"] = value.relationship;
    updateGuardian(value).then((res) => {
      if (res && res.status === 201) {
        getData();
        setShowPopup(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  const onClickDelete = (studentId, guardianId) => {
    setOpenConfirmDelete(true);
    setCurrentData({ studentId: studentId, guardianId: guardianId });
  };
  const deleteData = () => {
    setLoading(true);
    if (currentData && currentData.studentId)
      removeGuardian(currentData.studentId, currentData.guardianId).then(
        (res) => {
          if (res && res.status === 200) {
            getData();
            setOpenConfirmDelete(false);
          }
          setLoading(false);
        }
      );
  };
  const onEditProfile = () => {
    // Original
    // setData({
    //   ...data,
    //   bloodType: data["health"]["bloodType"],
    //   healthConditions: data["health"]["healthConditions"],
    //   race: data["health"]["race"],
    //   allergy: data["health"]["allergy"],
    //   reaction: data["health"]["reaction"],
    //   treatment: data["health"]["treatment"],
    //   hospital_name: data["health"]["hospital_name"],
    //   doctor_name: data["health"]["doctor_name"],
    //   doctor_phone: data["health"]["doctor_phone"],
    //   //Add data
    //   medication: data["health"]["medication"],
    //   food_allergy: data["health"]["food_allergy"],
    //   h_n_no: data["health"]["h_n_no"],
    // });

    // Modify: Veify null
    setData({
      ...data,
      bloodType: data["health"] != null ? data["health"]["bloodType"] : "",
      healthConditions:
        data["health"] != null ? data["health"]["healthConditions"] : "",
      race: data["health"] != null ? data["health"]["race"] : "",
      allergy: data["health"] != null ? data["health"]["allergy"] : "",
      reaction: data["health"] != null ? data["health"]["reaction"] : "",
      treatment: data["health"] != null ? data["health"]["treatment"] : "",
      hospital_name:
        data["health"] != null ? data["health"]["hospital_name"] : "",
      doctor_name: data["health"] != null ? data["health"]["doctor_name"] : "",
      doctor_phone:
        data["health"] != null ? data["health"]["doctor_phone"] : "",
      //Add data
      medication: data["health"] != null ? data["health"]["medication"] : "",
      food_allergy:
        data["health"] != null ? data["health"]["food_allergy"] : "",
      h_n_no: data["health"] != null ? data["health"]["h_n_no"] : "",
    });

    setShowPopupProfile(true);
  };

  //Todo for test
  const onTestQR = () => {
    setLoading(true);

    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateQR(formData, data.id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopupQR(false);
      }
      console.log(res.data.message);
      setLoading(false);
    });

    console.log(data.id);
  };

  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">Student Profile</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              {lastpage === "student" ? (
                <Breadcrumb.Section
                  onClick={() => props.history.push("/student")}
                  style={{ color: "grey" }}
                >
                  Student
                </Breadcrumb.Section>
              ) : (
                <Breadcrumb.Section
                  onClick={() => props.history.push("/dashboard")}
                  style={{ color: "grey" }}
                >
                  Dashboard
                </Breadcrumb.Section>
              )}
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Student Profile</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column>
            {checkPermissionMenu("Personnel", ["manage-student"]) && (
              <Button
                basic
                color="green"
                content="Upload Image"
                floated="right"
                style={{ marginBottom: "14px" }}
                onClick={() => setShowPopupImage(true)}
              />
            )}
            {checkPermissionMenu("Personnel", ["manage-student"]) && (
              <Button
                basic
                color="green"
                content="Edit profile"
                floated="right"
                style={{ marginBottom: "14px" }}
                onClick={onEditProfile}
              />
            )}
            {/* {checkPermissionMenu("Personnel", ["manage-student"]) && (
              <Button
                basic
                color="green"
                content="Test QR"
                floated="right"
                style={{ marginBottom: "14px" }}
                onClick={() => setShowPopupQR(true)}
              />
            )} */}
          </Grid.Column>
        </Grid>
        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "100vh", padding: "20px" }}
        >
          {loadingPage ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Wrap>
              <Grid columns="2" doubling>
                <Grid.Column width="4">
                  <Image
                    verticalAlign="middle"
                    src={
                      data && data.imageURL
                        ? data.imageURL + "?time=" + new Date()
                        : "../storage/imgs/dfkid.png"
                    }
                    // src={
                    //   data && data.authimgurl
                    //     ? data.authimgurl
                    //     : "../storage/imgs/dfkid.png"
                    // }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/dfkid.png";
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  columns="2"
                  width="12"
                  style={{ minHeight: "30vh" }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h2" style={{ color: config.colorTheme2 }}>
                          <Header.Content>
                            {data.studentID} {data.title && data.title}{" "}
                            {data.firstName && data.firstName}&nbsp;&nbsp;
                            {data.lastName && data.lastName}
                            <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.title_th && data.title_th}{" "}
                              {data.firstName_th && data.firstName_th}
                              &nbsp;&nbsp;{data.lastName_th && data.lastName_th}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2">
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Child's name (nick name)
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.nickname}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Gender
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.gender}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Date of birth
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.dateOfBirth}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Age
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {moment().diff(
                              moment(data.dateOfBirth, "DD-MM-YYYY"),
                              "years"
                            )}{" "}
                            Year old
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            E-mail
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.email}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Emergency contact no.
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.emergency_name}
                            <br />
                            {data.emergency_phone}
                            <br />{" "}
                            {data.emergency_relation &&
                              ` (${data.emergency_relation})`}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Passport or ID No.
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.idnumber}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Student Type
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.studenttype}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Status
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.status}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Start Date
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.start_card}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            End Date
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.end_card}
                          </Grid.Column>
                          {/* <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>Course</Grid.Column>
                          <Grid.Column>{data.regist_course && data.regist_course.map(item =>
                            <p>{item.course_name} ({item.class_name})<br />
                              {item.teacher}<br />
                              <div  >{item.term_name}</div> </p>
                          )}</Grid.Column> */}
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column
                        width="4"
                        style={{ paddingBottom: "20px" }}
                        className="bold"
                      >
                        Course
                      </Grid.Column>
                      <Grid.Column width="12">
                        {data.regist_course &&
                          data.regist_course.map((item) => (
                            <p>
                              {item.course_name} : {item.class_name} :{" "}
                              {item.teacher}
                              <br />
                              {item.term_name}
                            </p>
                          ))}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
              <br />
              <br />
              <Tab
                menu={{ color: "brown", secondary: true, pointing: true }}
                renderActiveOnly={true}
                panes={[
                  {
                    menuItem: "Parent",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        {checkPermissionMenu("Personnel", ["manage-student"]) &&
                          data &&
                          data.guardians &&
                          data.guardians.length < 4 && (
                            <Button
                              basic
                              color="green"
                              content="Add Guardian"
                              floated="right"
                              style={{ marginBottom: "14px" }}
                              onClick={() => setShowPopup(true)}
                            />
                          )}

                        {/* {data && data.guardians && data.guardians.length < 3
                        && <Button basic color='green'
                          content='Add Parent' floated='right' style={{ marginBottom: '14px' }}
                          onClick={() => setShowPopup(true)} />
                      } */}

                        <Table singleLine>
                          <Table.Body>
                            {/* Todonow */}
                            {data &&
                              data.guardians &&
                              data.guardians.map((item) => (
                                <Table.Row>
                                  <Table.Cell width="4" textAlign="center">
                                    <Image
                                      spaced
                                      verticalAlign="middle"
                                      style={{
                                        maxHeight: "100px",
                                        width: "auto",
                                      }}
                                      src={
                                        item.guardian.imageURL
                                          ? item.guardian.imageURL +
                                            "?time=" +
                                            new Date()
                                          : "../storage/imgs/dfparent.png"
                                      }
                                      // src={
                                      //   item.guardian.g_auth_url
                                      //     ? item.guardian.g_auth_url
                                      //     : "../storage/imgs/dfparent.png"
                                      // }
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "../storage/imgs/dfparent.png";
                                      }}
                                    />
                                  </Table.Cell>
                                  <Table.Cell
                                    verticalAlign="middle"
                                    textAlign="left"
                                  >
                                    <p
                                      style={{
                                        color: config.colorTheme2,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Name
                                    </p>
                                    <p>
                                      {item.guardian.firstName &&
                                        item.guardian.firstName}
                                      &nbsp;&nbsp;
                                      {item.guardian.lastName &&
                                        item.guardian.lastName}
                                    </p>
                                  </Table.Cell>
                                  <Table.Cell
                                    verticalAlign="middle"
                                    textAlign="left"
                                  >
                                    <p
                                      style={{
                                        color: config.colorTheme2,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Relationship
                                    </p>
                                    <p>{item.relationship}</p>
                                  </Table.Cell>
                                  <Table.Cell
                                    verticalAlign="middle"
                                    textAlign="left"
                                  >
                                    <p
                                      style={{
                                        color: config.colorTheme2,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Phone no.
                                    </p>
                                    <p>{item.guardian.phone || "-"}</p>
                                  </Table.Cell>

                                  <Table.Cell>
                                    <Wrap>
                                      {checkPermissionMenu("Personnel", [
                                        "manage-parent",
                                      ]) &&
                                        data &&
                                        data.guardians &&
                                        data.guardians.length <= 4 && (
                                          <Icon
                                            link
                                            bordered
                                            color="red"
                                            name="trash"
                                            onClick={() =>
                                              onClickDelete(
                                                data.id,
                                                item.guardian.id
                                              )
                                            }
                                          />
                                        )}
                                      {checkPermissionMenu("Personnel", [
                                        "view-parent",
                                      ]) &&
                                        data &&
                                        data.guardians &&
                                        data.guardians.length <= 4 && (
                                          <Icon
                                            link
                                            bordered
                                            // color="green"
                                            style={{
                                              color: config.colorTheme,
                                              boxShadow:
                                                "0 0 0 0.1em " +
                                                config.colorTheme +
                                                " inset",
                                            }}
                                            name="user"
                                            onClick={() =>
                                              props.history.push({
                                                pathname: "/parent/profile",
                                                state: {
                                                  id: item.guardian.id,
                                                  page: "parent",
                                                },
                                              })
                                            }
                                          />
                                        )}
                                    </Wrap>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                          </Table.Body>
                        </Table>
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "School Attendance",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        <TableAttendance studentId={data.id} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "Class Attendance",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        <TableClassAttendance studentId={data.id} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "Health Conditions",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        <HealthCondition studentId={data.id} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "Document",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        <StudentDocument studentId={data.id} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "QR Code",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        {data && data.secret_key && (
                          <div>
                            <Image
                              centered
                              bordered
                              src={config.urlAPI + "/card/" + data.id}
                            />
                            {/* <Image
                            centered
                            bordered
                            src={"https://kidz.loxleyorbit.com:8080/api/v1/web/card/" + data.id} /> */}
                            {/* <QRCode
                            id={data.id}
                            value={'?student=' + data.secret_key}
                            size={290}
                            level={"H"}
                            includeMargin={true}
                          /> */}
                            <br />
                          </div>
                        )}
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Wrap>
          )}
        </Segment>
      </Segment>
      <ModalBasic
        open={showPopup}
        content="Add Guardian"
        closeIcon
        close={() => setShowPopup(false)}
      >
        <ParentForm
          parentOption={parentOption}
          onSubmit={insertParent}
          onSelectParent={updateParent}
          onClose={() => setShowPopup(false)}
          onSearchQuery={onSearchQuery}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic>
      <ModalBasic
        size="large"
        open={showPopupProfile}
        content="Student Profile"
        close={() => setShowPopupProfile(false)}
      >
        <StudentForm
          onSubmit={updateProfile}
          onClose={() => setShowPopupProfile(false)}
          loading={loading}
          initialValues={data}
          errormsg={errormsg}
        />
      </ModalBasic>
      <ModalBasic
        closeIcon={true}
        open={showPopupImage}
        content="Profile Image"
        close={() => setShowPopupImage(false)}
      >
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              {data && data.imageURL && (
                <Image
                  size="medium"
                  name="photo"
                  src={data.imageURL}
                  centered
                />
              )}
              {/* {data && data.authimgurl && (
                <Image
                  size="medium"
                  name="photo"
                  src={data.authimgurl}
                  centered
                />
              )} */}
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Form.Input
                type="file"
                name="photo"
                onChange={(e) => setPhoto(e.target.files)}
              />
              <br />
              <br />
              <Button
                floated="left"
                content="Upload"
                fluid
                style={{
                  backgroundColor: config.colorTheme,
                  color: "#ffffff",
                  minWidth: "30%",
                }}
                loading={loading}
                onClick={updateImage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </ModalBasic>
      {/* Todo for Test*/}
      <ModalBasic
        closeIcon={true}
        open={showPopupQR}
        content="QR Image"
        close={() => setShowPopupQR(false)}
      >
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              {data && data.imageURL && (
                <Image
                  size="medium"
                  name="photo"
                  src={data.imageURL}
                  centered
                />
              )}
              {/* {data && data.authimgurl && (
                <Image
                  size="medium"
                  name="photo"
                  src={data.authimgurl}
                  centered
                />
              )} */}
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Form.Input
                type="file"
                name="photo"
                onChange={(e) => setPhoto(e.target.files)}
              />
              <br />
              <br />
              <Button
                floated="left"
                content="Upload QR"
                fluid
                style={{
                  backgroundColor: config.colorTheme,
                  color: "#ffffff",
                  minWidth: "30%",
                }}
                loading={loading}
                onClick={onTestQR}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </ModalBasic>
      <Confirmmodal
        loading={loading}
        open={openConfirmDelete}
        title="Guardian"
        onClose={() => setOpenConfirmDelete(false)}
        onComfirm={deleteData}
      />
    </Wrap>
  );
};

export default withRouter(StudentProfile);
