import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderDateField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import * as config from '../../config';
import { connect } from "react-redux";
import {
    Header,
    Image,
    Segment,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Icon,
    Label,
    FormGroup,
    Step
} from 'semantic-ui-react';
import ModalBasic from "../../components/modal";
import FormDocument from "./document/formdocument";
import Wrap from "../../components/wrap";
import { withRouter } from "react-router-dom";


let Document = (props) => {

    const [loading, setLoading] = useState(true);
    const [student, setStudent] = useState(props.studentId);
    // console.log(student);


    return (
        <Segment basic textAlign='left'>
                <FormDocument
                    loading={loading}
                    initialValues={student}
                />

        </Segment>
    );

}



export default withRouter(Document);

