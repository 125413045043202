import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import {
  Button,
  Form,
  Message,
  Grid,
  SegmentGroup,
  GridColumn,
} from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  // FormField, //Todo
} from "semantic-ui-react";
import { set } from "core-js/core/dict";

const validate = (values) => {
  const errors = {};
  //   if (!values.item_code) errors.item_code = true;
  //   if (!values.main_type) errors.main_type = true;
  //   if (!values.type) errors.type = true;
  //   if (!values.name_en) errors.name_en = true;
  //   if (!values.name_th) errors.name_th = true;
  //   if (!values.category) errors.category = true;
  //   if (!values.sub_category1) errors.sub_category1 = true;
  //   if (!values.sub_category2) errors.sub_category2 = true;
  //   if (!values.borrow_status) errors.borrow_status = true;
  //   if (!values.status) errors.status = true;
  return errors;
};

let FormManageStock = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    data,
  } = props;

  const [dataqty, setDataQty] = useState([]);
  const [total, setTotal] = useState(data.initial_stock);
  const [availableqty, setAvailableQty] = useState(data.qty);
  const [rejectqty, setRejectQty] = useState(
    data.reject_qty ? data.reject_qty : 0
  );
  const [repairqty, setRepairQty] = useState(
    data.defect_qty ? data.defect_qty : 0
  );
  const [addnew, setAddNew] = useState(0);
  const [addrepair, setAddRepair] = useState(0);

  // Todo
  const handleChangeQty = (qty) => {
    console.log(`handleChangeQty`, qty);
    console.log(`initialValue`, data);
    if (qty) {
      let caltotal = parseInt(data.initial_stock) + parseInt(qty);
      let calqty = parseInt(data.qty) + parseInt(qty) + parseInt(addrepair);
      setTotal(caltotal);
      setAvailableQty(calqty);
      setAddNew(qty);
    } else {
      let calqty = parseInt(data.qty) + parseInt(addrepair);
      setTotal(data.initial_stock);
      setAvailableQty(calqty);
      setAddNew(0);
    }
  };

  // Todo
  const handleChangeRepairQty = (qty) => {
    console.log(`handleChangeRepairQty`, qty);
    if (qty) {
      let calrepair =
        parseInt(data.defect_qty ? data.defect_qty : 0) - parseInt(qty);
      let calqty = parseInt(data.qty) + parseInt(addnew) + parseInt(qty);
      // //verify
      // if(calrepair < 0){

      // }
      setRepairQty(calrepair);
      setAvailableQty(calqty);
      setAddRepair(qty);
    } else {
      let calqty = parseInt(data.qty) + parseInt(addnew);
      setRepairQty(data.defect_qty ? data.defect_qty : 0);
      setAvailableQty(calqty);
      setAddRepair(0);
    }
  };

  return (
    <Segment basic textAlign="left">
      <Segment secondary>
        <Form>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column>
                <Form.Group unstackable>
                  <Field
                    width="6"
                    name="item_code"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Item code"
                    readOnly
                  />
                  <Field
                    width="6"
                    name="model"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Model"
                    readOnly
                  />
                  <Field
                    width="6"
                    name="brand"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Brand"
                    readOnly
                  />
                  <Field
                    width="6"
                    name="supplier"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Supplier"
                    readOnly
                  />
                  <Field
                    width="5"
                    name="initial_stock"
                    type="number"
                    required={false}
                    component={renderField}
                    label="Total Initial Qty"
                    readOnly
                  />
                  <Field
                    width="5"
                    name="unit"
                    type="text"
                    component={renderField}
                    label="Unit"
                    required={false}
                    readOnly
                  />
                </Form.Group>
                <FormGroup unstackable>
                  <Field
                    width="9"
                    name="name_en"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Item name (EN)"
                    readOnly
                  />
                  <Field
                    width="9"
                    name="name_th"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Item name (TH)"
                    readOnly
                  />
                </FormGroup>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
      {/* <Segment> */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid columns={2}>
          <Grid.Row>
            <GridColumn width={10}>
              {/* <Segment.Group horizontal> */}
              {/* <Grid divided stackable>
              <Grid.Row>
                <Grid.Column> */}
              <Segment color="green">
                <Form.Group unstackable>
                  <Field
                    width="13"
                    name="add_date"
                    type="date"
                    required={false}
                    component={renderField}
                    label="Add Date"
                  />
                  <Field
                    width="10"
                    min={0}
                    name="add_new"
                    type="number"
                    required={false}
                    component={renderField}
                    label="Add New Qty"
                    onChange={(e) => handleChangeQty(e.target.value)}
                  />
                  <Field
                    width="15"
                    name="invoice"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Invoice No."
                  />
                </Form.Group>

                <Form.Group unstackable>
                  <Field
                    width="13"
                    name="repair_date"
                    type="date"
                    required={false}
                    component={renderField}
                    label="Repair Date"
                  />
                  <Field
                    width="10"
                    min={0}
                    name="add_repair"
                    type="number"
                    required={false}
                    component={renderField}
                    label="Add Repair Qty"
                    onChange={(e) => handleChangeRepairQty(e.target.value)}
                  />
                  <Field
                    width="15"
                    name="doc"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Repair Document No."
                  />
                </Form.Group>
              </Segment>
            </GridColumn>
            <GridColumn width={6} floated="right">
              <Segment color="blue">
                <Grid columns={2} divided>
                  <Grid.Row>
                    <GridColumn align="center">
                      {/* <Grid.Column width={5}> */}
                      Total Initial Qty <br />
                      {total}
                      {/* </Grid.Column> */}
                    </GridColumn>
                    <Grid.Column align="center">
                      Remaining Qty <br />
                      {availableqty}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column align="center">
                      Reject Qty
                      <br /> {rejectqty}
                    </Grid.Column>
                    <Grid.Column align="center">
                      Repair Qty
                      <br /> {repairqty}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </GridColumn>
          </Grid.Row>
          {/* </Grid.Column>
              </Grid.Row>
            </Grid> */}
          {/* </Segment.Group> */}
        </Grid>
        <br />
        <div>
          <SecondButton
            floated="right"
            content="Cancel"
            color="grey"
            onClick={onClose}
          />
          <Button
            floated="left"
            content="Save"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
            }}
            loading={loading}
            disabled={pristine || submitting}
          />
        </div>
        <br />
      </Form>
      {/* </Segment> */}
    </Segment>
  );
};

FormManageStock = reduxForm({
  // a unique name for the form
  form: "managestock",
  validate,
  enableReinitialize: true,
})(FormManageStock);

export default FormManageStock;
