import React, { useState, useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import * as config from '../../config';
import moment from 'moment';
import { getSchoolAttendance } from "./service";
import Wrap from "../../components/wrap";
import TableList from "../../components/table";


let AttendanceTable = (props) => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [student, setStudent] = useState(props.studentId);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([
    {
      Header: "Date.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => moment(cellInfo.original.checkin_at).format('YYYY-MM-DD'),
    }, {
      Header: "Check In",
      accessor: "imageURL",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => cellInfo.original.checkin_at ? moment(cellInfo.original.checkin_at).format('HH:mm') : '-',
    }, {
      Header: "Check In Guardian",
      accessor: "checkin_by",
      sortable: false,
      Cell: (cellInfo) => cellInfo.original.checkin_by
        ? cellInfo.original.checkin_by
        : cellInfo.original.checkin_other &&
        <p>{cellInfo.original.checkin_other.name} ({cellInfo.original.checkin_other.relationship})<br />
          {cellInfo.original.checkin_other.phone}
          <br />
          {cellInfo.original.checkin_other.photo && <a href={cellInfo.original.checkin_other.photo} target="_blank">Click</a>}<br />
          {cellInfo.original.checkin_other.photo_other && <a href={cellInfo.original.checkin_other.photo_other} target="_blank">Click</a>}
        </p>,
    }, {
      Header: "Check Out",
      accessor: "checkout",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => cellInfo.original.checkout_at ? moment(cellInfo.original.checkout_at).format('HH:mm') : '-',
    }, {
      Header: "Check Out Guardian",
      accessor: "checkout_by",
      sortable: false,
      Cell: (cellInfo) => cellInfo.original.checkout_by
        ? cellInfo.original.checkout_by
        : cellInfo.original.checkout_other &&
        <p>{cellInfo.original.checkout_other.name} ({cellInfo.original.checkout_other.relationship})<br />
          {cellInfo.original.checkout_other.phone}<br />
          {cellInfo.original.checkout_other.photo && <a href={cellInfo.original.checkout_other.photo} target="_blank">Click</a>}<br />
          {cellInfo.original.checkout_other.photo_other && <a href={cellInfo.original.checkout_other.photo_other} target="_blank">Click</a>}
        </p>,

    },
    // {
    //   Header: "Parent",
    //   accessor: "name",
    //   sortable: false,
    //   width: 280,
    //   Cell: (cellInfo) => (
    //     <Wrap>
    //         {cellInfo.original.title} {cellInfo.original.firstName} {cellInfo.original.lastName}
    //     </Wrap>
    //   ),
    // },
    // {
    //   Header: "Class",
    //   accessor: "class",
    //   sortable: false,
    //   Cell: (cellInfo) => '-'
    // }, {
    //   Header: "Course",
    //   accessor: "course",
    //   sortable: false,
    //   Cell: (cellInfo) => '-'
    // }, {
    //   Header: "Note",
    //   accessor: "note",
    //   sortable: false
    // }
  ]);

  useEffect(() => {
    setLoadingPage(true);
    if (student) {
      getData();
    }
  }, [student]);

  const getData = () => {
    setLoadingPage(true);
    getSchoolAttendance(student).then(res => {
      if (res && res.status === 200) {
        // console.log(res.data);
        setData(res.data);
      }
      setLoadingPage(false);
    });
  };

  return (
    <Wrap>
      {loadingPage
        ? <Dimmer active inverted >
          <Loader inverted>Loading</Loader>
        </Dimmer>
        : <Wrap>
          <TableList
            data={data}
            columns={column}
          />
        </Wrap>
      }
    </Wrap>
  )
}


export default AttendanceTable;
