import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderDateTimeField4 from "../../components/renderDateTimeField4.js";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import ModalBasic from "../../components/modal";

const validate = (values) => {
  const errors = {};
  if (!values.idnumber) errors.idnumber = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.phone) errors.phone = true;
  return errors;
};

let FormParent = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    onClose,
    formTitle,
    errormsg,
  } = props;
  // const [open, setOpen] = useState(false);

  // const showPopup = () => {
  //   setOpen(true);
  // };
  // const popUpClose = () => {
  //   setOpen(false);
  //   window.location.reload();
  //   window.close();
  // };

  return (
    <Segment basic textAlign="left">
      <Segment>
        <div style={{ paddingLeft: "20px" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid divided stackable>
              <Grid.Row>
                <Grid.Column>
                  {/* <Header as="h4">FATHER (บิดา)</Header> */}
                  <Header as="h4">{formTitle}</Header>
                  <Form.Group>
                    <Field
                      width="5"
                      name="idnumber"
                      type="text"
                      required={true}
                      component={renderField}
                      label="Passport or ID No. (เลขประจำตัวประชาชน)"
                    />
                    <Field
                      width="3"
                      name="dateOfBirth"
                      required={true}
                      component={renderDateOnlyField}
                      label="Date Of Birth (ว/ด/ป เกิด)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      width="3"
                      name="firstName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="firstname"
                    />
                    <Field
                      width="3"
                      name="lastName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="lastname"
                    />
                    <Field
                      width="3"
                      name="nationality"
                      type="text"
                      required={false}
                      component={renderSelectField}
                      options={config.options_nationality}
                      label="nationlity (สัญชาติ)"
                    />
                    <Field
                      width="3"
                      name="nativelanguage"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Native Language (ภาษาประจำชาติ)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      width="3"
                      name="firstName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="ชื่อ"
                    />
                    <Field
                      width="3"
                      name="lastName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="นามสกุล"
                    />
                    <Field
                      width="3"
                      name="phone"
                      type="number"
                      required={true}
                      component={renderField}
                      label="Mobile (มือถือ)"
                    />
                    <Field
                      width="3"
                      name="email"
                      type="text"
                      required={false}
                      component={renderField}
                      label="E-mail (อีเมล์)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      width="6"
                      name="placeOfWork"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Place of work (สถานที่ทำงาน)"
                    />
                    <Field
                      width="3"
                      name="officePhone"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Tel (เบอร์โทรศัพท์)"
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            {errormsg && (
              <Message negative>
                <Message.Content>{errormsg}</Message.Content>
              </Message>
            )}
            <br />
            <Button
              floated="right"
              content="Save"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "20%",
                marginRight: "10%",
              }}
              // onClick={() => showPopup()}
              loading={loading}
              disabled={pristine || submitting}
            />
            <br></br>
            <br></br>
            <br></br>
          </Form>
        </div>
      </Segment>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการบันทึกข้อมูลแล้ว
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </Segment>
  );
};

FormParent = reduxForm({
  // a unique name for the form
  form: "FormParent",
  validate,
  enableReinitialize: true,
})(FormParent);

// const selector = formValueSelector("class");
// FormParent = connect((state) => {
//   const start_card = selector(state, "start_card");
//   const end_card = selector(state, "end_card");
//   return {
//     start_card: start_card,
//     end_card: end_card,
//   };
// })(FormParent);

export default withRouter(FormParent);
