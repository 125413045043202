import axios from "axios";
import * as config from "../../config";

// book
export function getBook(qString) {
  return (
    axios
      // .get(config.urlAPI + "/report/book" + qString, config.headerCallAPI)
      .get(config.urlAPI + "/bookperpage" + qString, config.headerCallAPI)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}

export function exportBook(qString) {
  return axios
    .get(config.urlAPI + "/export/book" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportHistoryBook(qString) {
  return axios
    .get(config.urlAPI + "/export/historybook" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postBook(data) {
  return axios
    .post(config.urlAPI + "/books", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getBookProfile(booklist_id) {
  return axios
    .get(config.urlAPI + "/bookprofile/" + booklist_id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateBookProfile(data) {
  return axios
    .post(config.urlAPI + "/updatebookprofile", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateImageBook(data, id) {
  return axios
    .post(config.urlAPI + "/book/upload/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateStatus(data) {
  return axios
    .post(config.urlAPI + "/updatestatus", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getHistoryBook(qString) {
  return axios
    .get(config.urlAPI + "/report/historybook" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getHistoryBookID(bookProfiles) {
  return axios
    .get(config.urlAPI + "/historybookid/" + bookProfiles, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getStudent_view_library_history(id) {
  return axios
    .get(
      config.urlAPI + "/getstudent_view_library_history/" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getDataBooking(id) {
  return axios
    .get(config.urlAPI + "/getdatabooking/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getdatabookinghistory(id) {
  return axios
    .get(config.urlAPI + "/getdatabookinghistory/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// Get asset history tab
export function getBookHistoryByItem(id) {
  return axios
    .get(config.urlAPI + "/bookhisbyitem/perpage" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// Get book borrow history data row
export function getBorrowHistoryBook(bookingid) {
  return axios
    .get(config.urlAPI + "/bookhistory/row" + bookingid, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// update defect image
export function updateDefectImage(data, id) {
  return axios
    .post(
      config.urlAPI + "/bookreturn/upload/" + id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// Get book history detail
export function getBookHistoryDetail(data) {
  return axios
    .get(config.urlAPI + "/bookhistory/detail" + data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
