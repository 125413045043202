import axios from "axios";
import * as config from "../../config";


export function getRole() {
    return axios
        .get(config.urlAPI + "/role", config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getStaffList(qString) {
    return axios
        .get(config.urlAPI + "/stafflist/perpage" + qString, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
export function getTeacherList(qString) {
    return axios
        .get(config.urlAPI + "/teacherlist/perpage" + qString, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getStaffProfile(id) {
    return axios
        .get(config.urlAPI + "/employee/" + id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getTeacherProfile(id) {
    return axios
        .get(config.urlAPI + "/employee/" + id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
export function updateEmployee(data) {
    return axios
        .put(config.urlAPI + "/employee/" + data.id, data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}


export function updateImageProfile(data, id) {
    return axios
        .post(config.urlAPI + "/employee/upload/" + id, data, config.headerCallAPI)
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.response;
        });
}


export function getUser(qString) {
    return axios
        .get(config.urlAPI + "/user/perpage" + qString, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function postUser(data) {
    return axios
        .post(config.urlAPI + "/user", data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
export function updateUser(data) {
    return axios
        .put(config.urlAPI + "/user/" + data.id, data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}


export function deleteUser(id) {
    return axios
        .delete(config.urlAPI + "/user/" + id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
export function resetpassUser(id) {
    return axios
        .get(config.urlAPI + "/resetpassword/" + id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
