import React, { useState, useEffect } from "react";
import * as config from '../../config';

import {
  Header,
  Icon,
  Segment,
  Card
} from 'semantic-ui-react';

const Mainmenu = (props) => {

  const [role, setRole] = useState(JSON.parse(sessionStorage.getItem("kidz_roles")));
  const [allPermistion, setAllPermistion] = useState(JSON.parse(sessionStorage.getItem("kidz_permission")));


  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (userpermissionGroup && userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  return (
    <Segment basic textAlign='left'>
      <Header as='h2' >Management</Header>
      <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>

        {checkPermissionMenu("Academic", [
          "manage-course",
          "manage-registration",
          "manage-class",
        ]) && <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/management/course")}
        >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="graduation cap"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
             Course
              </Header>
            </Card.Content>
          </Card>
        }
        {checkPermissionMenu("Academic", [
          "manage-course",

        ]) && <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/management/term")}
        >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="graduation cap"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
             Term
              </Header>
            </Card.Content>
          </Card>
        }
        {checkPermissionMenu("Personnel", [
          "manage-teacher",
          "view-teacher",
        ]) && <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/management/teacher")}
        >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="users"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
             Teacher
              </Header>
            </Card.Content>
          </Card>
        }
        {checkPermissionMenu("Personnel", [
          "manage-staff",
          "view-staff",
        ]) && <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/management/staff")}
        >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="user circle"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
             Staff
              </Header>
            </Card.Content>
          </Card>
        }
        {checkPermissionMenu("Management", [
          "manage-user",
        ]) && <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/management/user")}
        >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="cogs"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
             User
              </Header>
            </Card.Content>
          </Card>
        }
        {checkPermissionMenu("Management", [
          "manage-role",
        ]) && <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/management/role")}
        >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="eye slash outline"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
             Role & Permission
              </Header>
            </Card.Content>
          </Card>
        }
        {/* {menu.map((item) => (
          <Card
            raised
            key={item.id}
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push({
              pathname: item.linkto
            })}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name={item.icon}
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                {item.topic}
              </Header>
              <Card.Description>{item.description}</Card.Description>
            </Card.Content>
          </Card>
        ))} */}
      </Card.Group>
      <br />
    </Segment>
  );
}
export default Mainmenu;
