import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import TableList from "../../components/table";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Modal,
  Header,
  Icon,
  Image,
  Grid,
  Table,
  Divider,
  Card,
  Item,
  Segment,
} from "semantic-ui-react";
import ModalBasic from "../../components/modal";
import Wrap from "../../components/wrap";
import SecondButton from "../../components/secondbutton";
import {
  //   postBorrowBookParent,
  postBorrowBook,
  getDataBooking,
  countDataBooking,
} from "./service";

const Bookborrowparent = (props) => {
  const { data } = props;
  const [student, setStudent] = useState(
    props.location.state.data.childs_attendance[0].child_attendance
  );
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState(props);
  const [getBooking, setBooking] = useState([]);
  const [open, setOpen] = useState(false);

  const showPopup = (data) => {
    setOpen(true);
  };
  const popUpClose = (data) => {
    setOpen(false);
  };

  const addBorrow = () => {
    setLoading(true);

    var dataSubmit = {
      book_id: data.id,
      student_id: student.id,
    };

    console.log(`TEST-dataSubmit:`, dataSubmit);

    // postBorrowBookParent(data).then((res) => {
    postBorrowBook(dataSubmit).then((res) => {
      console.log(`TEST-postBorrowBook:`, res);
      if (res && res.status === 200 && res.data.success === true) {
        console.log(res.data.message);
        setLoading(false);
        setOpen(false);
        window.location.reload();
      } else {
        console.log(res.data.message);
      }
    });
    console.log(`TEST-END:`);
  };

  return (
    <Grid>
      <br />
      <br />
      <Header as="h2">Book Detail</Header>
      <Table style={{ borderRadius: 0, fontSize: "95%", overflowx: "auto" }}>
        <Table.Header
          style={{
            boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
            textAlign: "center",
          }}
        >
          <Table.Row>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "100px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Image
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Category
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Item Code
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Brand
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Model
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              ISBN
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Name(EN)
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Age range
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Author
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Quantity
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="center">
              <Image
                width="100px"
                verticalAlign="middle"
                src={
                  data && data.imageURL
                    ? data.imageURL + "?time=" + new Date()
                    : "../storage/imgs/dfkid.png"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../storage/imgs/dfkid.png";
                }}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">{data.category}</Table.Cell>
            <Table.Cell textAlign="center">{data.book_code}</Table.Cell>
            <Table.Cell textAlign="center">{data.brand}</Table.Cell>
            <Table.Cell textAlign="center">{data.model}</Table.Cell>
            <Table.Cell textAlign="center">{data.isbn}</Table.Cell>
            <Table.Cell textAlign="center">{data.name_en}</Table.Cell>
            <Table.Cell textAlign="center">{data.age_range}</Table.Cell>
            <Table.Cell textAlign="center">{data.author}</Table.Cell>
            <Table.Cell textAlign="center">1</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <br />

      <Button
        floated="left"
        content="Save"
        style={{
          backgroundColor: config.colorTheme,
          color: "#ffffff",
          minWidth: "30%",
          marginRight: "10%",
        }}
        // onClick={() => addBorrow(data)}
        onClick={() => showPopup(data)}
      />
      <br />

      <ModalBasic open={open} size="small" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          Confirm Borrow?
        </Header>
        <center>
          <Button
            content="Cancel"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "15%",
            }}
            onClick={() => popUpClose(data)}
          />
          &nbsp;&nbsp;
          <Button
            // floated="left"
            content="OK"
            // fluid
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "15%",
            }}
            loading={loading}
            onClick={addBorrow}
          />
        </center>
      </ModalBasic>
    </Grid>
  );
};

export default withRouter(Bookborrowparent);
