import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import * as config from '../../config';
import {
    Button,
    Modal,
    Header,
    Icon,
    Image,
    Grid,
    Table,
    Divider,
    Card,
    Item
} from 'semantic-ui-react';
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import QRBorrow from './qrborrow';
import QRBorrowParent from './qrborrowparent';
import { change } from "redux-form";

const BookCheck = (props) => {

    const [data, setData] = useState(props.data);
    const [open, setOpen] = useState(false);
    // const [dataStudent, setDataStudent] = useState(data.childs_attendance['0'].child_attendance);

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    // console.log(props);
    // console.log(data);
    // console.log(data.childs_attendance['0'].child_attendance);


    return (
        <Modal
            onClose={props.onClose}
            open={props.open}
            size='Extra large'
            centered={false}
            closeIcon={true}
        >

            <Modal.Content >
                <Image src='../storage/imgs/logo.png' centered style={{ padding: '4px', height: '100%' }} />
                <br /><br />
                {data.type === 'student'
                    ? <Wrap>
                        <Grid columns='2' doubling>
                            <Grid.Column width='4' textAlign='center'>
                                <Image
                                    verticalAlign="middle"
                                    src={
                                        data && data.imageURL ?
                                            data.imageURL + "?time=" + new Date()
                                            : '../storage/imgs/dfkid.png'
                                    }
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                            '../storage/imgs/dfkid.png';
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column width='12'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header as="h2" style={{ color: config.colorTheme2 }}>
                                                <Header.Content>
                                                    {data.firstName && data.firstName}&nbsp;&nbsp;{data.lastName && data.lastName}
                                                    <Header.Subheader style={{ color: config.colorTheme2 }}>
                                                        {data.firstName_th && data.firstName_th}&nbsp;&nbsp;{data.lastName_th && data.lastName_th}
                                                    </Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns='2'>
                                        <Grid.Column columns='2'>
                                            <Grid columns='2'>
                                                <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Child's name (nick name)</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} >{data.nickname}</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Gender</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} >{data.gender}</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Date of birth</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} >{data.dateOfBirth}</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Age</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} >{moment().diff(data.dateOfBirth, 'years')} Year old</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Emergency contact no.</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} > {data.emergency_phone}</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} className='bold'>E-mail</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} >{data.email}</Grid.Column>
                                            </Grid>
                                        </Grid.Column>
                                        <Grid.Column columns='2'>
                                            <Grid columns='2'>
                                                <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>Status</Grid.Column>
                                                <Grid.Column>{data.status}</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>Start Date</Grid.Column>
                                                <Grid.Column>{data.start_card}</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>End Date</Grid.Column>
                                                <Grid.Column>{data.end_card}</Grid.Column>
                                            </Grid>
                                        </Grid.Column>

                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width='4' style={{ paddingBottom: '20px' }} className='bold'>Course</Grid.Column>
                                        <Grid.Column width='12'>
                                            {data.regist_course && data.regist_course.map(item =>
                                                <p>{item.course_name} : {item.class_name} : {item.teacher}<br />
                                                    {item.term_name}
                                                </p>
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        {props.status == 'borrowBook' &&
                            <Button floated='left' content='Scan Qr code for Borrow'
                                style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%', marginRight: '10%' }}
                                onClick={() => props.history.push({
                                    pathname: './qrborrow',
                                    state: { data }
                                })
                                }
                            />
                        }
                        {props.status == 'returnBook' &&
                            <Button floated='left' content='Scan Qr code for Return'
                                style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%', marginRight: '10%' }}
                                onClick={() => props.history.push({
                                    pathname: './qrreturn',
                                    state: { data }
                                })
                                }
                            />
                        }
                        <br /><br />
                    </Wrap>
                    : <Wrap>
                        <Grid columns='2' doubling>
                            <Grid.Column width='4' textAlign='center'>
                                <Image
                                    verticalAlign="middle"
                                    src={
                                        data && data.imageURL ?
                                            data.imageURL + "?time=" + new Date()
                                            : '../storage/imgs/dfparent.png'
                                    }
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                            '../storage/imgs/dfparent.png';
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column width='12'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header as="h2" style={{ color: config.colorTheme2 }}>
                                                <Header.Content>
                                                    {data.firstName && data.firstName}&nbsp;&nbsp;{data.lastName && data.lastName}
                                                    <Header.Subheader style={{ color: config.colorTheme2 }}>
                                                        {data.firstName_th && data.firstName_th}&nbsp;&nbsp;{data.lastName_th && data.lastName_th}
                                                    </Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns='1'>
                                        <Grid.Column >
                                            <Grid columns='2' >
                                                <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Passport or ID No.</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }}>{data.idnumber}</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Mobile Phone</Grid.Column>
                                                <Grid.Column style={{ paddingBottom: 0 }}>{data.phone}</Grid.Column>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        <h3 style={{ color: config.colorTheme2 }}>Childs</h3>
                        <Item.Group divided unstackable>
                            {data && data.childs_attendance && data.childs_attendance.map(item =>
                                <Item>
                                    <Item.Image
                                        verticalAlign="middle"
                                        size='small'
                                        src={
                                            item.child_attendance.imageURL ?
                                                item.child_attendance.imageURL + "?time=" + new Date()
                                                : '../storage/imgs/dfkid.png'
                                        }
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                '../storage/imgs/dfkid.png';
                                        }} />
                                    <Item.Content>
                                        <Item.Header as='h3' style={{ color: config.colorTheme2, fontWeight: 'bold' }}>
                                            {item.child_attendance.firstName && item.child_attendance.firstName}&nbsp;&nbsp;{item.child_attendance.lastName && item.child_attendance.lastName}
                                        </Item.Header>
                                        <Item.Description>
                                            <p>{data.nickname}</p>
                                        </Item.Description>
                                        <Item.Extra>
                                            {props.status == 'borrowBook' &&
                                                <Button floated='left' content='Borrow'
                                                    style={{ backgroundColor: '#3399FF', color: "#ffffff", minWidth: '30%', marginRight: '10%' }}
                                                    onClick={() => props.history.push({
                                                        pathname: './qrborrowparent',
                                                        state: { data }
                                                    })
                                                    }
                                                />
                                            }
                                            {props.status == 'returnBook' &&
                                                <Button floated='left' content='Return'
                                                    style={{ backgroundColor: '#3399FF', color: "#ffffff", minWidth: '30%', marginRight: '10%' }}
                                                    onClick={() => props.history.push({
                                                        pathname: './qrreturnparent',
                                                        state: { data }
                                                    })
                                                    }
                                                />
                                            }
                                            
                                        </Item.Extra>
                                    </Item.Content>
                                </Item>
                            )}
                        </Item.Group>
                    </Wrap>

                }
            </Modal.Content>

        </Modal>


    )
}

export default withRouter(BookCheck);

