import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Label,
  Dimmer,
  Loader,
  Grid,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import {
  getSchoolAttendance,
  exportAttendance,
  getCourse,
  getClassInCourse,
} from "./service";
import TableList from "../../components/table";
import { DatesRangeInput } from "semantic-ui-calendar-react";

const Attendance = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({ course: "All", class: "All" });
  const [data, setData] = useState([]);
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [course_option, setCourse_option] = useState(null);
  const [class_option, setClass_option] = useState(null);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: false,
      width: 200,
      Cell: (cellInfo) => (
        <Wrap>
          <span
            className="bold"
            // style={{ cursor: "pointer" }}
            // onClick={() => props.history.push({
            //   pathname: '/student/profile',
            //   // search: '?query=abc',
            //   state: { id: cellInfo.original.id, page: 'student' }
            // })
            // }
          >
            {cellInfo.original.title} {cellInfo.original.firstName}{" "}
            {cellInfo.original.lastName}
          </span>
        </Wrap>
      ),
    },
    {
      Header: "Child's name (Nickname)",
      accessor: "nickname",
      sortable: false,
      width: 80,
    },
    {
      Header: "Course",
      accessor: "course_name",
      style: { textAlign: "left" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original && cellInfo.original.course_name
          ? cellInfo.original.course_name.toString()
          : "-",
    },
    {
      Header: "Class",
      accessor: "class_name",
      sortable: false,
      style: { textAlign: "left" },
      Cell: (cellInfo) =>
        cellInfo.original && cellInfo.original.class_name
          ? cellInfo.original.class_name.toString()
          : "-",
    },
    {
      Header: "Check In",
      accessor: "checkin_at",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original && cellInfo.original.checkin_at
          ? moment(cellInfo.original.checkin_at).format("YYYY-MM-DD HH:mm")
          : "-",
    },
    {
      Header: "Check In Guardian",
      accessor: "checkin_by",
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original && cellInfo.original.checkin_by
          ? cellInfo.original.checkin_by
          : cellInfo.original &&
            cellInfo.original.checkin_other && (
              <p>
                {cellInfo.original.checkin_other.name} (
                {cellInfo.original.checkin_other.relationship})<br />
                {cellInfo.original.checkin_other.phone}
                <br />
                {cellInfo.original.checkin_other.photo && (
                  <a
                    href={cellInfo.original.checkin_other.photo}
                    target="_blank"
                  >
                    Click
                  </a>
                )}
                <br />
                {cellInfo.original.checkin_other.photo_other && (
                  <a
                    href={cellInfo.original.checkin_other.photo_other}
                    target="_blank"
                  >
                    Click
                  </a>
                )}
              </p>
            ),
    },
    {
      Header: "User/Operation",
      accessor: "actioncheckin_by",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Check Out",
      accessor: "checkout",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original && cellInfo.original.checkout_at
          ? moment(cellInfo.original.checkout_at).format("YYYY-MM-DD HH:mm")
          : "-",
    },
    {
      Header: "Check Out Guardian",
      accessor: "checkout_by",
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original && cellInfo.original.checkout_by
          ? cellInfo.original.checkout_by
          : cellInfo.original &&
            cellInfo.original.checkout_other && (
              <p>
                {cellInfo.original.checkout_other.name} (
                {cellInfo.original.checkout_other.relationship})<br />
                {cellInfo.original.checkout_other.phone}
                <br />
                {cellInfo.original.checkout_other.photo && (
                  <a
                    href={cellInfo.original.checkout_other.photo}
                    target="_blank"
                  >
                    Click
                  </a>
                )}
                <br />
                {cellInfo.original.checkout_other.photo_other && (
                  <a
                    href={cellInfo.original.checkout_other.photo_other}
                    target="_blank"
                  >
                    Click
                  </a>
                )}
              </p>
            ),
    },
    {
      Header: "User/Operation",
      accessor: "actioncheckout_by",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "School Status",
      accessor: "attendees",
      style: { textAlign: "center" },
      sortable: false,
      width: 300,
      Cell: (cellInfo) => (
        <div>
          <p>
            {cellInfo.original && cellInfo.original.checkin_at
              ? "Present"
              : "Absent"}
          </p>
          {cellInfo.original &&
            cellInfo.original.checkout_at &&
            moment(cellInfo.original.checkout_at) >=
              moment(cellInfo.original.late_max, "HH:mm") &&
            moment
              .duration(
                moment(cellInfo.original.checkout_at).diff(
                  moment(cellInfo.original.late_max, "HH:mm")
                )
              )
              .asMinutes() >= 15 && (
              <p style={{ fontWeight: "bold", color: "red" }}>
                Pickup Late ({cellInfo.original.late_max} :{" "}
                {moment
                  .duration(
                    moment(cellInfo.original.checkout_at, "HH:mm").diff(
                      moment(cellInfo.original.late_time, "HH:mm")
                    )
                  )
                  .asMinutes()}{" "}
                Minutes
              </p>
            )}
        </div>
      ),
    },
  ]);

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    setCourse_option(null);
    let option = [];
    getCourse().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: { id: item.id, name: item.name },
          });
        });
        setCourse_option(option);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (date)
  //     getData();
  // }, [date])

  // Modify for filter active after select Course
  useEffect(() => {
    if (date) getData();
  }, [date, values]);

  useEffect(() => {
    // getData();
    if (values.course !== "All") {
      setClass_option(null);
      let option = [];
      getClassInCourse(values.course.id).then((res) => {
        if (res && res.status === 200) {
          let newcourse = true;
          res.data.class_schedule &&
            res.data.class_schedule.map((item) => {
              option.push({
                key: item.id,
                text: item.name,
                value: { id: item.id, name: item.name },
              });
              if (values.class !== "All" && values.class.id === item.id)
                newcourse = false;
            });
          if (newcourse) {
            values.class = "All";
            setValues(values);
          }
          setClass_option(option);
        }
      });
    } else if (values.course === "All") {
      values.class = "All";
      setValues(values);
      setClass_option(null);
    }
  }, [values]);

  const getData = () => {
    setLoading(true);
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    }
    if (values.course !== "All")
      qString = qString + "&course=" + values.course.id;
    if (values.class !== "All") qString = qString + "&class=" + values.class.id;

    getSchoolAttendance(qString + "&school").then((res) => {
      if (res && res.status === 200) {
        // setColumn(column)
        setData(res.data);
      }
      setLoading(false);
    });
  };

  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };
  const onClickExport = () => {
    setLoadPopup(true);
    setErrormsg(null);
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    }
    if (values.course !== "All")
      qString = qString + "&course=" + values.course.id;
    if (values.class !== "All") qString = qString + "&class=" + values.class.id;

    exportAttendance(qString + "&school").then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false);
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "AttendanceReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        // this.setState({link: link.href });
        link.download =
          "AttendanceReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">School Attendance Report</Header>

      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/report")}
              style={{ color: "grey" }}
            >
              Report
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>
              School Attendance Report
            </Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="green"
            content="Export Excel"
            floated="right"
            style={{ marginBottom: "14px" }}
            onClick={onClickExport}
          />
        </Grid.Column>
      </Grid>

      <Segment
        className="contentSegment"
        textAlign="left"
        style={{ minHeight: "50vh", padding: "20px" }}
      >
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <DatesRangeInput
                name="date"
                placeholder="Start - End"
                hideMobileKeyboard
                dateFormat="YYYY-MM-DD"
                iconPosition="right"
                value={date}
                allowSameEndDate
                onChange={handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="course"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Course :{" "}
                  <b>
                    {values.course && values.course.name
                      ? values.course.name
                      : values.course}
                  </b>
                </Button>
              }
              options={
                course_option && [
                  { key: "all", text: "All", value: "All" },
                  ...course_option,
                ]
              }
              defaultValue={
                values.course && values.course.name
                  ? values.course.name
                  : values.course
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="class"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Class :{" "}
                  <b>
                    {values.class && values.class.name
                      ? values.class.name
                      : values.class}
                  </b>
                </Button>
              }
              options={
                class_option && [
                  { key: "all", text: "All", value: "All" },
                  ...class_option,
                ]
              }
              defaultValue={
                values.class && values.class.name
                  ? values.class.name
                  : values.class
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
          </Form.Group>
        </Form>

        <TableList loading={loading} data={data} columns={column} />
      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Waiting Download File
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>
    </Segment>
  );
};
export default Attendance;
