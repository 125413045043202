import React, { useState, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Grid, Header, Image, Segment, Dropdown, Message } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import Wrap from "../../components/wrap";
import * as config from '../../config';

const validate = values => {
  const errors = {}
  if (!values.id) errors.id = true
  if (!values.firstName) errors.firstName = true
  if (!values.lastName) errors.lastName = true
  if (!values.relationship) errors.relationship = true
  if (!values.phone) errors.phone = true
  return errors;
};


let ParentForm = (props) => {
  const { loading, pristine, submitting, handleSubmit, onSubmit, onSelectParent, initialValue, onClose, onSearchQuery, parentOption, errormsg } = props;
  const [parent, setParent] = useState(null);
  // const [parentOption, setParentOption] = useState(parentOption);
  const [newparent, setNewparent] = useState(false);



  const onClickParent = (values) => {
    props.dispatch(change('parent', 'idnumber', values.value));
    if (parentOption) {
      const found = parentOption.find(element => element.idnumber === values.value);
      if (found) {
        setParent(found);
        props.dispatch(change('parent', 'id', found.id));
      }
    }
  }

  const onAddItem = (e, { value }) => {
    // setParentOption([{ 'text': value, value }, ...parentOption])
    props.dispatch(change('parent', 'idnumber', value));
    setParent(null)
    setNewparent(true)
  }

  return (
    <Form onSubmit={parent ? handleSubmit(onSelectParent) : handleSubmit(onSubmit)} style={{ minHeight: '40vh' }}>
      <Form.Dropdown
        options={parentOption}
        placeholder='Search'
        label='Type to search..'
        required
        search
        selection
        fluid
        name='idnumber'
        allowAdditions
        component={Dropdown}
        loading={loading}
        onAddItem={onAddItem}
        onChange={(e, data) => onClickParent(data)}
        onSearchChange={(e, data) => onSearchQuery(data)}
      />
      <br />
      {
        errormsg && <Message negative>
          <Message.Content>{errormsg}</Message.Content>
        </Message>
      }
      {
        parent
          ? <Wrap>
            <Segment>
              <Grid columns='2' doubling>
                <Grid.Column width='6'>
                  <Image
                    verticalAlign="middle"
                    src={
                      parent && parent.imageURL ?
                        parent.imageURL + "?time=" + new Date()
                        : '../storage/imgs/dfparent.png'
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        '../storage/imgs/dfparent.png';
                    }}
                  />
                </Grid.Column>
                <Grid.Column columns='2' width='10' style={{ minHeight: '30vh' }}>
                  <Header as="h2" style={{ color: config.colorTheme2, paddingBottom: 0 }}>
                    <Header.Content>
                      {parent.firstName && parent.firstName}&nbsp;&nbsp;{parent.lastName && parent.lastName}
                      <Header.Subheader style={{ color: config.colorTheme2 }}>
                        {parent.firstName_th && parent.firstName_th}&nbsp;&nbsp;{parent.lastName_th && parent.lastName_th}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Grid columns='2' >
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Passport or ID No.</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>{parent.idnumber}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Mobile Phone</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>{parent.phone}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>E-mail</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}> {parent.email}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Address</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>{parent.address}</Grid.Column>
                  </Grid>
                  <br />  <br />
                  <Form.Group unstackable>
                    <Field
                      name="relationship"
                      type="text"
                      required={true}
                      width="12"
                      component={renderField}
                      label="Relationship"
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid>

            </Segment>
            <br /><br /><br />
            <div>
              <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
              <Button floated='left' content='Save'
                style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                loading={loading}
              />
            </div>
            <br /><br />
          </Wrap>
          : newparent
          && <div>
            <Form.Group unstackable>
              <Field
                name="idnumber"
                type="text"
                required={false}
                fluid
                component={renderField}
                label="Passport or ID No."
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="title"
                width="4"
                component={renderSelectField}
                label="Title"
                options={config.options_title_eng}
              />
              <Field
                name="firstName"
                type="text"
                required={true}
                width="6"
                component={renderField}
                label="firstName"
              />
              <Field
                name="lastName"
                type="text"
                required={true}
                width="6"
                component={renderField}
                label="lastName"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="title_th"
                width="4"
                component={renderSelectField}
                label="คำนำหน้า"
                options={config.options_title_th}
              />
              <Field
                name="firstName_th"
                type="text"
                required={false}
                width="6"
                component={renderField}
                label="ชื่อ"
              />
              <Field
                name="lastName_th"
                type="text"
                required={false}
                width="6"
                component={renderField}
                label="นามสกุล"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="address"
                type="text"
                required={false}
                width="16"
                component={renderTextAreaField}
                label="Address"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="dateOfBirth"
                component={renderDateOnlyField}
                label="Date Of Birth"
              />
              <Field
                name="gender"
                component={renderSelectField}
                label="Gender"
                options={config.options_gender}
              />
              <Field
                name="nationality"
                type="text"
                required={false}
                component={renderField}
                label="Nationality"
              />
              <Field
                name="nativelanguage"
                type="text"
                required={false}
                component={renderField}
                label="Native Language"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="relationship"
                type="text"
                required={true}
                width="4"
                component={renderField}
                label="Relationship"
              />
              <Field
                name="phone"
                type="text"
                required={true}
                width="6"
                component={renderField}
                label="Mobile Phone"
              />
              <Field
                name="email"
                type="text"
                required={false}
                width="6"
                component={renderField}
                label="E-mail"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="occupation"
                type="text"
                required={false}
                width="8"
                component={renderField}
                label="Occupation"
              />
              <Field
                name="placeOfWork"
                type="text"
                required={false}
                width="8"
                component={renderField}
                label="Place of work"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="position"
                type="text"
                required={false}
                width="8"
                component={renderField}
                label="Position"
              />
              <Field
                name="officePhone"
                type="text"
                required={false}
                width="8"
                component={renderField}
                label="Office Phone"
              />
            </Form.Group>
            <br /><br /><br />
            <div>
              <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
              <Button floated='left' content='Save'
                style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                loading={loading}
              />
            </div>
            <br /><br />
          </div>
      }
    </Form>
  );

}

ParentForm = reduxForm({
  // a unique name for the form
  form: "parent",
  validate,
  enableReinitialize: true
})(ParentForm);

export default ParentForm;
