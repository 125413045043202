import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

class TableData extends Component {
  render() {
    return (
      <ReactTable
        style={this.props.styleTB}
        manual // informs React Table that you'll be handling sorting and pagination server-side
        defaultPageSize={20}
        pageSize={this.props.pageSize}
        page={this.props.page}
        pages={this.props.pages}
        loading={this.props.loading}
        className="-highlight"
        data={this.props.data}
        columns={this.props.columns} // loading={true}
        onPageChange={(pageIndex) => this.props.onPageChange(pageIndex)}
        onPageSizeChange={(pageSize, pageIndex) =>
          this.props.onPageSizeChange(pageSize, pageIndex)
        }
        onSortedChange={(newSorted, column, shiftKey) => {
          this.props.onSortedChange(newSorted, column);
        }}
      />
    );
  }
}
export default TableData;
