import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Image,
  Segment,
  Breadcrumb,
  Grid,
  Form,
} from "semantic-ui-react";

import moment from "moment";
import TableList from "../../components/tablePerPage";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import TemplateSendURLRegister from "./templatesendurlregister";
import { getApplicationPerPage } from "../service";
import { set } from "core-js/core/dict";

const Application = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    class: "",
    term: "",
    status_interview: "",
  });
  const [search, setSearch] = useState({ name: null });
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [url, seturl] = useState([]);
  const [Id, setId] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loadPopup, setLoadPopup] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  const [open, setOpen] = useState(false);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Application No.",
      accessor: "id",
      sortable: false,
      width: 100,
      Cell: (cellInfo) => "A000" + cellInfo.original.id,
    },
    {
      Header: "Student Name",
      accessor: "name",
      sortable: false,
      width: 150,
      Cell: (cellInfo) => (
        <Wrap>
          {cellInfo.original.firstName} {cellInfo.original.lastName}
        </Wrap>
      ),
    },
    {
      Header: "Visit Date",
      accessor: "dateTimeOfVisit",
      style: { textAlign: "left" },
      sortable: false,
    },
    {
      Header: "Interview Date",
      accessor: "interview_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Interview by",
      accessor: "interview_by",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Class Enlloed",
      accessor: "class",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Term Enlloed",
      accessor: "term",
      style: { textAlign: "center" },
      sortable: false,
    },
    // {
    //   Header: "Status visit",
    //   accessor: "status",
    //   style: { textAlign: "center" },
    //   sortable: false,
    // },
    {
      Header: "Status Interview",
      accessor: "status_interview",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Action",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/application/applicationformdetail",
                state: { id: cellInfo.original.id },
              })
            }
          />
        </Wrap>
      ),
      width: 80,
    },
    // {
    //     Header: "URL Register Form",
    //     accessor: "visit_id",
    //     style: { textAlign: "center" },
    //     sortable: false,
    //     Cell: (cellInfo) => cellInfo.original.status_interview === "Approved" &&
    //         <Wrap>
    //             <Icon
    //                 link
    //                 bordered
    //                 style={{ color: config.colorTheme, boxShadow: '0 0 0 0.1em ' + config.colorTheme + ' inset' }}
    //                 name="linkify"
    //                 onClick={() => onOpen(config.urlRegisterform + cellInfo.original.visit_id)}
    //             />
    //         </Wrap>,
    //     width: 140,
    // }
  ]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, sort_page, values]);

  useEffect(() => {
    setLoading(true);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getData();
    }, 2000);
  }, [search]);

  useEffect(() => {
    if (date) getData();
  }, [date]);

  const getData = () => {
    setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    if (values.status_interview)
      qString = qString + "&status_interview=" + values.status_interview;
    if (values.term) qString = qString + "&term=" + values.term;
    if (values.class) qString = qString + "&class=" + values.class;
    if (search.name) qString = qString + "&name=" + search.name;

    let sort = sort_page;
    if (sort !== null) {
      var sortName = sort[0].id;
      var sortType = sort[0].desc === false ? "asc" : "desc";
      if (sortName === "name") {
        sortName = "firstName";
      }
      qString = qString + "&sortName=" + sortName + "&sortType=" + sortType;
    }

    getApplicationPerPage(qString).then((res) => {
      if (res && res.status === 200) {
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
  };

  const onOpen = (url) => {
    setOpen(true);
    seturl(url);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getData();
    }, 2000);
  };

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Application Form</Header>
      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/application")}
              style={{ color: "grey" }}
            >
              Application
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Application Form</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid>

      <Segment className="contentSegment" textAlign="left">
        <Form>
          <Grid columns="5">
            <Grid.Column>
              <Form.Input
                width="16"
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={handleInputChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                icon={null}
                fluid
                floating
                style={{ width: "100%", marginRight: "30px" }}
                name="term"
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Term : <b>{values.term}</b>
                  </Button>
                }
                options={[
                  { key: " ", text: "All", value: " " },
                  ...config.option_termenrolled,
                ]}
                defaultValue={values.term}
                selectOnBlur={false}
                onChange={handleSelectChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                icon={null}
                fluid
                floating
                style={{ width: "100%", marginRight: "30px" }}
                name="class"
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Class : <b>{values.class}</b>
                  </Button>
                }
                options={[
                  { key: " ", text: "All", value: " " },
                  ...config.option_classenrolled,
                ]}
                defaultValue={values.class}
                selectOnBlur={false}
                onChange={handleSelectChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                icon={null}
                fluid
                floating
                style={{ width: "100%", marginRight: "30px" }}
                name="status_interview"
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status Interview : <b>{values.status_interview}</b>
                  </Button>
                }
                options={[
                  { key: "", text: "All", value: "" },
                  { key: "Pending", text: "Pending", value: "Pending" },
                  { key: "Confirm", text: "Confirm", value: "Confirm" },
                  { key: "Cancel", text: "Cancel", value: "Cancel" },
                  { key: "Approved", text: "Approved", value: "Approved" },
                  { key: "Reject", text: "Reject", value: "Reject" },
                ]}
                defaultValue={values.status_interview}
                selectOnBlur={false}
                onChange={handleSelectChange}
              />
            </Grid.Column>
          </Grid>
          <br />
        </Form>

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>

      <ModalBasic open={open} size="big">
        <Grid columns="2">
          <Grid.Column>
            <Header as="h3">URL for send.</Header>
          </Grid.Column>
          <Grid.Column>
            <Header
              as="h3"
              floated="right"
              style={{ cursor: "pointer" }}
              onClick={() => onClose()}
            >
              X
            </Header>
          </Grid.Column>
        </Grid>
        <TemplateSendURLRegister initialValues={url} />
      </ModalBasic>
    </Segment>
  );
};
export default withRouter(Application);
