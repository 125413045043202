import React, { useState, useRef } from "react";
import {
  Header,
  Icon,
  Segment,
  Card,
  Breadcrumb,
  Button,
} from "semantic-ui-react";
import QrReader from "react-qr-reader";
import QrScanner from "react-qr-scanner";

import { getDataFromQR, postCheckIn, postCheckOut } from "./service";
import Wrap from "../../components/wrap";
import ErrorModal from "./errormodal";
import StatusModal from "./statusmodal";
import BookCheck from "./bookcheck";

const QRAsset = (props) => {
  const [result, setResult] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [status, setStatus] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [success, setSuccess] = useState(null);
  const [qrString, setQrString] = useState(null);
  const [legacyMode, setLegacyMode] = useState(false);

  const timeoutRef = useRef(null);
  const reader = useRef(null);

  const getData = (data) => {
    setLoading(true);
    getDataFromQR(data).then((res) => {
      if (res && res.status === 200) {
        setResult("found");
        setData(res.data);
      } else if (res && res.status === 404) {
        setResult(res.data.error && res.data.error.errorsmessage);
      }
      setLoading(false);
    });
  };
  const handleScan = (data) => {
    if (data) {
      setScanning(false);
      setLoading(true);
      setQrString(data);
      getData(data);
    }
  };
  const handleError = (err) => {
    if (err) setResult(err.message);
  };

  const borrowBook = (e) => {
    setScanning(true);
    setStatus("borrowBook");
  };
  const returnBook = (e) => {
    setScanning(true);
    setStatus("returnBook");
  };
  const onClosePopup = (e) => {
    setErrormsg(null);
    setSuccess(null);
  };

  const openImageDialog = () => {
    setLegacyMode(true);
    if (legacyMode) reader.current.openImageDialog();
  };
  return (
    <Segment basic textAlign="left">
      <StatusModal
        open={loading || success || errormsg}
        loading={loading}
        text={status}
        errormsg={errormsg}
        onClose={onClosePopup}
      />
      <Header as="h2">Asset</Header>
      <Breadcrumb>
        <Breadcrumb.Section
          onClick={() => props.history.push("/dashboard")}
          style={{ color: "grey" }}
        >
          Dashboard
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>Asset</Breadcrumb.Section>
      </Breadcrumb>
      <br /> <br /> <br />
      {data && result === "found" && (
        <BookCheck
          open={result === "found"}
          data={data}
          status={status}
          onClose={() => setResult(null)}
        />
      )}
      {result !== "found" && (
        <ErrorModal
          open={result}
          error={result}
          onClose={() => setResult(null)}
        />
      )}
      {scanning ? (
        <Wrap>
          {/* <Icon bordered color='teal' name='redo' onClick={() => setScanning(false)} /> */}
          <Button
            floated="left"
            basic
            compact
            icon="camera"
            onClick={openImageDialog}
            content={legacyMode ? "Submit a QR Code" : "Take a photos"}
          />

          <br />
          <br />
          {legacyMode ? (
            <QrScanner
              delay={100}
              ref={reader}
              facingMode
              legacyMode
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%", height: 500, textAlign: "center" }}
              resolution={500}
              className="reader-container"
            />
          ) : (
            <QrReader
              delay={100}
              // legacyMode={legacyMode}
              // facingMode={facingMode}
              facingMode={"environment"}
              // chooseDeviceId={"cameraId"}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%", height: 500, textAlign: "center" }}
              resolution={500}
              className="reader-container"
            />
          )}
        </Wrap>
      ) : (
        <Card.Group centered itemsPerRow={2}>
          <Card
            onClick={() =>
              props.history.push({
                pathname: "/asset/borrow",
                // search: '?query=abc',
                state: {
                  status: "Request",
                  page: "Borrow Asset",
                  mode: "borrow",
                },
              })
            }
          >
            <Card.Content>
              <Header as="h3" icon>
                <Icon name="qrcode" style={{ color: "green" }} />
                Borrow
              </Header>
            </Card.Content>
          </Card>
          <Card
            onClick={() =>
              props.history.push({
                pathname: "/asset/borrow",
                // search: '?query=abc',
                state: {
                  status: "Borrowing",
                  page: "Return Asset",
                  mode: "return",
                },
              })
            }
          >
            <Card.Content>
              <Header as="h3" icon>
                <Icon name="qrcode" style={{ color: "red" }} />
                Return
              </Header>
            </Card.Content>
          </Card>
        </Card.Group>
      )}
    </Segment>
  );
};
export default QRAsset;
