import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateTimeField2 from "../../components/renderDateTimeField2";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../components/wrap";
import { getTermsList, getClassList } from "../service";

const validate = (values) => {
  const errors = {};
  if (!values.interview_date) errors.interview_date = true;
  if (!values.interview_by) errors.interview_by = true;
  if (!values.comment) errors.comment = true;
  if (!values.class) errors.class = true;
  if (!values.term) errors.term = true;
  if (!values.start_date) errors.start_date = true;
  if (!values.status_interview) errors.status_interview = true;
  return errors;
};

let Evaluation = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    start_card,
    end_card,
    handleSubmit,
  } = props;

  // To be continue (incomplete)
  const [firstload, setFirstload] = useState(true);
  const [termlist, setTerms] = useState([]);
  const [classlist, setClass] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);

  // for load list of Class Enrolled and Term Enrolled
  useEffect(() => {
    if (firstload) {
      console.log(`useEffect`, props.initialValues);
      const status = props.initialValues.status_interview;
      if (status != "Approved" && status != "Reject") {
        props.initialValues.status_interview = null;
      }
      console.log(`useEffect-after`, props.initialValues);

      //Get present term list (terms.end > now())
      getTermsList().then((res) => {
        console.log(`getTermsList`, res.data);

        if (res && res.status === 200) {
          let option = [];
          let match = 0;

          res.data.map((item) => {
            // //prevent duplicate term name of current setting and present term
            // if (item.name != props.initialValues.term) {
            //   option.push({
            //     key: item.id,
            //     text: item.name,
            //     value: item.name,
            //   });
            // }
            option.push({
              key: item.id,
              text: item.name,
              value: item.name,
            });
            //prevent duplicate term name of current setting and present term
            if (item.name == props.initialValues.term) {
              match = 1;
            }
          });

          if (match != 1) {
            //Add current setting data for display
            option.push({
              key: "0",
              text: props.initialValues.term,
              value: props.initialValues.term,
            });
          }

          setTerms(option);

          // //Set initial class
          // let initoption = [];
          // initoption.push({
          //   key: "0",
          //   text: props.initialValues.class,
          //   value: props.initialValues.class,
          // });
          // setClass(initoption);
          // console.log(`init-class`, classlist);

          let term_id = 0;
          option.map((item) => {
            if (item.value == props.initialValues.term) {
              term_id = item.key;
            }
          });

          getClass(term_id);
        }
      });

      setFirstload(false);
    }
  }, []);

  const getClass = (term_id) => {
    console.log(`getClassList-id`, term_id);

    if (term_id != 0) {
      let qString = "?term_id=" + term_id;

      //Get present class list (end > now())
      getClassList(qString).then((res) => {
        console.log(`getClassList-fnc`, res.data);

        if (res && res.status === 200) {
          let optionclass = [];
          res.data.map((item) => {
            //prevent duplicate class name of current setting and present class
            // if (item.name != props.initialValues.class) {
            optionclass.push({
              key: item.id,
              text: item.name,
              value: item.name,
            });
            // }
          });

          setClass(optionclass);

          console.log(`getClassList-fnc2`, optionclass);
        }
      });
    } else {
      let initclass = [];
      // add current setting data for display
      initclass.push({
        key: "0",
        text: props.initialValues.class,
        value: props.initialValues.class,
      });
      setClass(initclass);
      console.log(`getClassList-fnc3`, initclass);
    }
  };

  const handleChangeTerm = (e) => {
    console.log(`handleChangeTerm`, e);

    if (e) {
      let term_id = 0;
      termlist.map((item) => {
        if (item.value == e) {
          term_id = item.key;
        }
      });
      console.log(`handleChangeTerm-id`, term_id);

      getClass(term_id);
    }
  };

  // const handleChangeTerm = (e) => {
  //   console.log(`handleChangeTerm`, e);

  //   if (e) {
  //     let term_id = 0;
  //     termlist.map((item) => {
  //       if (item.value == e) {
  //         term_id = item.key;
  //       }
  //     });
  //     console.log(`handleChangeTerm-id`, term_id);

  //     if (term_id != 0) {
  //       let qString = "?term_id=" + term_id;

  //       //Get present class list (end > now())
  //       getClassList(qString).then((res) => {
  //         console.log(`getClassList`, res.data);

  //         if (res && res.status === 200) {
  //           let optionclass = [];
  //           res.data.map((item) => {
  //             //prevent duplicate class name of current setting and present class
  //             if (item.name != props.initialValues.class) {
  //               optionclass.push({
  //                 key: item.id,
  //                 text: item.name,
  //                 value: item.name,
  //               });
  //             }
  //           });

  //           setClass(optionclass);

  //           console.log(`getClassList`, optionclass);
  //         }
  //       });
  //     } else {
  //       let initclass = [];
  //       // add current setting data for display
  //       initclass.push({
  //         key: "0",
  //         text: props.initialValues.class,
  //         value: props.initialValues.class,
  //       });
  //       setClass(initclass);
  //     }
  //   }
  // };

  return (
    <Segment basic textAlign="left">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <b>
          interview date<span style={{ color: "red" }}> *</span>
        </b>
        <b style={{ paddingLeft: "45%" }}>
          interview time<span style={{ color: "red" }}> *</span>
        </b>
        <Field
          width="16"
          name="interview_date"
          required={true}
          component={renderDateTimeField2}
          label="Interview Date"
        />
        <Form.Group>
          <Field
            width="6"
            name="interview_by"
            required={true}
            component={renderField}
            label="Interview by"
          />
          <Field
            width="10"
            name="comment"
            required={true}
            component={renderField}
            label="Comment from the interview"
          />
        </Form.Group>
        <Field
          width="8"
          component={renderSelectField}
          name="term"
          label="Term Enrolled"
          required={true}
          // options={config.option_termenrolled}
          options={termlist}
          onChange={(e) => handleChangeTerm(e)}
          disableclear
        />
        <Field
          width="8"
          component={renderSelectField}
          name="class"
          label="Class Enrolled"
          required={true}
          // options={config.option_classenrolled}
          options={classlist}
          disableclear
        />
        <Field
          width="8"
          name="start_date"
          required={true}
          component={renderDateOnlyField}
          label="Start Date"
        />
        <Field
          width="8"
          component={renderSelectField}
          name="status_interview"
          label="Approve or Reject"
          required={true}
          options={[
            { key: "Approved", text: "Approved", value: "Approved" },
            { key: "Reject", text: "Reject", value: "Reject" },
          ]}
        />
        {errormsg && (
          <Message negative>
            <Message.Content>{errormsg}</Message.Content>
          </Message>
        )}

        <Form.Group>
          <Button
            floated="left"
            content="Save"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "20%",
              marginRight: "35%",
              minWidth: "30%",
            }}
            loading={loading}
            disabled={pristine || submitting}
          />
        </Form.Group>
      </Form>
    </Segment>
  );
};

Evaluation = reduxForm({
  // a unique name for the form
  form: "Evaluation",
  validate,
  enableReinitialize: true,
})(Evaluation);

// const selector = formValueSelector("class");
// Evaluation = connect((state) => {
//   const start_card = selector(state, "start_card");
//   const end_card = selector(state, "end_card");
//   return {
//     start_card: start_card,
//     end_card: end_card,
//   };
// })(Evaluation);

export default withRouter(Evaluation);
