import axios from "axios";
import * as config from "../config";

export function sendMailResetPassword(data) {
    return axios
        .post(config.urlAPI + "/resetpassword", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function confirmResetPassword(data) {
    return axios
        .post(config.urlAPI + "/resetpassword/confirm", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function postLogin(data) {
    return axios
        .post(config.urlAPI + "/login", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function postLoginGoogle(data) {
    return axios
        .post(config.urlAPI + "/loginGoogle", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}
export function logout() {
    return axios
        .get(config.urlAPI + "/logout", config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function resetPassword(data) {
    return axios
        .post(config.urlAPI + "/resetpassword", data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

