import React, { useState, useEffect, useRef, useCallback } from "react";
import { withRouter } from "react-router-dom";

import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Segment,
  Form,
  Grid,
  Table,
  List,
  Checkbox,
  Label,
  Dropdown,
  Image,
  TableBody,
} from "semantic-ui-react";
import ModalBasic from "../../components/modal";
import TableList from "../../components/table";
import Wrap from "../../components/wrap";
import { getClassList, postCheckIn } from "./service";
import SecondButton from "../../components/secondbutton";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

const ClassList = (props) => {
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [errormsg, setErrormsg] = useState(null);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [attendees, setAttendees] = useState(null);
  const [today, setToday] = useState(moment().format("YYYY-MM-DD"));
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  // const [date, setDate] = useState("2023-09-09");
  const [column, setColumn] = useState([]);
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [date]);

  const getData = () => {
    setIsLoading(true);
    let qString = "?date=" + date;
    // if (values.status) qString = "?status=" + values.status;
    // if (search.name) qString = qString + "&name=" + search.name;
    if (checkPermissionMenu("Attendance", ["class-attendance-admin"])) {
      qString = qString + "&admin";
    }
    getClassList(qString).then((res) => {
      if (res && res.status === 200) {
        let studentSum = 0;
        let totalStudent = " ";
        let attendedSum = 0;
        let toattendedSum = " ";
        console.log(`getClassList`, res); //Test
        for (let i = 0; i < res.data.length; i++) {
          setColumn(column);
          setData(res.data);

          // totalStudent = studentSum += res.data[i].attendees.length;
          // document.getElementById("idtotalStudent").innerHTML = totalStudent;
          studentSum = studentSum + res.data[i].attendees.length;

          // for (let j = 0; j < res.data[i].attendees.length; j++) {
          //   if (res.data[i].attendees[j].school_attendance !== null) {
          //     console.log(res.data[i].attendees.length);

          //     toattendedSum = attendedSum += res.data[i].attendees.length;
          //     document.getElementById("idtotalAttended").innerHTML = toattendedSum;
          //   }
          // }
        }
        //Total student
        totalStudent = studentSum;
        document.getElementById("idtotalStudent").innerHTML = totalStudent;
      }
      setLoading(false);
      setIsLoading(false);
    });
  };
  const handleChange = (event, { value }) => {
    setDate(value);
    console.log(`date:`, value);
  };

  const onCheckAttendance = (values) => {
    setOpen(true);
    setCurrentData(values);
    setAttendees(values.attendees);
    console.log(`onCheckAttendance`, values);
  };
  const onShowAttendance = (values) => {
    setShow(true);
    setOpen(true);

    setCurrentData(values);
    setAttendees(values.attendees);
  };

  const onClose = () => {
    setOpen(false);
    setShow(false);

    setCurrentData(null);
    setAttendees(null);
  };

  const handleEventChange = (e, values) => {
    let data = currentData;
    data.attendees[values.name]["extended"] = values.checked;
    setCurrentData({ ...data });
  };

  const handleLessonChange = (value, index) => {
    let data = currentData;
    data.attendees[index]["attendees"] = value;
    setCurrentData({ ...data });
  };

  const onSubmitAttendance = () => {
    setLoading(true);
    let attendees = [];
    let leave = [];
    let late = [];
    let absent = [];
    let extended = [];

    // console.log(`checkin-currentData`, currentData); //Test

    currentData.attendees.map((item) => {
      if (item.attendees === "Attended") attendees.push(item.id);
      else if (item.attendees === "Late") late.push(item.id);
      else if (item.attendees === "Leave") leave.push(item.id);
      else absent.push(item.id);

      if (item.extended) extended.push(item.id);
    });
    let data = {
      date: date,
      class_id: currentData.id,
      attendees: attendees,
      late: late,
      leave: leave,
      absent: absent,
      extended: extended,
    };

    // console.log(`checkin-data`, data); //Test

    postCheckIn(data).then((res) => {
      // console.log(`checkin-result`, res); //Test
      if (res && res.status === 200) {
        setOpen(false);
        getData();
      }
      setLoading(false);
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Class Attendance </Header>
      <Segment className="contentSegment" textAlign="left">
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <DateInput
                name="date"
                placeholder="Date"
                value={date}
                iconPosition="left"
                dateFormat="YYYY-MM-DD"
                onChange={handleChange}
                minDate={date}
                maxDate={date}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {isLoading ? " Loading..." : ""}
        <Table celled padded style={{ borderRadius: 0, fontSize: "95%" }}>
          <Table.Header
            style={{
              boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
              textAlign: "center",
            }}
          >
            <Table.Row key="tableHead">
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Class
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Course
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Teacher
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Student
              </Table.HeaderCell>
              {/* <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Attended
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Absent
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Late
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Leave
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Status
              </Table.HeaderCell> */}
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Action
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item, index) => (
              <>
                {item.attendees.length != 0 && (
                  <Table.Row key={index}>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>{item.course_name}</Table.Cell>
                    <Table.Cell>{item.teacher}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {item.attendees.length}
                    </Table.Cell>
                    {/* <Table.Cell textAlign="center"></Table.Cell>
                    <Table.Cell textAlign="center"></Table.Cell>
                    <Table.Cell textAlign="center"></Table.Cell>
                    <Table.Cell textAlign="center"></Table.Cell>
                    <Table.Cell textAlign="center">
                      <p>
                        {item.status === "Attended" ? (
                          <Label className="statusLabel" color="green">
                            {" "}
                            Attended
                          </Label>
                        ) : (
                          ""
                        )}
                      </p>
                    </Table.Cell> */}
                    <Table.Cell>
                      {checkPermissionMenu("Attendance", [
                        "class-attendance-admin",
                      ]) ? (
                        <Button
                          size="mini"
                          basic
                          compact
                          color="green"
                          content=" Check In"
                          onClick={() => onCheckAttendance(item)}
                        />
                      ) : item.status === "Attended" ? (
                        <Icon
                          link
                          bordered
                          style={{
                            color: config.colorTheme,
                            boxShadow:
                              "0 0 0 0.1em " + config.colorTheme + " inset",
                          }}
                          name="users"
                          onClick={() => onShowAttendance(item)}
                        />
                      ) : (
                        <Button
                          size="mini"
                          basic
                          compact
                          color="green"
                          content=" Check In"
                          onClick={() => onCheckAttendance(item)}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
              </>
            ))}
          </Table.Body>
          <Table.Body>
            <Table.Row key="endoftable">
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell textAlign="center">
                <b>Total </b>
              </Table.Cell>
              <Table.Cell textAlign="center" id="idtotalStudent"></Table.Cell>
              {/* <Table.Cell textAlign="center" id="idtotalAttended"></Table.Cell>
              <Table.Cell textAlign="center" id="idTotalAbsent"></Table.Cell>
              <Table.Cell textAlign="center" id="idTotalLate"></Table.Cell>
              <Table.Cell textAlign="center" id="idTotalLeave"></Table.Cell>
              <Table.Cell></Table.Cell> */}
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
      <ModalBasic
        open={open}
        size="big"
        content="Attendance"
        close={onClose}
        closeIcon={show}
      >
        {currentData && (
          <Wrap>
            <Header as="h2" textAlign="center">
              {currentData.name}
            </Header>
            <Grid centered>
              <Grid.Column>
                <List className="bold" style={{ paddingLeft: "20%" }}>
                  <List.Item>
                    <b>
                      <Icon name="clock" /> Date &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp;:&nbsp;
                    </b>
                    {moment(date).format("ddd, MMM D ")}
                  </List.Item>
                  <List.Item>
                    <b>
                      <Icon name="user circle" /> Teacher &nbsp; &nbsp;&nbsp;
                      &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; :
                    </b>{" "}
                    {currentData.teacher}
                  </List.Item>
                  <List.Item>
                    <b>
                      <Icon name="user circle" /> TA. &nbsp; &nbsp;&nbsp; &nbsp;
                      &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; :
                    </b>{" "}
                    {currentData.ta || "-"}
                  </List.Item>
                  <List.Item>
                    <b>
                      <Icon name="user circle" /> Nanny &nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; :
                    </b>{" "}
                    {currentData.nanny || "-"}
                  </List.Item>
                  <List.Item>
                    <b>
                      <Icon name="users circle" /> Student &nbsp; &nbsp;&nbsp;
                      &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; :
                    </b>{" "}
                    {currentData.attendees && currentData.attendees.length}
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
            <br />
            <br />
            <Table
              compact
              celled
              style={{
                marginBottom: "30px",
              }}
            >
              <Table.Header>
                <Table.Row key="subTableHead">
                  <Table.HeaderCell
                    textAlign="center"
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#fff",
                    }}
                  ></Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#fff",
                    }}
                  >
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#fff",
                    }}
                  >
                    Allergy
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#fff",
                    }}
                  >
                    Check In
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#fff",
                    }}
                  >
                    Extended Hour
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {currentData.attendees.length > 0 &&
                  currentData.attendees.map((item, index) => {
                    return (
                      <Table.Row key={item.id}>
                        <Table.Cell verticalAlign="top">
                          <Image src={item.imageURL} size="small" />
                        </Table.Cell>
                        <Table.Cell verticalAlign="top" width="10">
                          {item.firstName} {item.lastName}
                        </Table.Cell>
                        <Table.Cell verticalAlign="top" width="10">
                          {item.allergy}
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          width="6"
                          verticalAlign="top"
                        >
                          {show ? (
                            <Wrap>
                              {item.attendees === "Attended" ? (
                                <Label className="statusLabel" color="green">
                                  Attended
                                </Label>
                              ) : item.attendees === "Leave" ? (
                                <Label className="statusLabel" color="orange">
                                  Leave
                                </Label>
                              ) : (
                                <Label className="statusLabel" color="red">
                                  Absent{" "}
                                </Label>
                              )}
                            </Wrap>
                          ) : item.school_attendance &&
                            item.school_attendance.checkin_at ? (
                            <Dropdown
                              selection
                              name="attendance"
                              key={item.id}
                              //   defaultValue={"Attended"}
                              //   value={item.attendees || "Attended"}
                              placeholder="Select status"
                              value={item.attendees}
                              options={[
                                {
                                  key: "Attended",
                                  text: "Attended",
                                  value: "Attended",
                                },
                                {
                                  key: "Absent",
                                  text: "Absent",
                                  value: "Absent",
                                },
                                {
                                  key: "Late",
                                  text: "Late",
                                  value: "Late",
                                },
                                {
                                  key: "Leave",
                                  text: "Leave",
                                  value: "Leave",
                                },
                              ]}
                              onChange={(e, { value }) =>
                                handleLessonChange(value, index)
                              }
                            />
                          ) : (
                            <div>
                              <p style={{ color: "red" }}>
                                School Not Checked in
                              </p>
                              <Dropdown
                                selection
                                name="absent"
                                key={item.id}
                                // defaultValue={"Absent"}
                                // value={item.attendees || "Absent"}
                                placeholder="Select status"
                                value={item.attendees}
                                options={[
                                  {
                                    key: "Attended",
                                    text: "Attended",
                                    value: "Attended",
                                  },
                                  {
                                    key: "Absent",
                                    text: "Absent",
                                    value: "Absent",
                                  },
                                  {
                                    key: "Late",
                                    text: "Late",
                                    value: "Late",
                                  },
                                  {
                                    key: "Leave",
                                    text: "Leave",
                                    value: "Leave",
                                  },
                                ]}
                                onChange={(e, { value }) =>
                                  handleLessonChange(value, index)
                                }
                              />
                            </div>
                          )}
                        </Table.Cell>
                        <Table.Cell verticalAlign="top" width="10">
                          <Checkbox
                            key={index}
                            name={index}
                            value={item.id}
                            defaultChecked={item.extended}
                            onChange={handleEventChange}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Wrap>
        )}
        {!show && (
          <Wrap>
            <br />
            <br />
            <div>
              <SecondButton
                floated="right"
                content="Cancel"
                color="grey"
                onClick={onClose}
              />
              {currentData && currentData.attendees.length > 0 && (
                <Button
                  floated="left"
                  content="Confirm"
                  style={{
                    backgroundColor: config.colorTheme,
                    color: "#ffffff",
                    minWidth: "30%",
                  }}
                  loading={loading}
                  onClick={onSubmitAttendance}
                />
              )}
              {/* <Button floated='left' content='Save'
                                style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                                loading={loading}
                                onClick={onSubmitAttendance} /> */}
            </div>
            <br />
            <br />
          </Wrap>
        )}
      </ModalBasic>
    </Segment>
  );
};
export default withRouter(ClassList);
