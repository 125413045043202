import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import * as config from '../../config'
import {
    Button,
    Header,
    Icon,
    Modal,
    Segment,
    Form,
    Grid,
    Breadcrumb
} from 'semantic-ui-react';
import Wrap from "../../components/wrap";
import TableList from "../../components/table";
import {
    DateInput,
    DatesRangeInput
} from 'semantic-ui-calendar-react';
import {
    getApplication,
    exportApplication,
    reportApplicationCountPending, reportApplicationCountConfirm, reportApplicationCountCancel, reportApplicationCountApproved, reportApplicationCountReject, reportApplicationCountAll
} from "./service";


const ApplicationReport = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [per_page, setPerPage] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [countPading, setCountPading] = useState([]);
    const [countConfirm, setCountConfirm] = useState([]);
    const [countCancel, setCountCancel] = useState([]);
    const [countApproved, setCountApproved] = useState([]);
    const [countReject, setCountReject] = useState([]);
    const [countAll, setCountAll] = useState([]);
    const [last_page, setLastPage] = useState(1);
    const [values, setValues] = useState({ class: '', term: '', status_visit: '', status_interview: '' });
    const [search, setSearch] = useState({ name: null });
    const [errormsg, setErrormsg] = useState(null)
    const [loadPopup, setLoadPopup] = useState(null)
    const [date, setDate] = useState(moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD"));
    const [visit_date, setvisit_date] = useState(moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD"));
    const [column, setColumn] = useState([
        {
            Header: "No.",
            accessor: "",
            style: { textAlign: "center" },
            sortable: false,
            Cell: (cellInfo) => cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
            width: 50,
        },
        {
            Header: "Application No.",
            accessor: "id",
            sortable: false,
            width: 100,
            Cell: (cellInfo) => "A000" + cellInfo.original.id
        },
        {
            Header: "Student Name",
            accessor: "name",
            sortable: false,
            width: 150,
            Cell: (cellInfo) => (
                <Wrap>
                    {cellInfo.original.firstName} {cellInfo.original.lastName}
                </Wrap>
            ),
        },
        {
            Header: "Visit Date",
            accessor: "dateTimeOfVisit",
            style: { textAlign: "left" },
            sortable: false,
        },
        {
            Header: "Interview Date",
            accessor: "interview_date",
            style: { textAlign: "center" },
            sortable: false,
        },
        {
            Header: "Interview by",
            accessor: "interview_by",
            style: { textAlign: "center" },
            sortable: false,
        },
        {
            Header: "Class Enlloed",
            accessor: "class",
            style: { textAlign: "center" },
            sortable: false,
        },
        {
            Header: "Term Enlloed",
            accessor: "term",
            style: { textAlign: "center" },
            sortable: false,
        },
        {
            Header: "Status visit",
            accessor: "status",
            style: { textAlign: "center" },
            sortable: false,
        },
        {
            Header: "Status Interview",
            accessor: "status_interview",
            style: { textAlign: "center" },
            sortable: false,
        }
    ]);

    useEffect(() => {
        if (date)
          getData();
      }, [date, visit_date, values.class, values.term, values.status_visit, values.status_interview])

    const getData = () => {
        setLoading(true)
        if (values.status_interview && values.status_interview !== "") qString = qString + "&status_interview=" + values.status_interview;
        if (values.term && values.term !== "All") qString = qString + "&term=" + values.term;
        if (values.class && values.class !== "All") qString = qString + "&class=" + values.class;
        if (search.name) qString = qString + "&name=" + search.name;

        const val = date.split(" - ");
        let start = val[0];
        let end = val[1];
        let qString = "";
        if (start && end) {
            qString = "?start=" + start + "&end=" + end;
        }

        getApplication(qString + "&status_interview=" + values.status_interview + "&term=" + values.term + "&class=" + values.class).then(res => {
            if (res && res.status === 200) {
                setColumn(column)
                setData(res.data)
            }
            setLoading(false)
        })


        reportApplicationCountPending().then(res => {
            if (res && res.status === 200) {
                setCountPading(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportApplicationCountConfirm().then(res => {
            if (res && res.status === 200) {
                setCountConfirm(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportApplicationCountCancel().then(res => {
            if (res && res.status === 200) {
                setCountCancel(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportApplicationCountApproved().then(res => {
            if (res && res.status === 200) {
                setCountApproved(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportApplicationCountReject().then(res => {
            if (res && res.status === 200) {
                setCountReject(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportApplicationCountAll().then(res => {
            if (res && res.status === 200) {
                setCountAll(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })

    };

    const handleSelectChange = (e, data) => {
        const { name, value } = data
        setValues({ ...values, [name]: value })
    }
    const handleChangeDate = (event, { name, value }) => {
        setDate(value)
    };

    const onClickExport = () => {
        setLoadPopup(true)
        if (values.status_interview && values.status_interview !== "") qString = qString + "&status_interview=" + values.status_interview;
        if (values.class && values.class !== "All") qString = qString + "&class=" + values.class;
        if (values.term && values.term !== "All") qString = qString + "&term=" + values.term;
        if (search.name) qString = qString + "&name=" + search.name;

        const val = date.split(" - ");
        let start = val[0];
        let end = val[1];
        let qString = "";
        if (start && end) {
            qString = "?start=" + start + "&end=" + end;
        }

        exportApplication(qString).then((res) => {
            if (res && res.status === 200) {
                setLoadPopup(false)
                var newBlob = new Blob([res.data]);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    var newBlobIE = new Blob([res.data], {
                        type:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    return navigator.msSaveOrOpenBlob(
                        newBlobIE,
                        "visit_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
                    );
                }
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement("a");
                link.href = data;
                // this.setState({link: link.href });
                link.download =
                    "application-Report" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL

                    window.URL.revokeObjectURL(data);
                }, 100);
            } else {
                setErrormsg("The request is taking too long. Please try again");
            }
        });

    }

    return (
        <Segment basic textAlign='left'>
            <Header as='h2' >Application Report</Header>

            <Grid columns='2' >
                <Grid.Column>
                    <Breadcrumb>
                        <Breadcrumb.Section
                            onClick={() => props.history.push("/report")}
                            style={{ color: "grey" }}
                        >
                            Report
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider icon="right angle" />
                        <Breadcrumb.Section active>Application Report</Breadcrumb.Section>
                    </Breadcrumb>
                </Grid.Column>
                <Grid.Column>
                    <Button basic color='green'
                        content='Export Excel' floated='right' style={{ marginBottom: '14px' }}
                        onClick={onClickExport} />
                </Grid.Column>
            </Grid>
            <Segment className="contentSegment" textAlign="left" style={{ minHeight: '50vh', padding: '20px' }}>
                <Form>
                    <Grid columns='5'>
                        <Grid.Column>
                            <DatesRangeInput
                                name="date"
                                placeholder="Start - End"
                                hideMobileKeyboard
                                dateFormat="YYYY-MM-DD"
                                iconPosition="right"
                                value={date}
                                allowSameEndDate
                                onChange={handleChangeDate}
                                clearable
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Dropdown
                                icon={null}
                                fluid
                                floating
                                style={{ width: "100%", marginRight: "30px" }}
                                name='term'
                                trigger={
                                    <Button
                                        basic
                                        icon
                                        fluid
                                        labelPosition="right"
                                        style={{ textAlign: "left" }}
                                    >
                                        <Icon name="angle down" />
                                        Term : <b>{values.term}</b>
                                    </Button>
                                }
                                options={[{ key: " ", text: "All", value: " " }, ...config.option_termenrolled]}
                                defaultValue={values.term}
                                selectOnBlur={false}
                                onChange={handleSelectChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Dropdown
                                icon={null}
                                fluid
                                floating
                                style={{ width: "100%", marginRight: "30px" }}
                                name='class'
                                trigger={
                                    <Button
                                        basic
                                        icon
                                        fluid
                                        labelPosition="right"
                                        style={{ textAlign: "left" }}
                                    >
                                        <Icon name="angle down" />
                                        Class : <b>{values.class}</b>
                                    </Button>
                                }
                                options={[{ key: " ", text: "All", value: " " }, ...config.option_classenrolled]}
                                defaultValue={values.class}
                                selectOnBlur={false}
                                onChange={handleSelectChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Dropdown
                                icon={null}
                                fluid
                                floating
                                style={{ width: "100%", marginRight: "30px" }}
                                name='status_interview'
                                trigger={
                                    <Button
                                        basic
                                        icon
                                        fluid
                                        size="small"
                                        labelPosition="right"
                                        style={{ textAlign: "left" }}
                                    >
                                        <Icon name="angle down" />
                                        Status Interview : <b>{values.status_interview}</b>
                                    </Button>
                                }
                                options={[
                                    { key: "", text: "All", value: "" },
                                    { key: "Pending", text: "Pending", value: "Pending" },
                                    { key: "Confirm", text: "Confirm", value: "Confirm" },
                                    { key: "Cancel", text: "Cancel", value: "Cancel" },
                                    { key: "Approved", text: "Approved", value: "Approved" },
                                    { key: "Reject", text: "Reject", value: "Reject" }
                                ]}
                                defaultValue={values.status_interview}
                                selectOnBlur={false}
                                onChange={handleSelectChange}
                            />
                        </Grid.Column>
                    </Grid>
                    <Grid.Column>
                        <b>Total Application: {countAll.length}</b><br></br>
                        <span>Pending: {countPading.length}</span><br></br>
                        <span>Confirm: {countConfirm.length}</span><br></br>
                        <span>Cancel: {countCancel.length}</span><br></br>
                        <span>Approved: {countApproved.length}</span><br></br>
                        <span>Reject: {countReject.length}</span>
                    </Grid.Column>
                </Form>
                <br></br>

                <TableList
                    loading={loading}
                    data={data}
                    columns={column}
                />

            </Segment>
            <Modal
                size={errormsg ? "tiny" : "mini"}
                open={loadPopup}
                closeIcon={errormsg ? true : false}
                dimmer="blurring"
                closeOnDimmerClick={false}
                onClose={() =>
                    setLoadPopup(false)
                }
            >
                <Wrap>
                    {errormsg ? (
                        <Wrap>
                            <Modal.Header>
                                <Icon name="warning sign" /> Warning
                            </Modal.Header>
                            <Modal.Content>{errormsg}</Modal.Content>
                        </Wrap>
                    ) : (
                        <Modal.Content>
                            <div style={{ textAlign: "center" }}>
                                <Icon loading name="circle notch" />
                                <br />
                                Waiting Download File
                            </div>
                        </Modal.Content>
                    )}
                </Wrap>
            </Modal>

        </Segment>
    );
}
export default ApplicationReport;
