import axios from "axios";
import * as config from "../../../config";

export function getdocumentbyvisit_idbystudent_id(id) {
    return axios
        .get(config.urlAPI + "/getdocumentbyvisit_idbystudent_id/"+id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getdocument(student_id) {
    return axios
        .get(config.urlAPI + "/getdocument/"+student_id)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype1(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype1/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype2(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype2/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype3(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype3/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype4(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype4/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype5(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype5/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype6(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype6/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function updatedoctype7(data, student_id) {
    return axios
        .post(
            config.urlAPI + "/doctype7/upload/" + student_id,
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

