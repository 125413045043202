import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import TableList from "../../components/table";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Modal,
  Header,
  Icon,
  Image,
  Grid,
  Table,
  Divider,
  Card,
  Item,
  Segment,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
// import BookDamage from "./bookdamage.js"
import BookDefectForm from "../Library/formDefectBook.js"; //NEW
import {
  postReturnBook,
  getDataBooking,
  countDataBooking,
  getDataDamage,
  damageDetail,
  getBorrowBook,
  insertDefectBook,
} from "./service";

const BookReturn = (props) => {
  const { bookID, bookingID, userData, data } = props;
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();
  // const [data, setData] = useState(props);
  // const [dataDamage, setDataDamage] = useState();
  const [currentData, setCurrentData] = useState(null);
  const [getBooking, setBooking] = useState([]);
  const [showPopupDefective, setShowPopupDefective] = useState(false);
  const [open, setOpen] = useState(false);
  const [returnRow, setReturnRow] = useState(null);
  const [defectData, setDefectData] = useState(null);
  const [DefectImageUrl, setDefectImageUrl] = useState(null);
  const [showPopupDefect, setShowPopupDefect] = useState(false);
  const [errormsg, setErrormsg] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [defectLoading, setDefectLoading] = useState(false);
  const [loadMsgPopup, setLoadMsgPopup] = useState(null); //for common popup msg
  const [defectID, setDefectID] = useState(null);

  // console.log(data.location.state.data['id'])

  const showPopup = () => {
    setOpen(true);
  };
  const popUpClose = () => {
    setOpen(false);
    // window.location.reload();
    // window.close();
  };

  // useEffect(() => {
  //   setData(props);
  //   getData();
  // }, [props.data]);

  // const getData = () => {
  //   getDataBooking(data.location.state.data["id"]).then((res) => {
  //     if (res && res.status === 200) {
  //       setBooking(res.data);
  //     }
  //     setLoading(false);
  //   });

  //   getDataDamage(data.data.id).then((res) => {
  //     if (res && res.status === 200) {
  //       setDataDamage(res.data[0]);
  //     }
  //     setLoading(false);
  //   });
  // };

  const deleteBorrow = () => {
    setLoading(true);
    var dataSubmit = {
      book_id: bookID,
    };

    console.log(`TEST-deleteBorrow:`, dataSubmit);

    postReturnBook(dataSubmit).then((res) => {
      console.log(`TEST-postReturnBook:`, res);
      if (res && res.status === 200 && res.data.success === true) {
        console.log(res.data.message);
        setLoading(false);
        setOpen(false);
        window.location.reload();
      } else {
        console.log(res.data.message);
      }
    });
  };

  // const damage = (values) => {
  //   setLoading(true);
  //   let dataDamage = values;
  //   damageDetail(values).then((res) => {
  //     if (res && res.status === 200) {
  //       getData();
  //     }
  //     setCurrentData(null);
  //   });
  // };

  const onEditDefective = (values) => {
    setShowPopupDefective(true);
    setCurrentData(values);
  };
  const onCloseDefective = () => {
    setShowPopupDefective(false);
    setCurrentData(null);
  };

  const updateDefect = (values) => {
    setErrormsg(null);
    setDefectLoading(true); //save button
    setLoadMsgPopup(true); //warning msg

    values["record_by"] = userData.id;
    values["book_id"] = returnRow.book_id;
    values["booking_id"] = returnRow.booking_id;
    console.log(`TEST-updateDefect`, values);

    // // Protect human error
    // // >> Verify defect & reject qty must not null
    // if (values.defect_qty == null && values.reject_qty == null) {
    //   setErrormsg("Empty quantity!, please fill in quantity.");
    //   setDefectLoading(false);
    //   return;
    // }
    // // >> Verify defect & reject qty must not be zero or must not less then zero
    // if (parseInt(values.defect_qty) <= 0 || parseInt(values.reject_qty) <= 0) {
    //   setErrormsg(
    //     "Wrong quantity! (Must not less than zero or Must not be zero)"
    //   );
    //   setDefectLoading(false);
    //   return;
    // }
    // // >> Verify defect & reject qty must not more than borrow qty
    // let sumqty = 0;
    // if (values.reject_qty) sumqty = parseInt(values.reject_qty);
    // if (values.defect_qty) sumqty = sumqty + parseInt(values.defect_qty);
    // if (parseInt(returnRow.qty) < parseInt(sumqty)) {
    //   setErrormsg("Over borrow quantity! ...please try again");
    //   setDefectLoading(false);
    //   return;
    // }

    //Add defect item
    insertDefectBook(values).then((res) => {
      console.log(`insertDefectBook`, res);

      if (res && res.status === 201) {
        // loadHistoryDetail(returnRow.request_no);
        // loadHistoryDetail();
        setDefectID(res.data.defect_id);
        setLoadMsgPopup(false);
        // setShowPopupDefect(false);  //Cancel

        // showCompletePopup();
      } else {
        setErrormsg(res.data.message);
      }
      setDefectLoading(false);
    });
  };

  const onItemDefect = () => {
    let qString = "?book_id=" + bookID + "&booking_id=" + bookingID;
    console.log(`onItemDefect`, qString);

    getBorrowBook(qString).then((res) => {
      console.log(`getBorrowBook`, res);
      if (res && res.status === 200) {
        setReturnRow(res.data[0]);
        setDefectData(res.data[0]);
        setDefectImageUrl(res.data[0].imageURL);
        setShowPopupDefect(true);
      }
      // setLoading(false);
    });

    // setShowPopupDefect(true); //TEST only
  };

  return (
    <Grid>
      <br />
      <br />
      <Header as="h2">Book Detail</Header>
      <Table style={{ borderRadius: 0, fontSize: "95%", overflowx: "auto" }}>
        <Table.Header
          style={{
            boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
            textAlign: "center",
          }}
        >
          <Table.Row>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "100px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Image
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Category
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Item Code
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Brand
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Model
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              ISBN
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Name(EN)
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Age range
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Author
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Quantity
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Defective
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="center">
              <Image
                width="100px"
                verticalAlign="middle"
                src={
                  data && data.imageURL
                    ? data.imageURL + "?time=" + new Date()
                    : "../storage/imgs/dfkid.png"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../storage/imgs/dfkid.png";
                }}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">{data.category}</Table.Cell>
            <Table.Cell textAlign="center">{data.book_code}</Table.Cell>
            <Table.Cell textAlign="center">{data.brand}</Table.Cell>
            <Table.Cell textAlign="center">{data.model}</Table.Cell>
            <Table.Cell textAlign="center">{data.isbn}</Table.Cell>
            <Table.Cell textAlign="center">{data.name_en}</Table.Cell>
            <Table.Cell textAlign="center">{data.age_range}</Table.Cell>
            <Table.Cell textAlign="center">{data.author}</Table.Cell>
            <Table.Cell textAlign="center">1</Table.Cell>
            <Table.Cell textAlign="center">
              <Icon
                link
                style={{ color: "red", fontSize: "29px" }}
                name="warning circle"
                // onClick={() => onEditDefective(data)}
                onClick={() => onItemDefect()}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <br />

      <Button
        floated="left"
        content="Save"
        style={{
          backgroundColor: config.colorTheme,
          color: "#ffffff",
          minWidth: "30%",
          marginRight: "10%",
        }}
        onClick={() => showPopup()}
      />
      <br />

      {/* Cancel */}
      {/* <ModalBasic
        open={showPopupDefective}
        size="small"
        close={() => onCloseDefective()}
      >
        <BookDamage
          onSubmit={damage}
          onClose={() => onCloseDefective(false)}
          loading={loading}
          initialValues={dataDamage}
        />
      </ModalBasic> */}

      {/* Defect form */}
      <ModalBasic
        size="large"
        open={showPopupDefect}
        content="Book Damaged"
        close={() => setShowPopupDefect(false)}
      >
        <BookDefectForm
          onSubmit={updateDefect}
          onClose={() => setShowPopupDefect(false)}
          // loading={loading}
          loading={defectLoading}
          initialValues={defectData}
          errormsg={errormsg}
          imageURL={DefectImageUrl}
          data={defectData}
          viewOnly={viewOnly}
          defectID={defectID}
        />
      </ModalBasic>

      <ModalBasic open={open} size="small" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          Confirm Return?
        </Header>
        <center>
          <Button
            content="Cancel"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "15%",
            }}
            onClick={() => popUpClose()}
          />
          &nbsp;&nbsp;
          <Button
            // floated="left"
            content="OK"
            // fluid
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "15%",
            }}
            loading={loading}
            onClick={deleteBorrow}
          />{" "}
        </center>
      </ModalBasic>
    </Grid>
  );
};

export default withRouter(BookReturn);
