import React from "react";
import { Field, reduxForm } from "redux-form";
import renderField from "../../components/renderField";
import renderFileField from "../../components/renderFileField";
import { Button, Form, Message, Icon } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from '../../config';

const validate = values => {
    const errors = {}
    if (!values.name) errors.name = true
    if (!values.phone) errors.phone = true
    if (!values.relationship) errors.relationship = true
    if (!values.photo) errors.photo = true
    return errors;
};


let OtherparentForm = (props) => {
    const { loading, pristine, submitting, handleSubmit, onSubmit, onClose } = props;
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group unstackable>
                <Field
                    name="name"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Name"
                />
                <Field
                    name="phone"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Phone"
                />
            </Form.Group>
            <Form.Group unstackable>
                <Field
                    name="relationship"
                    type="text"
                    width='8'
                    required={true}
                    component={renderField}
                    label="Relationship"
                />
            </Form.Group>
            <Form.Group unstackable>
                <Field
                    name="photo"
                    type="file"
                    width='8'
                    required={true}
                    component={renderFileField}
                    label="ID Card"
                />
            </Form.Group>
            <Form.Group unstackable>
                <Field
                    name="photo_other"
                    type="file"
                    width='8'
                    component={renderFileField}
                    label="Other file"
                />
            </Form.Group>
            <br /><br /><br />
            <div>
                <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
                <Button floated='left' content='Save'
                    style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                    loading={loading}
                    disabled={pristine || submitting}
                />
            </div>
            <br /><br />
        </Form>
    );

}

OtherparentForm = reduxForm({
    // a unique name for the form
    form: "otherparent",
    validate,
    enableReinitialize: true
})(OtherparentForm);

export default OtherparentForm;
