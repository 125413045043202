import React from "react";
import { Form } from "semantic-ui-react";
import Wrap from "./wrap";
import { TimeInput } from "semantic-ui-calendar-react";

class renderField extends React.Component {


    render() {
        const state = this.state;
        const {
            label,
            width,
            required,
            input,
            placeholder,
            meta: { touched, error, warning }
        } = this.props;
        return (
            <Wrap>
                <Form.Field style={{ marginBottom: "0px" }}>
                    <label style={{ textAlign: "left" }}>
                        {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
                    </label>
                </Form.Field>
                <TimeInput
                    {...input}
                    value={input.value}
                    iconPosition="left"
                    width={width}
                    onChange={(e, { value }) => input.onChange(value)}
                    hideMobileKeyboard={true}
                    placeholder={placeholder}
                    error={error}
                />
            </Wrap>
        );
    }
}

export default renderField;