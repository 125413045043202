import FormAlert from "./formalert";
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
  Form,
  Label,
  FormGroup,
  Card,
} from "semantic-ui-react";
import ModalBasic from "../components/modal";

import Wrap from "../components/wrap";
import {
  getdatavisitrequestalert,
  updatevisitprofile,
  getinterviewafterapplicationform,
} from "./service";

const Alert = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    class: "",
    course: "",
    status: "Active",
    type: "All",
  });
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [interviewAfterForm, setInterviewAfterForm] = useState([]);
  const timeoutRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, values]);

  const getData = () => {
    getdatavisitrequestalert().then((res) => {
      if (res && res.status === 200) {
        // console.log(`TEST`, res.data); //TEST
        let option = [];
        let optionAppointment = [];
        var d = new Date();
        res.data.map((item) => {
          // เอา date time มา แยกกัน
          let splitDateTimeVisit = item.dateTimeOfVisit;
          const myArrayDateTimeVisit = splitDateTimeVisit.split(" ");

          // split แค่ วันที่
          let date = myArrayDateTimeVisit[0];
          const myArrDate = date.split("-");
          if (
            myArrDate[2] == d.getFullYear() &&
            Number(myArrDate[1]) == d.getMonth() + 1 &&
            Number(myArrDate[0]) == d.getDate()
          ) {
            optionAppointment.push({
              Id: item.Id,
              channel: item.channel,
              firstName: item.firstName,
              lastName: item.lastName,
              created_at: item.created_at,
              dateOfBirth: item.dateOfBirth,
              dateOfVisit: myArrayDateTimeVisit[0],
              TimeOfVisit: myArrayDateTimeVisit[1],
              dateTimeOfVisit: item.dateTimeOfVisit,
              email: item.email,
              gender: item.gender,
              id_campus: item.id_campus,
              campusname: item.campusname,
              id_program: item.id_program,
              programsname: item.programsname,
              mobile: item.mobile,
              nickname: item.nickname,
              parent_firstName: item.parent_firstName,
              parent_lastName: item.parent_lastName,
              parent_nationality: item.parent_nationality,
              status: item.status,
              updated_at: item.updated_at,
            });
          }

          option.push({
            Id: item.Id,
            channel: item.channel,
            firstName: item.firstName,
            lastName: item.lastName,
            created_at: item.created_at,
            dateOfBirth: item.dateOfBirth,
            dateOfVisit: myArrayDateTimeVisit[0],
            TimeOfVisit: myArrayDateTimeVisit[1],
            dateTimeOfVisit: item.dateTimeOfVisit,
            email: item.email,
            gender: item.gender,
            id_campus: item.id_campus,
            campusname: item.campusname,
            id_program: item.id_program,
            programsname: item.programsname,
            mobile: item.mobile,
            nickname: item.nickname,
            parent_firstName: item.parent_firstName,
            parent_lastName: item.parent_lastName,
            parent_nationality: item.parent_nationality,
            status: item.status,
            updated_at: item.updated_at,
          });
        });
        setData2(optionAppointment);
        setData(option);
      }
    });

    getinterviewafterapplicationform().then((res) => {
      if (res && res.status === 200) {
        setInterviewAfterForm(res.data);
      }
      setLoading(false);
    });
  };

  const onEdit = (values) => {
    setOpen(true);
    setCurrentData(values);
  };
  const onClose = () => {
    setOpen(false);
    setCurrentData(null);
  };

  const updateData = (values) => {
    setLoading(true);
    let data = values;
    if (values.Id) {
      updatevisitprofile(values).then((res) => {
        if (res && res.status === 200) {
          getData();
          setOpen(false);
        }
        setCurrentData(null);
        setLoading(false);
      });
    }
  };

  return (
    <Wrap>
      <Header as="h2">Visit Form</Header>
      <Card.Group itemsPerRow="3" style={{ padding: "20px" }}>
        <Card raised style={{ background: "#ffffff" }}>
          <Header
            as="h5"
            style={{
              textAlign: "Left",
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            Visit Request
          </Header>
          <Grid columns="4">
            <Grid.Column style={{ paddingLeft: "40px", color: "#aaa" }}>
              Visit date<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Guardian<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Phone<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              view<br></br>
            </Grid.Column>
          </Grid>
          <br></br>
          {data.map((item) => (
            <>
              {item.status === "Pending" && (
                <>
                  <Grid columns="4">
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {item.dateOfVisit} {item.TimeOfVisit}
                      <br></br>
                    </Grid.Column>
                    <Grid.Column>
                      {item.parent_firstName} {item.parent_lastName}
                      <br></br>
                    </Grid.Column>
                    <Grid.Column>
                      {item.mobile}
                      <br></br>
                    </Grid.Column>
                    <Grid.Column>
                      <u
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() =>
                          props.history.push({
                            pathname: "/application/visitProfile",
                            state: { Id: item.Id },
                          })
                        }
                      >
                        view
                      </u>
                    </Grid.Column>
                  </Grid>
                  <br></br>
                </>
              )}
            </>
          ))}
        </Card>

        <Card raised style={{ background: "#ffffff" }}>
          <Header
            as="h5"
            style={{
              textAlign: "Left",
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            Confirm Visit
          </Header>
          <Grid columns="4">
            <Grid.Column style={{ paddingLeft: "40px", color: "#aaa" }}>
              Visit date<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Guardian<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Phone<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              view<br></br>
            </Grid.Column>
          </Grid>
          <br></br>
          {data.map((item) => (
            <>
              {item.status === "Confirm" && (
                <>
                  <Grid columns="4">
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      {item.dateOfVisit} {item.TimeOfVisit}
                      <br></br>
                    </Grid.Column>
                    <Grid.Column>
                      {item.parent_firstName} {item.parent_lastName}
                      <br></br>
                    </Grid.Column>
                    <Grid.Column>
                      {item.mobile}
                      <br></br>
                    </Grid.Column>
                    <Grid.Column>
                      <u
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() =>
                          props.history.push({
                            pathname: "/application/visitProfile",
                            state: { Id: item.Id },
                          })
                        }
                      >
                        view
                      </u>
                    </Grid.Column>
                  </Grid>
                  <br></br>
                </>
              )}
            </>
          ))}
        </Card>

        <Card raised style={{ background: "#ffffff" }}>
          <Header
            as="h5"
            style={{
              textAlign: "Left",
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            Make an appointment to visit school today
          </Header>
          <Grid columns="4">
            <Grid.Column style={{ paddingLeft: "40px", color: "#aaa" }}>
              Visit date<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Guardian<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Phone<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              view<br></br>
            </Grid.Column>
          </Grid>
          <br></br>
          {data2.map((item) => (
            <>
              {item.TimeOfVisit &&
                Number(item.TimeOfVisit.split(":")[0]) <=
                  new Date().getHours() &&
                item.status === "Confirm" && (
                  <Grid columns="4">
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      <span style={{ color: "red" }}>
                        {item.dateOfVisit} {item.TimeOfVisit}
                        <br></br>
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ color: "red" }}>
                        {item.parent_firstName} {item.parent_lastName}
                        <br></br>
                        <br></br>
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ color: "red" }}>
                        {item.mobile}
                        <br></br>
                        <br></br>
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      <u
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() =>
                          props.history.push({
                            pathname: "/application/visitProfile",
                            state: { Id: item.Id },
                          })
                        }
                      >
                        view
                      </u>
                    </Grid.Column>
                  </Grid>
                )}
              {item.TimeOfVisit &&
                Number(item.TimeOfVisit.split(":")[0]) >
                  new Date().getHours() &&
                item.status === "Confirm" && (
                  <Grid columns="4">
                    <Grid.Column style={{ paddingLeft: "40px" }}>
                      <span style={{ color: "#33CC33" }}>
                        {item.dateOfVisit} {item.TimeOfVisit}
                        <br></br>
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ color: "#33CC33" }}>
                        {item.parent_firstName} {item.parent_lastName}
                        <br></br>
                        <br></br>
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ color: "#33CC33" }}>
                        {item.mobile}
                        <br></br>
                        <br></br>
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      <u
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() =>
                          props.history.push({
                            pathname: "/application/visitProfile",
                            state: { Id: item.Id },
                          })
                        }
                      >
                        view
                      </u>
                    </Grid.Column>
                  </Grid>
                )}
            </>
          ))}
        </Card>
      </Card.Group>

      <ModalBasic open={open} size="big" close={() => onClose()}>
        <FormAlert
          loading={loading}
          initialValues={currentData}
          onClose={() => onClose()}
          onSubmit={updateData}
        />
      </ModalBasic>
    </Wrap>
  );
};
export default withRouter(Alert);
