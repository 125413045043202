import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import {
  Button,
  Form,
  Message,
  Grid,
  SegmentGroup,
  GridColumn,
} from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  // FormField, //Todo
} from "semantic-ui-react";
import { set } from "core-js/core/dict";
import { insertDefect, updateDefectImage } from "./service";

const validate = (values) => {
  const errors = {};
  //   if (!values.item_code) errors.item_code = true;
  //   if (!values.main_type) errors.main_type = true;
  //   if (!values.type) errors.type = true;
  //   if (!values.name_en) errors.name_en = true;
  //   if (!values.name_th) errors.name_th = true;
  //   if (!values.category) errors.category = true;
  //   if (!values.sub_category1) errors.sub_category1 = true;
  //   if (!values.sub_category2) errors.sub_category2 = true;
  //   if (!values.borrow_status) errors.borrow_status = true;
  //   if (!values.status) errors.status = true;
  return errors;
};

let FormDefect = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    // initialValues,
    onClose,
    errormsg,
    imageURL,
    data,
    viewOnly,
    defectID,
  } = props;

  const [photo, setPhoto] = useState(null);
  const [showMsg, setShowMsg] = useState(null);
  const fileInputRef = useRef(null);
  const [inputs, setInputs] = useState({
    detail: "",
    name: "",
    // type: "",
    // category: "",
    // borrow_status: "",
    // // status: "Active",
    // status: "",
  });
  const [imgloading, setImgLoading] = useState(false);

  // useEffect(() => {
  //   console.log(`useEffect-imageURL`, imageURL);
  // }, []);

  const updateImage = () => {
    setImgLoading(true);
    // console.log(`defect_id`, data.defect_id);
    // console.log(`defect_id-New`, defectID);

    var DEFECT_ID = null;
    if (data.defect_id !== null) {
      DEFECT_ID = data.defect_id;
    } else if (defectID !== null) {
      DEFECT_ID = defectID;
    }

    // console.log(`DEFECT_ID`, DEFECT_ID);

    //Verify empty defect_id
    // if (data.defect_id != null) {
    if (DEFECT_ID !== null) {
      var formData = new FormData();
      if (photo) {
        if (typeof photo === "object") formData.append("photo", photo[0]);
        else formData.append("photo", photo);
      }
      // console.log(`updateImage`, formData);
      // console.log(`updateImage`, photo);
      // updateDefectImage(formData, data.defect_id).then((res) => {
      updateDefectImage(formData, DEFECT_ID).then((res) => {
        // console.log(`updateDefectImage`, res);
        if (res && res.status === 200) {
          // getData();
          // setShowPopupImage(false);
          if (res.data.photo) {
            setShowMsg(`Warning! ...${res.data.photo}`);
          } else {
            setShowMsg("Upload image complete.");
          }
        }
        setImgLoading(false);
      });
    } else {
      setShowMsg("Cannot upload image! ...No have defect data");
      setImgLoading(false);
    }
  };

  const notThing = () => {};
  // const updDefectData = (inputs) => {
  //   // setLoadMsgPopup(true);
  //   // setErrormsg(null);

  //   // if (!dataBooking[0]) {
  //   //   setErrormsg("Empty booking list! ...Cannot Confirm booking.");
  //   //   return;
  //   // }

  //   // values["borrow_id"] = borrowid;
  //   // scan["request_no"] = data[0].request_no;

  //   console.log(`updDefectData`, inputs); //Test

  //   // insertDefect(values).then((res) => {
  //   //   console.log(`insertDefect`, res); //Test
  //   //   if (res && res.status === 200) {
  //   //     // setLoadMsgPopup(false);
  //   //     // setMsgConfirm1(res.data.request_no);
  //   //     // setLoadSucPopup(true); //Successful Booking
  //   //     updateImage(res.data.id);
  //   //   }
  //   //   // else if (res.data.cnt_scan === 0 && res.status === 200) {
  //   //   //   setErrormsg("Scan qr update failed!");
  //   //   // }
  //   //   // else {
  //   //   //   setErrormsg(res.data.message);
  //   //   // }
  //   //   // getData();
  //   //   // setShowAssetHistoryDetail(false);
  //   //   // if (res && res.data.cnt_scan > 0 && res.status === 200) {
  //   //   //   props.history.push("/asset/myhistory");
  //   //   // }
  //   // });
  // };

  return (
    <Segment basic textAlign="left">
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Form> */}
        <Grid>
          <Grid.Row>
            <GridColumn>
              <Form.Group unstackable>
                <Field
                  // width="13"
                  name="return_date"
                  type="date"
                  required={false}
                  component={renderField}
                  label="Date"
                />
                <Field
                  // width="15"
                  name="creator"
                  type="text"
                  required={false}
                  component={renderField}
                  label="Creator"
                />

                <Field
                  width="10"
                  min={0}
                  name="defect_qty"
                  type="number"
                  required={false}
                  component={renderField}
                  label="Repair Qty"
                />
                <Field
                  width="10"
                  min={0}
                  name="reject_qty"
                  type="number"
                  required={false}
                  component={renderField}
                  label="Reject Qty"
                />
              </Form.Group>

              <Form.Group unstackable>
                <Field
                  // width="15"
                  name="detail"
                  type="text"
                  required={false}
                  component={renderField}
                  label="Detail"
                />
              </Form.Group>
            </GridColumn>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {viewOnly ? "" : "Step 1"}
              <div>
                <SecondButton
                  floated="right"
                  content="Cancel"
                  color="grey"
                  onClick={onClose}
                />
                {viewOnly ? (
                  <Button
                    floated="left"
                    content="Save"
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                      minWidth: "30%",
                    }}
                    loading={loading}
                    // disabled={pristine || submitting}
                    disabled="true"
                  />
                ) : (
                  <Button
                    floated="left"
                    content="Save"
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                      minWidth: "30%",
                    }}
                    loading={loading}
                    disabled={pristine || submitting}
                    // onClick={updDefectData}
                  />
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Image
                size="medium"
                name="photo"
                centered
                src={imageURL ? imageURL : "../storage/imgs/no-image.jpg"}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../storage/imgs/no-image.jpg";
                }}
              />
              {/* {data && data.authimgurl && (
                <Image
                  size="medium"
                  name="photo"
                  src={data.authimgurl}
                  centered
                />
              )} */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      {/* <br /> */}
      <Grid.Column verticalAlign="middle">
        <Header as="h4" color="green">
          {showMsg}
        </Header>
        <Form.Input
          type="file"
          name="photo"
          onChange={(e) => setPhoto(e.target.files)}
        />
        <br />
        {viewOnly ? "" : "Step 2"}
        <br />
        {viewOnly ? (
          <Button
            floated="left"
            content="Upload"
            // fluid
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
            }}
            // loading={loading}
            // onClick={updateImage}
            disabled="true"
          />
        ) : (
          <Button
            floated="left"
            content="Upload"
            // fluid
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
            }}
            loading={imgloading}
            onClick={updateImage}
          />
        )}
        <SecondButton
          floated="right"
          content="Close"
          color="grey"
          onClick={onClose}
        />
      </Grid.Column>
      <br />
    </Segment>
  );
};

FormDefect = reduxForm({
  // a unique name for the form
  form: "itemdefect",
  validate,
  enableReinitialize: true,
})(FormDefect);

export default FormDefect;
