import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import { Button, Modal, Header, Icon, Dimmer, Loader } from "semantic-ui-react";

const ErrorModal = (props) => {
  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      size="mini"
      style={{ minHeight: "20%" }}
    >
      <Modal.Content>
        {props.loading ? (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        ) : props.errormsg ? (
          <Header as="h2" icon textAlign="center">
            <Icon name="warning circle" color="red" />
            <Header.Content>
              {props.errormsg || "Data not found."}
            </Header.Content>
            <Header.Subheader>
              Something went wrong. Please try again
            </Header.Subheader>
          </Header>
        ) : (
          <Header as="h2" icon textAlign="center">
            <Icon name="check" color="green" />
            <Header.Content>{props.status} success!</Header.Content>
            <Header.Subheader>{moment().format("LLL")}</Header.Subheader>
          </Header>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ErrorModal;
