import axios from "axios";
import * as config from "../../config";


export function getRole() {
    return axios
        .get(config.urlAPI + "/role", config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}


export function postRole(data) {
    return axios
        .post(config.urlAPI + "/role", data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}


export function updateRole(data) {
    return axios
        .put(config.urlAPI + "/role/" + data.id, data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function deleteRole(id) {
    return axios
        .delete(config.urlAPI + "/role/" + id, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function getRolesWithPermission() {
    return axios
        .get(config.urlAPI + "/role/with/permission", config.headerCallAPI)
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.response;
        });
}

export function postRolesWithPermission(data) {
    return axios
        .post(config.urlAPI + "/role/add/permission", data, config.headerCallAPI)
        .then(res => {
            return res;
        })
        .catch(err => {
            return err.response;
        });
}
