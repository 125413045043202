import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { connect } from "react-redux";
import moment from "moment";

import { Button, Form, Message } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { getstudentForRegistClass, getstudentClassHistory } from "./service";

const validate = (values) => {
  const errors = {};

  if (!values.name) errors.name = true;
  if (!values.start) errors.term = true;
  if (!values.end) errors.term = true;
  // if (!values.end) errors.end = true
  if (!values.teacher_id) errors.teacher_id = true;
  if (!values.status) errors.status = true;

  return errors;
};

let ClassForm = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    master,
    term,
    start,
    end,
    course,
    onClose,
    teacher_option,
    ta_option,
    term_option,
    nanny_option,
    errormsg,
    viewOnly,
  } = props;
  const [student, setStudent] = useState(props.student_option);

  useEffect(() => {
    if (term) {
      props.dispatch(change("class", "start", term.start));
      props.dispatch(change("class", "end", term.end));
      props.dispatch(change("regist", "student", null));
      let qString = "?course=" + course.id + "&term=" + term.id;
      if (props.initialValues && props.initialValues.id)
        qString = qString + "&class_id=" + props.initialValues.id;

      if (viewOnly) {
        getstudentClassHistory(qString).then((res) => {
          if (res && res.status === 200) {
            let option = [];
            res.data.map((item) => {
              option.push({
                key: item.id,
                text: `${item.firstName} ${item.lastName} `,
                value: item.id,
              });
            });
            setStudent(option);
          }
        });
      } else {
        getstudentForRegistClass(qString).then((res) => {
          if (res && res.status === 200) {
            let option = [];
            res.data.map((item) => {
              option.push({
                key: item.id,
                text: `${item.firstName} ${item.lastName} `,
                value: item.id,
              });
            });
            setStudent(option);
          }
        });
      }
    }
  }, [term]);

  useEffect(() => {
    if (!term && start && end) {
      if (viewOnly) {
        getstudentClassHistory(
          "?course=" + course.id + "&start=" + start + "&end=" + end
        ).then((res) => {
          if (res && res.status === 200) {
            let option = [];
            res.data.map((item) => {
              option.push({
                key: item.id,
                text: `${item.firstName} ${item.lastName} `,
                value: item.id,
              });
            });
            setStudent(option);
          }
        });
      } else {
        getstudentForRegistClass(
          "?course=" + course.id + "&start=" + start + "&end=" + end
        ).then((res) => {
          if (res && res.status === 200) {
            let option = [];
            res.data.map((item) => {
              option.push({
                key: item.id,
                text: `${item.firstName} ${item.lastName} `,
                value: item.id,
              });
            });
            setStudent(option);
          }
        });
      }
    }
  }, [start, end]);

  // console.log(props, "ddd");
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="name"
        type="text"
        component={renderField}
        required={true}
        label="Class name"
      />
      {errormsg && (
        <Message negative>
          <Message.Content>{errormsg}</Message.Content>
        </Message>
      )}

      {master ? (
        <Field
          name="term"
          component={renderSelectField}
          label="Term"
          required={true}
          options={term_option}
        />
      ) : (
        <Form.Group>
          <Field
            name="start"
            maxDate={end}
            width="8"
            label=""
            component={renderDateOnlyField}
          />
          <Field
            name="end"
            minDate={start}
            width="8"
            label=""
            component={renderDateOnlyField}
          />
        </Form.Group>
      )}

      <Field
        name="teacher"
        component={renderSelectField}
        label="Teacher"
        required={true}
        options={teacher_option}
      />
      <Form.Group unstackable>
        <Field
          name="ta"
          component={renderSelectField}
          label="TA."
          options={ta_option}
        />
        <Field
          name="nanny"
          component={renderSelectField}
          label="Nanny"
          options={nanny_option}
        />
      </Form.Group>
      {/* <Field
                name="extended"
                width="16"
                label={<label>Extended Hours Program</label>}
                component={renderCheckboxField}
            /> */}
      <Field
        name="attendees"
        component={renderSelectField}
        label="Students"
        search
        multiple={true}
        options={student}
      />
      <Field
        id="remark"
        name="remark"
        type="text"
        component={renderField}
        label="Remark"
      />
      <Field
        width="6"
        name="status"
        component={renderSelectField}
        label="Status"
        required={true}
        options={config.options_status_course}
      />
      <br />
      <br />
      <br />
      <div>
        <SecondButton
          floated="right"
          content="Cancel"
          color="grey"
          onClick={onClose}
        />
        {viewOnly ? (
          ""
        ) : (
          <Button
            floated="left"
            content="Save"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
            }}
            loading={loading}
            disabled={pristine || submitting}
          />
        )}
      </div>
      <br />
      <br />
    </Form>
  );
};

ClassForm = reduxForm({
  // a unique name for the form
  form: "class",
  validate,
  enableReinitialize: true,
})(ClassForm);

const selector = formValueSelector("class");
ClassForm = connect((state) => {
  const term = selector(state, "term");
  const start = selector(state, "start");
  const end = selector(state, "end");
  return {
    term: term,
    start: start,
    end: end,
  };
})(ClassForm);

export default ClassForm;
