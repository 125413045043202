import React from "react";
import { Field, reduxForm } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderEditorField from "../../components/renderEditorField";
import renderCheckboxGroupField from "../../components/renderCheckboxGroupField";
import renderTimeField from "../../components/renderTimeField";
import { Button, Form, Message, Icon } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import Wrap from "../../components/wrap";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

const validate = (values) => {
  const errors = {};
  if (!values.campus) errors.campus = true;
  if (!values.program) errors.program = true;
  if (!values.name) errors.name = true;
  if (values.halfStart) {
    if (!values.halfEnd) errors.halfEnd = true;
  }
  if (values.halfEnd) {
    if (!values.halfStart) errors.halfStart = true;
  }
  if (values.fullStart) {
    if (!values.fullEnd) errors.fullEnd = true;
  }
  if (values.fullEnd) {
    if (!values.fullStart) errors.fullStart = true;
  }
  if (!values.status) errors.status = true;
  return errors;
};

let CourseForm = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    initialValues,
    onClose,
    campus_option,
    program_option,
    errormsg,
  } = props;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {errormsg && (
        <Message negative>
          <Message.Content>{errormsg}</Message.Content>
        </Message>
      )}

      <Form.Group unstackable>
        <Field
          name="campus"
          component={renderSelectField}
          label="Campus"
          required={true}
          options={campus_option}
        />
        <Field
          name="program"
          component={renderSelectField}
          label="Program"
          required={true}
          options={program_option}
        />
      </Form.Group>
      <Field
        name="name"
        type="text"
        component={renderField}
        required={true}
        label="Sub Program"
      />

      <Field
        id="desc"
        name="desc"
        type="text"
        dfvalue={initialValues && initialValues.desc}
        component={renderEditorField}
        label="Age (Description)"
      />
      <Field
        id="period"
        name="period"
        type="text"
        dfvalue={initialValues && initialValues.period}
        component={renderEditorField}
        label="Period (Description)"
      />

      {(initialValues && initialValues.master) ||
      (props.program && props.program.master) ? (
        <Wrap>
          <Form.Group unstackable>
            <Field
              name="halfStart"
              width="3"
              dfvalue={initialValues && initialValues.halfStart}
              component={renderTimeField}
              label="Half Day"
              placeholder="Start"
            />
            <Field
              name="halfEnd"
              width="3"
              dfvalue={initialValues && initialValues.halfEnd}
              component={renderTimeField}
              placeholder="End"
            />
          </Form.Group>
          <Form.Group unstackable>
            <Field
              name="fullStart"
              width="3"
              dfvalue={initialValues && initialValues.fullStart}
              component={renderTimeField}
              label="Full Day"
              placeholder="Start"
            />
            <Field
              name="fullEnd"
              width="3"
              dfvalue={initialValues && initialValues.fullEnd}
              component={renderTimeField}
              placeholder="End"
            />
          </Form.Group>
        </Wrap>
      ) : (
        <Form.Group unstackable>
          <Field
            name="fullStart"
            width="3"
            dfvalue={initialValues && initialValues.fullStart}
            component={renderTimeField}
            label="Full Day"
            placeholder="Start"
          />
          <Field
            name="fullEnd"
            width="3"
            dfvalue={initialValues && initialValues.fullEnd}
            component={renderTimeField}
            placeholder="End"
          />
        </Form.Group>
      )}
      <Field
        style={{ paddingLeft: "34px" }}
        name="food"
        component={renderCheckboxGroupField}
        inline
        label="Food"
        options={[
          {
            key: "Lunch",
            name: "Lunch",
            label: "Lunch",
            value: "Lunch",
          },
          {
            key: "Snack",
            name: "Snack",
            label: "Snack",
            value: "Snack",
          },
          {
            key: "Lunch and Snack",
            name: "Lunch and Snack",
            label: "Lunch and Snack",
            value: "Lunch and Snack",
          },
        ]}
      />

      <Field
        id="remark"
        name="remark"
        type="text"
        dfvalue={initialValues && initialValues.remark}
        component={renderEditorField}
        label="Remark"
      />
      <Field
        width="6"
        name="status"
        component={renderSelectField}
        label="Status"
        required={true}
        options={config.options_status_course}
      />
      <br />
      <br />
      <br />
      <div>
        <SecondButton
          floated="right"
          content="Cancel"
          color="grey"
          onClick={onClose}
        />
        <Button
          floated="left"
          content="Save"
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffffff",
            minWidth: "30%",
          }}
          loading={loading}
          disabled={pristine || submitting}
        />
      </div>
      <br />
      <br />
    </Form>
  );
};

CourseForm = reduxForm({
  // a unique name for the form
  form: "course",
  validate,
  enableReinitialize: true,
})(CourseForm);

// Decorate with connect to read form values
const selector = formValueSelector("course"); // <-- same as form name
CourseForm = connect((state) => {
  // can select values individually
  const program = selector(state, "program");

  return {
    program,
  };
})(CourseForm);
export default CourseForm;
