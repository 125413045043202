import React, { useState, useEffect } from "react";
import { withRouter, NavLink } from "react-router-dom";
import * as config from './../config'
import {
  Menu,
  Image,
  Label,
  Popup,
  list,
  Icon,
  Dropdown,
  Form,
  Button,
  Message
} from 'semantic-ui-react';
import { resetPassword } from "./service";
// ModalForm'
import ModalForm from "../components/modal";
import Wrap from "../components/wrap";

const HeaderMenu = (props) => {
  const [userData, setUserData] = useState(props.userData);
  const [openCPassword, setOpenCPassword] = useState(false);
  const [resetpass, setResetpass] = useState({
    confirm_password: {
      value: "",
      validation: {
        required: true,
        minLength: 6,
        maxLength: 32,
      },
      valid: false,
    },
    password: {
      value: "",
      validation: {
        required: true,
        minLength: 6,
        maxLength: 32,
      },
      valid: false,
    },
  }
  );
  const [errormsg, setErrormsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSignOut = () => {
    sessionStorage.clear();
    props.history.push('/');
  }
  const checkValidate = (value, rule) => {
    let isValid = false;
    if (rule.required) {
      isValid = value.trim() === "";
    }
    if (rule.minLength) {
      isValid = value.length < rule.minLength;
    }

    if (rule.maxLength) {
      isValid = value.length > rule.maxLength;
    }
    return isValid;
  };


  const resetpassword = () => {
    setErrormsg(null);
    setLoading(true);
    var x, error;
    for (x in resetpass) {
      resetpass[x].valid = checkValidate(
        resetpass[x].value,
        resetpass[x].validation
      );
      if (resetpass[x].valid) error = true;
      setErrormsg("Please check your password (6-32 characters.)");
      setLoading(false);
    }
    if (!error) {
      if (resetpass.password.value !== resetpass.confirm_password.value) {
        setErrormsg("Password do not match");
        setLoading(false);
      } else {
        resetPassword({
          password: resetpass.password.value,
          password_confirmation: resetpass.confirm_password.value,
        }).then((res) => {
          if (res.status === 200) {
            // alert('Your password has been changed! Please login again')
            setLoading(false);
            onSignOut();
          } else {
            setLoading(false);
          }
        });
        setErrormsg(null);
        setOpenCPassword(false);
      }
    } else {
      setLoading(false);
    }
    setResetpass(resetpass);

  }
  const onChangeFormPassword = (e) => {
    var target = e.target;
    resetpass[target.name].value = target.value;
    setResetpass(resetpass);
  };

  return (
    <Wrap>
      <ModalForm
        icon="key"
        content="Change Password"
        size="mini"
        open={openCPassword}
        close={() => setOpenCPassword(false)}
      >
        <Form onSubmit={resetpassword}>
          <Form.Input
            name="password"
            label="Password"
            type="password"
            placeholder="Password"
            onChange={onChangeFormPassword}
            error={resetpass.password.valid}
          />
          <Form.Input
            name="confirm_password"
            label="Confirm Password"
            type="password"
            placeholder="Confirm Password"
            onChange={onChangeFormPassword}
            error={resetpass.confirm_password.valid}
          />
          {errormsg && (
            <Message negative>
              <p>{errormsg}</p>
            </Message>
          )}
          <br />
          <Button
            floated="left"
            type="submit"
            content="Save"
            color="green"
            loading={loading}
          />
          <Button
            floated="right"
            type="button"
            content="Cancel"
            color="green"
            basic
            onClick={() => setOpenCPassword(false)}
          />
        </Form>
      </ModalForm>


      <Menu.Menu position="right">
        {/* <Menu.Item>
          <Icon
            name="bell outline"
            size="large"
            style={{ margin: 0, color: config.colorTheme }}
          />
          <Label
            className="shake-bottom"
            circular
            size="mini"
            color="pink"
            style={{ margin: 0, marginBottom: "10px" }}
          >
            0
            </Label>
        </Menu.Item> */}
        <Dropdown
          icon="angle down"
          item inline
          text={`${userData.firstName} ${userData.lastName}`}
        // text={`${userData.firstName} ${userData.lastName}`}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              style={{ color: " rgba(0,0,0,.68)" }}
              onClick={() => setOpenCPassword(true)}
            >
              <Icon name="key" style={{ color: " rgba(0,0,0,.68)" }} />
              Change Password
            </Dropdown.Item>
            <Dropdown.Item
              style={{ color: " rgba(0,0,0,.68)" }}
              onClick={onSignOut}
            >
              <Icon name="sign-out" style={{ color: " rgba(0,0,0,.68)" }} />
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Wrap>
  );
}
export default withRouter(HeaderMenu);
