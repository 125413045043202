import React from "react";
import { Button } from "semantic-ui-react";
import * as config from "../config";
import PropTypes from "prop-types";

const MainButton = props => {
  const { size,type, onClick, content, fluid, loading, disable,floated } = props;
  return (
    <Button
      style={{ backgroundColor: config.colorTheme, color: "#ffffff",minWidth: '30%' }}
      type={type || 'button'}
      size={size}
      fluid={fluid}
      loading={loading}
      disabled={disable}
      onClick={onClick}
      content={content}
      floated={floated}
    />
  );
};

MainButton.propTypes = {
  content: PropTypes.string.isRequired
};

export default MainButton;
