import FormAlert from "./formalert";
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
  Form,
  Label,
  FormGroup,
  Card,
} from "semantic-ui-react";
import ModalBasic from "../components/modal";

import Wrap from "../components/wrap";
import { getApplication } from "./service";

const Alert = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    class: "",
    course: "",
    status: "Active",
    type: "All",
  });
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [AfterForm, setAfterForm] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, values]);

  const getData = () => {
    getApplication().then((res) => {
      if (res && res.status === 200) {
        // console.log(res.data);
        setAfterForm(res.data);
      }
      setLoading(false);
    });
  };

  return (
    <Wrap>
      <Header as="h2">Application Form</Header>
      <Card.Group itemsPerRow="3" style={{ padding: "20px" }}>
        <Card raised style={{ background: "#ffffff" }}>
          <Header
            as="h5"
            style={{
              textAlign: "Left",
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            New Application
          </Header>

          <Grid columns="4">
            <Grid.Column style={{ paddingLeft: "40px", color: "#aaa" }}>
              Apply date<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Guardian<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Phone<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              view<br></br>
            </Grid.Column>
          </Grid>
          {AfterForm.map((item) => (
            <>
              {item.submit_application == "yes" &&
                item.status_interview == "Pending" && (
                  <>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        {item.created_at}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        {item.parent_firstName}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        {item.mobile}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        <u
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() =>
                            props.history.push({
                              pathname: "/application/applicationformdetail",
                              // search: '?query=abc',
                              state: { id: item.id },
                            })
                          }
                        >
                          view
                        </u>
                      </Grid.Column>
                    </Grid>
                  </>
                )}
            </>
          ))}
        </Card>

        <Card raised style={{ background: "#ffffff" }}>
          <Header
            as="h5"
            style={{
              textAlign: "Left",
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            Confirm Interview
          </Header>
          <Grid columns="4">
            <Grid.Column style={{ paddingLeft: "40px", color: "#aaa" }}>
              Apply date<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Guardian<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Phone<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              view<br></br>
            </Grid.Column>
          </Grid>
          {AfterForm.map((item) => (
            <>
              {item.submit_application == "yes" &&
                item.status_interview == "Confirm" && (
                  <>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        {item.created_at}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        {item.parent_firstName}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        {item.mobile}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        <u
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() =>
                            props.history.push({
                              pathname: "/application/applicationformdetail",
                              // search: '?query=abc',
                              state: { id: item.id },
                            })
                          }
                        >
                          view
                        </u>
                      </Grid.Column>
                    </Grid>
                  </>
                )}
            </>
          ))}
        </Card>

        <Card raised style={{ background: "#ffffff" }}>
          <Header
            as="h5"
            style={{
              textAlign: "Left",
              paddingLeft: "20px",
              paddingTop: "20px",
            }}
          >
            New Registration
          </Header>
          <Grid columns="4">
            <Grid.Column style={{ paddingLeft: "40px", color: "#aaa" }}>
              Apply date<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Guardian<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              Phone<br></br>
            </Grid.Column>
            <Grid.Column style={{ color: "#aaa" }}>
              view<br></br>
            </Grid.Column>
          </Grid>
          {AfterForm.map((item) => (
            <>
              {item.submit_application == "yes" &&
                item.status_interview == "Approved" &&
                item.approve_registation != "yes" && (
                  <>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        {item.created_at}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        {item.parent_firstName}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        {item.mobile}
                        <br></br>
                      </Grid.Column>
                      <Grid.Column>
                        <u
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() =>
                            props.history.push({
                              pathname: "/application/registationdetail",
                              state: { id: item.id },
                            })
                          }
                        >
                          view
                        </u>
                      </Grid.Column>
                    </Grid>
                  </>
                )}
            </>
          ))}
        </Card>
      </Card.Group>
    </Wrap>
  );
};
export default withRouter(Alert);
