import React, { useState, useRef, useEffect } from "react";
import {

    Header,
    Icon,
    Segment,
    Card,
    Breadcrumb,
    Button,
    Grid,
    GridColumn,
    Image,
    Divider,
    Table,
} from 'semantic-ui-react';
import QrReader from 'react-qr-reader'
import QrScanner from 'react-qr-scanner';
import moment from 'moment';
import * as config from '../../../config';
import { getDataFromBorrowQR, getdatabookinghistory, getStudent_view_library_history } from "../../Library/service";
import Wrap from "../../../components/wrap";


const DetailHistory = (props) => {

    const [result, setResult] = useState(null);
    const [data, setData] = useState(null);
    // const [student, setStudent] = useState(props.location.state.data);
    const [loading, setLoading] = useState(false);
    const [scanning, setScanning] = useState(false);
    const [status, setStatus] = useState(null);
    const [errormsg, setErrormsg] = useState(null)
    const [success, setSuccess] = useState(null)
    const [qrString, setQrString] = useState(null)
    const [legacyMode, setLegacyMode] = useState(false);
    const reader = useRef(null);
    const [getBooking, setBooking] = useState([]);
    const [studentimageURL, setStudentimageURL] = useState(null);
    const [studentfirstName, setStudentfirstName] = useState(null);
    const [studentlastName, setStudentlastName] = useState(null);
    const [studentfirstName_th, setStudentfirstName_th] = useState(null);
    const [studentlastName_th, setStudentlastName_th] = useState(null);
    const [studentnickname, setStudentnickname] = useState(null);
    const [studentgender, setStudentgender] = useState(null);
    const [studentdateOfBirth, setStudentdateOfBirth] = useState(null);
    const [studentemergency_phone, setStudentemergency_phone] = useState(null);
    const [studentemail, setStudentemail] = useState(null);
    const [studentstatus, setStudentstatus] = useState(null);
    const [studentstart_card, setStudentstart_card] = useState(null);
    const [studentend_card, setStudentend_card] = useState(null);

    console.log(props);

    const getData = data => {
        setLoading(true);
        //   getDataFromBorrowQR(data).then(res => {
        //     if (res && res.status === 200) {
        //       setResult("found");
        //       setData(res.data);
        //     } 
        //     setLoading(false);
        //   })

    }

    useEffect(() => {
        GetStudent_view_library_history();
        getDataBookList();
    }, [props.location]);
    const getDataBookList = () => {
        // const id = [props.location.state.student_id,props.location.state2.booking_id]
        // console.log(id)
        getdatabookinghistory(props.location.state.student_id).then(res => {
            if (res && res.status === 200) {
                setBooking(res.data);
            }
            setLoading(false);
        });

    };
    // console.log(getBooking);

    const GetStudent_view_library_history = () => {
        getStudent_view_library_history(props.location.state.student_id).then(res => {
            if (res && res.status === 200) {
                setStudentfirstName(res.data.firstName);
                setStudentlastName(res.data.lastName);
                setStudentfirstName_th(res.data.firstName_th);
                setStudentlastName_th(res.data.lastName_th);
                setStudentimageURL(res.data.imageURL)
                setStudentnickname(res.data.nickname);
                setStudentgender(res.data.gender);
                setStudentdateOfBirth(res.data.dateOfBirth);
                setStudentemergency_phone(res.data.emergency_phone)
                setStudentemail(res.data.email);
                setStudentstatus(res.data.status);
                setStudentstart_card(res.data.start_card);
                setStudentend_card(res.data.end_card);
            }
            setLoading(false);
        });

    };

    const handleScan = data => {
        if (data) {
            setScanning(false);
            setLoading(true);
            setQrString(data);
            getData(data);
        }
    }
    const handleError = err => {
        if (err)
            setResult(err.message)
    }

    const onCheckIn = e => {
        setScanning(true);
        setStatus("Check In")
    }
    const onClosePopup = e => {
        setErrormsg(null)
        setSuccess(null)
    }

    const openImageDialog = () => {
        setLegacyMode(true)
        if (legacyMode)
            reader.current.openImageDialog();

    }

    return (
        <Segment basic textAlign='left' style={{ backgroundColor: '#fff' }}>

            <Wrap>
                <Grid columns='2' doubling>
                    <Grid.Column width='4' textAlign='center'>
                        <Image
                            verticalAlign="middle"
                            src={
                                studentimageURL && studentimageURL ?
                                    studentimageURL + "?time=" + new Date()
                                    : '../storage/imgs/dfkid.png'
                            }
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                    '../storage/imgs/dfkid.png';
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width='12'>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as="h2" style={{ color: config.colorTheme2 }}>
                                        <Header.Content>
                                            {studentfirstName && studentfirstName}&nbsp;&nbsp;{studentlastName && studentlastName}
                                            <Header.Subheader style={{ color: config.colorTheme2 }}>
                                                {studentfirstName_th && studentfirstName_th}&nbsp;&nbsp;{studentlastName_th && studentlastName_th}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns='2'>
                                <Grid.Column columns='2'>
                                    <Grid columns='2'>
                                        <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Child's name (nick name)</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} >{studentnickname}</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Gender</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} >{studentgender}</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Date of birth</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} >{studentdateOfBirth}</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Age</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} >{moment().diff(studentdateOfBirth, 'years')} Year old</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Emergency contact no.</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} > {studentemergency_phone}</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} className='bold'>E-mail</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: 0 }} >{studentemail}</Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column columns='2'>
                                    <Grid columns='2'>
                                        <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>Status</Grid.Column>
                                        <Grid.Column>{studentstatus}</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>Start Date</Grid.Column>
                                        <Grid.Column>{studentstart_card}</Grid.Column>
                                        <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>End Date</Grid.Column>
                                        <Grid.Column>{studentend_card}</Grid.Column>
                                    </Grid>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </Wrap>

            <Header as='h2'>Book Borrow Lists</Header>
            <Table style={{ borderRadius: 0, fontSize: '95%', overflowx: 'auto', border: '1px solid black' }}>
                <Table.Header style={{
                    boxShadow: '0 0.5px 5px 0 rgb(0 0 0 / 15%)',
                    textAlign: 'center',
                    border: '1px solid black'
                }}>
                    <Table.Row>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '100px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Image</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Category</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>BookingID</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Item Code</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Brand</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Model</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>ISBN</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Name(EN)</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Age range</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Author</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Borrowed date</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Return Due Date</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Status</Table.HeaderCell>
                        {/* <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Remove</Table.HeaderCell> */}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getBooking.map(item =>
                        <Table.Row>
                            <Table.HeaderCell style={{ border: '1px solid black' }}>
                                <Image
                                    width='100px'
                                    verticalAlign="middle"
                                    src={
                                        item && item.imageURL ?
                                            item.imageURL + "?time=" + new Date()
                                            : '../storage/imgs/dfkid.png'
                                    }
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                            '../storage/imgs/dfkid.png';
                                    }}
                                />
                            </Table.HeaderCell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.category}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.booking_id}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.book_code}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.brand}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.model}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.isbn}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.name_en}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.age_range}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.author}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.borrow_date}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.return_due_date}</Table.Cell>
                            <Table.Cell textAlign='center' style={{ border: '1px solid black' }}>{item.view_status_status}</Table.Cell>
                            {/* <Table.Cell textAlign='center' style={{ border: '1px solid black' }}><Icon name='trash alternate' style={{ cursor: 'pointer', color: "red" }} /></Table.Cell> */}
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </Segment>
    );
}
export default DetailHistory;
