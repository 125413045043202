import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Grid,
  Image,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import TableList from "../../components/tablePerPage";
import ModalBasic from "../../components/modal";
import BorrowForm from "./formborrow";
import ReturnForm from "./formreturn";
// import ItemDefectForm from "./formdefect";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import {
  postAsset,
  getAssetsMyHistory,
  updateAssetStock,
  getAssetProfile,
  exportAssetsList,
  getAssetHistoryDetail,
  confirmBorrow,
  cancelBorrow,
  confirmReturn,
  insertDefect,
  getBorrowItem,
} from "./service";
import "./Book.css";

const RequestList = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  );
  const [assetstatus, setAssetStatus] = useState(
    props && props.location.state && props.location.state.status
  );
  const [title, setTitle] = useState(
    props && props.location.state && props.location.state.page
  );
  const [mode, setMode] = useState(
    props && props.location.state && props.location.state.mode
  );
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataReqNo, setDataReqNo] = useState([]);
  const [reqNo, setReqNo] = useState(null);
  const [returnRow, setReturnRow] = useState(null);
  // const [defectData, setDefectData] = useState(null);
  const [DefectImageUrl, setDefectImageUrl] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [loading, setLoading] = useState(true);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [values, setValues] = useState({
    maintype: "",
    type: "",
    category: "",
    borrow_status: "",
    // status: "Active",
    status: "",
  });
  // const [search, setSearch] = useState({ name: "" });
  const [search, setSearch] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [loadMsgPopup, setLoadMsgPopup] = useState(null); //for common popup msg
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  // const [showPopupDefect, setShowPopupDefect] = useState(false);
  const [showAssetHistoryDetail, setShowAssetHistoryDetail] = useState(false);
  const [showAssetReturn, setShowAssetReturn] = useState(false);
  // const reload = () => window.location.reload();
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  // const [visit_date, setvisit_date] = useState(
  //   moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  // );
  // const [showPopupBooks, setShowPopupBooks] = useState(false);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Request No.",
      accessor: "request_no",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Request Date",
      accessor: "confirm_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Requester",
      accessor: "fullname",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Date of Use",
      accessor: "date_of_use",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Use for",
      accessor: "use_detail",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Borrowed Date",
      accessor: "borrow_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Due Date",
      accessor: "return_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Date",
      accessor: "return_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.status === "Complete" ? (
          <div className="complete">{cellInfo.original.status} </div>
        ) : cellInfo.original.status === "Borrowing" ? (
          <div className="borrow">{cellInfo.original.status}</div>
        ) : (
          <div className="request">{cellInfo.original.status}</div>
        ),
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            // onClick={() =>
            //   props.history.push({
            //     pathname: "/asset/myhistory/requestdetail",
            //     state: { id: cellInfo.original.request_no },
            //   })
            // }
            onClick={() => loadHistoryDetail(cellInfo.original.request_no)}
          />
        </Wrap>
      ),
    },
  ]);

  //Todo
  // set colum for bookinglist
  // const [columnbookinglist, setColumnbookinglist] = useState([
  //   {
  //     Header: "No.",
  //     accessor: "",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 35,
  //     Cell: (cellInfo) =>
  //       cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
  //   },
  //   {
  //     Header: "Borrow",
  //     // accessor: "bscan",
  //     accessor: "isScan",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 55,
  //     Cell: (cellInfo) =>
  //       cellInfo.original.isScan === "0" ||
  //       cellInfo.original.isScan === null ? (
  //         ""
  //       ) : (
  //         <Wrap>
  //           <Icon bordered name="check" />
  //         </Wrap>
  //       ),
  //   },
  //   {
  //     Header: "Image",
  //     accessor: "id",
  //     sortable: false,
  //     width: 70,
  //     Cell: (cellInfo) => (
  //       <Image
  //         // size="mini"
  //         width="50"
  //         height="50"
  //         verticalAlign="middle"
  //         src={
  //           cellInfo.original.imageURL
  //             ? cellInfo.original.imageURL + "?time=" + new Date()
  //             : "../storage/imgs/no-image.jpg"
  //         }
  //         // src={
  //         //   cellInfo.original.authimgurl
  //         //     ? cellInfo.original.authimgurl
  //         //     : "../storage/imgs/no-image.jpg"
  //         // }
  //         onError={(e) => {
  //           e.target.onerror = null;
  //           e.target.src = "../storage/imgs/no-image.jpg";
  //         }}
  //       />
  //     ),
  //     style: { textAlign: "center" },
  //   },
  //   {
  //     Header: "Item Code",
  //     accessor: "item_code",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Type",
  //     accessor: "type",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Category",
  //     accessor: "category",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Brand",
  //     accessor: "brand",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Model",
  //     accessor: "model",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [EN]",
  //     accessor: "name_en",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [TH]",
  //     accessor: "name_th",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Supplier",
  //     accessor: "supplier",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Quantity",
  //     accessor: "borqty",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Unit",
  //     accessor: "unit",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 50,
  //   },
  //   // {
  //   //   Header: "Scan QR",
  //   //   accessor: "id",
  //   //   style: { textAlign: "center" },
  //   //   sortable: false,
  //   //   width: 55,
  //   //   Cell: (cellInfo) => (
  //   //     <Wrap>
  //   //       <Icon
  //   //         link
  //   //         bordered
  //   //         style={{
  //   //           color: "red",
  //   //           boxShadow: "0 0 0 0.1em " + "red" + " inset",
  //   //         }}
  //   //         name="qrcode"
  //   //         // onClick={() =>
  //   //         //   onDeleteBooking(
  //   //         //     cellInfo.original.id,
  //   //         //     cellInfo.original.asset_id,
  //   //         //     cellInfo.original.qty
  //   //         //   )
  //   //         // }
  //   //         // onClick={() =>
  //   //         //   props.history.push({
  //   //         //     pathname: "/booking/bookprofile",
  //   //         //     state: { id: cellInfo.original.id },
  //   //         //   })
  //   //         // }
  //   //       />
  //   //     </Wrap>
  //   //   ),
  //   // },
  // ]);

  //Todo
  // set colum for return list
  // const [columnReturnlist, setColumnReturnlist] = useState([
  //   {
  //     Header: "No.",
  //     accessor: "",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 35,
  //     Cell: (cellInfo) =>
  //       cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
  //   },
  //   {
  //     Header: "Return",
  //     // accessor: "bscan",
  //     accessor: "isScan",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 55,
  //     Cell: (cellInfo) =>
  //       cellInfo.original.isScan === "0" ||
  //       cellInfo.original.isScan === null ? (
  //         ""
  //       ) : (
  //         <Wrap>
  //           <Icon bordered name="check" />
  //         </Wrap>
  //       ),
  //   },
  //   {
  //     Header: "Image",
  //     accessor: "id",
  //     sortable: false,
  //     width: 70,
  //     Cell: (cellInfo) => (
  //       <Image
  //         // size="mini"
  //         width="50"
  //         height="50"
  //         verticalAlign="middle"
  //         src={
  //           cellInfo.original.imageURL
  //             ? cellInfo.original.imageURL + "?time=" + new Date()
  //             : "../storage/imgs/no-image.jpg"
  //         }
  //         // src={
  //         //   cellInfo.original.authimgurl
  //         //     ? cellInfo.original.authimgurl
  //         //     : "../storage/imgs/no-image.jpg"
  //         // }
  //         onError={(e) => {
  //           e.target.onerror = null;
  //           e.target.src = "../storage/imgs/no-image.jpg";
  //         }}
  //       />
  //     ),
  //     style: { textAlign: "center" },
  //   },
  //   {
  //     Header: "Item Code",
  //     accessor: "item_code",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Type",
  //     accessor: "type",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Category",
  //     accessor: "category",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Brand",
  //     accessor: "brand",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Model",
  //     accessor: "model",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [EN]",
  //     accessor: "name_en",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [TH]",
  //     accessor: "name_th",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Supplier",
  //     accessor: "supplier",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Return Date",
  //     accessor: "return_date",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Quantity",
  //     accessor: "borqty",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Unit",
  //     accessor: "unit",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 50,
  //   },
  //   {
  //     Header: "Defect",
  //     accessor: "defect",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 55,
  //     Cell: (cellInfo) =>
  //       cellInfo.original.defect === null ? (
  //         <Wrap>
  //           <Icon
  //             link
  //             bordered
  //             style={{
  //               color: "green",
  //               boxShadow: "0 0 0 0.1em " + "green" + " inset",
  //             }}
  //             name="exclamation"
  //             onClick={() => onItemDefect(cellInfo.original.borrow_id)}
  //           />
  //         </Wrap>
  //       ) : (
  //         <Wrap>
  //           <Icon
  //             link
  //             bordered
  //             style={{
  //               color: "red",
  //               boxShadow: "0 0 0 0.1em " + "red" + " inset",
  //             }}
  //             name="exclamation"
  //             onClick={() => onItemDefect(cellInfo.original.borrow_id)}
  //           />
  //         </Wrap>
  //       ),
  //     // Cell: (cellInfo) => (
  //     //   <Wrap>
  //     //     <Icon
  //     //       link
  //     //       bordered
  //     //       style={{
  //     //         color: "red",
  //     //         boxShadow: "0 0 0 0.1em " + "red" + " inset",
  //     //       }}
  //     //       name="exclamation"
  //     //       // onClick={() =>
  //     //       //   onDeleteBooking(
  //     //       //     cellInfo.original.id,
  //     //       //     cellInfo.original.asset_id,
  //     //       //     cellInfo.original.qty
  //     //       //   )
  //     //       // }
  //     //       // onClick={() =>
  //     //       //   props.history.push({
  //     //       //     pathname: "/booking/bookprofile",
  //     //       //     state: { id: cellInfo.original.id },
  //     //       //   })
  //     //       // }
  //     //     />
  //     //   </Wrap>
  //     // ),
  //   },
  // ]);

  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page]);

  const getData = () => {
    // // setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;

    qString = qString + "&uid=" + userData.id;
    // qString = qString + "&status=" + "Request";
    qString = qString + "&status=" + assetstatus;

    getAssetsMyHistory(qString).then((res) => {
      if (res && res.status === 200) {
        // console.log(`getAssetsMyHistory`, res);
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
  };

  //Todo
  const loadHistoryDetail = (reqno) => {
    //get history detail
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    qString = qString + "&request_no=" + reqno;

    getAssetHistoryDetail(qString).then((res) => {
      // console.log(`getAssetHistoryDetail`, res); //Test

      //get Request No. for cancel borrow
      setReqNo(reqno);

      if (res && res.status === 200) {
        // setColumnbookinglist(columnbookinglist);
        // setColumnReturnlist(columnReturnlist);
        setDataDetail(res.data.data);
        setDataReqNo(res.data.data[0].request_no);
        if (mode === "borrow" && !showAssetHistoryDetail)
          setShowAssetHistoryDetail(true);
        else if (mode === "return" && !showAssetReturn)
          setShowAssetReturn(true);
      }
      // setLoading(false);
    });
  };

  const onBorrowConfirm = (values) => {
    setLoadMsgPopup(true);
    setErrormsg(null);

    // if (!dataBooking[0]) {
    //   setErrormsg("Empty booking list! ...Cannot Confirm booking.");
    //   return;
    // }

    let qString = "?request_no=" + reqNo;

    // console.log(`onBorrowConfirm`, values); //Test

    confirmBorrow(qString).then((res) => {
      // console.log(`confirmBorrow`, res); //Test
      if (res && res.data.cnt_confirm > 0 && res.status === 200) {
        setLoadMsgPopup(false);

        // setMsgConfirm1(res.data.request_no);
        // setLoadSucPopup(true); //Successful Booking
      } else if (res.data.cnt_confirm === 0 && res.status === 200) {
        setErrormsg("Confirm borrow failed!");
      } else if (res && res.status === 302) {
        setErrormsg(
          "Scan QR remaining items! ...Please scan QR follow request list"
        );
      } else {
        setErrormsg(res.data.message);
      }
      getData();
      // setShowAssetHistoryDetail(false); //Cancel
      if (res && res.data.cnt_confirm > 0 && res.status === 200) {
        props.history.push("/asset/myhistory");
      }
    });
  };

  // const onBorrowCancel = () => {
  //   // setLoadMsgPopup(true);
  //   setErrormsg(null);

  //   let qString = "?request_no=" + reqNo;

  //   console.log(`onBorrowCancel`, qString); //Test

  //   cancelBorrow(qString).then((res) => {
  //     console.log(`cancelBorrow`, res); //Test
  //     // if (res && res.data.cnt_confirm > 0 && res.status === 200) {
  //     //   setLoadMsgPopup(false);

  //     //   // setMsgConfirm1(res.data.request_no);
  //     //   // setLoadSucPopup(true); //Successful Booking
  //     // } else if (res.data.cnt_confirm === 0 && res.status === 200) {
  //     //   setErrormsg("Confirm booking failed!");
  //     // } else {
  //     //   setErrormsg(res.data.message);
  //     // }
  //     // getData();
  //     setShowAssetHistoryDetail(false);
  //     // if (res && res.data.cnt_confirm > 0 && res.status === 200) {
  //     //   props.history.push("/asset/myhistory");
  //     // }
  //   });
  // };

  const onReturnConfirm = (values) => {
    setLoadMsgPopup(true);
    setErrormsg(null);

    // if (!dataBooking[0]) {
    //   setErrormsg("Empty booking list! ...Cannot Confirm booking.");
    //   return;
    // }

    let qString = "?request_no=" + reqNo;

    // console.log(`onReturnConfirm`, values); //Test

    confirmReturn(qString).then((res) => {
      // console.log(`confirmReturn`, res); //Test
      if (res && res.data.cnt_confirm > 0 && res.status === 200) {
        setLoadMsgPopup(false);

        // setMsgConfirm1(res.data.request_no);
        // setLoadSucPopup(true); //Successful Booking
      } else if (res.data.cnt_confirm === 0 && res.status === 200) {
        setErrormsg("Confirm return failed!");
      } else if (res && res.status === 302) {
        setErrormsg(
          "Scan QR remaining items! ...Please scan QR follow return list"
        );
      } else {
        setErrormsg(res.data.message);
      }
      getData();
      // setShowAssetHistoryDetail(false);  //Cancel
      if (res && res.data.cnt_confirm > 0 && res.status === 200) {
        props.history.push("/asset/myhistory");
      }
    });
  };

  // const onItemDefect = (id) => {
  //   let qString = "?borrow_id=" + id;

  //   console.log(`onItemDefect`, id);

  //   getBorrowItem(qString).then((res) => {
  //     console.log(`getBorrowItem`, res);
  //     if (res && res.status === 200) {
  //       setReturnRow(res.data[0]);
  //       setDefectData(res.data[0]);
  //       setDefectImageUrl(res.data[0].imageURL);

  //       setShowPopupDefect(true);
  //     }
  //     // setLoading(false);
  //   });
  // };

  // Todo
  // const updateDefect = (values) => {
  //   setErrormsg(null);
  //   setLoading(true); //save button
  //   setLoadMsgPopup(true); //warning msg

  //   values["record_by"] = userData.id;
  //   values["request_no"] = returnRow.request_no;
  //   values["asset_id"] = returnRow.asset_id;
  //   values["borrow_id"] = returnRow.id;
  //   console.log(`updateDefect`, values);

  //   // Protect human error
  //   // >> Verify defect & reject qty must not null
  //   if (values.defect_qty == null && values.reject_qty == null) {
  //     setErrormsg("Empty quantity!, please fill in quantity.");
  //     setLoading(false);
  //     return;
  //   }
  //   // >> Verify defect & reject qty must not be zero or must not less then zero
  //   if (parseInt(values.defect_qty) <= 0 || parseInt(values.reject_qty) <= 0) {
  //     setErrormsg(
  //       "Wrong quantity! (Must not less than zero or Must not be zero)"
  //     );
  //     setLoading(false);
  //     return;
  //   }
  //   // >> Verify defect & reject qty must not more than borrow qty
  //   let sumqty = 0;
  //   if (values.reject_qty) sumqty = parseInt(values.reject_qty);
  //   if (values.defect_qty) sumqty = sumqty + parseInt(values.defect_qty);
  //   if (parseInt(returnRow.qty) < parseInt(sumqty)) {
  //     setErrormsg("Over borrow quantity! ...please try again");
  //     setLoading(false);
  //     return;
  //   }

  //   //Add defect item
  //   insertDefect(values).then((res) => {
  //     console.log(`insertDefect`, res);

  //     if (res && res.status === 201) {
  //       loadHistoryDetail(returnRow.request_no);
  //       setLoadMsgPopup(false);
  //       setShowPopupDefect(false);

  //       // showCompletePopup();
  //     } else {
  //       setErrormsg(res.data.message);
  //     }
  //     setLoading(false);
  //   });
  // };

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };

  return (
    <Segment basic textAlign="left">
      {/* <Header as="h2">Borrow Asset</Header> */}
      <Header as="h2">{title}</Header>

      <Segment
        className="contentSegment"
        textAlign="left"
        // style={{ minHeight: "50vh", padding: "20px" }}
      >
        {/* <Form>
          <Form.Group>
            <Form.Field>
              <DatesRangeInput
                name="date"
                placeholder="Start - End"
                dateFormat="YYYY-MM-DD"
                iconPosition="right"
                value={date}
                allowSameEndDate
                onChange={handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Request No."
              name="reqno"
              onChange={handleInputChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status: <b>{values.status}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_asset_status_history,
              ]}
              defaultValue={values.status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Field width="1" />
          </Form.Group>
        </Form>
        <br></br> */}

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          // onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Waiting Download File
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      {/* Msg for Confirm borrow */}
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadMsgPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadMsgPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Proceeding...
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      {/* History detail form */}
      <ModalBasic
        size="large"
        open={showAssetHistoryDetail}
        content=""
        close={() => setShowAssetHistoryDetail(false)}
        // close={onBorrowCancel}
        // onExit={reload}
      >
        <BorrowForm
          onSubmit={onBorrowConfirm}
          onClose={() => setShowAssetHistoryDetail(false)}
          loading={loading}
          // initialValues={dataDetail}
          errormsg={errormsg}
          // data={dataDetail}
          // columnbookinglist={columnbookinglist}
          dataReqNo={dataReqNo}
        />
      </ModalBasic>

      {/* Return form */}
      <ModalBasic
        size="large"
        open={showAssetReturn}
        content=""
        close={() => setShowAssetReturn(false)}
      >
        <ReturnForm
          onSubmit={onReturnConfirm}
          onClose={() => setShowAssetReturn(false)}
          loading={loading}
          // initialValues={dataDetail}
          errormsg={errormsg}
          // data={dataDetail}
          // columnReturnlist={columnReturnlist}
          // mode={mode}
          dataReqNo={dataReqNo}
          userData={userData}
          viewOnly={viewOnly}
        />
      </ModalBasic>

      {/* Defect form */}
      {/* <ModalBasic
        size="large"
        open={showPopupDefect}
        content="Item Damaged"
        close={() => setShowPopupDefect(false)}
      >
        <ItemDefectForm
          onSubmit={updateDefect}
          onClose={() => setShowPopupDefect(false)}
          loading={loading}
          initialValues={defectData}
          errormsg={errormsg}
          imageURL={DefectImageUrl}
          data={defectData}
          viewOnly={viewOnly}
        />
      </ModalBasic> */}
    </Segment>
  );
};
export default withRouter(RequestList);
