import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import {
  Button,
  Form,
  Message,
  Grid,
  SegmentGroup,
  GridColumn,
} from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  // FormField, //Todo
} from "semantic-ui-react";
import { set } from "core-js/core/dict";

const validate = (values) => {
  const errors = {};
  if (!values.request_qty) errors.request_qty = true;
  return errors;
};

let FormBookNow = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    data,
  } = props;

  return (
    <Segment basic textAlign="left">
      <Grid columns="2" doubling>
        <Grid.Column width="6">
          <Image
            verticalAlign="middle"
            src={
              data && data.imageURL
                ? data.imageURL
                : "../storage/imgs/no-image.jpg"
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "../storage/imgs/no-image.jpg";
            }}
          />
        </Grid.Column>
        <Grid.Column columns="1" width="10" style={{ minHeight: "30vh" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" style={{ color: config.colorTheme2 }}>
                  <Header.Content>
                    {data.name_en != null && data.name_en}
                    <Header.Subheader style={{ color: config.colorTheme2 }}>
                      {data.name_th}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column style={{ paddingBottom: 10 }} className="bold">
                Initial Stock :
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: 10 }}>
                {data.initial_stock}
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: 10 }} className="bold">
                Available Qty :
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: 10 }}>
                {data.qty}
              </Grid.Column>
              <Grid.Column style={{ paddingTop: 10 }} className="bold">
                Booking Qty :
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: 0 }}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group unstackable>
                    <Field
                      width="10"
                      min={0}
                      name="request_qty"
                      type="number"
                      required={true}
                      component={renderField}
                      //   label="Booking Qty"
                    />
                  </Form.Group>
                  <br />
                  <div>
                    <SecondButton
                      floated="right"
                      content="Cancel"
                      color="grey"
                      onClick={onClose}
                    />
                    <Button
                      floated="left"
                      content="Save"
                      style={{
                        backgroundColor: config.colorTheme,
                        color: "#ffffff",
                        minWidth: "30%",
                      }}
                      loading={loading}
                      disabled={pristine || submitting}
                    />
                  </div>
                  <br />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

FormBookNow = reduxForm({
  // a unique name for the form
  form: "booknow",
  validate,
  enableReinitialize: true,
})(FormBookNow);

export default FormBookNow;
