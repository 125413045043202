import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Grid,
  Image,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import ModalBasic from "../../components/modal";
import ItemForm from "./formitem";
import ManageStockForm from "./formmanagestock";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import {
  postAsset,
  getAssetsList,
  updateAssetStock,
  getAssetProfile,
  exportAssetsList,
} from "./service";

const Items = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  ); //Todo
  const [data, setData] = useState([]);
  const [mstockdata, setMStockData] = useState([]);
  const [curDefectQty, setCurDefectQty] = useState(0);
  const [loading, setLoading] = useState(true);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [values, setValues] = useState({
    maintype: "",
    type: "",
    category: "",
    borrow_status: "",
    // status: "Active",
    status: "",
  });
  // const [search, setSearch] = useState({ name: "" });
  const [search, setSearch] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [loadMsgPopup, setLoadMsgPopup] = useState(null); //for Manage stock
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  const [showPopupManageStock, setShowPopupManageStock] = useState(false);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  // const [visit_date, setvisit_date] = useState(
  //   moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  // );
  // const [showPopupBooks, setShowPopupBooks] = useState(false);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          // size="mini"
          width="50"
          height="50"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/book.png"
          }
          // src={
          //   cellInfo.original.authimgurl
          //     ? cellInfo.original.authimgurl
          //     : "../storage/imgs/no-image.jpg"
          // }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/no-image.jpg";
          }}
        />
      ),
      style: { textAlign: "center" },
      width: 100,
    },
    {
      Header: "Type",
      accessor: "type",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Code",
      accessor: "item_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Initial Stock",
      accessor: "initial_stock",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Available Quantity",
      accessor: "qty",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Unit",
      accessor: "unit",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
    },
    {
      Header: "Status Stock",
      accessor: "borrow_status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status Item",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/asset/itemprofile",
                state: { id: cellInfo.original.id },
              })
            }
          />
        </Wrap>
      ),
    },
    {
      Header: "Manage Stock",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            // bordered
            // circular
            size="big"
            style={{
              color: config.colorTheme,
              // boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="plus circle"
            // onClick={() =>
            //   props.history.push({
            //     // pathname: "/asset/itemprofile",
            //     state: { id: cellInfo.original.id },
            //   })
            // }
            onClick={() => onManageStock(cellInfo.original.id)}
          />
        </Wrap>
      ),
    },
  ]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, values]);

  useEffect(() => {
    if (search) {
      setLoading(true);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        getData();
      }, 2000);
    }
  }, [search]);

  const getData = () => {
    // setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;

    // let qString = "?";
    if (search) {
      if (search.name) qString = qString + "&name=" + search.name;
    }
    if (values.maintype && values.maintype != "" && values.maintype != "All") {
      qString = qString + "&maintype=" + values.maintype;
    }
    if (values.type && values.type != "" && values.type != "All") {
      qString = qString + "&type=" + values.type;
    }
    if (values.category && values.category != "" && values.category != "All") {
      qString = qString + "&category=" + values.category;
    }
    if (
      values.borrow_status &&
      values.borrow_status != "" &&
      values.borrow_status != "All"
    ) {
      qString = qString + "&borrow_status=" + values.borrow_status;
    }
    if (values.status && values.status != "" && values.status != "All") {
      qString = qString + "&status=" + values.status;
    }

    getAssetsList(qString).then((res) => {
      if (res && res.status === 200) {
        // console.log(`getAssetsList`, res);
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
  };

  const insertAsset = (values) => {
    setErrormsg(null);
    setLoading(true); //save button
    setLoadMsgPopup(true); //warning msg

    values["created_by"] = userData.id;

    // Protect human error
    // >> Verify qty must not be zero or must not less then zero
    if (parseInt(values.initial_stock) <= 0) {
      setErrormsg(
        "Wrong quantity! (Must not less than zero or Must not be zero)"
      );
      setLoading(false);
      return;
    }

    postAsset(values).then((res) => {
      if (res && res.status === 201) {
        // console.log(`postAsset`, res);
        setLoadMsgPopup(false);
        setShowPopupProfile(false);
        props.history.push({
          pathname: "/asset/itemprofile",
          // search: '?query=abc',
          state: { id: res.data.id, page: "asset" },
        });
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  // Todo
  const onManageStock = (id) => {
    // console.log(`onManageStock`, id);

    getAssetProfile(id).then((res) => {
      if (res && res.status === 200) {
        // console.log(`getAssetProfile`, res);
        setMStockData(res.data);

        // data for verify
        setCurDefectQty(res.data.defect_qty);

        setShowPopupManageStock(true);
      }
      // setLoading(false);
    });
  };

  // Todo
  const updateStock = (values) => {
    setErrormsg(null);
    setLoading(true); //save button
    setLoadMsgPopup(true); //warning msg

    values["updated_by"] = userData.id;
    // console.log(`updateValue`, values);

    // Protect human error
    // >> Verify new & repair qty must not be zero or must not less then zero
    if (parseInt(values.add_new) <= 0 || parseInt(values.add_repair) <= 0) {
      setErrormsg(
        "Wrong quantity! (Must not less than zero or Must not be zero)"
      );
      setLoading(false);
      return;
    }
    if (
      (values.invoice && !values.add_new) ||
      (values.invoice && values.add_new === "0")
    ) {
      setErrormsg("Empty quantity! \n...please fill in [add new qty]");
      setLoading(false);
      return;
    }
    if (
      (values.doc && !values.add_repair) ||
      (values.doc && values.add_repair === "0")
    ) {
      setErrormsg("Empty quantity! ...please fill in [add repair qty]");
      setLoading(false);
      return;
    }
    // >> Verify new repair qty must not more than old repair qty
    if (curDefectQty < values.add_repair) {
      setErrormsg("Over repair quantity! ...please try again");
      setLoading(false);
      return;
    }

    //Update stock
    updateAssetStock(values).then((res) => {
      if (res && res.status === 201) {
        // console.log(`updateAssetStock`, res);
        getData();
        setLoadMsgPopup(false);
        setShowPopupManageStock(false);
        // showCompletePopup();
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };

  const onClickExport = () => {
    setLoadPopup(true);
    setErrormsg(null);

    let qString = "?";
    if (search) {
      if (search.name) qString = qString + "&name=" + search.name;
    }
    if (values.maintype && values.maintype != "" && values.maintype != "All") {
      qString = qString + "&maintype=" + values.maintype;
    }
    if (values.type && values.type != "" && values.type != "All") {
      qString = qString + "&type=" + values.type;
    }
    if (values.category && values.category != "" && values.category != "All") {
      qString = qString + "&category=" + values.category;
    }
    if (
      values.borrow_status &&
      values.borrow_status != "" &&
      values.borrow_status != "All"
    ) {
      qString = qString + "&borrow_status=" + values.borrow_status;
    }
    if (values.status && values.status != "" && values.status != "All") {
      qString = qString + "&status=" + values.status;
    }

    exportAssetsList(qString).then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false);
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "AssetListReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        // this.setState({ link: link.href });
        link.download =
          "AssetListReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Asset items</Header>

      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/asset")}
              style={{ color: "grey" }}
            >
              Asset
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Items</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="green"
            content="Export Excel"
            floated="right"
            style={{ marginBottom: "14px" }}
            onClick={onClickExport}
          />
        </Grid.Column>
      </Grid>
      <Segment
        className="contentSegment"
        textAlign="left"
        // style={{ minHeight: "50vh", padding: "20px" }}
      >
        <Form>
          <Form.Group>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={handleInputChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="maintype"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Main Type : <b>{values.maintype}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_maintype,
              ]}
              defaultValue={values.maintype}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="type"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Type : <b>{values.type}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_type,
              ]}
              defaultValue={values.type}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              scrolling={true}
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="category"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Category : <b>{values.category}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_assetcategory,
              ]}
              defaultValue={values.category}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="borrow_status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status stock : <b>{values.borrow_status}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_status_stock,
              ]}
              defaultValue={values.borrow_status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status item : <b>{values.status}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_status_item,
              ]}
              defaultValue={values.status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Field width="1" />
            <Button
              color="green"
              content="Add Item"
              floated="right"
              onClick={() => setShowPopupProfile(true)}
            />
          </Form.Group>
        </Form>
        <br></br>

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          // onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Waiting Download File
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>
      <ModalBasic
        size="big"
        open={showPopupManageStock}
        content="Manage Stock"
        close={() => setShowPopupManageStock(false)}
      >
        <ManageStockForm
          onSubmit={updateStock}
          onClose={() => setShowPopupManageStock(false)}
          loading={loading}
          initialValues={mstockdata}
          errormsg={errormsg}
          data={mstockdata}
        />
      </ModalBasic>

      {/* <ModalBasic
        size="large"
        open={showPopupBooks}
        content="Item"
        close={() => setShowPopupBooks(false)}
      >
        <ItemForm
          onSubmit={insertBook}
          onClose={() => setShowPopupBooks(false)}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic> */}

      <ModalBasic
        size="large"
        open={showPopupProfile}
        content="Add Item"
        close={() => setShowPopupProfile(false)}
      >
        <ItemForm
          onSubmit={insertAsset}
          onClose={() => setShowPopupProfile(false)}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic>

      {/* Msg for Manage Stock */}
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadMsgPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadMsgPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Updating stock...
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>
    </Segment>
  );
};
// export default Items;
export default withRouter(Items);
