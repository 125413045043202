import axios from "axios";
import * as config from "../../config";

export function getCourseList(qString) {
  return axios
    .get(config.urlAPI + "/course" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseHistoryList(qString) {
  return axios
    .get(config.urlAPI + "/coursehistory" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseProfile(id) {
  return axios
    .get(config.urlAPI + "/course/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseHistoryProfile(id) {
  return axios
    .get(config.urlAPI + "/coursehistory/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postCourse(data) {
  return axios
    .post(config.urlAPI + "/course", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateCourse(data) {
  return axios
    .put(config.urlAPI + "/course/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function deleteCourse(id) {
  return axios
    .delete(config.urlAPI + "/course/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getCampus() {
  return axios
    .get(config.urlAPI + "/campus", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getProgram() {
  return axios
    .get(config.urlAPI + "/program", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getStaff() {
  return axios
    .get(config.urlAPI + "/staff?status=Active", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getNanny() {
  return axios
    .get(
      config.urlAPI + "/staff?status=Active&type=nanny",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getTeacher() {
  return axios
    .get(config.urlAPI + "/teacher?status=Active", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getStudent() {
  return axios
    .get(config.urlAPI + "/student?status=Active", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getstudentForRegistCourse(qString) {
  return axios
    .get(
      config.urlAPI + "/registcourse/student" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getstudentForRegistClass(qString) {
  return axios
    .get(config.urlAPI + "/registclass/student" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getstudentClassHistory(qString) {
  return axios
    .get(
      config.urlAPI + "/registclasshistory/student" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getstudentByStatus(id, qString) {
  return axios
    .get(
      config.urlAPI + "/registration/all/" + id + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postClass(data) {
  return axios
    .post(config.urlAPI + "/class", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateClass(data) {
  return axios
    .put(config.urlAPI + "/class/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteClass(id) {
  return axios
    .delete(config.urlAPI + "/class/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getTerm() {
  return axios
    .get(config.urlAPI + "/term", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postTerm(data) {
  return axios
    .post(config.urlAPI + "/term", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateTerm(data) {
  return axios
    .put(config.urlAPI + "/term/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function deleteTerm(id) {
  return axios
    .delete(config.urlAPI + "/term/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getRegist(id) {
  return axios
    .get(config.urlAPI + "/registration/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getRegisHistory(id) {
  return axios
    .get(config.urlAPI + "/registration/history/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postRegist(data) {
  return axios
    .post(config.urlAPI + "/registration", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateRegist(data) {
  return axios
    .put(config.urlAPI + "/registration/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postRegistSpecial(data) {
  return axios
    .post(config.urlAPI + "/registration/special", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postGraduate(data) {
  return axios
    .post(config.urlAPI + "/registration/graduate", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postApprove(data) {
  return axios
    .post(config.urlAPI + "/registration/approve", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postPromote(data) {
  return axios
    .post(config.urlAPI + "/registration/promote", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function delRegistered(data) {
  return axios
    .post(config.urlAPI + "/del-registration/", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(`err:`, err);
      return err.response;
    });
}
