import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as config from "../../config";
import { Button, Header, Icon, Image, Segment, Form } from "semantic-ui-react";

import TableList from "../../components/tablePerPage";
import Wrap from "../../components/wrap";
import { getParentList, getAuthenImageGaurdian } from "./service";

const ParentList = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    class: "",
    course: "",
    status: "Active",
  });
  const [search, setSearch] = useState({ name: null });
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "imageURL",
      // accessor: "authimgurl",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/dfparent.png"
          }
          // src={
          //   cellInfo.original.authimgurl
          //     ? cellInfo.original.authimgurl
          //     : "../storage/imgs/dfkid.png"
          // }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/dfusers.png";
          }}
        />
      ),
      width: 150,
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
      width: 380,
      Cell: (cellInfo) => (
        <Wrap>
          <span
            className="bold"
            style={{ cursor: "pointer" }}
            onClick={() =>
              props.history.push({
                pathname: "/parent/profile",
                // search: '?query=abc',
                state: { id: cellInfo.original.id, page: "parent" },
              })
            }
          >
            {cellInfo.original.title} {cellInfo.original.firstName}{" "}
            {cellInfo.original.lastName}
          </span>
        </Wrap>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
      sortable: false,
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            // color="green"
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/parent/profile",
                // search: '?query=abc',
                state: { id: cellInfo.original.id, page: "parent" },
              })
            }
          />
        </Wrap>
      ),
      width: 100,
    },
  ]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, sort_page, values]);

  useEffect(() => {
    setLoading(true);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getData();
    }, 2000);
  }, [search]);

  const getData = () => {
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    if (values.status) qString = qString + "&status=" + values.status;
    if (search.name) qString = qString + "&name=" + search.name;

    let sort = sort_page;
    if (sort !== null) {
      var sortName = sort[0].id;
      var sortType = sort[0].desc === false ? "asc" : "desc";
      if (sortName === "name") {
        sortName = "firstName";
      }
      qString = qString + "&sortName=" + sortName + "&sortType=" + sortType;
    }

    getParentList(qString).then((res) => {
      if (res && res.status === 200) {
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);

        // console.log(`setColumn`, column); //TEST
        // console.log(`setData`, res.data.data); //TEST

        //Cancel: Move to use inside getParentList query --------------------
        // //Todo: get authen guardian's image
        // let option = [];
        // if (res.data.data.length > 0) {
        //   const result = res.data.data.map((e) => {
        //     getAuthenImageGaurdian(e.id).then((res) => {
        //       if (res && res.status === 200) {
        //         e["authimgurl"] = res.data;
        //         option.push(e);

        //         // console.log(`Student url response=`, res.data); //Test
        //       } else {
        //         console.log("Error");
        //         console.log(e.id);
        //       }
        //     });
        //   });
        // }
        //---------------------------------------------------------------------
      }
      setLoading(false);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };
  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Guardian</Header>
      <Segment className="contentSegment" textAlign="left">
        <Form>
          <Form.Group>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={handleInputChange}
            />

            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status : <b>{values.status}</b>
                </Button>
              }
              options={[
                { key: "af", value: "Active", text: "Active" },
                { key: "ax", value: "Inactive", text: "Inactive" },
              ]}
              defaultValue={values.status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
          </Form.Group>
        </Form>

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>
    </Segment>
  );
};
export default withRouter(ParentList);
