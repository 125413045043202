import React from "react";
import { Field, reduxForm } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Icon } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";

const validate = (values) => {
  const errors = {};
  if (!values.id) errors.id = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.relationship) errors.relationship = true;
  if (!values.phone) errors.phone = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.status) errors.status = true;
  if (/@kidz-village.ac.th\s*$/.test(values.email) === false) {
    errors.email = "Invalid email format";
  }
  if (!values.employee_type) errors.employee_type = true;

  return errors;
};

let ParentForm = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    state,
  } = props;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group unstackable>
        <Field
          name="idnumber"
          type="text"
          required={true}
          width="10"
          component={renderField}
          placeholder="type here to search"
          label="Passport or ID No."
        />
      </Form.Group>
      {errormsg && (
        <Message negative>
          <Message.Content>{errormsg}</Message.Content>
        </Message>
      )}

      <Form.Group unstackable>
        <Field
          name="title"
          width="4"
          component={renderSelectField}
          label="Title"
          options={config.options_title_eng}
        />
        <Field
          name="firstName"
          type="text"
          required={true}
          width="6"
          component={renderField}
          label="firstName"
        />
        <Field
          name="lastName"
          type="text"
          required={true}
          width="6"
          component={renderField}
          label="lastName"
        />
      </Form.Group>
      <Form.Group unstackable>
        <Field
          name="title_th"
          width="4"
          component={renderSelectField}
          label="คำนำหน้า"
          options={config.options_title_th}
        />
        <Field
          name="firstName_th"
          type="text"
          required={false}
          width="6"
          component={renderField}
          label="ชื่อ"
        />
        <Field
          name="lastName_th"
          type="text"
          required={false}
          width="6"
          component={renderField}
          label="นามสกุล"
        />
      </Form.Group>
      <Form.Group unstackable>
        <Field
          name="address"
          type="text"
          required={false}
          width="16"
          component={renderTextAreaField}
          label="Address"
        />
      </Form.Group>
      <Form.Group unstackable>
        <Field
          name="dateOfBirth"
          required={true}
          component={renderDateOnlyField}
          label="Date Of Birth"
        />
        <Field
          name="gender"
          component={renderSelectField}
          label="Gender"
          options={config.options_gender}
        />
        <Field
          name="nationality"
          type="text"
          required={false}
          component={renderField}
          label="Nationality"
        />
        <Field
          name="nativelanguage"
          type="text"
          required={false}
          component={renderField}
          label="Native Language"
        />
      </Form.Group>
      <Form.Group unstackable>
        <Field
          name="phone"
          type="text"
          required={true}
          width="6"
          component={renderField}
          label="Mobile Phone"
        />
        <Field
          name="email"
          type="text"
          required={true}
          width="6"
          component={renderField}
          label="E-mail"
        />
        <Field
          name="employee_type"
          required={true}
          component={renderSelectField}
          label="Employee type"
          options={state.optionsUserType}
          width="4"
        />
      </Form.Group>
      <Form.Group>
        <Field
          name="status"
          width="8"
          component={renderSelectField}
          label="Status"
          required={true}
          options={config.options_active}
        />
      </Form.Group>
      <br />
      <br />
      <br />
      <div>
        <SecondButton
          floated="right"
          content="Cancel"
          color="grey"
          onClick={onClose}
        />
        <Button
          floated="left"
          content="Save"
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffffff",
            minWidth: "30%",
          }}
          loading={loading}
          disabled={pristine || submitting}
        />
      </div>
      <br />
      <br />
    </Form>
  );
};

ParentForm = reduxForm({
  // a unique name for the form
  form: "parent",
  validate,
  enableReinitialize: true,
})(ParentForm);

export default ParentForm;
