import React from 'react'
import { Form } from 'semantic-ui-react'
import Wrap from './wrap'

const renderField = ({ input, label, labelDesc, placeholder, type, width, required, readOnly, meta: { touched, error, warning } }) => (
  <Wrap>
    {/* <Form.Group unstackable>
      <Form.Field width='4' style={{ textAlign: 'right' }}>
        <label style={{ textAlign: 'right' }}>{label} {required ? <span style={{ color: 'red' }}>*</span> : ''}</label>
        {labelDesc ? <small >{labelDesc}</small> : null}
      </Form.Field> */}
      <Form.Field width={width || 16} style={{ marginBottom: '0px' }}>
        <label style={{ textAlign: 'left' }}>{label} {required ? <span style={{ color: 'red' }}>*</span> : ''}</label>
        <Form.Input
          {...input} error={touched && error} value={input.value}
          placeholder={placeholder} type={type} readOnly={readOnly} />
        <p style={{ color: '#9f3a38', textAlign: 'left', fontSize: '90%' }}>
          {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </p>
      </Form.Field>
    {/* </Form.Group> */}
  </Wrap>
)

export default renderField
