import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Image,
  Segment,
  Form,
  TableCell,
} from "semantic-ui-react";
import TableList from "../../components/tablePerPage";
import Wrap from "../../components/wrap";
import { getStudentList, postStudent, getAuthenImage } from "./service";
import StudentForm from "./form";
import ModalBasic from "../../components/modal";
import {
  getSchoolAttendance,
  exportAttendance,
  getCourse,
  getClassInCourse,
} from "../Report/service";

const StudentList = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    class: "All",
    course: "All",
    status: "Active",
    type: "All",
  });
  const [search, setSearch] = useState(null);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  const [errormsg, setErrormsg] = useState(null);
  const [course_option, setCourse_option] = useState(null);
  const [class_option, setClass_option] = useState(null);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "imageURL",
      // accessor: "authimgurl",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/dfkid.png"
          }
          // src={
          //   cellInfo.original.authimgurl
          //     ? cellInfo.original.authimgurl
          //     : "../storage/imgs/dfkid.png"
          // }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/dfkid.png";
          }}
        />
      ),
      width: 60,
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
      width: 280,
      Cell: (cellInfo) => (
        <Wrap>
          <span
            className="bold"
            style={{ cursor: "pointer" }}
            onClick={() =>
              props.history.push({
                pathname: "/student/profile",
                // search: '?query=abc',
                state: {
                  id: cellInfo.original.id,
                  page: "student",
                },
              })
            }
          >
            {cellInfo.original.studentID} {cellInfo.original.title}{" "}
            {cellInfo.original.firstName} {cellInfo.original.lastName}
          </span>
        </Wrap>
      ),
    },
    {
      Header: "Nick name",
      accessor: "nickname",
      sortable: true,
      Cell: (cellInfo) => <Wrap>{cellInfo.original.nickname} </Wrap>,
    },
    {
      Header: "Student type",
      accessor: "studenttype",
      sortable: false,
    },
    {
      Header: "Course",
      accessor: "course_name",
      style: { textAlign: "left" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.course_name && cellInfo.original.course_name.join(),
    },
    {
      Header: "Class",
      accessor: "class_name",
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.class_name && cellInfo.original.class_name.join(),
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            // color="green"
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/student/profile",
                // search: '?query=abc',
                state: {
                  id: cellInfo.original.id,
                  page: "student",
                },
              })
            }
          />
        </Wrap>
      ),
      width: 100,
    },
  ]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, sort_page, values]);

  useEffect(() => {
    if (search) {
      setLoading(true);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        getData();
      }, 2000);
    }
  }, [search]);

  //ส่วนของ course
  useEffect(() => {
    setCourse_option(null);
    let option = [];
    getCourse().then((res) => {
      if (res && res.status === 200) {
        //   console.log(res.data);
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: { id: item.id, name: item.name },
          });
          //   console.log({ key: item.id, text: item.name, value: { id: item.id, name: item.name } });
        });
        setCourse_option(option);
      }
    });
  }, []);

  //เมื่อเลือก course แล้ว จะทำให้เลือก class ได้ แล้วส่งค่าไปฝั่ง Backend
  useEffect(() => {
    // getData();
    if (values.course !== "All") {
      setClass_option(null);
      let option = [];
      getClassInCourse(values.course.id).then((res) => {
        if (res && res.status === 200) {
          let newcourse = true;
          res.data.class_schedule &&
            res.data.class_schedule.map((item) => {
              option.push({
                key: item.id,
                text: item.name,
                value: { id: item.id, name: item.name },
              });
              if (values.class !== "All" && values.class.id === item.id)
                newcourse = false;
            });
          if (newcourse) {
            values.class = "All";
            setValues(values);
          }
          setClass_option(option);
        }
      });
    } else if (values.course === "All") {
      values.class = "All";
      setValues(values);
      setClass_option(null);
    }
  }, [values]);

  const getData = () => {
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    if (values.status) qString = qString + "&status=" + values.status;
    if (values.course !== "All")
      qString = qString + "&course=" + values.course.id;
    if (values.class !== "All") qString = qString + "&class=" + values.class.id;
    if (values.type && values.type !== "All")
      qString = qString + "&studenttype=" + values.type;
    if (search) {
      if (search.name) qString = qString + "&name=" + search.name;
    }
    let sort = sort_page;
    if (sort !== null) {
      var sortName = sort[0].id;
      var sortType = sort[0].desc === false ? "asc" : "desc";
      if (sortName === "name") {
        sortName = "firstName";
      }
      qString = qString + "&sortName=" + sortName + "&sortType=" + sortType;
    }
    getStudentList(qString).then((res) => {
      if (res && res.status === 200) {
        console.log(`getStudentList`, res);
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);

        // console.log(`setColumn`, column); //TEST
        // console.log(`setData`, res.data.data); //TEST

        //Cancel: Move to use inside getStudentList query --------------------
        // //Todo: get authen student's image
        // let option = [];
        // if (res.data.data.length > 0) {
        //   const result = res.data.data.map((e) => {
        //     getAuthenImage(e.id).then((res) => {
        //       if (res && res.status === 200) {
        //         e["authimgurl"] = res.data;
        //         option.push(e);

        //         // console.log(`Student url response=`, res.data); //Test
        //       } else {
        //         console.log("Error");
        //         console.log(e.id);
        //       }
        //     });
        //   });
        // }
        //---------------------------------------------------------------------
      }
      setLoading(false);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };
  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  const insertStudent = (values) => {
    values["status"] = "Active";
    postStudent(values).then((res) => {
      if (res && res.status === 201) {
        setShowPopupProfile(false);
        props.history.push({
          pathname: "/student/profile",
          // search: '?query=abc',
          state: { id: res.data.id, page: "student" },
        });
      } else if (res && res.status === 409) {
        console.log(`Duplicate`, res);
        // setErrormsg(
        //   "Duplicated student. Please check [ID No.] or [firstName] or [lastName]"
        // );
        setErrormsg("Duplicated student, already registered!!");
      } else {
        setErrormsg(res.data.message);
      }
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Student</Header>
      <Segment className="contentSegment" textAlign="left">
        <Form>
          <Form.Group>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={handleInputChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="type"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Type : <b>{values.type}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_studenttype,
              ]}
              defaultValue={values.type}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status : <b>{values.status}</b>
                </Button>
              }
              options={config.options_studentstatus}
              defaultValue={values.status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="course"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Course :{" "}
                  <b>
                    {values.course && values.course.name
                      ? values.course.name
                      : values.course}
                  </b>
                </Button>
              }
              options={
                course_option && [
                  { key: "all", text: "All", value: "All" },
                  ...course_option,
                ]
              }
              defaultValue={
                values.course && values.course.name
                  ? values.course.name
                  : values.course
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="class"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Class :{" "}
                  <b>
                    {values.class && values.class.name
                      ? values.class.name
                      : values.class}
                  </b>
                </Button>
              }
              options={
                class_option && [
                  { key: "all", text: "All", value: "All" },
                  ...class_option,
                ]
              }
              defaultValue={
                values.class && values.class.name
                  ? values.class.name
                  : values.class
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Field width="2" />
            <Button
              color="green"
              content="Add Student"
              floated="right"
              onClick={() => setShowPopupProfile(true)}
            />
          </Form.Group>
        </Form>

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>
      <ModalBasic
        size="large"
        open={showPopupProfile}
        content="Student"
        close={() => setShowPopupProfile(false)}
      >
        <StudentForm
          onSubmit={insertStudent}
          onClose={() => setShowPopupProfile(false)}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic>
    </Segment>
  );
};
export default withRouter(StudentList);
