import axios from "axios";
import * as config from "../config";

export function postVisitRequest(data) {
  return axios
    .post(config.urlAPI + "/addvisitrequest", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function addapplicationstudentdetail(data) {
  return axios
    .post(config.urlAPI + "/addapplicationstudentdetail", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateapplicationstudentdetail(data) {
  return axios
    .post(
      config.urlAPI + "/updateapplicationstudentdetail/" + data.visit_id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getstudentdetailProfile(Id) {
  return axios
    .get(config.urlAPI + "/getstudentdetailProfile/" + Id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getinterviewall(qString) {
  return axios
    .get(
      config.urlAPI + "/getinterviewall/perpage" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getinterviewafterapplicationform() {
  return axios
    .get(
      config.urlAPI + "/getinterviewafterapplicationform/",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getinterviewprofile(Id) {
  return axios
    .get(config.urlAPI + "/getinterviewprofile/" + Id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getstudentbystudent_id(student_id) {
  return axios
    .get(
      config.urlAPI + "/getstudentbystudent_id/" + student_id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function gethealthbystudent_id(student_id) {
  return axios
    .get(
      config.urlAPI + "/gethealthbystudent_id/" + student_id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getmotherbystudent_id(student_id) {
  return axios
    .get(
      config.urlAPI + "/getmotherbystudent_id/" + student_id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getfatherbystudent_id(student_id) {
  return axios
    .get(
      config.urlAPI + "/getfatherbystudent_id/" + student_id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSibling2bystudent_id(student_id) {
  return axios
    .get(
      config.urlAPI + "/getSibling2bystudent_id/" + student_id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function guardian1bystudent_id(student_id) {
  return axios
    .get(
      config.urlAPI + "/guardian1bystudent_id/" + student_id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function guardian2bystudent_id(student_id) {
  return axios
    .get(
      config.urlAPI + "/guardian2bystudent_id/" + student_id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getimmunization_gethealthCheckStudents_healthbystudent_id(id) {
  return axios
    .get(
      config.urlAPI +
        "/getimmunization_gethealthCheckStudents_healthbystudent_id/" +
        id
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getdocumentbyvisit_idbystudent_id(id) {
  return axios
    .get(config.urlAPI + "/getdocumentbyvisit_idbystudent_id/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getreportapplication(qString) {
  return axios
    .get(
      config.urlAPI + "/getreportapplication/perpage" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getfather(student_Id) {
  return axios
    .get(config.urlAPI + "/getfather/" + student_Id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getmother(student_Id) {
  return axios
    .get(config.urlAPI + "/getmother/" + student_Id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSibling2(sibling2) {
  return axios
    .get(config.urlAPI + "/getsibling2/" + sibling2)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getinterview(id) {
  return axios
    .get(config.urlAPI + "/getinterview/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// visit
export function getdatavisitrequestalert() {
  return axios
    .get(config.urlAPI + "/getdatavisitrequestalert", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getdatavisitrequest(qString) {
  return axios
    .get(
      config.urlAPI + "/getdatavisitrequest/perpage" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getvisitProfile(Id) {
  return axios
    .get(config.urlAPI + "/visit/" + Id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updatevisitprofile(data) {
  return axios
    .post(
      config.urlAPI + "/updatevisitprofile/" + data.Id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// application
export function getApplication() {
  return axios
    .get(config.urlAPI + "/getapplication", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getApplicationPerPage(qString) {
  return axios
    .get(
      config.urlAPI + "/getapplication/perpage" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getApplicationProfile(id) {
  return axios
    .get(config.urlAPI + "/getapplicationprofile/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// application edit
export function updateinerviewprofile(data) {
  return axios
    .post(
      config.urlAPI + "/updateinerviewprofile/" + data.id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateinerviewevaluationprofile(data) {
  return axios
    .post(
      config.urlAPI + "/updateinerviewevaluationprofile/" + data.id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// register
export function getRegistationPerPage(qString) {
  return axios
    .get(
      config.urlAPI + "/getregistation/perpage" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function submitApproveRegistation(data) {
  return axios
    .post(config.urlAPI + "/submitapproveregistation", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// edit student
export function updatehealthform(data) {
  return axios
    .post(config.urlAPI + "/updatehealthform", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateapplicationstudent(data) {
  return axios
    .post(config.urlAPI + "/updateapplicationstudent", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateImageProfile(data, id) {
  return axios
    .post(config.urlAPI + "/student/upload/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// Img Parent Guardian
export function updateImageProfile2(data, id) {
  return (
    axios
      // .post(config.urlAPI + "/parent/upload/" + id, data, config.headerCallAPI)
      .post(
        config.urlAPI + "/guardian/upload/" + id,
        data,
        config.headerCallAPI
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}

export function updateImageProfile3(data, id) {
  return axios
    .post(config.urlAPI + "/guardian/upload/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// edit parent
export function updateapplicationfather(data) {
  return axios
    .post(config.urlAPI + "/updateapplicationfather", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateapplicationmother(data) {
  return axios
    .post(config.urlAPI + "/updateapplicationmother", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updatesibling(data) {
  return axios
    .post(config.urlAPI + "/updatesibling/" + data.id, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateemergency2(data) {
  return axios
    .post(config.urlAPI + "/updateemergency2", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateapplicationguardian1(data) {
  return axios
    .post(config.urlAPI + "/updateapplicationguardian1", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateapplicationguardian2(data) {
  return axios
    .post(config.urlAPI + "/updateapplicationguardian2", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getTermsList() {
  return axios
    .get(config.urlAPI + "/presentterm", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getClassList(qString) {
  return axios
    .get(config.urlAPI + "/presentclass" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
