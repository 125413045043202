import React from 'react';
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import renderField from "../../components/renderField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { connect } from "react-redux";

import { Button, Form, Message, Icon, Divider } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from '../../config';

const validate = values => {
    const errors = {}

    if (!values.name) errors.name = true
    if (!values.start) errors.start = true
    if (!values.end) errors.end = true
    // if (!values.teacher_id) errors.teacher_id = true
    return errors;
};


let TermForm = (props) => {
    const { loading, pristine, submitting, handleSubmit, onSubmit, start, end, onClose, errormsg } = props;



    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Field
                name="name"
                type="text"
                component={renderField}
                required={true}
                label="Term"
            />
            {
                errormsg && <Message negative>
                    <Message.Content>{errormsg}</Message.Content>
                </Message>
            }

            <Form.Group>
                <Field name="start" maxDate={end} width="8" label='Start' component={renderDateOnlyField} />
                <Field
                    name="end"
                    minDate={start}
                    width="8"
                    label='End'

                    component={renderDateOnlyField}
                />
            </Form.Group>

            <Field
                id="desc"
                name="desc"
                type="text"
                component={renderField}
                label="Description"
            />
            <br /><br /><br />
            <div>
                <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
                <Button floated='left' content='Save'
                    style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                    loading={loading}
                    disabled={pristine || submitting}
                />
            </div>
            <br /><br />
        </Form>
    );

}


TermForm = reduxForm({
    // a unique name for the form
    form: "term",
    validate,
    enableReinitialize: true,
})(TermForm);

// Decorate with connect to read form values
const selector = formValueSelector("term"); // <-- same as form name
TermForm = connect((state) => {
    // can select values individually
    const start = selector(state, "start");
    const end = selector(state, "end");
    return {
        start,
        end
    };
})(TermForm);
export default TermForm;
