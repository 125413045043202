import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import FormVisitRequest from "./formvisitrequest"
import { postVisitRequest } from './service';


let VisitRequest = (props) => {
    const [errormsg, setErrormsg] = useState(null)

    const postData = (values) => {
        let data = values;
        console.log(data);
        postVisitRequest(values).then(res => {
            if (res && res.status === 201) {

            }
        })
    }


    return (
        <Segment style={{ backgroundColor: '#eee' }}>
            <FormVisitRequest 
                onSubmit={postData} 
                errormsg={errormsg}
            />
        </Segment>
    );

}

export default withRouter(VisitRequest);
