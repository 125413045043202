import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import {
  Button,
  Form,
  Message,
  Grid,
  SegmentGroup,
  GridColumn,
  GridRow,
  Card,
} from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Input,
  // FormField, //Todo
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import { set } from "core-js/core/dict";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import moment from "moment";
// import QrReader from "react-qr-reader";
import { getAssetHistoryDetail, getBorrowHistoryItem } from "./service";
import ItemDefectForm from "./formDefectBook";

const validate = (values) => {
  const errors = {};
  if (!values.date_of_use) errors.date_of_use = true;
  if (!values.return_due_date) errors.return_due_date = true;
  if (!values.use_for) errors.use_for = true;
  if (!values.use_detail) errors.use_detail = true;
  return errors;
};

let FormHistoryDetail = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    // onSubmit,
    initialValues,
    mainData,
    onClose,
    // errormsg,
    bookingID,
    viewOnly,
    bookStatus,
    pickupDueDate,
    bookingDate,
    borrowDate,
    columnhistorylist,
    // defectData,
    // DefectImageUrl,
    // showPopupDefectProps,
  } = props;

  const [errormsg, setErrormsg] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [defectLoading, setDefectLoading] = useState(false);

  const [per_page, setPerPage] = useState(5);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  // const [defectData, setDefectData] = useState(null);
  // const [DefectImageUrl, setDefectImageUrl] = useState(null);
  const [showPopupDefect, setShowPopupDefect] = useState(false);

  // // set colum for bookinglist
  // const [columnbookinglist, setColumnbookinglist] = useState([
  //   {
  //     Header: "No.",
  //     accessor: "",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 35,
  //     Cell: (cellInfo) =>
  //       cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
  //   },
  //   {
  //     Header: "Image",
  //     accessor: "id",
  //     sortable: false,
  //     width: 70,
  //     Cell: (cellInfo) => (
  //       <Image
  //         // size="mini"
  //         width="50"
  //         height="50"
  //         verticalAlign="middle"
  //         src={
  //           cellInfo.original.imageURL
  //             ? cellInfo.original.imageURL + "?time=" + new Date()
  //             : "../storage/imgs/no-image.jpg"
  //         }
  //         // src={
  //         //   cellInfo.original.authimgurl
  //         //     ? cellInfo.original.authimgurl
  //         //     : "../storage/imgs/no-image.jpg"
  //         // }
  //         onError={(e) => {
  //           e.target.onerror = null;
  //           e.target.src = "../storage/imgs/no-image.jpg";
  //         }}
  //       />
  //     ),
  //     style: { textAlign: "center" },
  //   },
  //   {
  //     Header: "Item Code",
  //     accessor: "item_code",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Type",
  //     accessor: "type",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Category",
  //     accessor: "category",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Brand",
  //     accessor: "brand",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Model",
  //     accessor: "model",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [EN]",
  //     accessor: "name_en",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [TH]",
  //     accessor: "name_th",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Supplier",
  //     accessor: "supplier",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Quantity",
  //     accessor: "borqty",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Unit",
  //     accessor: "unit",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 50,
  //   },
  //   {
  //     Header: "Defect",
  //     accessor: "defect",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 55,
  //     Cell: (cellInfo) =>
  //       cellInfo.original.defect === null ? (
  //         <Wrap>
  //           <Icon
  //             link
  //             bordered
  //             style={{
  //               color: "green",
  //               boxShadow: "0 0 0 0.1em " + "green" + " inset",
  //             }}
  //             name="exclamation"
  //             // onClick={() => onItemDefect(cellInfo.original.borrow_id)}
  //           />
  //         </Wrap>
  //       ) : (
  //         <Wrap>
  //           <Icon
  //             link
  //             bordered
  //             style={{
  //               color: "red",
  //               boxShadow: "0 0 0 0.1em " + "red" + " inset",
  //             }}
  //             name="exclamation"
  //             onClick={() => onItemDefect(cellInfo.original.borrow_id)}
  //           />
  //         </Wrap>
  //       ),
  //   },
  //   // {
  //   //   Header: "Remove",
  //   //   accessor: "id",
  //   //   style: { textAlign: "center" },
  //   //   sortable: false,
  //   //   width: 55,
  //   //   Cell: (cellInfo) => (
  //   //     <Wrap>
  //   //       <Icon
  //   //         link
  //   //         bordered
  //   //         style={{
  //   //           color: "red",
  //   //           boxShadow: "0 0 0 0.1em " + "red" + " inset",
  //   //         }}
  //   //         name="trash alternate outline" //bin icon
  //   //         onClick={() =>
  //   //           onDeleteBooking(
  //   //             cellInfo.original.id,
  //   //             cellInfo.original.asset_id,
  //   //             cellInfo.original.qty
  //   //           )
  //   //         }
  //   //         // onClick={() =>
  //   //         //   props.history.push({
  //   //         //     pathname: "/booking/bookprofile",
  //   //         //     state: { id: cellInfo.original.id },
  //   //         //   })
  //   //         // }
  //   //       />
  //   //     </Wrap>
  //   //   ),
  //   // },
  // ]);

  useEffect(() => {
    if (!tableLoading) {
      loadHistoryDetail();
    }
  }, [per_page, current_page]);

  const loadHistoryDetail = () => {
    // setTableLoading(true);
    // //get history detail
    // let page = current_page + 1;
    // let qString = "?_perpage=" + per_page + "&page=" + page;
    // qString = qString + "&booking_id=" + bookingID;
    // getAssetHistoryDetail(qString).then((res) => {
    //   // console.log(`getAssetHistoryDetail`, res); //Test
    //   if (res && res.status === 200) {
    //     // setColumnbookinglist(columnbookinglist);
    //     // setDataDetail(res.data.data);
    //     // setShowAssetHistoryDetail(true);
    //     setData(res.data.data);
    //     setPerPage(per_page);
    //     setCurrentPage(res.data.current_page - 1);
    //     setLastPage(res.data.last_page);
    //   }
    //   // setLoading(false);
    //   setTableLoading(false);
    // });
  };

  const onItemDefect = (id) => {
    // let qString = "?borrow_id=" + id;
    // // console.log(`onItemDefect`, id);
    // getBorrowHistoryItem(qString).then((res) => {
    //   // console.log(`getBorrowHistoryItem`, res);
    //   if (res && res.status === 200) {
    //     // setReturnRow(res.data[0]);
    //     setDefectData(res.data[0]);
    //     setDefectImageUrl(res.data[0].imageURL);
    //     setShowPopupDefect(true);
    //   }
    //   // setLoading(false);
    // });
  };

  return (
    <Segment basic textAlign="left">
      <Grid columns="2" doubling>
        <Grid.Column width="4">
          <Image
            style={{ maxHeight: "300px", maxwidth: "200px" }}
            centered
            spaced
            // size=""
            src={
              mainData && mainData.userImage
                ? mainData.userImage
                : "../storage/imgs/dfusers.png"
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "../storage/imgs/dfusers.png";
            }}
          />
        </Grid.Column>
        <Grid.Column columns="2" width="12" style={{ minHeight: "30vh" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header
                  as="h2"
                  style={{
                    color: config.colorTheme2,
                  }}
                >
                  <Header.Content>
                    {/* {mainData.firstName}&nbsp;&nbsp;
                    {mainData.lastName} */}
                    {mainData.fullname}
                    {/* {data.studentID} {data.title && data.title}{" "}
                            {data.firstName && data.firstName}&nbsp;&nbsp;
                            {data.lastName && data.lastName} */}
                    <Header.Subheader
                    // style={{ color: config.colorTheme2 }}
                    >
                      {/* {data.title_th && data.title_th}{" "}
                              {data.firstName_th && data.firstName_th}
                              &nbsp;&nbsp;{data.lastName_th && data.lastName_th} */}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
              <Grid.Column columns="2">
                <Grid columns="2">
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Child's name (nick name)
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.nickname}
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Gender
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.gender}
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Date of birth
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.dateOfBirth}
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Age
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {moment().diff(
                      moment(mainData.dateOfBirth, "DD-MM-YYYY"),
                      "years"
                    )}{" "}
                    Year old
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    E-mail
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.email}
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Emergency contact no.
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.emergency_name}
                    <br />
                    {mainData.emergency_phone}
                    <br />{" "}
                    {mainData.emergency_relation &&
                      ` (${mainData.emergency_relation})`}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column columns="2">
                <Grid columns="2">
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Passport or ID No.
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.idnumber}
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Student Type
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.studenttype}
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Status
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.student_status}
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    Start Date
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.start_card}
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                    End Date
                  </Grid.Column>
                  <Grid.Column style={{ paddingBottom: 0 }}>
                    {mainData.end_card}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>

      <Grid.Column style={{ paddingBottom: 0 }}>
        <Form>
          {/* NEW */}
          <Wrap>
            <Grid columns="4" doubling>
              <Grid.Column columns="4" width="12" style={{ minHeight: "20vh" }}>
                <Grid.Row columns="4">
                  <Grid.Column columns="4">
                    <Grid columns="4">
                      <Grid.Column
                        style={{ paddingBottom: 0 }}
                        className="bold"
                      >
                        Booking ID
                      </Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>
                        :&nbsp;&nbsp;&nbsp;{bookingID}
                      </Grid.Column>
                      <Grid.Column
                        style={{ paddingBottom: 0 }}
                        className="bold"
                      ></Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}></Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column columns="4">
                    <Grid columns="4">
                      <Grid.Column
                        style={{ paddingBottom: 0 }}
                        className="bold"
                      >
                        Booking Date
                      </Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>
                        :&nbsp;&nbsp;&nbsp;{bookingDate}
                      </Grid.Column>
                      <Grid.Column
                        style={{ paddingBottom: 0 }}
                        className="bold"
                      >
                        Status
                      </Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>
                        :&nbsp;&nbsp;&nbsp;{bookStatus}
                      </Grid.Column>
                      <Grid.Column
                        style={{ paddingBottom: 0 }}
                        className="bold"
                      >
                        Pickup Due Date
                      </Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>
                        :&nbsp;&nbsp;&nbsp;{pickupDueDate}
                      </Grid.Column>
                      <Grid.Column
                        style={{ paddingBottom: 0 }}
                        className="bold"
                      >
                        Borrow Date
                      </Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>
                        :&nbsp;&nbsp;&nbsp;{borrowDate}
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Wrap>
          {/* END NEW */}

          <Grid.Column>
            {/* Table list */}
            <TableList
              loading={tableLoading}
              // data={data}
              data={initialValues}
              // columns={columnbookinglist}
              columns={columnhistorylist}
              pageSize={per_page}
              page={current_page}
              pages={last_page}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(value) => setPerPage(value)}
            />
          </Grid.Column>
          <br />
          <div>
            <SecondButton
              floated="right"
              content="Cancel"
              color="grey"
              onClick={onClose}
            />
            {/* <Button
            floated="left"
            content="Save"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
            }}
            loading={loading}
            disabled={pristine || submitting}
          /> */}
          </div>
          <br />
        </Form>
      </Grid.Column>

      {/* Defect form */}
      {/* <ModalBasic
        size="large"
        open={showPopupDefect}
        content="Item Damaged"
        close={() => setShowPopupDefect(false)}
      >
        <ItemDefectForm
          onSubmit={() => setShowPopupDefect(false)}
          onClose={() => setShowPopupDefect(false)}
          loading={loading}
          initialValues={defectData}
          errormsg={errormsg}
          imageURL={DefectImageUrl}
          viewOnly={viewOnly}
        />
      </ModalBasic> */}
    </Segment>
  );
};

FormHistoryDetail = reduxForm({
  // a unique name for the form
  form: "historydetail",
  validate,
  enableReinitialize: true,
})(FormHistoryDetail);

export default FormHistoryDetail;
