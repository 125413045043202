import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
  Modal,
} from "semantic-ui-react";
import { getAssetProfile, updateAsset, requestAsset } from "./service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import TableStock from "./stock";
// import ItemHistiry from "./itemhistory";
// import FormBooks from './formbook'
// import FormImgBook from './formimgbook.js'
import BookNowForm from "./formbooknow";

const ItemProfileBooking = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  ); //Todo
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [asset, setAsset] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [bndata, setBnData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [openImgBook, setOpenImgBook] = useState(false);
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  const [curAvailableQty, setCurAvailableQty] = useState(0);
  const [loadPopup, setLoadPopup] = useState(null);
  const [availableMode, setAvailableMode] = useState(false); //Todo: show/hide "BOOK NOW" button
  const [showPopupBookNow, setShowPopupBookNow] = useState(false);
  const [loadMsgPopup, setLoadMsgPopup] = useState(null); //for Request stock

  // Upload Image
  const [showPopupImage, setShowPopupImage] = useState(false);
  const [photo, setPhoto] = useState(null);

  // // Complete popup
  // const [openComplete, setOpenComplete] = useState(false);
  // const showCompletePopup = () => {
  //   setOpenComplete(true);
  // };
  // const closeCompletePopup = () => {
  //   setOpenComplete(false);
  //   // window.location.reload();
  //   // window.close();
  // };

  useEffect(() => {
    if (props.location && !props.location.state)
      props.history.push("/asset/items");

    setLoadingPage(true);
    if (asset) {
      getData();
    }
  }, [asset]);

  const getData = () => {
    setLoadingPage(true);
    getAssetProfile(props.location.state.id).then((res) => {
      if (res && res.status === 200) {
        // console.log(`getAssetProfile`, res);
        setData(res.data);

        // data for verify
        setCurAvailableQty(res.data.qty);

        if (res.data.borrow_status != "Available") setAvailableMode(true); //Todo: show/hide button

        // console.log(`getAssetProfile qty`, qty);
      }
      setLoading(false);
    });
  };

  const onRequestStock = () => {
    setShowPopupBookNow(true);

    // getAssetProfile(props.location.state.id).then((res) => {
    //   if (res && res.status === 200) {
    //     // console.log(`getAssetProfile`, res);
    //     setBnData(res.data);

    //     // data for verify
    //     // setCurDefectQty(res.data.defect_qty);

    //     setShowPopupBookNow(true);
    //   }
    //   // setLoading(false);
    // });
  };

  const updateRequest = (values) => {
    setErrormsg(null);
    setLoading(true); //save button
    setLoadMsgPopup(true); //warning msg

    // Protect human error
    // >> Verify request qty must not be zero or must not less then zero
    if (parseInt(values.request_qty) <= 0) {
      setErrormsg(
        "Wrong quantity! (Must not less than zero or Must not be zero)"
      );
      setLoading(false);
      return;
    }
    // >> Verify request qty must not more than available qty
    if (parseInt(values.request_qty) > curAvailableQty) {
      setErrormsg("Over quantity! \n...please try again.");
      setLoading(false);
      return;
    }

    values["updated_by"] = userData.id;
    values["qty"] = curAvailableQty - parseInt(values.request_qty);
    values["borrow_qty"] = data.borrow_qty + parseInt(values.request_qty);
    if (values.qty === 0) {
      values["borrow_status"] = "Unavailable";
    }

    // console.log(`updateRequest`, values);

    //Update request (booking)
    requestAsset(values).then((res) => {
      // console.log(`requestAsset`, res);

      if (res && res.status === 201) {
        getData();
        setLoadMsgPopup(false);
        setShowPopupBookNow(false);
        // showCompletePopup();
      }
      // else if (res && res.status === 302) {
      //   setErrormsg(
      //     "Duplicate item! ...This item already booking, please check you items booking list"
      //   );
      // }
      else if (res && res.status === 200 && res.data.success === false) {
        // Over borrow limit qty _OR_ Duplicate item booking
        setErrormsg(res.data.message);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  const onBookingAdd = (values) => {
    // setLoadPopup(true);
    // // setShowPopup(true);
    // // setCurrentData(values);
    // setErrormsg(null);
    // // let data = values;
    // values["student_id"] = userData.id;
    // values["book_id"] = props.location.state.id;
    // postBookingList(values).then((res) => {
    //   // console.log(res);
    //   if (res && res.status === 201) {
    //     // getData();
    //     // setShowPopup(false);
    //     setLoadPopup(false);
    //   } else if (res.data === 2) {
    //     setErrormsg("Overbook (Max. 2 books)");
    //   } else {
    //     setErrormsg(res.data.message);
    //     // setErrormsg("Over booking");
    //   }
    //   // setCurrentData(null);
    //   // setLoading(false);
    // });
  };

  // Todo
  //   const updateProfile = (values) => {
  //     setErrormsg(null);
  //     setLoading(true); //save button
  //     setLoadPopup(true); //warning msg

  //     //Verify initial stock
  //     if (values.initial_stock >= minInitQty) {
  //       //re-cal qty
  //       if (values.initial_stock != curInitQty) {
  //         values["qty"] = values.initial_stock - minInitQty;
  //       }

  //       values["updated_by"] = userData.id;

  //       // console.log(`updateValue`, values);

  //       updateAsset(values).then((res) => {
  //         if (res && res.status === 200) {
  //           getData();
  //           setLoadPopup(false);
  //           setShowPopupProfile(false);
  //           // showCompletePopup(); //for show complete text
  //         } else {
  //           setErrormsg(res.data.message);
  //         }
  //         setLoading(false);
  //       });
  //     } else {
  //       setErrormsg("Wrong initial stock (Min. " + minInitQty + ")");
  //       setLoading(false);
  //     }
  //   };

  const onClose = () => {
    setShowPopup(false);
    setCurrentData(null);
  };

  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">Items Profile</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => props.history.push("/asset")}
                style={{ color: "grey" }}
              >
                Asset
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section
                onClick={() => props.history.push("/asset/booking_item")}
                style={{ color: "grey" }}
              >
                Request Stock
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Item Profile</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
        </Grid>
        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "70vh", padding: "20px" }}
        >
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Wrap>
              <Grid columns="2" doubling>
                <Grid.Column width="4">
                  <Image
                    verticalAlign="middle"
                    src={
                      data && data.imageURL
                        ? data.imageURL + "?time=" + new Date()
                        : "../storage/imgs/book.png"
                    }
                    // src={
                    //   data && data.authimgurl
                    //     ? data.authimgurl
                    //     : "../storage/imgs/no-image.jpg"
                    // }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/no-image.jpg";
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  columns="2"
                  width="12"
                  style={{ minHeight: "30vh" }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h2" style={{ color: config.colorTheme2 }}>
                          <Header.Content>
                            {data.name_en != null && data.name_en}
                            <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.name_th}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2">
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Type :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.type}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Category :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.category}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Item code :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.item_code}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Brand :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.brand}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Model :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.model}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Detail:{" "}
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.description}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          {/* <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Borrowing Period(days) :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.borrowing_period} days
                          </Grid.Column> */}
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Initial Stock
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.initial_stock}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Available Quantity
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.qty}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Unit
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.unit}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Status Stock
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.borrow_status}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Status Item
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.status}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
              <Grid columns="1">
                <Grid.Column>
                  <Button
                    basic
                    color={availableMode ? "grey" : "blue"}
                    content="BOOK NOW"
                    floated="right"
                    style={{ marginBottom: "14px" }}
                    disabled={availableMode ? true : false}
                    // onClick={onBookingAdd} //Todo: add "complete booking"
                    onClick={onRequestStock}
                  />
                </Grid.Column>
              </Grid>
            </Wrap>
          )}
        </Segment>

        <Modal
          size={errormsg ? "tiny" : "mini"}
          open={loadPopup}
          closeIcon={errormsg ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() => setLoadPopup(false)}
        >
          <Wrap>
            {errormsg ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{errormsg}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Updating...
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <ModalBasic
          size="small"
          open={showPopupBookNow}
          content="Stock Request"
          close={() => setShowPopupBookNow(false)}
        >
          <BookNowForm
            onSubmit={updateRequest}
            onClose={() => setShowPopupBookNow(false)}
            loading={loading}
            initialValues={data}
            errormsg={errormsg}
            data={data}
          />
        </ModalBasic>

        {/* Msg for Request stock */}
        <Modal
          size={errormsg ? "tiny" : "mini"}
          open={loadMsgPopup}
          closeIcon={errormsg ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() => setLoadMsgPopup(false)}
        >
          <Wrap>
            {errormsg ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{errormsg}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Booking...
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
      </Segment>
    </Wrap>
  );
};

export default withRouter(ItemProfileBooking);
