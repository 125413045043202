import axios from "axios";
import * as config from "../../config";

export function getProfileFromQR(qString) {
  return axios
    .get(config.urlAPI + "/scanprofile" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getDataFromQR(qString) {
  return axios
    .get(config.urlAPI + "/scanqr" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postCheckIn(data) {
  return axios
    .post(config.urlAPI + "/checkin", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postCheckOut(data) {
  return axios
    .post(config.urlAPI + "/checkout", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postBorrow(data) {
  return axios
    .post(config.urlAPI + "/checkin", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getDataFromBorrowQR(qString) {
  return axios
    .get(config.urlAPI + "/scanqrbook" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getDataFromReturnQR(qString) {
  return axios
    .get(config.urlAPI + "/scanqrbook-return" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postBorrowBook(data) {
  return axios
    .post(config.urlAPI + "/postborrowbook", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postBorrowBookParent(data) {
  return axios
    .post(config.urlAPI + "/postborrowbookparent", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getDataBooking(id) {
  return axios
    .get(config.urlAPI + "/getdatabooking/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function countDataBooking(id) {
  return axios
    .get(config.urlAPI + "/countdatabooking/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function forRunBooking(id) {
  return axios
    .get(config.urlAPI + "/forrunbooking/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//return
export function postReturnBook(data) {
  return axios
    .post(config.urlAPI + "/postreturnbook", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postReturnBookParent(data) {
  return axios
    .post(config.urlAPI + "/postreturnbookparent", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getDataDamage(data) {
  return axios
    .get(config.urlAPI + "/getdatadamage/" + data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function damageDetail(data) {
  return axios
    .post(config.urlAPI + "/damagedetail", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// Get book borrow data row
export function getBorrowBook(qstring) {
  return axios
    .get(config.urlAPI + "/bookreturn/row" + qstring, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// insert defect data
export function insertDefectBook(data) {
  return axios
    .post(config.urlAPI + "/bookreturn/defect", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
