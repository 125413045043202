import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector, change, FieldArray } from "redux-form";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderSelectField from "../../components/renderSelectField";
import renderField from "../../components/renderField";
import renderCheckboxGroupField from "../../components/renderCheckboxGroupField";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Form, Message, Icon, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from '../../config';
import { getstudentForRegistCourse } from "./service";
const validate = values => {
    const errors = {}
    // if (!values.term) errors.term = true

    if (!values.student) errors.student = true
    if (!values.days || !values.days.length) {
        errors.days = { _error: 'At least one day must be entered' }
    } else {
        if (values.days && values.days.length) {
            const arrayErrors = []
            values.days.forEach((day, index) => {
                if (!day || !day.length) {
                    arrayErrors[index] = true
                }
            })
            if (arrayErrors.length) {
                errors.days = {
                    _error: 'At least one day must be entered'
                }
            }
        }
    }
    return errors;
};



let RegistForm = (props) => {
    const { loading, pristine, submitting, handleSubmit, onSubmit, course, student_option,
        onClose, errormsg, days } = props;

    const renderDate = ({ fields, meta: { error } }) => (
        <Grid.Row style={{ padding: 0 }}>
            <Grid.Column width='6' textAlign='right' className='bold'>
                <Button type='button' basic color='black' compact content='Add Period' onClick={() => fields.push()} />
            </Grid.Column>
            <Grid.Column>
                {fields.map((day, index) => (
                    <Form.Group key={index}>
                        <Field
                            width='10'
                            name={day}
                            label=''
                            minDate={days && days[index - 1]}
                            component={renderDateOnlyField}
                        />
                        <Icon
                            link
                            color="red"
                            name="x"
                            onClick={() => fields.remove(index)}
                        />
                    </Form.Group>
                ))}
                {error && <li className="error">{error}</li>}
            </Grid.Column>
        </Grid.Row>

    )
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {
                errormsg && <Message negative>
                    <Message.Content>{errormsg}</Message.Content>
                </Message>
            }

            <Grid columns={2} >
                <Grid.Row  >
                    <Grid.Column width='6' textAlign='right' className='bold'>Course</Grid.Column>
                    <Grid.Column>{course && `${course.name} (${course.program_name})`}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column width='6' textAlign='right' className='bold'>Student  <span style={{ color: 'red' }}>*</span></Grid.Column>
                    <Grid.Column>
                        <Field
                            name="student"
                            component={renderSelectField}
                            label=""
                            multiple
                            search
                            options={student_option}
                        />
                    </Grid.Column>
                </Grid.Row>
                <FieldArray name="days" component={renderDate} />


            </Grid>
            <br /><br /><br />
            <div>
                <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
                <Button floated='left' content='Save'
                    style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                    loading={loading}
                    disabled={pristine || submitting}
                />
            </div>
            <br /><br />
        </Form>
    );

}

RegistForm = reduxForm({
    // a unique name for the form
    form: "registspe",
    validate,
    enableReinitialize: true,
})(RegistForm);

const selector = formValueSelector("registspe");
RegistForm = connect(state => {
    const days = selector(state, "days");
    const start = selector(state, "start");
    const end = selector(state, "end");
    return {
        days: days,
        start: start,
        end: end
    };
})(RegistForm);

export default RegistForm;

