import React from 'react'
import { Form } from 'semantic-ui-react'
import ControlledEditor from "./controlledEditor";
import Wrap from './wrap'
import './form.css'


const renderField = ({ input: { onChange, value }, dfvalue, label, width, required, disabled, id, meta: { touched, error, warning } }) => (
    <Wrap>
        <Form.Field width={width} required={required} >
            <label style={{ marginBottom: '0px', textAlign: 'left', color: '#00000099' }}>{label}</label>
            <ControlledEditor
                id={id}
                value={value || dfvalue}
                disabled={disabled}
                onChange={onChange}
            />
            <p style={{ color: 'red', textAlign: 'left' }}> {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}</p>
        </Form.Field>
    </Wrap>
)

export default renderField
