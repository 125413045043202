import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import * as config from '../../config'
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  Card,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import QrReader from 'react-qr-reader';
import QrScanner from 'react-qr-scanner';
import { getProfileFromQR } from "./service";
import Wrap from "../../components/wrap";
import ErrorModal from "./errormodal";
import DetailModel from "./detailmodel";


const Scanqr = (props) => {

  const [result, setResult] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [legacyMode, setLegacyMode] = useState(false);
  const [cameraId, setCameraId] = useState(undefined);
  const [devices, setDevices] = useState([]);
  const [facingMode, setFacingMode] = useState('rear');
  // rear | back | environment / ig
  const timeoutRef = useRef(null);
  const reader = useRef(null);


  // useEffect(() => {


  //   if (navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices()) {
  //     setLoading(true)
  //     // log()
  //     navigator.mediaDevices.enumerateDevices()
  //       .then((devices) => {
  //         const videoSelect = []
  //         devices.forEach((device) => {
  //           if (device.kind === 'videoinput') {
  //             videoSelect.push(device)
  //           }
  //         })
  //         return videoSelect
  //       })
  //       .then((devices) => {
  //         console.log(devices);
  //         setCameraId(devices[0].deviceId);
  //         setDevices(devices);
  //         setLoading(false)
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       })
  //   }

  // }, []);

  useEffect(() => {
    setLoading(true);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      setLoading(false);
    }, 1000);
  }, [result]);


  const handleScan = data => {

    if (data) {
      console.log(data, "d");
      setLoading(true);
      getProfileFromQR(data).then(res => {
        if (res && res.status === 200) {
          setResult("found");
          setData(res.data);
          setLoading(false);
        } else if (res && res.status === 404) {
          setResult(res.data.error && res.data.error.errorsmessage);
          setLoading(false);
        }
      });
      setLegacyMode(false)

    }
  }

  const handleError = err => {
    console.log(err);
    if (err)
      setResult(err.message);
  }

  const openImageDialog = () => {
    setLegacyMode(true)
    if (legacyMode)
      reader.current.openImageDialog();

  }

  return (
    <Segment basic textAlign='left'>
      <Header as='h2' >Scan QR</Header>
      <Segment className="contentSegment" textAlign="left" style={{ minHeight: '50vh', padding: '20px' }}>
        {
          loading
            ? <Dimmer active inverted >
              <Loader inverted>Loading</Loader>
            </Dimmer>
            : <Wrap>
              {
                result
                  ? <Wrap>
                    {
                      result === 'found'
                        ? <DetailModel open={result === 'found'} data={data} onClose={() => setResult(null)} />
                        : <ErrorModal open={result} error={result} onClose={() => setResult(null)} />
                    }
                  </Wrap>
                  : <Wrap>

                    <Button floated='left' basic compact
                      icon='camera' onClick={openImageDialog} content={legacyMode ? 'Submit a QR Code' : 'Take a photos'} />
                    {/* <Button floated='right' labelPosition='right' icon='redo' onClick={() => setResult(null)} content='Redo' /> */}

                    <br />   <br />
                    {/* {JSON.stringify(devices)}
                    {
                      devices.length && (
                        <select
                          onChange={e => setCameraId(e.target.value)}
                        >
                          {devices.map((deviceInfo, index) => (
                            <React.Fragment key={deviceInfo.deviceId}><option value={deviceInfo.deviceId}>{deviceInfo.label || `camera ${index}`}</option></React.Fragment>
                          ))}
                        </select>
                      )
                    }
                    {cameraId} */}
                    {legacyMode ? <QrScanner
                      delay={100}
                      ref={reader}
                      facingMode
                      legacyMode
                      onError={handleError}
                      onScan={handleScan}
                      style={{ width: '100%', height: 500, textAlign: 'center' }}
                      resolution={500}
                      className="reader-container"
                    /> : <QrReader
                      delay={100}
                      // legacyMode={legacyMode}
                      // facingMode={facingMode}
                      facingMode={"environment"}
                      // chooseDeviceId={"cameraId"}
                      onError={handleError}
                      onScan={handleScan}
                      style={{ width: '100%', height: 500, textAlign: 'center' }}
                      resolution={500}
                      className="reader-container"
                    />

                    }

                  </Wrap>
              }
            </Wrap>
        }
      </Segment>
    </Segment >
  );
}
export default Scanqr;
