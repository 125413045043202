// const prod = "http://35.240.175.255:8080/api/v1/web";
// const dev = "http://35.247.176.96:8080/api/v1/web";
// export const urlAPI = "http://localhost:8000/api/v1/web";
// export const urlAPI = 'https://kidz.loxleyorbit.com:8080/api/v1/web';

// UAT
// export const urlAPI =
//   "https://api-kidz-dot-kidz-village.as.r.appspot.com/api/v1/web";

// PRODUCTION
export const urlAPI =
  "https://api-kidz-dot-kidz-village-ii.as.r.appspot.com/api/v1/web";

// {process.env.NODE_ENV === 'development' ?
//                         "http://35.247.176.96:8080/api/v1/web"
//                       : process.env.NODE_ENV === 'production' ?
//                         "http://35.240.175.255:8080/api/v1/web"
//                       };
// export const urlAPI = "https:///api-bac.firebaseapp.com/api";
// export const urlAPI = "https://api-json-bac.firebaseapp.com/api";
// 13: 50 NEUNG superadmin
// 13: 50 NEUNG OrbitOnly1
// p.aaaa@stu.bac.ac.th  14051994 BAC57

// for local
// // FORM TYEP 3
// export const urlApplicationformOther =
//   "https://localhost:3001/formapplicationother/student?id=";
// // FORM TYEP 2
// export const urlApplicationform =
//   "https://localhost:3001/formapplication/student?id=";
// // FORM TYPE 1
// export const urlRegisterform =
//   "https://localhost:3001/formregister/student?id=";

// for UAT
// // FORM TYEP 3
// export const urlApplicationformOther =
//   "https://kidzweb-public-dot-kidz-village.as.r.appspot.com/formapplicationother/student?id=";
// // FORM TYEP 2
// export const urlApplicationform =
//   "https://kidzweb-public-dot-kidz-village.as.r.appspot.com/formapplication/student?id=";
// // FORM TYPE 1
// export const urlRegisterform =
//   "https://kidzweb-public-dot-kidz-village.as.r.appspot.com/formregister/student?id=";

// // for PRODUCTION
// FORM TYEP 3
export const urlApplicationformOther =
  "https://enroll.kidz-village.ac.th/formapplicationother/student?id=";
// FORM TYEP 2
export const urlApplicationform =
  "https://enroll.kidz-village.ac.th/formapplication/student?id=";
// FORM TYPE 1
export const urlRegisterform =
  "https://enroll.kidz-village.ac.th/formregister/student?id=";

// export const urlApplicationform = 'https://kidz.loxleyorbit.com';

// export const colorMenu = "#faa7ee"; //UAT
export const colorMenu = "#ffffff"; //PRODUCTION

export const headerCallAPI = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
export const headerFileCallAPI = {
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
    Accept: "application/json",
    // "Content-Type": "multipart/form-data"
  },
};

export const colorTheme = "#7AC142";
export const colorTheme2 = "#80561B";

export const options_religion = [
  { key: "b", text: "Buddhism", value: "Buddhism" },
  { key: "c", text: "Christian", value: "Christian" },
  { key: "i", text: "Islam", value: "Islam" },
  { key: "n", text: "None", value: "None" },
];
export const options_degree = [
  { key: "s", text: "Senior High School", value: "Senior High School" },
  { key: "b", text: "Bachelor Degree", value: "Bachelor Degree" },
  { key: "m", text: "Master Degree", value: "Master Degre" },
  { key: "d", text: "Doctor Degree", value: "Doctor Degree" },
];
export const options_regisstatus = [
  { key: "r", text: "Registered", value: "Registered" },
  { key: "u", text: "Unregistered", value: "Unregistered" },
  // ,
  // { key: "g", text: "Graduated", value: "Graduated" }
];

export const options_studentstatus = [
  { key: "a", text: "Active", value: "Active" },
  { key: "i", text: "Inactive", value: "Inactive" },
  // { key: "g", text: "Graduated", value: "Graduated" },
  { key: "d", text: "Drop", value: "Drop" },
  { key: "l", text: "Leave", value: "Leave" },
  // { key: "t", text: "Terminated", value: "Terminated" },
];

export const options_studenttype = [
  { key: "a", text: "Full-time", value: "Full-time" },
  { key: "i", text: "Part-time", value: "Part-time" },
];

export const options_active = [
  { key: "a", text: "Active", value: "Active" },
  { key: "i", text: "Inactive", value: "Inactive" },
];

export const options_gender = [
  { key: "a", text: "Male", value: "Male" },
  { key: "i", text: "Female", value: "Female" },
];

export const options_campus = [
  { key: 1, text: "Kidz Village (KV)", value: 1 },
  { key: 2, text: "Kidz Learning Centre (KLC)", value: 2 },
];

export const options_program = [
  { key: 1, text: "International Program", value: 1 },
  { key: 2, text: "After School", value: 2 },
  { key: 3, text: "Special class", value: 3 },
  { key: 4, text: "Summer Program", value: 4 },
  { key: 5, text: "Regular Program (Per Month)", value: 5 },
  { key: 6, text: "Playgroup", value: 6 },
  { key: 7, text: "Day care", value: 7 },
];

export const options_status_visit = [
  { key: "Pending", text: "Pending", value: "Pending" },
  { key: "Confirm", text: "Confirm", value: "Confirm" },
  { key: "Visited", text: "Visited", value: "Visited" },
  { key: "Cancel", text: "Cancel", value: "Cancel" },
];

export const options_title_eng = [
  { key: "1", value: "Mr.", text: "Mr." },
  { key: "2", value: "Mrs.", text: "Mrs." },
  { key: "3", value: "Miss", text: "Miss" },
];
export const options_title_th = [
  { key: "1", value: "นาย", text: "นาย" },
  { key: "2", value: "นาง", text: "นาง" },
  { key: "3", value: "นางสาว", text: "นางสาว" },
  { key: "4", value: "เด็กหญิง", text: "เด็กหญิง" },
  { key: "5", value: "เด็กชาย", text: "เด็กชาย" },
];

export const options_classstatus = [
  { key: "0", value: "All", text: "All" },
  { key: "1", value: "Attended", text: "Attended" },
  { key: "2", value: "Leave", text: "Leave" },
  { key: "3", value: "Late", text: "Late" },
  { key: "4", value: "Absent", text: "Absent" },
];
export const options_schoolstatus = [
  { key: "0", value: "All", text: "All" },
  { key: "1", value: "Present", text: "Present" },
  { key: "2", value: "Absent", text: "Absent" },
  // { key: "4", value: "Absent", text: "Absent" }
];
//Todo
export const options_classattstatus = [
  { key: "0", value: "All", text: "All" },
  { key: "1", value: "Complete", text: "Complete" },
  { key: "2", value: "Not Complete", text: "Not Complete" },
];
// visit
export const option_classenrolled = [
  { key: "Toddler 1", text: "Toddler 1", value: "Toddler 1" },
  { key: "Toddler 2", text: "Toddler 2", value: "Toddler 2" },
  { key: "Kindergarten 1", text: "Kindergarten 1", value: "Kindergarten 1" },
  { key: "Kindergarten 2", text: "Kindergarten 2", value: "Kindergarten 2" },
  { key: "Kindergarten 3", text: "Kindergarten 3", value: "Kindergarten 3" },
];

export const option_termenrolled = [
  { key: "Term 1", text: "Term 1", value: "Term 1" },
  { key: "Term 2", text: "Term 2", value: "Term 2" },
  { key: "Term 3", text: "Term 3", value: "Term 3" },
];

export const options_nationality = [
  { key: "Australian", text: "Australian", value: "Australian" },
  { key: "Belgian", text: "Belgian", value: "Belgian" },
  { key: "Brazilian", text: "Brazilian", value: "Brazilian" },
  { key: "Cambodian", text: "Cambodian", value: "Cambodian" },
  { key: "Canadian", text: "Canadian", value: "Canadian" },
  { key: "Chinese", text: "Chinese", value: "Chinese" },
  { key: "Danish", text: "Danish", value: "Danish" },
  { key: "Egyptian", text: "Egyptian", value: "Egyptian" },
  { key: "English", text: "English", value: "English" },
  { key: "Finnish", text: "Finnish", value: "Finnish" },
  { key: "French", text: "French", value: "French" },
  { key: "German", text: "German", value: "German" },
  { key: "Greek", text: "Greek", value: "Greek" },
  { key: "Indonesian", text: "Indonesian", value: "Indonesian" },
  { key: "Iranian", text: "Iranian", value: "Iranian" },
  { key: "Irish", text: "Irish", value: "Irish" },
  { key: "Israeli", text: "Israeli", value: "Israeli" },
  { key: "Italian", text: "Italian", value: "Italian" },
  { key: "Japanese", text: "Japanese", value: "Japanese" },
  { key: "Latian", text: "Latian", value: "Latian" },
  { key: "Malaysian", text: "Malaysian", value: "Malaysian" },
  { key: "Mexican", text: "Mexican", value: "Mexican" },
  { key: "New Zealander", text: "New Zealander", value: "New Zealander" },
  { key: "Norwegian", text: "Norwegian", value: "Norwegian" },
  { key: "Filipino", text: "Filipino", value: "Filipino" },
  { key: "Portuguese", text: "Portuguese", value: "Portuguese" },
  { key: "Romanian", text: "Romanian", value: "Romanian" },
  { key: "Russian", text: "Russian", value: "Russian" },
  { key: "Korean", text: "Korean", value: "Korean" },
  { key: "Spanish", text: "Spanish", value: "Spanish" },
  { key: "Swedish", text: "Swedish", value: "Swedish" },
  { key: "Swiss", text: "Swiss", value: "Swiss" },
  { key: "Thai", text: "Thai", value: "Thai" },
  { key: "British", text: "British", value: "British" },
  { key: "American", text: "American", value: "American" },
  { key: "Vietnamese", text: "Vietnamese", value: "Vietnamese" },
  { key: "Dutch", text: "Dutch", value: "Dutch" },
  { key: "Other", text: "Other", value: "Other" },
];

export const options_relationship = [
  { key: "GrandFather", value: "GrandFather", text: "GrandFather" },
  { key: "GrandMother", value: "GrandMother", text: "GrandMother" },
  { key: "Uncle", value: "Uncle", text: "Uncle" },
  { key: "Aunt", value: "Aunt", text: "Aunt" },
  { key: "Other", value: "Other", text: "Other" },
];

// library
export const options_status_item = [
  { key: "Active", text: "Active", value: "Active" },
  { key: "Inactive", text: "Inactive", value: "Inactive" },
];

export const options_book_type = [
  { key: "Book", text: "Book", value: "Book" },
  { key: "VCD", text: "VCD", value: "VCD" },
];

export const options_status_stock = [
  { key: "Available", text: "Available", value: "Available" },
  { key: "Unavailable", text: "Unavailable", value: "Unavailable" },
  { key: "Reserved", text: "Reserved", value: "Reserved" },
];

export const options_status_history = [
  { key: "Booking", text: "Booking", value: "Booking" },
  { key: "Overdue", text: "Overdue", value: "Overdue" },
  { key: "Borrowing", text: "Borrowing", value: "Borrowing" },
  { key: "Complete", text: "Complete", value: "Complete" },
  { key: "Cancel", text: "Cancel", value: "Cancel" },
];

// Todo
export const options_asset_status_history = [
  { key: "1", text: "Request", value: "Request" },
  // { key: "2", text: "Overdue", value: "Overdue" },
  { key: "3", text: "Borrowing", value: "Borrowing" },
  { key: "4", text: "Complete", value: "Complete" },
  // { key: "5", text: "Wait confirm booking", value: "Wait confirm booking" },
];

export const options_bookType = [
  { key: "Book", text: "Book", value: "Book" },
  { key: "VCD", text: "VCD", value: "VCD" },
];

// export const options_category = [
//   { key: "Picture book", text: "Picture book", value: "Picture book" },
//   { key: "Book", text: "Book", value: "Book" },
// ];
//Todo: 2022.10.17_add book category
export const options_category = [
  { key: "Gardening", text: "Gardening", value: "Gardening" },
  { key: "Fantasy", text: "Fantasy", value: "Fantasy" },
  { key: "Art", text: "Art", value: "Art" },
  { key: "Languages", text: "Languages", value: "Languages" },
  { key: "Animals", text: "Animals", value: "Animals" },
  {
    key: "Social-Emotional",
    text: "Social-Emotional",
    value: "Social-Emotional",
  },
  { key: "Physical & Body", text: "Physical & Body", value: "Physical & Body" },
  { key: "Transportation", text: "Transportation", value: "Transportation" },
  { key: "Science", text: "Science", value: "Science" },
  { key: "AudioBook", text: "AudioBook", value: "AudioBook" },
  { key: "Game", text: "Game", value: "Game" },
  { key: "Cooking/Food", text: "Cooking/Food", value: "Cooking/Food" },
  { key: "Sing along", text: "Sing along", value: "Sing along" },
  {
    key: "Fable/Fairy Tale",
    text: "Fable/Fairy Tale",
    value: "Fable/Fairy Tale",
  },
  { key: "Math", text: "Math", value: "Math" },
  { key: "Guided Reading", text: "Guided Reading", value: "Guided Reading" },
  { key: "ICT", text: "ICT", value: "ICT" },
  { key: "Colors", text: "Colors", value: "Colors" },
  { key: "Culture", text: "Culture", value: "Culture" },
  { key: "Occupation", text: "Occupation", value: "Occupation" },
  { key: "Rhymes", text: "Rhymes", value: "Rhymes" },
  { key: "Sensory Book", text: "Sensory Book", value: "Sensory Book" },
  { key: "Mythology", text: "Mythology", value: "Mythology" },
  { key: "Historical", text: "Historical", value: "Historical" },
  { key: "Series", text: "Series", value: "Series" },
  { key: "Construction", text: "Construction", value: "Construction" },
  { key: "Comic / Graphic", text: "Comic / Graphic", value: "Comic / Graphic" },
  { key: "Storytelling", text: "Storytelling", value: "Storytelling" },
  { key: "Tools", text: "Tools", value: "Tools" },
  { key: "Thai Language", text: "Thai Language", value: "Thai Language" },
];
export const options_damage = [
  { key: "Repair", text: "Repair", value: "Repair" },
  { key: "Reject", text: "Reject", value: "Reject" },
];

export const permission = [
  {
    group: "Personnel",
    permission: [
      {
        label: "Manage Teacher",
        name: "manage-teacher",
      },
      {
        label: "View Teacher",
        name: "view-teacher",
      },
      {
        label: "Manage Student",
        name: "manage-student",
      },
      {
        label: "View Student",
        name: "view-student",
      },
      {
        label: "Manage Staff",
        name: "manage-staff",
      },
      {
        label: "View Staff",
        name: "view-staff",
      },
      {
        label: "Manage Parent",
        name: "manage-parent",
      },
      {
        label: "View Parent",
        name: "view-parent",
      },
      {
        label: "Import Student",
        name: "import-student",
      },
      //  {
      //   label: "Register",
      //   name: "register-student",
      // },
    ],
  },
  {
    group: "Academic",
    permission: [
      {
        label: "Manage Registration (Promotion)",
        name: "manage-registration",
      },
      {
        label: "Approve Registration",
        name: "approve-registration",
      },
      {
        label: "Manage Course",
        name: "manage-course",
      },
      {
        label: "Manage Class",
        name: "manage-class",
      },
    ],
  },
  {
    group: "Management",
    permission: [
      {
        label: "Manage Term",
        name: "manage-term",
      },
      {
        label: "Manage Role & Permission",
        name: "manage-role",
      },
      {
        label: "Manage User",
        name: "manage-user",
      },
    ],
  },
  {
    group: "Report",
    permission: [
      {
        label: "Attendance Report",
        name: "attendance-report",
      },
      {
        label: "Absent Report",
        name: "absent-report",
      },
      {
        label: "Kitchen Report",
        name: "kitchen-report",
      },
      {
        label: "Login Report",
        name: "login-report",
      },
      {
        label: "Application Report",
        name: "application-report",
      },
      {
        label: "Consent Report",
        name: "consent-report",
      },
      {
        label: "Visit Report",
        name: "visit-report",
      },
      {
        label: "Registation Report",
        name: "registation-report",
      },
    ],
  },
  {
    group: "Attendance",
    permission: [
      {
        label: "School Attendances",
        name: "scan-school",
      },
      {
        label: "Scan QR",
        name: "scan-qr",
      },
      {
        label: "Class Attendance",
        name: "class-attendance",
      },
      {
        label: "Class Attendance (Admin)",
        name: "class-attendance-admin",
      },
    ],
  },
  {
    group: "Application",
    permission: [
      {
        label: "Visit",
        name: "visit-form",
      },
      {
        label: "Application",
        name: "application-form",
      },
      {
        label: "Register",
        name: "register-form",
      },
    ],
  },
  {
    group: "Library",
    permission: [
      {
        label: "Book",
        name: "book",
      },
      // {
      //   label: "booking-list",
      //   name: "booking-list",
      // },
      {
        label: "History",
        name: "history",
      },
    ],
  },
  {
    group: "Asset",
    permission: [
      {
        label: "Item",
        name: "item",
      },
      {
        label: "Booking Item",
        name: "booking-item",
      },
      {
        label: "Asset History",
        name: "asset-history",
      },
    ],
  },
];

//Todo
export const options_maintype = [
  { key: "asset", text: "Asset", value: "Asset" },
  { key: "consumable", text: "Consumable", value: "Consumable" },
];
//Todo
export const options_type = [
  { key: "toys", text: "Toys", value: "Toys" },
  { key: "furnitures", text: "Furnitures", value: "Furnitures" },
  { key: "school supplies", text: "School supplies", value: "School supplies" },
];
//Todo
export const options_assetcategory = [
  { key: "1", text: "Toys", value: "Toys" },
  { key: "2", text: "Furnitures", value: "Furnitures" },
  { key: "3", text: "Electrics", value: "Electrics" },
  { key: "4 IT", text: "Electronic IT", value: "Electronic IT" },
  { key: "5", text: "Playground", value: "Playground" },
  { key: "6", text: "Sand toy", value: "Sand toy" },
  { key: "7", text: "Kitchen ware", value: "Kitchen ware" },
  { key: "8", text: "Sanitary ware", value: "Sanitary ware" },
  { key: "9", text: "เครื่องมือทำสวน", value: "เครื่องมือทำสวน" },
  { key: "10", text: "เครื่องมือช่าง", value: "เครื่องมือช่าง" },
  { key: "11", text: "Stationery", value: "Stationery" },
  { key: "12", text: "Apparel", value: "Apparel" },
];
//Todo
export const options_asset_subcategory1 = [
  { key: "1", text: "Classroom", value: "Classroom" },
  { key: "2", text: "Libray", value: "Libray" },
  { key: "3", text: "office", value: "office" },
  { key: "4", text: "cray", value: "cray" },
  { key: "5", text: "Staff room", value: "Staff room" },
  { key: "6", text: "office ชั้น2", value: "office ชั้น2" },
  { key: "7", text: "GYM", value: "GYM" },
  { key: "8", text: "Special Class", value: "Special Class" },
  { key: "9", text: "Dragonfles", value: "Dragonfles" },
  { key: "10", text: "Firefiles", value: "Firefiles" },
  { key: "11", text: "Grasshoppers", value: "Grasshoppers" },
  { key: "12", text: "Bumblebees", value: "Bumblebees" },
  { key: "13", text: "Beetles", value: "Beetles" },
  { key: "14", text: "Ladybird", value: "Ladybird" },
  { key: "15", text: "Caterpillars", value: "Caterpillars" },
  { key: "16", text: "Kitchen", value: "Kitchen" },
  { key: "17", text: "Library", value: "Library" },
  { key: "18", text: "Clay", value: "Clay" },
  { key: "19", text: "Dragonflies", value: "Dragonflies" },
  { key: "20", text: "Fireflies", value: "Fireflies" },
  { key: "21", text: "Ladybirds", value: "Ladybirds" },
  { key: "22", text: "ป้อมยาม", value: "ป้อมยาม" },
  { key: "23", text: "Front office", value: "Front office" },
  { key: "24", text: "Big gate", value: "Big gate" },
  { key: "25", text: "Office 2 floor", value: "Office 2 floor" },
  { key: "26", text: "Office 2nd floor", value: "Office 2nd floor" },
  { key: "27", text: "Stafroom", value: "Stafroom" },
  { key: "28", text: "Small playground", value: "Small playground" },
  { key: "29", text: "Big playground", value: "Big playground" },
  { key: "30", text: "Smll playground", value: "Smll playground" },
  { key: "31", text: "Swimming pool", value: "Swimming pool" },
  { key: "32", text: "Office 1st floor", value: "Office 1st floor" },
  { key: "33", text: "Ligrary", value: "Ligrary" },
  { key: "34", text: "Stock เครื่องมือทำสวน", value: "Stock เครื่องมือทำสวน" },
  { key: "35", text: "Stock ช่าง", value: "Stock ช่าง" },
  { key: "36", text: "ริมคลองตรงบ้านลุงน้อย", value: "ริมคลองตรงบ้านลุงน้อย" },
  { key: "37", text: "ห้องครัว", value: "ห้องครัว" },
  { key: "38", text: "ข้างเรือนกระจก", value: "ข้างเรือนกระจก" },
  { key: "39", text: "Play ground เล็ก", value: "Play ground เล็ก" },
  { key: "40", text: "ข้างอาคารE", value: "ข้างอาคารE" },
  { key: "41", text: "ข้างอาคารAB", value: "ข้างอาคารAB" },
  { key: "42", text: "Play ground ใหญ่", value: "Play ground ใหญ่" },
];
//Todo
export const options_asset_subcategory2 = [
  { key: "1", text: "ห้องครัว", value: "ห้องครัว" },
  { key: "2", text: "ริมคลองตรงบ้านลุงน้อย", value: "ริมคลองตรงบ้านลุงน้อย" },
  { key: "3", text: "ข้างเรือนกระจก", value: "ข้างเรือนกระจก" },
  { key: "4", text: "ข้างอาคารE", value: "ข้างอาคารE" },
  { key: "5", text: "ข้างอาคารAB", value: "ข้างอาคารAB" },
  { key: "6", text: "Wrapper", value: "Wrapper" },
  { key: "7", text: "Water pirifier", value: "Water pirifier" },
  { key: "8", text: "Water cooler", value: "Water cooler" },
  { key: "9", text: "Washing machine", value: "Washing machine" },
  { key: "10", text: "Vase", value: "Vase" },
  { key: "11", text: "Urinal", value: "Urinal" },
  { key: "12", text: "Ups", value: "Ups" },
  { key: "13", text: "Tray", value: "Tray" },
  { key: "14", text: "Tongs", value: "Tongs" },
  { key: "15", text: "Toilet bowl", value: "Toilet bowl" },
  { key: "16", text: "Tissue box", value: "Tissue box" },
  { key: "17", text: "Thread", value: "Thread" },
  {
    key: "18",
    text: "Temperature check machine",
    value: "Temperature check machine",
  },
  { key: "19", text: "Telephone", value: "Telephone" },
  { key: "20", text: "Tablet", value: "Tablet" },
  { key: "21", text: "Table", value: "Table" },
  { key: "22", text: "Stock เครื่องมือทำสวน", value: "Stock เครื่องมือทำสวน" },
  { key: "23", text: "Stock ช่าง", value: "Stock ช่าง" },
  { key: "24", text: "Steamer", value: "Steamer" },
  { key: "25", text: "Stand", value: "Stand" },
  { key: "26", text: "Stamp", value: "Stamp" },
  { key: "27", text: "Spoon", value: "Spoon" },
  { key: "28", text: "Spatula", value: "Spatula" },
  { key: "29", text: "Sink", value: "Sink" },
  { key: "30", text: "Shower", value: "Shower" },
  { key: "31", text: "Shelf", value: "Shelf" },
  { key: "32", text: "Scale", value: "Scale" },
  { key: "33", text: "Sandtoys", value: "Sandtoys" },
  { key: "34", text: "Sand Tray", value: "Sand Tray" },
  { key: "35", text: "Sand toy", value: "Sand toy" },
  { key: "36", text: "Safety signal", value: "Safety signal" },
  { key: "37", text: "Rice cooker", value: "Rice cooker" },
  { key: "38", text: "Refrigarator", value: "Refrigarator" },
  { key: "39", text: "Refigerator", value: "Refigerator" },
  { key: "40", text: "Rack", value: "Rack" },
  { key: "41", text: "Puzzle", value: "Puzzle" },
  { key: "42", text: "Puppets", value: "Puppets" },
  { key: "43", text: "Projector stand", value: "Projector stand" },
  { key: "44", text: "Projector monitor", value: "Projector monitor" },
  { key: "45", text: "Projecteur Digital", value: "Projecteur Digital" },
  { key: "46", text: "Printer", value: "Printer" },
  { key: "47", text: "Pot", value: "Pot" },
  { key: "48", text: "Pocket wifi", value: "Pocket wifi" },
  { key: "49", text: "Playdough", value: "Playdough" },
  { key: "50", text: "Play ground ใหญ่", value: "Play ground ใหญ่" },
  { key: "51", text: "Play ground เล็ก", value: "Play ground เล็ก" },
  { key: "52", text: "Plate staff", value: "Plate staff" },
  { key: "53", text: "Plate kid", value: "Plate kid" },
  { key: "54", text: "Plate", value: "Plate" },
  { key: "55", text: "Plastic tray", value: "Plastic tray" },
  { key: "56", text: "Plastic cup", value: "Plastic cup" },
  { key: "57", text: "Plastic box", value: "Plastic box" },
  { key: "58", text: "Plastic bottle", value: "Plastic bottle" },
  { key: "59", text: "Plasic water bottle", value: "Plasic water bottle" },
  { key: "60", text: "Paper cup", value: "Paper cup" },
  { key: "61", text: "Pan", value: "Pan" },
  { key: "62", text: "Oven", value: "Oven" },
  { key: "63", text: "Numbers", value: "Numbers" },
  { key: "64", text: "Music Instrument", value: "Music Instrument" },
  { key: "65", text: "Mortar", value: "Mortar" },
  { key: "66", text: "Monitor", value: "Monitor" },
  { key: "67", text: "Microphone", value: "Microphone" },
  { key: "68", text: "Lunch box", value: "Lunch box" },
  { key: "69", text: "Ladle", value: "Ladle" },
  { key: "70", text: "Knife", value: "Knife" },
  { key: "71", text: "Kitchen hood", value: "Kitchen hood" },
  { key: "72", text: "Keyboard", value: "Keyboard" },
  { key: "73", text: "Kettle", value: "Kettle" },
  { key: "74", text: "Jug", value: "Jug" },
  { key: "75", text: "Ipod", value: "Ipod" },
  { key: "76", text: "Ipad Mini", value: "Ipad Mini" },
  { key: "77", text: "Ipad 2", value: "Ipad 2" },
  { key: "78", text: "Induction cooker", value: "Induction cooker" },
  { key: "79", text: "Ice bucket", value: "Ice bucket" },
  { key: "80", text: "Hanger", value: "Hanger" },
  { key: "81", text: "Gyms", value: "Gyms" },
  { key: "82", text: "Grater", value: "Grater" },
  { key: "83", text: "Glass", value: "Glass" },
  { key: "84", text: "Gas", value: "Gas" },
  { key: "85", text: "Game", value: "Game" },
  { key: "86", text: "Fork", value: "Fork" },
  { key: "87", text: "Finger scan", value: "Finger scan" },
  { key: "88", text: "Field player", value: "Field player" },
  { key: "89", text: "Fan", value: "Fan" },
  { key: "90", text: "Electric Pot", value: "Electric Pot" },
  { key: "91", text: "Electone", value: "Electone" },
  { key: "92", text: "Easel", value: "Easel" },
  { key: "93", text: "Dramatic Play", value: "Dramatic Play" },
  { key: "94", text: "Dish drainer", value: "Dish drainer" },
  { key: "95", text: "Cutting board", value: "Cutting board" },
  { key: "96", text: "Cushion", value: "Cushion" },
  { key: "97", text: "Cup", value: "Cup" },
  { key: "98", text: "Cpu", value: "Cpu" },
  { key: "99", text: "Cooker hood", value: "Cooker hood" },
  { key: "100", text: "Constructions", value: "Constructions" },
  { key: "101", text: "Chair", value: "Chair" },
  { key: "102", text: "Carpet", value: "Carpet" },
  { key: "103", text: "Car", value: "Car" },
  { key: "104", text: "Bucket", value: "Bucket" },
  { key: "105", text: "Box", value: "Box" },
  { key: "106", text: "Bowl", value: "Bowl" },
  { key: "107", text: "Board game", value: "Board game" },
  { key: "108", text: "Board", value: "Board" },
  { key: "109", text: "Bluetooth speaker", value: "Bluetooth speaker" },
  { key: "110", text: "Blocks", value: "Blocks" },
  { key: "111", text: "Blender", value: "Blender" },
  { key: "112", text: "Battery", value: "Battery" },
  { key: "113", text: "Basket", value: "Basket" },
  { key: "114", text: "Bakery", value: "Bakery" },
  { key: "115", text: "audio equipment", value: "audio equipment" },
  { key: "116", text: "Animal", value: "Animal" },
  { key: "117", text: "Alphabets", value: "Alphabets" },
  { key: "118", text: "Alcohol stand", value: "Alcohol stand" },
  { key: "119", text: "Air purifier", value: "Air purifier" },
  { key: "120", text: "Air Conditioner", value: "Air Conditioner" },
];
// Asset: Used for ...
export const options_usefor = [
  { key: "1", text: "Open House", value: "Open House" },
  { key: "2", text: "Sport Day", value: "Sport Day" },
  { key: "3", text: "Event", value: "Event" },
];

// Course & Class Active/Expired
export const options_status_course = [
  { key: "Active", text: "Active", value: "Active" },
  { key: "Expired", text: "Expired", value: "Expired" },
];
