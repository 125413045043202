import React from "react";
import { Field, reduxForm } from "redux-form";
import renderField from "../../components/renderField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Divider, Header, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from '../../config';

const validate = values => {
    const errors = {}
    if (!values.name) errors.name = true
    return errors;
};


let RoleForm = (props) => {
    const { loading, pristine, submitting, handleSubmit, onSubmit, initialValues, onClose, errormsg } = props;
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Field
                name="name"
                component={renderField}
                label="Title"
                required={true}
            />
            {
                errormsg && <Message negative>
                    <Message.Content>{errormsg}</Message.Content>
                </Message>
            }
            {config.permission.map(group => (
                <div>
                    <Divider horizontal>
                        <Header as="h4">
                            {/* <Icon name="bar chart" /> */}
                            {group.group}
                        </Header>
                    </Divider>
                    <Grid>
                        <Grid.Row columns="2">
                            {group.permission.map(item => (
                                <Grid.Column style={{ paddingBottom: "10px" }}>
                                    <Field
                                        readOnly={
                                            initialValues && initialValues.name === "Administrator"
                                                ? true
                                                : false
                                        }
                                        // readOnly={true}
                                        name={`permission.${group.group}.${item.name}`}
                                        width="16"
                                        label={<label>{item.label}</label>}
                                        component={renderCheckboxField}
                                    />
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                    </Grid>
                </div>
            ))}
            <br />

            <br /><br /><br />
            <div>
                <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
                <Button floated='left' content='Save'
                    style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                    loading={loading}
                    disabled={pristine || submitting}
                />
            </div>
            <br /><br />
        </Form>
    );

}

RoleForm = reduxForm({
    // a unique name for the form
    form: "role",
    validate,
    enableReinitialize: true
})(RoleForm);

export default RoleForm;
