import axios from "axios";
import * as config from "../../config";

export function getCourse() {
  return axios
    .get(config.urlAPI + "/course?status=Active", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getClassInCourse(id) {
  return axios
    .get(config.urlAPI + "/course/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSchoolAttendance(qString) {
  return axios
    .get(config.urlAPI + "/report/attendance" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function getClassAttendance(qString) {
  return axios
    .get(
      config.urlAPI + "/report/classattendance" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function getClassAttendanceDetail(qString, classid) {
  return axios
    .post(
      config.urlAPI + "/report/classattendance/" + classid,
      qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function getClassAttendanceByDate(qString) {
  return axios
    .get(
      config.urlAPI + "/report/classattendance/bydate" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function getClassAttendanceByPeriod(qString) {
  return axios
    .get(
      config.urlAPI + "/report/classattendance/byperiod" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function getClassAttendanceByStudent(qString) {
  return axios
    .get(
      config.urlAPI + "/report/classattendance/bystudent" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getKitchenReport(qString) {
  return axios
    .get(config.urlAPI + "/report/kitchen" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getConsent(qString) {
  return axios
    .get(config.urlAPI + "/report/consent" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getVisit(qString) {
  return axios
    .get(config.urlAPI + "/report/visit" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getApplication(qString) {
  return axios
    .get(config.urlAPI + "/report/application" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getRegistation(qString) {
  return axios
    .get(config.urlAPI + "/report/registation" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getLogLogin(qString) {
  return axios
    .get(config.urlAPI + "/report/login" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportAttendance(qString) {
  return axios
    .get(config.urlAPI + "/export/attendance" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportClassAttendance(qString) {
  return axios
    .get(config.urlAPI + "/export/classattendance" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function exportClassAttendExcel(qString) {
  return axios
    .get(config.urlAPI + "/export/classattendexcel" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function exportClassAttendByDateExcel(qString) {
  return axios
    .get(config.urlAPI + "/export/classattendbydateexcel" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function exportClassAttendByPeriodExcel(qString) {
  return axios
    .get(config.urlAPI + "/export/classattendbyperiodexcel" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
export function exportClassAttendByStudentExcel(qString) {
  return axios
    .get(config.urlAPI + "/export/classattendbystudentexcel" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function exportAbsent(qString) {
  return axios
    .get(config.urlAPI + "/export/absent" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportKitchen(qString) {
  return axios
    .get(config.urlAPI + "/export/kitchen" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function exportLogin(qString) {
  return axios
    .get(config.urlAPI + "/export/login" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportConsent(qString) {
  return axios
    .get(config.urlAPI + "/export/consent" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportVisit(qString) {
  return axios
    .get(config.urlAPI + "/export/visit" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportApplication(qString) {
  return axios
    .get(config.urlAPI + "/export/application" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportRegistation(qString) {
  return axios
    .get(config.urlAPI + "/export/registation" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// visit count
export function reportVisitCountPending() {
  return axios
    .get(config.urlAPI + "/reportvisitcountpending", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportVisitCountConfirm() {
  return axios
    .get(config.urlAPI + "/reportvisitcountconfirm", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportVisitCountVisited() {
  return axios
    .get(config.urlAPI + "/reportvisitcountvisited", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportVisitCountCancel() {
  return axios
    .get(config.urlAPI + "/reportvisitcountcancel", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportVisitCountAll() {
  return axios
    .get(config.urlAPI + "/reportvisitcountall", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// application count
export function reportApplicationCountPending() {
  return axios
    .get(config.urlAPI + "/reportapplicationcountpending", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportApplicationCountConfirm() {
  return axios
    .get(config.urlAPI + "/reportapplicationcountconfirm", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportApplicationCountCancel() {
  return axios
    .get(config.urlAPI + "/reportapplicationcountcancel", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportApplicationCountApproved() {
  return axios
    .get(
      config.urlAPI + "/reportapplicationcountapproved",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportApplicationCountReject() {
  return axios
    .get(config.urlAPI + "/reportapplicationcountreject", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function reportApplicationCountAll() {
  return axios
    .get(config.urlAPI + "/reportapplicationcountall", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo
export function getCampus() {
  return axios
    .get(config.urlAPI + "/campus", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo
export function getProgram() {
  return axios
    .get(config.urlAPI + "/program", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo
export function getTeacher() {
  return axios
    .get(config.urlAPI + "/teacher", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
