import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import {
  Button,
  Form,
  Message,
  Grid,
  SegmentGroup,
  GridColumn,
  GridRow,
  Card,
} from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Input,
  // FormField, //Todo
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import { set } from "core-js/core/dict";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import moment from "moment";
// import QrReader from "react-qr-reader";
import { getAssetHistoryDetail, getBorrowHistoryItem } from "./service";
import ItemDefectForm from "./formdefect";

const validate = (values) => {
  const errors = {};
  if (!values.date_of_use) errors.date_of_use = true;
  if (!values.return_due_date) errors.return_due_date = true;
  if (!values.use_for) errors.use_for = true;
  if (!values.use_detail) errors.use_detail = true;
  return errors;
};

let FormHistoryDetail = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    // onSubmit,
    initialValue,
    onClose,
    // errormsg,
    // data,
    // userData,
    // columnbookinglist,
    dataReqNo,
    viewOnly,
  } = props;

  const [errormsg, setErrormsg] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [defectLoading, setDefectLoading] = useState(false);

  const [per_page, setPerPage] = useState(5);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [defectData, setDefectData] = useState(null);
  const [DefectImageUrl, setDefectImageUrl] = useState(null);
  const [showPopupDefect, setShowPopupDefect] = useState(false);

  // set colum for bookinglist
  const [columnbookinglist, setColumnbookinglist] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      width: 35,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      width: 70,
      Cell: (cellInfo) => (
        <Image
          // size="mini"
          width="50"
          height="50"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/no-image.jpg"
          }
          // src={
          //   cellInfo.original.authimgurl
          //     ? cellInfo.original.authimgurl
          //     : "../storage/imgs/no-image.jpg"
          // }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/no-image.jpg";
          }}
        />
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "Item Code",
      accessor: "item_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Type",
      accessor: "type",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Supplier",
      accessor: "supplier",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Quantity",
      accessor: "borqty",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Unit",
      accessor: "unit",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    {
      Header: "Defect",
      accessor: "defect",
      style: { textAlign: "center" },
      sortable: false,
      width: 55,
      Cell: (cellInfo) =>
        cellInfo.original.defect === null ? (
          <Wrap>
            <Icon
              // link
              bordered
              style={{
                color: "green",
                boxShadow: "0 0 0 0.1em " + "green" + " inset",
              }}
              name="exclamation"
            />
          </Wrap>
        ) : (
          <Wrap>
            <Icon
              link
              bordered
              style={{
                color: "red",
                boxShadow: "0 0 0 0.1em " + "red" + " inset",
              }}
              name="exclamation"
              onClick={() => onItemDefect(cellInfo.original.borrow_id)}
            />
          </Wrap>
        ),
    },
    // {
    //   Header: "Remove",
    //   accessor: "id",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   width: 55,
    //   Cell: (cellInfo) => (
    //     <Wrap>
    //       <Icon
    //         link
    //         bordered
    //         style={{
    //           color: "red",
    //           boxShadow: "0 0 0 0.1em " + "red" + " inset",
    //         }}
    //         name="trash alternate outline" //bin icon
    //         onClick={() =>
    //           onDeleteBooking(
    //             cellInfo.original.id,
    //             cellInfo.original.asset_id,
    //             cellInfo.original.qty
    //           )
    //         }
    //         // onClick={() =>
    //         //   props.history.push({
    //         //     pathname: "/booking/bookprofile",
    //         //     state: { id: cellInfo.original.id },
    //         //   })
    //         // }
    //       />
    //     </Wrap>
    //   ),
    // },
  ]);

  useEffect(() => {
    if (!tableLoading) {
      loadHistoryDetail();
    }
  }, [per_page, current_page]);

  const loadHistoryDetail = () => {
    setTableLoading(true);

    //get history detail
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    qString = qString + "&request_no=" + dataReqNo;

    getAssetHistoryDetail(qString).then((res) => {
      // console.log(`getAssetHistoryDetail`, res); //Test

      if (res && res.status === 200) {
        // setColumnbookinglist(columnbookinglist);
        // setDataDetail(res.data.data);
        // setShowAssetHistoryDetail(true);
        setData(res.data.data);
        setPerPage(per_page);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      // setLoading(false);
      setTableLoading(false);
    });
  };

  const onItemDefect = (id) => {
    let qString = "?borrow_id=" + id;

    // console.log(`onItemDefect`, id);

    getBorrowHistoryItem(qString).then((res) => {
      // console.log(`getBorrowHistoryItem`, res);
      if (res && res.status === 200) {
        // setReturnRow(res.data[0]);
        setDefectData(res.data);
        setDefectImageUrl(res.data.imageURL);

        setShowPopupDefect(true);
      }
      // setLoading(false);
    });
  };

  return (
    <Segment basic textAlign="left">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column
            largeScreen={2}
            mobile={6}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            Request No. :
          </Grid.Column>
          <Grid.Column
            largeScreen={14}
            mobile={8}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            {data[0] ? data[0].request_no : ""}
          </Grid.Column>
          <Grid.Column
            largeScreen={2}
            mobile={6}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            Request Date :
          </Grid.Column>
          <Grid.Column
            largeScreen={14}
            mobile={8}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            {data[0] ? data[0].booking_date : ""}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns="2" doubling>
        <Grid.Column width="4">
          <Image
            verticalAlign="middle"
            src={
              data[0] && data[0].authUserimg
                ? data[0].authUserimg
                : "../storage/imgs/no-image.jpg"
            }
            // src={
            //     data.data[0] && data.data[0].userImage
            //     ? data[0].userImage
            //     : "../storage/imgs/no-image.jpg"
            // }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "../storage/imgs/no-image.jpg";
            }}
          />
        </Grid.Column>
        <Grid.Column columns="1" width="12" style={{ minHeight: "30vh" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" style={{ color: config.colorTheme2 }}>
                  <Header.Content>
                    {data[0] ? data[0].fullname : ""}
                    <Header.Subheader
                      style={{ color: config.colorTheme2 }}
                    ></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="4">
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Passport or ID No. :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].idnumber : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Date of birth :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].dateOfBirth : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Nationality :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].nationality : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Age :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].age : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Native Language :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].nativelanguage : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Gender :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].gender : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Mobile Phone :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].phone : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Address :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].address : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                E-mail :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].email : ""}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>

      {/* fill section */}
      {/* <Grid.Column style={{ paddingTop: 10 }} className="bold">
        Date of use :
      </Grid.Column> */}
      <Grid.Column style={{ paddingBottom: 0 }}>
        <Form>
          <Form.Group unstackable>
            <Form.Field>
              <label>Date of Use:</label>
              <input
                defaultValue={
                  data[0]
                    ? moment(data[0].date_of_use).format("YYYY-MM-DD")
                    : ""
                }
                readOnly={true}
              ></input>
            </Form.Field>
            <Form.Field>
              <label>Return due date:</label>
              <input
                defaultValue={
                  data[0]
                    ? moment(data[0].return_due_date).format("YYYY-MM-DD")
                    : ""
                }
                readOnly={true}
              ></input>
            </Form.Field>
            <Form.Field>
              <label>Use for:</label>
              <input
                defaultValue={data[0] ? data[0].use_for : ""}
                readOnly={true}
              ></input>
            </Form.Field>
            <Form.Field width={10}>
              <label>Use for detail:</label>
              <input
                defaultValue={data[0] ? data[0].use_detail : ""}
                readOnly={true}
              ></input>
            </Form.Field>
          </Form.Group>

          <br />
          <Grid.Column>
            {/* Table list */}
            {/* <TableList
              data={data}
              columns={columnbookinglist}
              loading={loading}
              defaultPageSize={3}
            /> */}
            <TableList
              loading={tableLoading}
              data={data}
              columns={columnbookinglist}
              pageSize={per_page}
              page={current_page}
              pages={last_page}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(value) => setPerPage(value)}
            />
          </Grid.Column>
          <br />
          <div>
            <SecondButton
              floated="right"
              content="Cancel"
              color="grey"
              onClick={onClose}
            />
            {/* <Button
            floated="left"
            content="Save"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
            }}
            loading={loading}
            disabled={pristine || submitting}
          /> */}
          </div>
          <br />
        </Form>
      </Grid.Column>

      {/* Defect form */}
      <ModalBasic
        size="large"
        open={showPopupDefect}
        content="Item Damaged"
        close={() => setShowPopupDefect(false)}
      >
        <ItemDefectForm
          onSubmit={() => setShowPopupDefect(false)}
          onClose={() => setShowPopupDefect(false)}
          loading={loading}
          initialValues={defectData}
          errormsg={errormsg}
          imageURL={DefectImageUrl}
          // data={defectData}
          viewOnly={viewOnly}
        />
      </ModalBasic>
    </Segment>
  );
};

FormHistoryDetail = reduxForm({
  // a unique name for the form
  form: "historydetail",
  validate,
  enableReinitialize: true,
})(FormHistoryDetail);

export default FormHistoryDetail;
