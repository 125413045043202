import React, { useState, useEffect } from "react";
import * as config from '../../config';
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import FormHealth from "./healthform"
// import SecondButton from "../../components/secondbutton";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
  Form,
  Label
} from 'semantic-ui-react';
import { getimmunization_gethealthCheckStudents_healthbystudent_id, updatehealthform } from "../service";


let HealthCondition = (props) => {

  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState(props.studentId);
  const [data, setData] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [immunization_gethealthCheckStudents_health, setimmunization_gethealthCheckStudents_health] = useState([]);
  const [immunization, setimmunization] = useState([]);
  const [healthCheck_students, sethealthCheck_students] = useState([]);
  const [health, sethealth] = useState([]);
  const [showdata, setShowData] = useState(false)
  const [currentData, setCurrentData] = useState(null);
  const [open, setOpen] = useState(false);


  useEffect(() => {

    setLoading(true);
    if (student) {
      getData();
    }
  }, [student]);

  const getData = () => {

    getimmunization_gethealthCheckStudents_healthbystudent_id(student).then(res => {
      if (res && res.status === 200) {
        console.log(res.data[0])
        setimmunization_gethealthCheckStudents_health(res.data[0]);
        if (res.data[0]) {
          setShowData(true);
        }
      }
      setLoading(false);
    });

  };


  const onEdit = (values) => {
    setOpen(true);
    setCurrentData(values)
  }
  const onClose = () => {
    setOpen(false);
    setCurrentData(null)
  }

  const updateData = (values) => {
    setLoading(true);
    let immunization_gethealthCheckStudents_health = values;
    updatehealthform(values).then(res => {
      if (res && res.status === 200) {

        getData();
        setOpen(false);
      }
      setCurrentData(null)
      setLoading(false);
    })
  }


  return (
    <Wrap>
      <Button floated='right' content='Edit Data'
        onClick={() => onEdit(immunization_gethealthCheckStudents_health)}
        style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '10%' }}
      ></Button>
      <br></br>
      <br></br>
      <Segment>
        <div style={{ paddingLeft: "20px" }}>
          <Grid divided stackable><br></br>
            <Grid.Row>
              <Grid.Column>
                <Header as="h4">ประวัติการรับวัคซีน (CERTIFICATE of IMMUIZATION)</Header>

                <Table celled padded style={{ borderRadius: 0, fontSize: '95%' }} >
                  <Table.Header style={{
                    boxShadow: '0 0.5px 5px 0 rgb(0 0 0 / 15%)',
                    textAlign: 'center'
                  }}>
                    <Table.Row>
                      <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', width: "30%" }}>
                        IMMUIZATION
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', width: "70%" }}>
                        RECECORD COMPLETE DATES (month,day,year) OF VACCINE DOSES GIVEN
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{ textAlign: 'center' }}>
                      <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', }}></Table.HeaderCell>
                      <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', }}>
                        <Table style={{ textAlign: 'center' }}>
                          <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', width: '20%' }}>1</Table.HeaderCell>
                          <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', width: '20%' }}>2</Table.HeaderCell>
                          <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', width: '20%' }}>3</Table.HeaderCell>
                          <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', width: '20%' }}>4</Table.HeaderCell>
                          <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', width: '20%' }}>5</Table.HeaderCell>
                        </Table>
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, backgroundColor: '#eee', paddingTop: '5px', paddingBottom: '5px', width: '10%' }}>Diphtheria Tetanus Pertussis(DTP)</Table.Cell>
                      <Table.Cell>
                        <Table style={{ textAlign: 'center' }}>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.DTP1}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.DTP2}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.DTP3}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.DTP4}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.DTP5}
                          </Table.Cell>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, backgroundColor: '#eee', paddingTop: '5px', paddingBottom: '5px', width: '35%' }}>Diphtheria Tetanus(Td)</Table.Cell>
                      <Table.Cell>
                        <Table style={{ textAlign: 'center' }}>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.Td1}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.Td2}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.Td3}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.Td4}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.Td5}
                          </Table.Cell>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, backgroundColor: '#eee', paddingTop: '5px', paddingBottom: '5px', width: '35%' }}>Poliomyelitis(OPV)</Table.Cell>
                      <Table.Cell>
                        <Table style={{ textAlign: 'center' }}>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.OPV1}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.OPV2}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.OPV3}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.OPV4}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.OPV5}
                          </Table.Cell>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, backgroundColor: '#eee', paddingTop: '5px', paddingBottom: '5px', width: '35%' }}>Measles Booster</Table.Cell>
                      <Table.Cell>
                        <Table style={{ textAlign: 'center' }}>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.Measles_Booster1}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.Measles_Booster2}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, backgroundColor: '#eee', paddingTop: '5px', paddingBottom: '5px', width: '35%' }}>Rubella</Table.Cell>
                      <Table.Cell>
                        <Table style={{ textAlign: 'center' }}>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.Rubella}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, backgroundColor: '#eee', paddingTop: '5px', paddingBottom: '5px', width: '35%' }}>Mumps</Table.Cell>
                      <Table.Cell>
                        <Table style={{ textAlign: 'center' }}>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.vaccineMumps}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, backgroundColor: '#eee', paddingTop: '5px', paddingBottom: '5px', width: '35%' }}>Measles,Mumps,Rubella(MMR)</Table.Cell>
                      <Table.Cell>
                        <Table style={{ textAlign: 'center' }}>
                          <Table.Cell style={{ width: '20%' }}>
                            {immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.MMR}
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                          <Table.Cell style={{ width: '20%', backgroundColor: '#eee' }}>
                          </Table.Cell>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <u>ข้อมูลเกี่ยวกับสุขภาพ</u>
                <p>Please check / any that this child has had</p>
                <Table celled padded style={{ borderRadius: 0, fontSize: '90%', width: '80%' }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px' }}>Conditions</Table.HeaderCell>
                      <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' }}>Yes/No</Table.HeaderCell>
                      <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px' }}>Conditions</Table.HeaderCell>
                      <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#ddd', paddingTop: '5px', paddingBottom: '5px', textAlign: 'center' }}>Yes/No</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>1.Abnormal spinal curvature (scoliosis, etc.)</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Abnormal_spinal == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Abnormal_spinal == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Abnormal_spinal == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>19.Hepatitis</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Hepatitis == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Hepatitis == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Hepatitis == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>2.Anemia</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Anemia == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Anemia == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Anemia == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>20.Measles (old fashioned or "ten day")</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Measles == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Measles == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Measles == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>3.Asthma or wheezing</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Asthma_or_wheezing == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Asthma_or_wheezing == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Asthma_or_wheezing == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>21.Meninggitis or encephalitis</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Meninggitis == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Meninggitis == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Meninggitis == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>4.Bedwetting at night</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Bedwetting_at_night == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Bedwetting_at_night == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Bedwetting_at_night == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>22.Multiple ear infections (3 or more)</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Multiple_ear == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Multiple_ear == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Multiple_ear == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>5.Behavior problem</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Behavior_problem == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Behavior_problem == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Behavior_problem == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>23.Wetting during day</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Wetting_during_day == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Wetting_during_day == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Wetting_during_day == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>6.Near-drowning or near-suffocation</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Neardrowning == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Neardrowning == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Neardrowning == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>24.Urinary tract infections</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Urinary_tract_infections == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Urinary_tract_infections == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Urinary_tract_infections == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>7.Nervous twitches or tics</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Nervous_twitches == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Nervous_twitches == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Nervous_twitches == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>25.Birth or congenital malformation</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Birth_or_congenital == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Birth_or_congenital == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Birth_or_congenital == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>8.Chronic diarrhea or constipation</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Chronic_diarrhea == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Chronic_diarrhea == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Chronic_diarrhea == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>26.Chicken pox</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Chicken_pox == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Chicken_pox == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Chicken_pox == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>9.Concern about relationship with sibling or friends</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Concern_about_relationship == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Concern_about_relationship == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Concern_about_relationship == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>27.Diabetes</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Diabetes == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Diabetes == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Diabetes == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>10.Cystic fibrosis</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Cystic_fibrosis == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Cystic_fibrosis == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Cystic_fibrosis == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>28.Eczema</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Eczema == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Eczema == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Eczema == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>11.Eye problems, poor vision</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Eye_problems == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Eye_problems == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Eye_problems == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>29.Emotional Problems</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Emotional_Problems == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Emotional_Problems == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Emotional_Problems == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>12.Frequent skin infections</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Frequent_skin == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Frequent_skin == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Frequent_skin == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>30.Frequent headaches</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Frequent_headaches == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Frequent_headaches == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Frequent_headaches == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>13.Heart disease, type</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Heart_disease == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Heart_disease == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Heart_disease == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>31.Frequent sore throat infections</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Frequent_sore_throat == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Frequent_sore_throat == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Frequent_sore_throat == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>14.Poisoning</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Poisoning == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Poisoning == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Poisoning == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>32.Mumps</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Mumps == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Mumps == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Mumps == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>15.Rheumatic fever</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Rheumatic_fever == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Rheumatic_fever == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Rheumatic_fever == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>33.Poor hearing</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Poor_hearing == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Poor_hearing == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Poor_hearing == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>16.Sickle cell disease</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Sickle_cell == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Sickle_cell == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Sickle_cell == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>34.Seizures or epilepsy</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Seizures == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Seizures == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Seizures == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>17.Kidney disease, type</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Kidney_disease == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Kidney_disease == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Kidney_disease == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>35.Stool soiling</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Stool == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Stool == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Stool == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>18.Allergies or hay fever</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Allergies == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Allergies == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Allergies == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '30%' }}>36.Toothaches or dental infections</Table.Cell>
                      <Table.Cell style={{ borderRadius: 0, paddingTop: '5px', paddingBottom: '5px', width: '10%', textAlign: 'center' }}>
                        {immunization_gethealthCheckStudents_health &&
                          <>
                            {immunization_gethealthCheckStudents_health.Toothaches == null && 'No'}
                            {immunization_gethealthCheckStudents_health.Toothaches == 0 && 'No'}
                            {immunization_gethealthCheckStudents_health.Toothaches == 1 && 'Yes'}
                          </>
                        }
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <b>What medication are grien frequently/daily</b>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4">
                      ข้อมูลการใช้ยาเป็นประจำ : <b>{immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.medication}</b>
                    </Grid.Column>
                    <Grid.Column width="4">
                      Allergy information : <b>{immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.allergy}</b>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4">
                      Allergy reaction : <b>{immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.reaction}</b>
                    </Grid.Column>
                    <Grid.Column width="4">
                      Allergy treatment : <b>{immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.treatment}</b>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <br></br>
                <b>ข้อมูลโรงพยาบาลและแพทย์ที่ดูแลเป็นประจำ</b>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4">
                      Hospital Name : <b>{immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.hospital_name}</b>
                    </Grid.Column>
                    <Grid.Column width="4">
                      H/N No. : <b>{immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.h_n_no}</b>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="4">
                      Doctor's name : <b>{immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.doctor_name}</b>
                    </Grid.Column>
                    <Grid.Column width="4">
                      Doctor's Phone : <b>{immunization_gethealthCheckStudents_health && immunization_gethealthCheckStudents_health.doctor_phone}</b>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Segment>

      <ModalBasic open={open} size='100%' close={() => onClose()} >
        <FormHealth
          loading={loading}
          initialValues={currentData}
          onClose={() => onClose()}
          onSubmit={updateData}
        />
      </ModalBasic>
    </Wrap>
  )
}


export default HealthCondition;

