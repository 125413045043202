import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateTimeField2 from "../../components/renderDateTimeField2";

const validate = (values) => {
  const errors = {};
  if (!values.interview_date) errors.interview_date = true;
  if (!values.status_interview) errors.status_interview = true;
  return errors;
};

let MakeAppointmentInterview = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    handleSubmit,
  } = props;
  return (
    <Segment basic textAlign="left">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <b>
          interview date<span style={{ color: "red" }}> *</span>
        </b>
        <b style={{ paddingLeft: "45%" }}>
          interview time<span style={{ color: "red" }}> *</span>
        </b>
        <Form.Group unstackable>
          <Field
            width="16"
            name="interview_date"
            required={true}
            component={renderDateTimeField2}
          />
        </Form.Group>
        <Form.Group>
          <Field
            name="interview_by"
            width="16"
            component={renderField}
            label="interview_by"
            required={false}
          />
          <Field
            name="status_interview"
            width="16"
            component={renderSelectField}
            label="Status"
            required={true}
            options={[
              { key: "Pending", text: "Pending", value: "Pending" },
              { key: "Confirm", text: "Confirm", value: "Confirm" },
              { key: "Cancel", text: "Cancel", value: "Cancel" },
            ]}
          />
        </Form.Group>
        {errormsg && (
          <Message negative>
            <Message.Content>{errormsg}</Message.Content>
          </Message>
        )}
        <Button
          floated="left"
          content="Save"
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffffff",
            minWidth: "20%",
            marginRight: "35%",
            minWidth: "30%",
          }}
          loading={loading}
          disabled={pristine || submitting}
        />
      </Form>
    </Segment>
  );
};

MakeAppointmentInterview = reduxForm({
  // a unique name for the form
  form: "MakeAppointmentInterview",
  validate,
  enableReinitialize: true,
})(MakeAppointmentInterview);

// const selector = formValueSelector("class");
// MakeAppointmentInterview = connect(state => {
//     const start_card = selector(state, "start_card");
//     const end_card = selector(state, "end_card");
//     return {
//         start_card: start_card,
//         end_card: end_card
//     };
// })(MakeAppointmentInterview);

export default MakeAppointmentInterview;
