import React, { Component } from "react";
// import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Switch, Route } from "react-router-dom";
import Mainmenu from "./containers/mainmenu";
import MainManagement from "./containers/User/mainmenu";
import MainApplication from "./containers/Application/mainapplicationmenu";
import MainLibrary from "./containers/Library/mainlibrarymenu";
import MainAsset from "./containers/Asset/mainassetmenu";
import Student from "./containers/Student/student";
import ImportStudent from "./containers/Student/importstudent";
import Parent from "./containers/Parent/parent";
import StudentProfile from "./containers/Student/profile";
import ParentProfile from "./containers/Parent/profile";
import Scanqr from "./containers/QR/scanProfile";
import SchoolAttendance from "./containers/QR/schoolAttendance";

import Staff from "./containers/User/staff";
import StaffProfile from "./containers/User/staffProfile";
import TeacherProfile from "./containers/User/teacherProfile";

import Teacher from "./containers/User/teacher";
import User from "./containers/User/user";
import Role from "./containers/Role/role";
import Course from "./containers/Course/course";
import CourseProfile from "./containers/Course/profile";
import Term from "./containers/Course/term";
import ClassAttendance from "./containers/ClassAttendance/classAttendance";
import CourseHistory from "./containers/Course/coursehistory";
import CourseHistoryProfile from "./containers/Course/profilehistory";

import Report from "./containers/Report/mainmenu";
import ReportAttendance from "./containers/Report/attendance";
import ReportClassAttendance from "./containers/Report/classattendance";
import Apptest from "./containers/Report/test"; //Test
import ReportLogin from "./containers/Report/login";
import ReportClassAttendanceBy from "./containers/Report/classattreportby"; //Todo
import ReportClassAttendanceByDate from "./containers/Report/classattreportbydate"; //Todo
import ReportClassAttendanceByPeriod from "./containers/Report/classattreportbyperiod"; //Todo
import ReportClassAttendanceByStudent from "./containers/Report/classattreportbystudent"; //Todo

import ApplicationReport from "./containers/Report/application";
import Consent from "./containers/Report/consent";
import VisitReport from "./containers/Report/visit";
import VisitProfileReport from "./containers/Report/visitProfile";
import RegistationReport from "./containers/Report/registation";

import Absent from "./containers/Report/absent";
import Kitchen from "./containers/Report/kitchen";

import Visit from "./containers/Application/visit";
import VisitProfile from "./containers/Application/visitProfile";
import Application from "./containers/Application/application";
import ApplicationFormDetail from "./containers/Application/applicationformdetail";
import Registration from "./containers/Application/registation";
import RegistationDetail from "./containers/Application/registationdetail";
// import RegistationDetailForm2 from "./containers/Application/registationdetailform2";
// import RegistationDetailForm3 from "./containers/Application/registationdetailform3";

import FormVisitRequest from "./containers/formvisitrequest";
import VisitRequest from "./containers/visitrequest";

import Books from "./containers/Library/books";
import BookProfile from "./containers/Library/bookprofile";
import History from "./containers/Library/History/history";
import DetailHistory from "./containers/Library/History/detailhistory";
import QRBook from "./containers/QR/qrbook";
import QRBorrow from "./containers/QR/qrborrow";
import QRBorrowParent from "./containers/QR/qrborrowparent";
import QRReturn from "./containers/QR/qrreturn";
import QRReturnParent from "./containers/QR/qrreturnparent";

import Items from "./containers/Asset/items";
import ItemProfile from "./containers/Asset/itemprofile";
import ItemProfileBooking from "./containers/Asset/itemprofilebooking";
import HistoryAsset from "./containers/Asset/History/history";
import BookingItem from "./containers/Asset/bookingitem";
import MyHistory from "./containers/Asset/historymyrequest";
// import MyHistoryDetail from "./containers/Asset/historymyrequestdetail";
import RequestList from "./containers/Asset/requestlist";

// import BookingItemProfile from "./containers/Asset/bookingitemprofile";
import ActionHistory from "./containers/Asset/History/actionhistory";
import QRAsset from "./containers/QR/qrasset";

class Routes extends Component {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Mainmenu} />
          <Route exact path="/dashboard" component={Mainmenu} />
          <Route exact path="/student" component={Student} />
          <Route exact path="/student/profile" component={StudentProfile} />
          <Route exact path="/import" component={ImportStudent} />

          <Route exact path="/parent" component={Parent} />
          <Route exact path="/parent/profile" component={ParentProfile} />
          <Route exact path="/staff/profile" component={StaffProfile} />
          <Route exact path="/teacher/profile" component={TeacherProfile} />

          <Route exact path="/scanqr" component={Scanqr} />
          <Route exact path="/schoolattendance" component={SchoolAttendance} />
          <Route exact path="/report" component={Report} />
          <Route exact path="/report/attendance" component={ReportAttendance} />
          <Route
            exact
            path="/report/classattendance"
            component={ReportClassAttendance}
          />
          {/* <Route exact path="/report/classattendance" component={Apptest} /> */}
          <Route
            exact
            path="/report/classattreportby"
            component={ReportClassAttendanceBy}
          />
          <Route
            exact
            path="/report/classattreportbydate"
            component={ReportClassAttendanceByDate}
          />
          <Route
            exact
            path="/report/classattreportbyperiod"
            component={ReportClassAttendanceByPeriod}
          />
          <Route
            exact
            path="/report/classattreportbystudent"
            component={ReportClassAttendanceByStudent}
          />
          <Route exact path="/report/login" component={ReportLogin} />

          <Route exact path="/report/absent" component={Absent} />
          <Route
            exact
            path="/report/application"
            component={ApplicationReport}
          />
          <Route
            exact
            path="/report/registation"
            component={RegistationReport}
          />
          <Route exact path="/report/consent" component={Consent} />
          <Route exact path="/report/kitchen" component={Kitchen} />
          <Route exact path="/report/visit" component={VisitReport} />
          <Route
            exact
            path="/report/visitprofile"
            component={VisitProfileReport}
          />

          <Route exact path="/classAttendance" component={ClassAttendance} />

          <Route exact path="/management" component={MainManagement} />
          <Route exact path="/management/staff" component={Staff} />
          <Route exact path="/management/teacher" component={Teacher} />
          <Route exact path="/management/user" component={User} />
          <Route exact path="/management/role" component={Role} />
          <Route exact path="/management/course" component={Course} />
          <Route exact path="/management/term" component={Term} />

          <Route exact path="/course/profile" component={CourseProfile} />
          <Route
            exact
            path="/management/coursehistory"
            component={CourseHistory}
          />
          <Route
            exact
            path="/course/profilehistory"
            component={CourseHistoryProfile}
          />

          <Route exact path="/application" component={MainApplication} />
          <Route exact path="/application/visit" component={Visit} />
          <Route
            exact
            path="/application/visitprofile"
            component={VisitProfile}
          />
          <Route
            exact
            path="/application/formvisitrequest"
            component={FormVisitRequest}
          />
          <Route
            exact
            path="/application/application"
            component={Application}
          />
          <Route
            exact
            path="/application/applicationformdetail"
            component={ApplicationFormDetail}
          />
          <Route
            exact
            path="/application/registation"
            component={Registration}
          />
          <Route
            exact
            path="/application/registationdetail"
            component={RegistationDetail}
          />
          {/* <Route
            exact
            path="/application/registationdetailform2"
            component={RegistationDetailForm2}
          />
          <Route
            exact
            path="/application/registationdetailform3"
            component={RegistationDetailForm3}
          /> */}

          <Route exact path="/xxx/visitrequest" component={VisitRequest} />

          <Route exact path="/library" component={MainLibrary} />
          <Route exact path="/library/books" component={Books} />
          <Route exact path="/library/bookprofile" component={BookProfile} />
          <Route exact path="/library/history" component={History} />
          <Route
            exact
            path="/library/detailhistory"
            component={DetailHistory}
          />
          <Route exact path="/qrbook" component={QRBook} />
          <Route exact path="/qrborrow" component={QRBorrow} />
          <Route exact path="/qrborrowparent" component={QRBorrowParent} />
          <Route exact path="/qrreturn" component={QRReturn} />
          <Route exact path="/qrreturnparent" component={QRReturnParent} />

          <Route exact path="/asset" component={MainAsset} />
          <Route exact path="/asset/items" component={Items} />
          <Route exact path="/asset/itemprofile" component={ItemProfile} />
          <Route
            exact
            path="/asset/itemprofilebooking"
            component={ItemProfileBooking}
          />
          <Route exact path="/asset/booking_item" component={BookingItem} />
          <Route exact path="/asset/myhistory" component={MyHistory} />
          {/* <Route exact  path="/asset/myhistory/requestdetail" component={MyHistoryDetail}/> */}
          <Route exact path="/asset/borrow" component={RequestList} />

          {/* <Route
            exact
            path="/asset/booking_itemprofile"
            component={BookingItemProfile}
          /> */}
          <Route exact path="/asset/history" component={HistoryAsset} />
          <Route exact path="/asset/actionhistory" component={ActionHistory} />
          <Route exact path="/qrasset" component={QRAsset} />
        </Switch>
      </div>
    );
  }
}

export default Routes;
