import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as config from '../../config'
import {
  Button,
  Header,
  Icon,
  Image,
  Segment,
  Form,
  Step,
  Table,
  Message
} from 'semantic-ui-react';

import Wrap from "../../components/wrap";
import { ImportStudent, ImportStudentConfirm } from "./service";
import Mainbutton from "../../components/mainbutton";
import TableList from "../../components/table";



const ImpoerStudent = (props) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [values, setValues] = useState(null)
  const [errormsg, setErrormsg] = useState(null)
  const [data, setData] = useState(null)
  const [search, setSearch] = useState({ name: null })
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    }, {
      Header: "Image",
      accessor: "imageURL",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL ?
              cellInfo.original.imageURL + "?time=" + new Date()
              : '../storage/imgs/dfkid.png'
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              '../storage/imgs/dfkid.png';
          }}
        />
      ),
      width: 60,
    }, {
      Header: "Name",
      accessor: "name",
      sortable: false,
      width: 280,
      Cell: (cellInfo) => (
        <Wrap>
          <span
            className="bold"
            style={{ cursor: "pointer" }}
            onClick={() => props.history.push({
              pathname: '/student/profile',
              // search: '?query=abc',
              state: { id: cellInfo.original.id, page: 'student' }
            })
            }
          >
            {cellInfo.original.title} {cellInfo.original.firstName} {cellInfo.original.lastName}
          </span>
        </Wrap>
      ),
    }, {
      Header: "Student type",
      accessor: "studenttype",
      sortable: false,
    }, {
      Header: "Program",
      accessor: "program",
      sortable: false,
    }, {
      Header: "Course",
      accessor: "course",
      style: { textAlign: "left" },
      sortable: false,
      Cell: (cellInfo) => (
        // console.log(cellInfo)
        <span>
          {cellInfo.original ? (
            cellInfo.original.course ? (
              <span>
                {cellInfo.original.course.name} (
                {cellInfo.original.course.desc})
              </span>
            ) : null
          ) : null}
        </span>
      ),
    }, {
      Header: "Class",
      accessor: "class",
      sortable: false,
    }, {
      Header: "Allergy",
      accessor: "allergy",
      sortable: false,
    }, {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            // color="green"
            style={{ color: config.colorTheme, boxShadow: '0 0 0 0.1em ' + config.colorTheme + ' inset' }}
            name="user"
            onClick={() => props.history.push({
              pathname: '/student/profile',
              // search: '?query=abc',
              state: { id: cellInfo.original.id, page: 'student' }
            })
            }
          />
        </Wrap>
      ),
      width: 100,
    },
  ]);

  useEffect(() => {
    setLoading(false);
  }, [step])

  const clearStep = e => {
    setStep(1);
    setData(null);
    setValues(null);
  }

  const handleInputChange = e => {
    setErrormsg(null);
    if (e.target.files[0].type === 'text/plain' ||
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type === "image/jpeg")
      setErrormsg("You have uploaded an invalid file type");
    else setValues({ ...values, 'file': e.target.files[0] });

  }

  const uploadFile = e => {
    setLoading(true);
    var formData = new FormData();
    formData.append('excel', values.file)
    ImportStudent(formData).then(res => {
      if (res && res.status === 200) {
        if (res.data && res.data.error && res.data.error.errors === 0) {
          uploadFileConfirm(res.data.data);
        } else {
          setData(res.data)
          setValues(null);
          setLoading(false);
        }
      }
    })
  }
  const uploadFileConfirm = data => {
    setLoading(true);
    ImportStudentConfirm({ "data": data }).then(res => {
      if (res && res.status === 200) {
        if (res.data && res.data.error && res.data.error.errors === 0) {
          setData(res.data);
          setStep(2);
          setLoading(false);
        } else {
          setData(res.data)
          setValues(null);
          setLoading(false);
        }
      }
    })
  }
  const downloadTemplate = () => {
    var link = document.createElement("a");
    link.href = "./storage/template.xlsx";
    link.download = "data_student_template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  return (
    <Segment basic textAlign='left'>
      <Header as='h2' >Import Students</Header>
      <Segment className="contentSegment" textAlign="left">
        <Step.Group stackable='tablet' fluid ordered>
          <Step active={step == 1} completed={step > 1} >
            <Step.Content >
              <Step.Title onClick={clearStep} >Select File</Step.Title>
              <Step.Description>Upload an xlsx or xls file</Step.Description>
            </Step.Content>
          </Step>
          <Step active={step == 2} completed={step > 2} disabled={step != 2}>
            <Step.Content>
              <Step.Title>Summary</Step.Title>
              <Step.Description>Show details</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>

        {step == 1
          ? <Segment placeholder textAlign='center'>
            <Header icon>
              <Icon name='pdf file outline' />
              Upload an xlsx or xls file
            </Header>
            {values && <p style={{ color: config.colorTheme2 }}><b>Selected file :</b> {values.file.name}</p>}
            <Form>
              <Form.Input
                fluid
                icon="file"
                size="small"
                name="file"
                type='file'
                accept=".xlsx"
                onChange={handleInputChange}
              />
              <Mainbutton content='Upload' type='reset' disable={!values} loading={loading} onClick={uploadFile} />

              <br />
              <a onClick={downloadTemplate} >
                Download template
              </a>
            </Form>
            {
              data && data.error &&
              <Message icon error style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Icon name='warning circle' />
                <Message.Content>
                  <Message.List items={data.error.errorsmessage} />
                </Message.Content>
              </Message>
            }
          </Segment>
          : <TableList
            loading={loading}
            data={data && data.data}
            columns={column}
          />
        }
      </Segment>
    </Segment>
  );
}
export default withRouter(ImpoerStudent);
