import axios from "axios";
import * as config from "../../config";


export function getClassList(qString) {
    return axios
        .get(config.urlAPI + "/class" + qString, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

export function postCheckIn(data) {
    return axios
        .post(config.urlAPI + "/class/checkin", data, config.headerCallAPI)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}



