import React, { useState, useRef, useEffect } from "react";
import {
  Header,
  Icon,
  Segment,
  Card,
  Breadcrumb,
  Button,
  Grid,
  GridColumn,
  Image,
  Divider,
  Table,
} from "semantic-ui-react";
import QrReader from "react-qr-reader";
import QrScanner from "react-qr-scanner";
import moment from "moment";
import * as config from "../../config";
import {
  getDataFromReturnQR,
  postBorrow,
  getDataBooking,
  countDataBooking,
  forRunBooking,
} from "./service";
import Wrap from "../../components/wrap";
import ErrorModal from "./errormodal";
import StatusModal from "./statusmodal";
// import BookBorrow from "./bookborrow";
import Bookreturn from "./bookreturn";
// import QRReturn from "./qrreturnparent";

const QRReturn = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  );
  const [result, setResult] = useState(null);
  const [data, setData] = useState(null);
  const [student, setStudent] = useState(props.location.state.data);
  const [loading, setLoading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [status, setStatus] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [success, setSuccess] = useState(null);
  const [qrString, setQrString] = useState(null);
  const [legacyMode, setLegacyMode] = useState(false);
  const reader = useRef(null);
  const [getBooking, setBooking] = useState([]);
  const [countBooking, setCountBooking] = useState([]);
  const [runBooking, setRunBooking] = useState([]);
  const [bookingID, setBookingID] = useState(null);
  const [bookID, setBookID] = useState(null);

  const getData = (data) => {
    setLoading(true);
    let qString = data + "&student_id=" + student.id;
    console.log(`TEST-getData:`, qString);

    getDataFromReturnQR(qString).then((res) => {
      // if (res && res.status === 200) {
      //   setResult("found");
      //   setData(res.data);
      // } else if (res && res.status === 404) {
      //   setResult(res.data.error && res.data.error.errorsmessage);
      // }
      if (res && res.status === 200 && res.data.success === true) {
        setResult("found");
        setData(res.data.data);
        setBookingID(res.data.booking_id);
        setBookID(res.data.book_id);
      } else if (res && res.status === 200 && res.data.success === false) {
        setResult(res.data.message);
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    getDataBookList();
  }, [props.data]);

  const getDataBookList = () => {
    getDataBooking(props.location.state.data["id"]).then((res) => {
      if (res && res.status === 200) {
        setBooking(res.data);
      }
      setLoading(false);
    });

    countDataBooking(props.location.state.data["id"]).then((res) => {
      if (res && res.status === 200) {
        setCountBooking(res.data);
      }
      setLoading(false);
    });

    forRunBooking(props.location.state.data["id"]).then((res) => {
      if (res && res.status === 200) {
        var booking_id = res.data;
        var booking = Number(booking_id.split("W")["1"]) + Number(1);
        let walk_in = booking.toString().padStart(6, "0");

        setRunBooking("W" + walk_in);
      }
      setLoading(false);
    });
  };
  // console.log(getBooking);
  // console.log(countBooking);
  // console.log(runBooking);

  const handleScan = (data) => {
    if (data) {
      setScanning(false);
      setLoading(true);
      setQrString(data);
      getData(data);
    }
  };
  const handleError = (err) => {
    if (err) setResult(err.message);
  };

  const onReturn = (e) => {
    setScanning(true);
    setStatus("returnBook");
  };
  const onClosePopup = (e) => {
    setErrormsg(null);
    setSuccess(null);
  };

  const openImageDialog = () => {
    setLegacyMode(true);
    if (legacyMode) reader.current.openImageDialog();
  };

  return (
    <Segment basic textAlign="left" style={{ backgroundColor: "#fff" }}>
      <Wrap>
        <Grid columns="2" doubling>
          <Grid.Column width="4" textAlign="center">
            <Image
              verticalAlign="middle"
              src={
                student && student.imageURL
                  ? student.imageURL + "?time=" + new Date()
                  : "../storage/imgs/dfkid.png"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "../storage/imgs/dfkid.png";
              }}
            />
          </Grid.Column>
          <Grid.Column width="12">
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h2" style={{ color: config.colorTheme2 }}>
                    <Header.Content>
                      {student.firstName && student.firstName}&nbsp;&nbsp;
                      {student.lastName && student.lastName}
                      <Header.Subheader style={{ color: config.colorTheme2 }}>
                        {student.firstName_th && student.firstName_th}
                        &nbsp;&nbsp;{student.lastName_th && student.lastName_th}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column columns="2">
                  <Grid columns="2">
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Child's name (nick name)
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {student.nickname}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Gender
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {student.gender}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Date of birth
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {student.dateOfBirth}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Age
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {moment().diff(student.dateOfBirth, "years")} Year old
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      Emergency contact no.
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {" "}
                      {student.emergency_phone}
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className="bold">
                      E-mail
                    </Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                      {student.email}
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column columns="2">
                  <Grid columns="2">
                    <Grid.Column
                      style={{ paddingBottom: "20px" }}
                      className="bold"
                    >
                      Status
                    </Grid.Column>
                    <Grid.Column>{student.status}</Grid.Column>
                    <Grid.Column
                      style={{ paddingBottom: "20px" }}
                      className="bold"
                    >
                      Start Date
                    </Grid.Column>
                    <Grid.Column>{student.start_card}</Grid.Column>
                    <Grid.Column
                      style={{ paddingBottom: "20px" }}
                      className="bold"
                    >
                      End Date
                    </Grid.Column>
                    <Grid.Column>{student.end_card}</Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  width="4"
                  style={{ paddingBottom: "20px" }}
                  className="bold"
                >
                  Course
                </Grid.Column>
                <Grid.Column width="12">
                  {student.regist_course &&
                    student.regist_course.map((item) => (
                      <p>
                        {item.course_name} : {item.class_name} : {item.teacher}
                        <br />
                        {item.term_name}
                      </p>
                    ))}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </Wrap>

      <Grid>
        <Grid.Row columns="2">
          <Grid.Column columns="2">
            <StatusModal
              open={loading || success || errormsg}
              loading={loading}
              text={status}
              errormsg={errormsg}
              onClose={onClosePopup}
            />
            {result && result !== "found" && (
              <ErrorModal
                open={result}
                error={result}
                onClose={() => setResult(null)}
              />
            )}
            {scanning ? (
              <Wrap>
                <Button
                  floated="left"
                  basic
                  compact
                  icon="camera"
                  onClick={openImageDialog}
                  content={legacyMode ? "Submit a QR Code" : "Take a photos"}
                />

                <br />
                <br />
                {legacyMode ? (
                  <QrScanner
                    delay={100}
                    ref={reader}
                    facingMode
                    legacyMode
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "100%", height: 500, textAlign: "center" }}
                    resolution={500}
                    className="reader-container"
                  />
                ) : (
                  <QrReader
                    delay={100}
                    facingMode={"environment"}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "50%", height: 500, textAlign: "center" }}
                    resolution={500}
                    className="reader-container"
                  />
                )}
              </Wrap>
            ) : (
              <>
                <Card onClick={onReturn}>
                  <Card.Content>
                    <Header as="h3" icon>
                      <Icon name="qrcode" style={{ color: "red" }} />
                      Return
                    </Header>
                  </Card.Content>
                </Card>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {/* {data && result === "found" && <Bookreturn data={data} student={props} />} */}
      {data && result === "found" && (
        <Bookreturn
          data={data}
          student={props}
          bookID={bookID}
          bookingID={bookingID}
          userData={userData}
        />
      )}

      <Header as="h2">Book Borrow Lists</Header>
      <Table
        style={{
          borderRadius: 0,
          fontSize: "95%",
          overflowx: "auto",
          border: "1px solid black",
        }}
      >
        <Table.Header
          style={{
            boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
            textAlign: "center",
            border: "1px solid black",
          }}
        >
          <Table.Row>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "100px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Image
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Category
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              BookingID
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Item Code
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Brand
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Model
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              ISBN
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Name(EN)
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Age range
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Author
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Borrowed date
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Return Due Date
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                width: "50px",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
                border: "1px solid black",
              }}
            >
              Status
            </Table.HeaderCell>
            {/* <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', width: '50px', color: '#fff', paddingTop: '5px', paddingBottom: '5px', border: '1px solid black' }}>Remove</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getBooking.map((item) => (
            <Table.Row>
              <Table.HeaderCell style={{ border: "1px solid black" }}>
                <Image
                  width="100px"
                  verticalAlign="middle"
                  src={
                    item && item.imageURL
                      ? item.imageURL + "?time=" + new Date()
                      : "../storage/imgs/dfkid.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../storage/imgs/dfkid.png";
                  }}
                />
              </Table.HeaderCell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.category}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.booking_id}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.book_code}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.brand}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.model}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.isbn}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.name_en}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.age_range}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.author}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.borrow_date}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.return_due_date}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ border: "1px solid black" }}
              >
                {item.status}
              </Table.Cell>
              {/* <Table.Cell textAlign='center' style={{ border: '1px solid black' }}><Icon name='trash alternate' style={{ cursor: 'pointer', color: "red" }} /></Table.Cell> */}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
};
export default QRReturn;
