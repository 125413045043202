import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import * as config from '../../config'
import {
    Button,
    Header,
    Icon,
    Modal,
    Segment,
    Form,
    Label,
    Dimmer,
    Loader,
    Grid,
    Breadcrumb
} from 'semantic-ui-react';
import Wrap from "../../components/wrap";
import { getConsent, exportConsent } from "./service";
import TableList from "../../components/table";
import {
    DateInput,
    DatesRangeInput,
} from 'semantic-ui-calendar-react';
import { getVisit, exportVisit, reportVisitCountPending, reportVisitCountConfirm, reportVisitCountVisited, reportVisitCountCancel, reportVisitCountAll } from "./service";



const VisitReport = (props) => {
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({ program: '', campus: '', status: "", name: '' });
    const [countPading, setCountPading] = useState([]);
    const [countConfirm, setCountConfirm] = useState([]);
    const [countVisited, setCountVisited] = useState([]);
    const [countCancel, setCountCancel] = useState([]);
    const [countAll, setCountAll] = useState([]);
    const [search, setSearch] = useState({ name: null });
    const [data, setData] = useState([]);
    const [loadPopup, setLoadPopup] = useState(null)
    const [errormsg, setErrormsg] = useState(null)
    const [date, setDate] = useState(moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD"));
    const [column, setColumn] = useState([
        {
            Header: "No.",
            accessor: "",
            style: { textAlign: "center" },
            sortable: false,
            Cell: (cellInfo) => cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
            width: 50,
        },
        {
            Header: "Appointment",
            accessor: "dateTimeOfVisit",
            sortable: false,
            width: 100,
        },
        {
            Header: "Visit No.",
            accessor: "Id",
            sortable: false,
            width: 80,
            Cell: (cellInfo) => "V0000" + cellInfo.original.Id
        },
        {
            Header: "Campus",
            accessor: "campusname",
            style: { textAlign: "left" },
            sortable: false,
        },
        {
            Header: "Program",
            accessor: "programsname",
            sortable: false,
            style: { textAlign: "left" },
        },
        {
            Header: "Student",
            accessor: "",
            style: { textAlign: "center" },
            sortable: false,
            Cell: (cellInfo) => cellInfo.original.firstName + " " + cellInfo.original.lastName + " (" + cellInfo.original.nickname + ")"
        },
        {
            Header: "Birthdate",
            accessor: "dateOfBirth",
            sortable: false,
        },
        {
            Header: "Year old",
            accessor: "dateOfBirth",
            style: { textAlign: "center" },
            sortable: false,
            width: 60,
            Cell: (cellInfo) => (
                <Wrap>
                    {moment().diff(moment(cellInfo.original.dateOfBirth, 'DD-MM-YYYY'), 'years')}
                </Wrap>
            )
        },
        {
            Header: "Parent",
            accessor: "",
            style: { textAlign: "center" },
            sortable: false,
            Cell: (cellInfo) => cellInfo.original.parent_firstName + " " + cellInfo.original.parent_lastName
        },
        {
            Header: "e-mail",
            accessor: "email",
            sortable: false,
        },
        {
            Header: "Mobile",
            accessor: "mobile",
            sortable: false,
        },
        {
            Header: "Status",
            accessor: "status",
            sortable: false,
            width: 60,
        },
        {
            Header: "Date",
            accessor: "created_at",
            sortable: false,
            Cell: (cellInfo) => (
                cellInfo.original.created_at.split(" ") && cellInfo.original.created_at.split(" ")[0].split("-")[2] + "-" +
                cellInfo.original.created_at.split(" ")[0].split("-")[1] + "-" +
                cellInfo.original.created_at.split(" ")[0].split("-")[0] + " " +
                cellInfo.original.created_at.split(" ")[1]
            ),
            width: 80,
        },
    ]);
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (date)
          getData();
      }, [date, values.status, values.program, values.campus])


    const getData = () => {
        setLoading(true)
        if (values.status && values.status !== "") qString = qString + "&status=" + values.status;
        if (values.program && values.program !== "") qString = qString + "&program=" + values.program;
        if (values.campus && values.campus !== "") qString = qString + "&campus=" + values.campus;
        // if (search.name) qString = qString + "&name=" + search.name;
        const val = date.split(" - ");
        let start = val[0];
        let end = val[1];
        let qString = "";
        if (start && end) {
            qString = "?start=" + start + "&end=" + end;
        }

        getVisit(qString + "&status=" + values.status + '&program=' + values.program + '&campus=' + values.campus).then(res => {
            if (res && res.status === 200) {
                setColumn(column)
                setData(res.data)
            }
            setLoading(false)
        })


        reportVisitCountPending().then(res => {
            if (res && res.status === 200) {
                setCountPading(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportVisitCountConfirm().then(res => {
            if (res && res.status === 200) {
                setCountConfirm(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportVisitCountVisited().then(res => {
            if (res && res.status === 200) {
                setCountVisited(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportVisitCountCancel().then(res => {
            if (res && res.status === 200) {
                setCountCancel(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })
        reportVisitCountAll().then(res => {
            if (res && res.status === 200) {
                setCountAll(res.data)
                // console.log(res.data);
            }
            setLoading(false)
        })


    }

    const handleSelectChange = (e, data) => {
        const { name, value } = data
        setValues({ ...values, [name]: value })
    }

    const handleChangeDate = (event, { name, value }) => {
        setDate(value)
    };

    const onClickExport = () => {
        setLoadPopup(true)
        if (values.status && values.status !== "") qString = qString + "&status=" + values.status;
        if (values.program && values.program !== "All") qString = qString + "&program=" + values.program;
        if (values.campus && values.campus !== "All") qString = qString + "&campus=" + values.campus;
        // if (search.name) qString = qString + "&name=" + search.name;

        const val = date.split(" - ");
        let start = val[0];
        let end = val[1];
        let qString = "";
        if (start && end) {
            qString = "?start=" + start + "&end=" + end;
        }

        exportVisit(qString + "&status=" + values.status + '&program=' + values.program + '&campus=' + values.campus).then((res) => {
            if (res && res.status === 200) {
                setLoadPopup(false)
                var newBlob = new Blob([res.data]);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    var newBlobIE = new Blob([res.data], {
                        type:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    return navigator.msSaveOrOpenBlob(
                        newBlobIE,
                        "visit_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
                    );
                }
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement("a");
                link.href = data;
                // this.setState({link: link.href });
                link.download =
                    "visit-Report" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL

                    window.URL.revokeObjectURL(data);
                }, 100);
            } else {
                setErrormsg("The request is taking too long. Please try again");
            }
        });

    }


    return (
        <Segment basic textAlign='left'>
            <Header as='h2' >Visit Report</Header>
            <Grid columns='2' >
                <Grid.Column>
                    <Breadcrumb>
                        <Breadcrumb.Section
                            onClick={() => props.history.push("/report")}
                            style={{ color: "grey" }}
                        >
                            Report
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider icon="right angle" />
                        <Breadcrumb.Section active>Visit Report</Breadcrumb.Section>
                    </Breadcrumb>
                </Grid.Column>
                <Grid.Column>
                    <Button basic color='green'
                        content='Export Excel' floated='right' style={{ marginBottom: '14px' }}
                        onClick={onClickExport} />

                </Grid.Column>
            </Grid>


            <Segment className="contentSegment" textAlign="left">
                <Form>
                    <Form.Group>
                        <DatesRangeInput
                            name="date"
                            placeholder="Start - End"
                            hideMobileKeyboard
                            dateFormat="YYYY-MM-DD"
                            iconPosition="right"
                            value={date}
                            allowSameEndDate
                            onChange={handleChangeDate}
                            clearable
                        />
                        {/* <Form.Input
                            width="4"
                            icon="search"
                            size="small"
                            placeholder="Search"
                            name="name"
                            onChange={handleInputChange}
                        /> */}
                        <Form.Dropdown
                            width="4"
                            icon={null}
                            fluid
                            floating
                            style={{ width: "100%", marginRight: "30px" }}
                            name='campus'
                            trigger={
                                <Button
                                    basic
                                    icon
                                    fluid
                                    size="small"
                                    labelPosition="right"
                                    style={{ textAlign: "left" }}
                                >
                                    <Icon name="angle down" />
                                    Campus : <b>{values.campus &&
                                        values.campus == '1' && 'Kidz Village (KV)' ||
                                        values.campus == '2' && 'Kidz Learning Centre (KLC)'
                                    }</b>
                                </Button>
                            }
                            options={[{ key: " ", text: "All", value: " " }, ...config.options_campus]}
                            defaultValue={values.campus && values.campus.name}
                            selectOnBlur={false}
                            onChange={handleSelectChange}
                        />
                        <Form.Dropdown
                            width="4"
                            icon={null}
                            fluid
                            floating
                            style={{ width: "100%", marginRight: "30px" }}
                            name='program'
                            trigger={
                                <Button
                                    basic
                                    icon
                                    fluid
                                    size="small"
                                    labelPosition="right"
                                    style={{ textAlign: "left" }}
                                >
                                    <Icon name="angle down" />
                                    Program : <b>{values.program &&
                                        values.program == '1' && 'International Program' ||
                                        values.program == '2' && 'After School' ||
                                        values.program == '3' && 'Special class' ||
                                        values.program == '4' && 'Summer Program' ||
                                        values.program == '5' && 'Regular Program (Per Month)' ||
                                        values.program == '6' && 'Playgroup' ||
                                        values.program == '7' && 'Day care'
                                    }</b>
                                </Button>
                            }
                            options={[{ key: " ", text: "All", value: " " }, ...config.options_program]}
                            defaultValue={values.program && values.program.name}
                            selectOnBlur={false}
                            onChange={handleSelectChange}
                        />
                        <Form.Dropdown
                            width="4"
                            icon={null}
                            fluid
                            floating
                            style={{ width: "100%", marginRight: "30px" }}
                            name='status'
                            trigger={
                                <Button
                                    basic
                                    icon
                                    fluid
                                    size="small"
                                    labelPosition="right"
                                    style={{ textAlign: "left" }}
                                >
                                    <Icon name="angle down" />
                                    Status : <b>{values.status}</b>
                                </Button>
                            }
                            options={[{ key: " ", text: "All", value: " " }, ...config.options_status_visit]}
                            defaultValue={values.status}
                            selectOnBlur={false}
                            onChange={handleSelectChange}
                        />
                    </Form.Group>
                    <Grid.Column style={{ pading: '10%' }}>
                        <b>Total Visit Request: {countAll.length}</b><br></br>
                        <span>Pending: {countPading.length}</span><br></br>
                        <span>Confirm: {countConfirm.length}</span><br></br>
                        <span>Visited: {countVisited.length}</span><br></br>
                        <span>Cancel: {countCancel.length}</span>
                    </Grid.Column>
                </Form>


                <TableList
                    loading={loading}
                    data={data}
                    columns={column}
                />
            </Segment>

        </Segment>
    );
}
export default withRouter(VisitReport);
