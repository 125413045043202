import React from 'react'
import { Form } from 'semantic-ui-react'
import Wrap from './wrap'

const renderField = ({ input, label, placeholder, type, width, required, readOnly, meta: { touched, error, warning } }) => (
  <Wrap>
    <Form.Field width={width} required={required} style={{ marginBottom: '0px' }}>
      <Form.TextArea autoHeight label={label} {...input} error={touched && error} value={input.value}
        placeholder={placeholder} type={type} readOnly={readOnly} />
      <p style={{ color: '#9f3a38', textAlign: 'left', fontSize: '90%' }}>
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </p>
    </Form.Field>
  </Wrap>
)

export default renderField
