import React from "react";
import { Header, Grid } from "semantic-ui-react";
import * as config from "../config";

const HeadText = props => {
  return (
    <Grid style={{ paddingBottom: 0 }}>
      <Grid.Column floated="left" computer={8} tablet={8} mobile={8}>
        <Header as={props.as || "h2"}  >
          <Header.Content style={{ textAlign: "left" }}>
            {props.name}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column
        floated="right"
        computer={8}
        tablet={8}
        mobile={8}
        textAlign="right"
        verticalAlign="bottom"
        style={{ paddingTop: 0 }}
      >
        {props.children}
      </Grid.Column>
    </Grid>
  );
};

export default HeadText;
