import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import {
  Button,
  Form,
  Message,
  Grid,
  SegmentGroup,
  GridColumn,
  GridRow,
  Card,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  Input,
  // FormField, //Todo
} from "semantic-ui-react";
import { set } from "core-js/core/dict";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import moment, { relativeTimeRounding } from "moment";
import QrReader from "react-qr-reader";
import { getAssetHistoryDetail, updScanBorrow } from "./service";

// const validate = (values) => {
//   const errors = {};
//   if (!values.date_of_use) errors.date_of_use = true;
//   if (!values.return_due_date) errors.return_due_date = true;
//   if (!values.use_for) errors.use_for = true;
//   if (!values.use_detail) errors.use_detail = true;
//   return errors;
// };

let FormBorrow = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    // initialValues,
    onClose,
    // errormsg,
    // data,
    // columnbookinglist,
    dataReqNo,
  } = props;

  const [errormsg, setErrormsg] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);

  const [per_page, setPerPage] = useState(5);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  // const [dataDetail, setDataDetail] = useState([]);

  const [dataBorrow, setDataBorrow] = useState([]);
  const [scan, setScan] = useState({
    request_no: "",
    asset_id: "",
  });

  const [scanResultFile, setScanResultFile] = useState("");
  const [scanResultCam, setScanResultCam] = useState("");
  const qrRef = useRef(null);
  const [legacyMode, setLegacyMode] = useState(false);
  // const [firstload, setFirstload] = useState(true);
  // set colum for bookinglist
  const [columnbookinglist, setColumnbookinglist] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      width: 35,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
    },
    {
      Header: "Borrow",
      // accessor: "bscan",
      accessor: "isScan",
      style: { textAlign: "center" },
      sortable: false,
      width: 55,
      Cell: (cellInfo) =>
        cellInfo.original.isScan === "0" ||
        cellInfo.original.isScan === null ? (
          ""
        ) : (
          <Wrap>
            <Icon bordered name="check" />
          </Wrap>
        ),
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      width: 70,
      Cell: (cellInfo) => (
        <Image
          // size="mini"
          width="50"
          height="50"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/no-image.jpg"
          }
          // src={
          //   cellInfo.original.authimgurl
          //     ? cellInfo.original.authimgurl
          //     : "../storage/imgs/no-image.jpg"
          // }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/no-image.jpg";
          }}
        />
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "Item Code",
      accessor: "item_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Type",
      accessor: "type",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Supplier",
      accessor: "supplier",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Quantity",
      accessor: "borqty",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Unit",
      accessor: "unit",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    // {
    //   Header: "Scan QR",
    //   accessor: "id",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   width: 55,
    //   Cell: (cellInfo) => (
    //     <Wrap>
    //       <Icon
    //         link
    //         bordered
    //         style={{
    //           color: "red",
    //           boxShadow: "0 0 0 0.1em " + "red" + " inset",
    //         }}
    //         name="qrcode"
    //         // onClick={() =>
    //         //   onDeleteBooking(
    //         //     cellInfo.original.id,
    //         //     cellInfo.original.asset_id,
    //         //     cellInfo.original.qty
    //         //   )
    //         // }
    //         // onClick={() =>
    //         //   props.history.push({
    //         //     pathname: "/booking/bookprofile",
    //         //     state: { id: cellInfo.original.id },
    //         //   })
    //         // }
    //       />
    //     </Wrap>
    //   ),
    // },
  ]);

  useEffect(() => {
    if (!tableLoading) {
      loadHistoryDetail();
    }
  }, [per_page, current_page]);

  const loadHistoryDetail = () => {
    setTableLoading(true);

    // console.log(`InitValue-test`, dataReqNo);

    //get history detail
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    qString = qString + "&request_no=" + dataReqNo;

    getAssetHistoryDetail(qString).then((res) => {
      // console.log(`getAssetHistoryDetail-Form`, res); //Test

      if (res && res.status === 200) {
        setColumnbookinglist(columnbookinglist);
        // setColumnReturnlist(columnReturnlist);
        setData(res.data.data);
        setPerPage(per_page);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);

        //   if (mode === "borrow" && !showAssetHistoryDetail)
        //     setShowAssetHistoryDetail(true);
        //   else if (mode === "return" && !showAssetReturn)
        //     setShowAssetReturn(true);
      }

      // setLoading(false);
      setTableLoading(false);
    });
  };

  const handleErrorFile = (error) => {
    console.log(`handleErrorFile`, error);
  };

  const handleScanFile = (result) => {
    if (result) {
      // let show = moment().format("YYYY-MM-DD hh:mm:ss");
      // setScanResultFile(show);

      const assetid = result.substring(6); // 1443
      updScanData(assetid);

      loadHistoryDetail(); //TEST

      //Data correct but cannot reload real time

      // // const qrdata = result; // "?item=1443"
      // const assetid = result.substring(6); // 1443
      // console.log(`test-scanfile-qr`, assetid);

      // dataBorrow.map((item) => {
      //   // console.log(`test-scanfile-map`, item.id);

      //   if (item.asset_id === parseInt(assetid)) {
      //     item.bscan = "scanned";
      //   }
      // });
    }
  };

  const onScanFile = () => {
    qrRef.current.openImageDialog();
  };

  const handleErrorCam = (error) => {
    console.log(`handleErrorCam`, error);
  };

  const handleScanCam = (result) => {
    if (result) {
      setLegacyMode(false); // turn off camera

      // let show = moment().format("YYYY-MM-DD hh:mm:ss");
      // setScanResultCam(show);

      const assetid = result.substring(6); // 1443
      updScanData(assetid);

      loadHistoryDetail(); //TEST
    }
  };
  const onScanCam = () => {
    if (legacyMode) {
      setLegacyMode(false);
    } else {
      setLegacyMode(true);
    }
  };

  const updScanData = (assetid) => {
    // setLoadMsgPopup(true);
    // setErrormsg(null);

    // if (!dataBooking[0]) {
    //   setErrormsg("Empty booking list! ...Cannot Confirm booking.");
    //   return;
    // }

    scan["asset_id"] = assetid;
    scan["request_no"] = data[0].request_no;

    // console.log(`updScanData`, scan); //Test

    updScanBorrow(scan).then((res) => {
      // console.log(`updScanBorrow`, res); //Test
      if (res && res.status === 200) {
        setScanResultCam(res.data.msg);
        // setLoadMsgPopup(false);
        // setMsgConfirm1(res.data.request_no);
        // setLoadSucPopup(true); //Successful Booking
      }
      // else if (res.data.cnt_scan === 0 && res.status === 200) {
      //   setErrormsg("Scan qr update failed!");
      // }
      // else {
      //   setErrormsg(res.data.message);
      // }
      // getData();
      // setShowAssetHistoryDetail(false);
      // if (res && res.data.cnt_scan > 0 && res.status === 200) {
      //   props.history.push("/asset/myhistory");
      // }
    });
  };

  return (
    <Segment basic textAlign="left">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column
            largeScreen={4}
            mobile={6}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            Request No. :
          </Grid.Column>
          <Grid.Column
            largeScreen={10}
            mobile={8}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            {data[0] ? data[0].request_no : ""}
          </Grid.Column>
          <Grid.Column
            largeScreen={4}
            mobile={6}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            Request Date :
          </Grid.Column>
          <Grid.Column
            largeScreen={10}
            mobile={8}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            {data[0] ? data[0].booking_date : ""}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns="2" doubling>
        <Grid.Column width="4">
          <Image
            verticalAlign="middle"
            src={
              data[0] && data[0].authUserimg
                ? data[0].authUserimg
                : "../storage/imgs/no-image.jpg"
            }
            // src={
            //     data.data[0] && data.data[0].userImage
            //     ? data[0].userImage
            //     : "../storage/imgs/no-image.jpg"
            // }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "../storage/imgs/no-image.jpg";
            }}
          />
        </Grid.Column>
        <Grid.Column columns="1" width="12" style={{ minHeight: "30vh" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" style={{ color: config.colorTheme2 }}>
                  <Header.Content>
                    {data[0] ? data[0].fullname : ""}
                    <Header.Subheader
                      style={{ color: config.colorTheme2 }}
                    ></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="4">
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Passport or ID No. :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].idnumber : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Date of birth :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].dateOfBirth : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Nationality :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].nationality : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Age :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].age : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Native Language :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].nativelanguage : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Gender :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].gender : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Mobile Phone :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].phone : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Address :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].address : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                E-mail :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].email : ""}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>

      {/* fill section */}

      <Grid.Column style={{ paddingBottom: 0 }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group unstackable>
            <Form.Field>
              <label>Date of Use:</label>
              <input
                defaultValue={
                  data[0]
                    ? moment(data[0].date_of_use).format("YYYY-MM-DD")
                    : ""
                }
                readOnly={true}
              ></input>
            </Form.Field>
            <Form.Field>
              <label>Return due date:</label>
              <input
                defaultValue={
                  data[0]
                    ? moment(data[0].return_due_date).format("YYYY-MM-DD")
                    : ""
                }
                readOnly={true}
              ></input>
            </Form.Field>
            <Form.Field>
              <label>Use for:</label>
              <input
                defaultValue={data[0] ? data[0].use_for : ""}
                readOnly={true}
              ></input>
            </Form.Field>
            <Form.Field width={10}>
              <label>Use for detail:</label>
              <input
                defaultValue={data[0] ? data[0].use_detail : ""}
                readOnly={true}
              ></input>
            </Form.Field>
          </Form.Group>
          <br />
          {/* QR section */}
          <Grid columns={2} divided>
            <Grid.Row>
              <GridColumn align="center">
                <Header as="h5" icon>
                  <Icon
                    link
                    style={{
                      color: "green",
                    }}
                    name="sign-in"
                    onClick={onScanFile}
                  />
                  {/* Scan QR Code by file */}
                  Import QR Code
                </Header>
              </GridColumn>
              {/* <Grid.Column align="center">
                <QrReader
                  ref={qrRef}
                  delay={300}
                  style={{ width: "50%" }}
                  onError={handleErrorFile}
                  onScan={handleScanFile}
                  legacyMode
                />
                Last scanned by file: {scanResultFile}
              </Grid.Column> */}
              <GridColumn align="center">
                <Header as="h5" icon>
                  <Icon
                    link
                    style={{
                      color: "green",
                    }}
                    name="qrcode"
                    onClick={onScanCam}
                  />
                  Scan QR Code
                </Header>
              </GridColumn>
            </Grid.Row>
          </Grid>
          <Grid.Row>
            <Grid.Column align="center">
              <Header as="h3" color="green">
                {scanResultCam}
              </Header>
              {legacyMode ? (
                <QrReader
                  delay={300}
                  style={{ width: "80%" }}
                  onError={handleErrorCam}
                  onScan={handleScanCam}
                />
              ) : (
                <QrReader
                  ref={qrRef}
                  delay={300}
                  style={{ width: "80%" }}
                  onError={handleErrorFile}
                  onScan={handleScanFile}
                  legacyMode
                />
              )}
              {/* <Header as="h3" color="green">
                Scanned Result:__{scanResultCam}
              </Header> */}
            </Grid.Column>
          </Grid.Row>
          <br />
          <Header as="h3">Request Lists</Header>
          <Grid.Column>
            {/* <TableList
              data={data}
              columns={columnbookinglist}
              loading={loading}
              defaultPageSize={5}
            /> */}
            <TableList
              loading={tableLoading}
              data={data}
              columns={columnbookinglist}
              pageSize={per_page}
              page={current_page}
              pages={last_page}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(value) => setPerPage(value)}
            />
          </Grid.Column>
          <br />
          {/* <Header as="h2">Book Borrow Lists</Header> */}
          {/* <Table
            style={{
              borderRadius: 0,
              fontSize: "95%",
              overflowx: "auto",
              // border: "1px solid black",
            }}
          >
            <Table.Header
              style={{
                boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
                textAlign: "center",
                // border: "1px solid black",
              }}
            >
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "50px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Borrow
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "100px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Image
                </Table.HeaderCell>

                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "80px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Item Code
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "50px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Type
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "50px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Category
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "50px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Brand
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "50px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Model
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "100px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Item Name [EN]
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "100px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Item Name [TH]
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "50px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Supplier
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "50px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Quantity
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderRadius: 0,
                    backgroundColor: "#80561B",
                    width: "50px",
                    color: "#fff",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    // border: "1px solid black",
                  }}
                >
                  Unit
                </Table.HeaderCell>

              </Table.Row>
            </Table.Header>
            <Table.Body>
              {dataShow.map((item) => (
                <Table.Row key={item.asset_id}>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "0.5px solid black" }}
                  >
                    {item.bscan}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "0.5px solid black" }}
                  >
                    <Image
                      // width="100px"
                      width="50"
                      height="50"
                      verticalAlign="middle"
                      src={
                        item && item.imageURL
                          ? item.imageURL + "?time=" + new Date()
                          : "../storage/imgs/no-image.jpg"
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../storage/imgs/no-image.jpg";
                      }}
                    />
                  </Table.Cell>

                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.item_code}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.type}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.category}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.brand}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.model}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.name_en}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.name_th}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.supplier}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.borqty}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    // style={{ border: "1px solid black" }}
                  >
                    {item.unit}
                  </Table.Cell>

                </Table.Row>
              ))}
            </Table.Body>
          </Table> */}
          <br />
          <div>
            <SecondButton
              floated="right"
              content="Cancel"
              color="grey"
              onClick={onClose}
            />
            <Button
              floated="left"
              content="Save"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "30%",
              }}
              loading={loading}
              // disabled={pristine || submitting}
            />
          </div>
          <br />
        </Form>
      </Grid.Column>
    </Segment>
  );
};

FormBorrow = reduxForm({
  // a unique name for the form
  form: "requestasset",
  // validate,
  enableReinitialize: true,
})(FormBorrow);

export default FormBorrow;
