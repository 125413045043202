import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import * as config from '../../config';
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon
} from 'semantic-ui-react';
import { getvisitProfile, updatevisitprofile } from "../service";
import Wrap from "../../components/wrap";
import FormAlert from "../formalert";
import ModalBasic from "../../components/modal";


const VisitProfileReport = (props) => {
  const [loading, setLoading] = useState(true);
  const [visitProfiles] = useState(props && props.location.state && props.location.state.Id);
  const [data, setData] = useState(null);
  const [errormsg, setErrormsg] = useState(null)
  const [showPopup, setShowPopup] = useState(false);


  useEffect(() => {
    if (!visitProfiles)
      props.history.push('/report/visit')

    setLoading(true);
    if (visitProfiles) {
      getData();
    }
  }, [visitProfiles]);

  const getData = () => {
    getvisitProfile(props.location.state.Id).then(res => {
      // console.log(props.location.state);
      if (res && res.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    });
  };

  const onEditProfile = () => {
    setShowPopup(true);
  }

  const updateData = (value) => {
    setLoading(true);
    setErrormsg(null);
    updatevisitprofile(value).then(res => {
      if (res && res.status === 200) {
        getData();
        setShowPopup(false)
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);

    });
  };
  // console.log(props.location.state.Id);

  return (
    <Wrap>
      <Segment basic textAlign='left'>
        <Header as='h2' >Visit Profile</Header>
        <Grid columns='2' >
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => props.history.push("/report/visit")}
                style={{ color: "grey" }}
              >
                Visit Form
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Visit Profile</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column>
            <Button basic color='green'
              content='Edit profile' floated='right' style={{ marginBottom: '14px' }}
              onClick={onEditProfile} />
          </Grid.Column>
        </Grid>
        <Segment className="contentSegment" textAlign="left" style={{ minHeight: '100vh', padding: '20px' }}>
          {loading
            ? <Dimmer active inverted >
              <Loader inverted>Loading</Loader>
            </Dimmer>
            : <Wrap>
              <Grid columns='2' doubling>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h3" style={{ color: config.colorTheme2 }}>
                      <Header.Content>
                        Number of visitor : V0000{props.location.state.Id}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='2' >
                  <Grid.Column columns='2'>
                    <Grid columns='2' >
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Campus</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>
                        { data.id_campus === 1 && "Kidz Village (KV)"  }
                        { data.id_campus === 2 && "Kidz Learning Centre (KLC)"  }
                      </Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Program</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>
                        { data.id_program === 1 && "International Program" }
                        { data.id_program === 2 && "After School" }
                        { data.id_program === 3 && "Special class" }
                        { data.id_program === 4 && "Summer Program" }
                        { data.id_program === 5 && "Regular Program (Per Month)" }
                        { data.id_program === 6 && "Playgroup" }
                        { data.id_program === 7 && "Day care" }
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column columns='2'>
                    <Grid columns='2' >
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Appointment to visit</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.dateTimeOfVisit}</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Status</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.status}</Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                </Grid.Row>

                <Grid.Row columns='2' >
                  <Grid.Column columns='2'>
                    <Grid columns='2' >
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Parent's name</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.parent_firstName} {data.parent_lastName}</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Mobile Phone</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.mobile}</Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column columns='2'>
                    <Grid columns='2' >
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Nationality</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.parent_nationality}</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>E-mail</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.email}</Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                </Grid.Row>

                <Grid.Row columns='2' >
                  <Grid.Column columns='2'>
                    <Grid columns='2' >
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Child's name</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.firstName} {data.lastName}</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Date of birth</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.dateOfBirth}</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Which channel do you hear about us</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.channel}</Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column columns='2'>
                    <Grid columns='2' >
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Nickname</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.nickname}</Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Gender </Grid.Column>
                      <Grid.Column style={{ paddingBottom: 0 }}>{data.gender}</Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Wrap>
          }
        </Segment>
      </Segment>
      <ModalBasic open={showPopup} content='Teacher' close={() => setShowPopup(false)}>
        <FormAlert
          onSubmit={updateData}
          onClose={() => setShowPopup(false)}
          loading={loading}
          initialValues={data}
          errormsg={errormsg}
        />
      </ModalBasic>
    </Wrap>
  )
}

export default withRouter(VisitProfileReport);






