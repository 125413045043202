import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Grid,
  Image,
  Breadcrumb,
  GridColumn,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import ModalBasic from "../../components/modal";
import ItemForm from "./formitem";
import ConfirmBookingForm from "./formconfirmbooking";
import { DateInput, DatesRangeInput } from "semantic-ui-calendar-react";
import {
  postAsset,
  getAssetsList,
  updateAssetStock,
  getAssetProfile,
  exportAssetsList,
  getBookingList,
  deleteBooking,
  confirmBooking,
} from "./service";
import "./Book.css";

const BookingItem = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  ); //Todo
  const [data, setData] = useState([]);
  const [msgConfirm1, setMsgConfirm1] = useState([]); //keep message
  const [msgConfirm2, setMsgConfirm2] = useState([]); //keep message
  const [bookingCount, setBookingCount] = useState(false);
  const [dataBooking, setDataBooking] = useState([]);

  //   const [mstockdata, setMStockData] = useState([]);
  const [curDefectQty, setCurDefectQty] = useState(0);
  const [loading, setLoading] = useState(true);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);

  const [per_pageBkList, setPerPageBkList] = useState(5);
  const [current_pageBkList, setCurrentPageBkList] = useState(0);
  const [sort_pageBkList, setSortPageBkList] = useState(null);
  const [last_pageBkList, setLastPageBkList] = useState(1);
  const [values, setValues] = useState({
    maintype: "",
    type: "",
    category: "",
    borrow_status: "",
    // status: "Active",
    status: "",
  });
  // const [search, setSearch] = useState({ name: "" });
  const [search, setSearch] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [loadMsgPopup, setLoadMsgPopup] = useState(null); //for confirm booking
  const [loadSucPopup, setLoadSucPopup] = useState(null); //for successful booking
  const [loadDeletePopup, setLoadDeletePopup] = useState(null); //for delete
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  const [showPopupConfirmBooking, setShowPopupConfirmBooking] = useState(false);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          // size="mini"
          width="50"
          height="50"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/book.png"
          }
          // src={
          //   cellInfo.original.authimgurl
          //     ? cellInfo.original.authimgurl
          //     : "../storage/imgs/no-image.jpg"
          // }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/no-image.jpg";
          }}
        />
      ),
      style: { textAlign: "center" },
      width: 100,
    },
    {
      Header: "Type",
      accessor: "type",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Code",
      accessor: "item_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Initial Stock",
      accessor: "initial_stock",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Available Quantity",
      accessor: "qty",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Unit",
      accessor: "unit",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
    },
    {
      Header: "Status Stock",
      accessor: "borrow_status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status Item",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/asset/itemprofilebooking",
                state: { id: cellInfo.original.id },
              })
            }
          />
        </Wrap>
      ),
    },
  ]);

  // //Todo
  // // set colum for bookinglist
  // const [columnbookinglist, setColumnbookinglist] = useState([
  //   {
  //     Header: "No.",
  //     accessor: "",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 35,
  //     Cell: (cellInfo) =>
  //       cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
  //   },
  //   {
  //     Header: "Image",
  //     accessor: "id",
  //     sortable: false,
  //     width: 70,
  //     Cell: (cellInfo) => (
  //       <Image
  //         // size="mini"
  //         width="50"
  //         height="50"
  //         verticalAlign="middle"
  //         src={
  //           cellInfo.original.imageURL
  //             ? cellInfo.original.imageURL + "?time=" + new Date()
  //             : "../storage/imgs/no-image.jpg"
  //         }
  //         // src={
  //         //   cellInfo.original.authimgurl
  //         //     ? cellInfo.original.authimgurl
  //         //     : "../storage/imgs/no-image.jpg"
  //         // }
  //         onError={(e) => {
  //           e.target.onerror = null;
  //           e.target.src = "../storage/imgs/no-image.jpg";
  //         }}
  //       />
  //     ),
  //     style: { textAlign: "center" },
  //   },
  //   {
  //     Header: "Item Code",
  //     accessor: "item_code",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Type",
  //     accessor: "type",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Category",
  //     accessor: "category",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Brand",
  //     accessor: "brand",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Model",
  //     accessor: "model",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [EN]",
  //     accessor: "name_en",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [TH]",
  //     accessor: "name_th",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Supplier",
  //     accessor: "supplier",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Quantity",
  //     accessor: "qty",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Unit",
  //     accessor: "unit",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 50,
  //   },

  //   {
  //     Header: "Remove",
  //     accessor: "id",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 55,
  //     Cell: (cellInfo) => (
  //       <Wrap>
  //         <Icon
  //           link
  //           bordered
  //           style={{
  //             color: "red",
  //             boxShadow: "0 0 0 0.1em " + "red" + " inset",
  //           }}
  //           name="trash alternate outline" //bin icon
  //           onClick={() =>
  //             onDeleteBooking(
  //               cellInfo.original.id,
  //               cellInfo.original.asset_id,
  //               cellInfo.original.qty
  //             )
  //           }
  //           // onClick={() =>
  //           //   props.history.push({
  //           //     pathname: "/booking/bookprofile",
  //           //     state: { id: cellInfo.original.id },
  //           //   })
  //           // }
  //         />
  //       </Wrap>
  //     ),
  //   },
  // ]);

  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, values]);

  useEffect(() => {
    if (search) {
      setLoading(true);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        getData();
      }, 2000);
    }
  }, [search]);

  const getData = () => {
    // setLoading(true);
    // if (values.type && values.type != "")
    //   qString = qString + "&type=" + values.type;
    // if (values.category && values.category != "")
    //   qString = qString + "&category=" + values.category;
    // if (values.borrow_status && values.borrow_status !== "")
    //   qString = qString + "&borrow_status=" + values.borrow_status;
    // if (values.status && values.status != "")
    //   qString = qString + "&status=" + values.status;
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;

    // let qString = "?";
    if (search) {
      if (search.name) qString = qString + "&name=" + search.name;
    }
    if (values.maintype && values.maintype != "" && values.maintype != "All") {
      qString = qString + "&maintype=" + values.maintype;
    }
    if (values.type && values.type != "" && values.type != "All") {
      qString = qString + "&type=" + values.type;
    }
    if (values.category && values.category != "" && values.category != "All") {
      qString = qString + "&category=" + values.category;
    }
    if (
      values.borrow_status &&
      values.borrow_status != "" &&
      values.borrow_status != "All"
    ) {
      qString = qString + "&borrow_status=" + values.borrow_status;
    }
    if (values.status && values.status != "" && values.status != "All") {
      qString = qString + "&status=" + values.status;
    }

    getAssetsList(qString).then((res) => {
      if (res && res.status === 200) {
        // console.log(`getAssetsList`, res);
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
    loadBookingList();
  };

  //Todo
  const loadBookingList = () => {
    //get booking list data
    let page = current_pageBkList + 1;
    let qbooking = "?_perpage=" + per_pageBkList + "&page=" + page;

    // let qbooking = "?";
    if (userData.id) qbooking = qbooking + "&user_id=" + userData.id; //Current user login
    getBookingList(qbooking).then((res) => {
      // console.log(`getBookingList`, res); //Test
      // console.log(`getBookingList-Userdata`, userData); //Test

      if (res && res.status === 200) {
        // setColumnbookinglist(columnbookinglist);
        setDataBooking(res.data.data);
        // // setBookingCount(res.data.data.length);
        setBookingCount(res.data.total);
        // setPerPageBkList(per_pageBkList);
        // setCurrentPageBkList(res.data.current_pageBkList - 1);
        // setLastPageBkList(res.data.last_pageBkList);
      }
      // setLoading(false);
    });
  };

  //Todo
  const onDeleteBooking = (borrowid, assetid, qty) => {
    setErrormsg(null);
    setLoadDeletePopup(true);
    values["borrow_id"] = borrowid;
    values["asset_id"] = assetid;
    values["qty"] = qty;
    values["updated_by"] = userData.id;
    // console.log(values); //Test
    deleteBooking(values).then((res) => {
      // console.log(res); //Test
      if (res.data.affectedRows === 1 && res.status === 200) {
        // setShowPopup(false);
        setLoadDeletePopup(false);
        loadBookingList();
      } else if (res.data.affectedRows == 0 && res.status === 200) {
        setErrormsg("Already confirm booking. Cannot delete.");
      } else {
        setErrormsg(res.data.message);
        // setErrormsg("Delete failed.");
      }
    });
  };

  const onBookingConfirm = (values) => {
    setLoadMsgPopup(true);
    setErrormsg(null);

    if (!dataBooking[0]) {
      setErrormsg("Empty booking list! ...Cannot Confirm booking.");
      return;
    }

    // values["user_id"] = userData.id;
    values["request_no"] = dataBooking[0].request_no;

    // console.log(`onBookingConfirm`, values); //Test

    confirmBooking(values).then((res) => {
      // console.log(`confirmBooking`, res); //Test
      // console.log(`msg`, res.data.msg);
      if (res && res.data.cnt_confirm > 0 && res.status === 200) {
        setLoadMsgPopup(false);

        setMsgConfirm1(res.data.request_no);
        setLoadSucPopup(true); //Successful Booking
        // getData();
      } else if (res.data.cnt_confirm === 0 && res.status === 200) {
        setErrormsg("Confirm booking failed!");
      } else if (res.status === 302) {
        setErrormsg(
          "Mixed main_type (Asset & Consumable)! ...Cannot confirm booking"
        );
      } else if (res.data.ecode === -1 && res.status === 200) {
        setErrormsg(res.data.msg);
      } else {
        setErrormsg(res.data.message);
      }
    });
  };

  const handleLoadSucPopup = () => {
    setLoadSucPopup(false);
    setShowPopupConfirmBooking(false);

    props.history.push("/asset/myhistory");
  };

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };

  const onClickExport = () => {
    setLoadPopup(true);
    setErrormsg(null);

    let qString = "?";
    if (search) {
      if (search.name) qString = qString + "&name=" + search.name;
    }
    if (values.maintype && values.maintype != "" && values.maintype != "All") {
      qString = qString + "&maintype=" + values.maintype;
    }
    if (values.type && values.type != "" && values.type != "All") {
      qString = qString + "&type=" + values.type;
    }
    if (values.category && values.category != "" && values.category != "All") {
      qString = qString + "&category=" + values.category;
    }
    if (
      values.borrow_status &&
      values.borrow_status != "" &&
      values.borrow_status != "All"
    ) {
      qString = qString + "&borrow_status=" + values.borrow_status;
    }
    if (values.status && values.status != "" && values.status != "All") {
      qString = qString + "&status=" + values.status;
    }

    exportAssetsList(qString).then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false);
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "AssetListReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        // this.setState({ link: link.href });
        link.download =
          "AssetListReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Request Stock</Header>

      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/asset")}
              style={{ color: "grey" }}
            >
              Asset
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Request Stock</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        {/* <Grid.Column>
          <Button
            basic
            color="green"
            content="Export Excel"
            floated="right"
            style={{ marginBottom: "14px" }}
            onClick={onClickExport}
          />
        </Grid.Column> */}
        <Grid.Column>
          <Button
            basic
            color="green"
            content="My Stock Request History"
            floated="right"
            style={{ marginBottom: "5px", marginRight: "15px" }}
            onClick={() => props.history.push("/asset/myhistory")}
          />
        </Grid.Column>
      </Grid>
      <Segment
        className="contentSegment"
        textAlign="left"
        // style={{ minHeight: "50vh", padding: "20px" }}
      >
        <Form>
          {/* <Grid columns={2}> */}
          <Grid>
            <Grid.Row>
              <GridColumn largeScreen={14} mobile={15} tablet={10}>
                <Form.Group>
                  <Form.Input
                    width="4"
                    icon="search"
                    size="small"
                    placeholder="Search"
                    name="name"
                    onChange={handleInputChange}
                  />
                  <Form.Dropdown
                    width="4"
                    icon={null}
                    fluid
                    floating
                    style={{ width: "100%", marginRight: "30px" }}
                    name="maintype"
                    trigger={
                      <Button
                        basic
                        icon
                        fluid
                        size="small"
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        <Icon name="angle down" />
                        Main Type : <b>{values.maintype}</b>
                      </Button>
                    }
                    options={[
                      { key: "all", text: "All", value: "All" },
                      ...config.options_maintype,
                    ]}
                    defaultValue={values.maintype}
                    selectOnBlur={false}
                    onChange={handleSelectChange}
                  />
                  <Form.Dropdown
                    width="4"
                    icon={null}
                    fluid
                    floating
                    style={{ width: "100%", marginRight: "30px" }}
                    name="type"
                    trigger={
                      <Button
                        basic
                        icon
                        fluid
                        size="small"
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        <Icon name="angle down" />
                        Type : <b>{values.type}</b>
                      </Button>
                    }
                    options={[
                      { key: "all", text: "All", value: "All" },
                      ...config.options_type,
                    ]}
                    defaultValue={values.type}
                    selectOnBlur={false}
                    onChange={handleSelectChange}
                  />
                  <Form.Dropdown
                    width="4"
                    scrolling="true"
                    icon={null}
                    fluid
                    floating
                    style={{ width: "100%", marginRight: "30px" }}
                    name="category"
                    trigger={
                      <Button
                        basic
                        icon
                        fluid
                        size="small"
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        <Icon name="angle down" />
                        Category : <b>{values.category}</b>
                      </Button>
                    }
                    options={[
                      { key: "all", text: "All", value: "All" },
                      ...config.options_assetcategory,
                    ]}
                    defaultValue={values.category}
                    selectOnBlur={false}
                    onChange={handleSelectChange}
                  />
                  <Form.Dropdown
                    width="4"
                    icon={null}
                    fluid
                    floating
                    style={{ width: "100%", marginRight: "30px" }}
                    name="borrow_status"
                    trigger={
                      <Button
                        basic
                        icon
                        fluid
                        size="small"
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        <Icon name="angle down" />
                        Status stock : <b>{values.borrow_status}</b>
                      </Button>
                    }
                    options={[
                      { key: "all", text: "All", value: "All" },
                      ...config.options_status_stock,
                    ]}
                    defaultValue={values.borrow_status}
                    selectOnBlur={false}
                    onChange={handleSelectChange}
                  />
                  <Form.Dropdown
                    width="4"
                    icon={null}
                    fluid
                    floating
                    style={{ width: "100%", marginRight: "30px" }}
                    name="status"
                    trigger={
                      <Button
                        basic
                        icon
                        fluid
                        size="small"
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        <Icon name="angle down" />
                        Status item : <b>{values.status}</b>
                      </Button>
                    }
                    options={[
                      { key: "all", text: "All", value: "All" },
                      ...config.options_status_item,
                    ]}
                    defaultValue={values.status}
                    selectOnBlur={false}
                    onChange={handleSelectChange}
                  />
                </Form.Group>
              </GridColumn>
              <GridColumn largeScreen={2} mobile={4} tablet={2} floated="right">
                <Form.Group>
                  <div className="Bag-icon">
                    <Image
                      className="bag"
                      circular
                      style={{ maxHeight: "60px" }}
                      spaced="left"
                      src={"../storage/imgs/bag.webp"}
                      onClick={() => setShowPopupConfirmBooking(true)}
                    />
                    <div className="NumberBook">{bookingCount}</div>
                  </div>
                </Form.Group>
              </GridColumn>
            </Grid.Row>
          </Grid>
        </Form>

        {/* <br></br> */}

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          // onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Waiting Download File
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      {/* Confirm form */}
      <ModalBasic
        size="large"
        open={showPopupConfirmBooking}
        content="Items booking list"
        close={() => setShowPopupConfirmBooking(false)}
      >
        <ConfirmBookingForm
          onSubmit={onBookingConfirm}
          onClose={() => setShowPopupConfirmBooking(false)}
          loading={loading}
          // initialValues={dataBooking}
          errormsg={errormsg}
          // data={dataBooking}
          userData={userData}
          // columnbookinglist={columnbookinglist}
        />

        {/* TEST */}
        {/* <TableList
          loading={loading}
          data={dataBooking}
          columns={columnbookinglist}
          pageSize={per_pageBkList}
          page={current_pageBkList}
          pages={last_pageBkList}
          onPageChange={(page) => setCurrentPageBkList(page)}
          onPageSizeChange={(value) => setPerPageBkList(value)}
          // onSortedChange={(value) => setSortPage(value)}
        /> */}
      </ModalBasic>

      {/* Msg for confirm booking */}
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadMsgPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadMsgPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Booking proceeding...
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      {/* Msg for delete booking */}
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadDeletePopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadDeletePopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Removing...
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      {/* Msg for successful booking */}
      <ModalBasic
        open={loadSucPopup}
        size="mini"
        // close={() => setLoadSucPopup(false)}
        close={handleLoadSucPopup}
      >
        <Header as="h5" style={{ textAlign: "center" }}>
          <div className="text1">
            Successful Request No. <span>{msgConfirm1}</span>
          </div>
        </Header>
        <center>
          <Button
            content="Confirm"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            // onClick={() => setLoadSucPopup(false)}
            onClick={handleLoadSucPopup}
          />
        </center>
      </ModalBasic>
    </Segment>
  );
};
// export default BookingItem;
export default withRouter(BookingItem);
