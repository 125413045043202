import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import {
  Button,
  Form,
  Message,
  Grid,
  SegmentGroup,
  GridColumn,
  GridRow,
  Modal,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  // FormField, //Todo
} from "semantic-ui-react";
import { set } from "core-js/core/dict";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import { getBookingList, deleteBooking, confirmBooking } from "./service";

const validate = (values) => {
  const errors = {};
  // if (!values.date_of_use) errors.date_of_use = true;
  // if (!values.return_due_date) errors.return_due_date = true;
  // if (!values.use_for) errors.use_for = true;
  // if (!values.use_detail) errors.use_detail = true;
  return errors;
};

let FormConfirmBooking = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    // initialValue,
    onClose,
    // errormsg,
    // data,
    userData,
    // columnbookinglist,
  } = props;

  const [errormsg, setErrormsg] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);

  const [per_page, setPerPage] = useState(5);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [loadDeletePopup, setLoadDeletePopup] = useState(null); //for delete
  const [data, setData] = useState([]);
  const [values, setValues] = useState({
    // maintype: "",
    // type: "",
    // category: "",
    // borrow_status: "",
    // status: "Active",
    // status: "",
  });
  // const [search, setSearch] = useState({ name: "" });

  const [columnbookinglist, setColumnbookinglist] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      width: 35,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      width: 70,
      Cell: (cellInfo) => (
        <Image
          // size="mini"
          width="50"
          height="50"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/no-image.jpg"
          }
          // src={
          //   cellInfo.original.authimgurl
          //     ? cellInfo.original.authimgurl
          //     : "../storage/imgs/no-image.jpg"
          // }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/no-image.jpg";
          }}
        />
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "Item Code",
      accessor: "item_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Type",
      accessor: "type",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Supplier",
      accessor: "supplier",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Quantity",
      accessor: "qty",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
    },
    {
      Header: "Unit",
      accessor: "unit",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },

    {
      Header: "Remove",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      width: 55,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: "red",
              boxShadow: "0 0 0 0.1em " + "red" + " inset",
            }}
            name="trash alternate outline" //bin icon
            onClick={() =>
              onDeleteBooking(
                cellInfo.original.id,
                cellInfo.original.asset_id,
                cellInfo.original.qty
              )
            }
            // onClick={() =>
            //   props.history.push({
            //     pathname: "/booking/bookprofile",
            //     state: { id: cellInfo.original.id },
            //   })
            // }
          />
        </Wrap>
      ),
    },
  ]);

  useEffect(() => {
    if (!tableLoading) {
      loadBookingList();
    }
  }, [per_page, current_page]);

  const loadBookingList = () => {
    // async function loadBookingList() {
    setTableLoading(true);

    //get booking list data
    let page = current_page + 1;
    let qbooking = "?_perpage=" + per_page + "&page=" + page;

    // let qbooking = "?";
    if (userData.id) qbooking = qbooking + "&user_id=" + userData.id; //Current user login
    getBookingList(qbooking).then((res) => {
      // console.log(`getBookingList`, res); //Test
      // console.log(`getBookingList-Userdata`, userData); //Test

      if (res && res.status === 200) {
        setColumnbookinglist(columnbookinglist);
        setData(res.data.data);
        // setBookingCount(res.data.data.length);
        // setBookingCount(res.data.total);
        setPerPage(per_page);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      // setLoading(false);
      setTableLoading(false);
    });
  };

  const onDeleteBooking = (borrowid, assetid, qty) => {
    setErrormsg(null);
    setLoadDeletePopup(true);
    values["borrow_id"] = borrowid;
    values["asset_id"] = assetid;
    values["qty"] = qty;
    values["updated_by"] = userData.id;
    // console.log(values); //Test
    deleteBooking(values).then((res) => {
      // console.log(res); //Test
      if (res.data.affectedRows === 1 && res.status === 200) {
        // setShowPopup(false);
        setLoadDeletePopup(false);
        loadBookingList();
      } else if (res.data.affectedRows == 0 && res.status === 200) {
        setErrormsg("Already confirm booking. Cannot delete.");
      } else {
        setErrormsg(res.data.message);
        // setErrormsg("Delete failed.");
      }
    });
  };

  return (
    <Segment basic textAlign="left">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column
            largeScreen={4}
            mobile={6}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            Request No. :
          </Grid.Column>
          <Grid.Column
            largeScreen={10}
            mobile={8}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            {data[0] ? data[0].request_no : ""}
          </Grid.Column>
          <Grid.Column
            largeScreen={4}
            mobile={6}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            Request Date :
          </Grid.Column>
          <Grid.Column
            largeScreen={10}
            mobile={8}
            style={{ paddingBottom: 0 }}
            className="bold"
          >
            {data[0] ? data[0].booking_date : ""}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid columns="2" doubling>
        <Grid.Column width="4">
          <Image
            verticalAlign="middle"
            // src={
            //   data && data.authimgurl
            //     ? data.authimgurl
            //     : "../storage/imgs/no-image.jpg"
            // }
            src={
              data[0] && data[0].userImage
                ? data[0].userImage
                : "../storage/imgs/no-image.jpg"
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "../storage/imgs/no-image.jpg";
            }}
          />
        </Grid.Column>
        <Grid.Column columns="1" width="12" style={{ minHeight: "30vh" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" style={{ color: config.colorTheme2 }}>
                  <Header.Content>
                    {data[0] ? data[0].fullname : ""}
                    <Header.Subheader
                      style={{ color: config.colorTheme2 }}
                    ></Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="4">
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Passport or ID No. :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].idnumber : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Date of birth :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].dateOfBirth : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Nationality :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].nationality : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Age :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].age : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Native Language :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].nativelanguage : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Gender :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].gender : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Mobile Phone :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].phone : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                Address :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].address : ""}
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10 }}
                className="bold"
              >
                E-mail :
              </Grid.Column>
              <Grid.Column
                largeScreen={4}
                mobile={8}
                style={{ paddingBottom: 10, paddingLeft: 0 }}
              >
                {data[0] ? data[0].email : ""}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>

      {/* fill section */}
      <Grid.Column style={{ paddingBottom: 0 }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Field
              width="4"
              name="date_of_use"
              type="date"
              // required={true}
              required={false}
              component={renderField}
              // component={renderDateOnlyField}
              label="Date of Use:"
            />
            <Field
              width="4"
              name="return_due_date"
              type="date"
              // required={true}
              required={false}
              component={renderField}
              label="Return due date:"
            />
            <Field
              width="4"
              name="use_for"
              component={renderSelectField}
              label="Use for:"
              // required={true}
              required={false}
              options={config.options_usefor}
            />
            <Field
              width="10"
              name="use_detail"
              type="text"
              // required={true}
              required={false}
              component={renderField}
              label="Use for details:"
            />
          </Form.Group>
          <br />
          <Grid.Column>
            {/* Table list */}
            {/* <TableList
              data={data}
              columns={columnbookinglist}
              loading={loading}
              defaultPageSize={3}
            /> */}
            <TableList
              loading={tableLoading}
              data={data}
              columns={columnbookinglist}
              pageSize={per_page}
              page={current_page}
              pages={last_page}
              onPageChange={(page) => setCurrentPage(page)}
              onPageSizeChange={(value) => setPerPage(value)}
            />
          </Grid.Column>
          <br />
          <div>
            <SecondButton
              floated="right"
              content="Cancel"
              color="grey"
              onClick={onClose}
            />
            <Button
              floated="left"
              content="Save"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "30%",
              }}
              loading={loading}
              // disabled={pristine || submitting}
            />
          </div>
          <br />
        </Form>
      </Grid.Column>

      {/* Msg for delete booking */}
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadDeletePopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadDeletePopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Removing...
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>
    </Segment>
  );
};

FormConfirmBooking = reduxForm({
  // a unique name for the form
  form: "confirmbooking",
  validate,
  enableReinitialize: true,
})(FormConfirmBooking);

export default FormConfirmBooking;
