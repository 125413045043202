import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateTimeField2 from "../../components/renderDateTimeField2";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from '../../config';
import { connect } from "react-redux";
import {
    Header,
    Image,
    Segment,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Icon,
    Label,
    FormGroup
} from 'semantic-ui-react';
import renderDateField from "../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../components/wrap";


let TemplateSendURLRegister = (props) => {
console.log(props);
    const { loading, pristine, submitting, onSubmit, initialValue, onClose, errormsg, start_card, end_card,handleSubmit} = props;
    return (

        <Segment basic textAlign='left'>
            <Segment>
                <b>เรียนผู้ปกครอง</b> 
                <br></br>
                <p>ตามที่ท่านได้ทำการกรอกแบบฟอร์ม Application ทางโรงเรียนจึงขอรบกวนให้ท่านกรอกขอมูลลงทะเบียนเข้าระบบของโรงเรียนตาม link ครับ</p>
                {/* <a href={props.initialValues}>{props.initialValues}</a> */}
                <p style={{color:'blue'}}>{props.initialValues}</p>
                <br></br><br></br>
                <p>
                    ด้วยความเคารพอย่างสูง<br></br>
                    โรงเรียนนานาชาติคิดส์วิลเลจ
                </p>
            </Segment>
        </Segment>
    );

}

TemplateSendURLRegister = reduxForm({
    // a unique name for the form
    form: "TemplateSendURLRegister",
    enableReinitialize: true,
})(TemplateSendURLRegister);

const selector = formValueSelector("class");
TemplateSendURLRegister = connect(state => {
    const start_card = selector(state, "start_card");
    const end_card = selector(state, "end_card");
    return {
        start_card: start_card,
        end_card: end_card
    };
})(TemplateSendURLRegister);

export default withRouter(TemplateSendURLRegister);

