import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Grid,
  Image,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import ModalBasic from "../../components/modal";
import FormBooks from "./formbook";
import { DateInput, DatesRangeInput } from "semantic-ui-calendar-react";
import { getBook, exportBook, postBook } from "./service";

const Books = (props) => {
  const [cpage, setCpage] = useState(
    props && props.location.state && props.location.state.cpage
  );
  const [cper_page, setCper_page] = useState(
    props && props.location.state && props.location.state.cper_page
  );
  const [cqsearch, setCqsearch] = useState(
    props && props.location.state && props.location.search
  );
  // let history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [last_page, setLastPage] = useState(1);
  const [values, setValues] = useState({
    type: "",
    category: "",
    borrow_status: "",
    status: "",
  });
  const [search, setSearch] = useState({ name: "" });
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  const [visit_date, setvisit_date] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  const [showPopupBooks, setShowPopupBooks] = useState(false);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/book.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/book.png";
          }}
        />
      ),
      style: { textAlign: "center" },
      width: 100,
    },
    {
      Header: "Type",
      accessor: "type",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Code",
      accessor: "book_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "ISBN",
      accessor: "isbn",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Age range",
      accessor: "age_range",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Author",
      accessor: "author",
      style: { textAlign: "center" },
      sortable: false,
    },
    // {
    //     Header: "Initail Stock",
    //     accessor: "id",
    //     style: { textAlign: "center" },
    //     sortable: false,
    //     Cell:  (cellInfo) => cellInfo.original.id
    // },
    // {
    //     Header: "Avaliable Quantitity",
    //     accessor: "status_interview",
    //     style: { textAlign: "center" },
    //     sortable: false,
    // },
    {
      Header: "Status Stock",
      accessor: "borrow_status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status Item",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/library/bookprofile",
                state: {
                  id: cellInfo.original.id,
                  page: cellInfo.page,
                  perpage: cellInfo.pageSize,
                },
                // search: cqsearch,
              })
            }
          />
        </Wrap>
      ),
    },
  ]);
  const timeoutRef = useRef(null);

  // useEffect(() => {
  //   if (date) getData();
  // }, [date, values.type, values.category, values.borrow_status, values.status]);

  useEffect(() => {
    if (!loading) {
      getData();
    }
  }, [per_page, current_page, values]);

  useEffect(() => {
    if (search) {
      setLoading(true);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        getData();
      }, 2000);
    }
  }, [search]);

  const getData = () => {
    setLoading(true);

    let page;
    if (cpage != undefined || cpage != null) {
      page = cpage + 1;
      setCpage(null);
    } else {
      page = current_page + 1;
    }

    let perpage;
    if (cper_page != undefined || cper_page != null) {
      perpage = cper_page;
      setCper_page(null);
    } else {
      perpage = per_page;
    }
    // let page = current_page + 1;
    let qString = "?_perpage=" + perpage + "&page=" + page;

    if (values.type && values.type != "")
      qString = qString + "&type=" + values.type;
    if (values.category && values.category != "")
      qString = qString + "&category=" + values.category;
    if (values.borrow_status && values.borrow_status !== "")
      qString = qString + "&borrow_status=" + values.borrow_status;
    if (values.status && values.status != "")
      qString = qString + "&status=" + values.status;
    if (search.name) qString = qString + "&name=" + search.name;
    // let qString = "?";

    getBook(qString).then((res) => {
      if (res && res.status === 200) {
        setColumn(column);
        setData(res.data.data);
        //Modify: Solve wrong page sequence
        setPerPage(perpage);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
        setCqsearch(qString);
      }
      setLoading(false);
    });
  };

  const insertBook = (values) => {
    values["status"] = "Active";
    postBook(values).then((res) => {
      if (res && res.status === 201) {
        setShowPopupBooks(false);
      } else {
        setErrormsg(res.data.message);
      }
    });
  };

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };

  const onClickExport = () => {
    setLoadPopup(true);

    if (values.type && values.type != "")
      qString = qString + "&type=" + values.type;
    if (values.category && values.category !== "")
      qString = qString + "&category=" + values.category;
    if (values.borrow_status && values.borrow_status !== "")
      qString = qString + "&borrow_status=" + values.borrow_status;
    if (values.status && values.status !== "")
      qString = qString + "&status=" + values.status;
    if (search.name) qString = qString + "&name=" + search.name;
    let qString = "?";

    exportBook(
      qString +
        "&name=" +
        search.name +
        "&type=" +
        values.type +
        "&category=" +
        values.category +
        "&borrow_status=" +
        values.borrow_status +
        "&status=" +
        values.status
    ).then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false);
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "book/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "book_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download =
          "book-Report" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Books</Header>

      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/library")}
              style={{ color: "grey" }}
            >
              Library
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Books</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="green"
            content="Export Excel"
            floated="right"
            style={{ marginBottom: "14px" }}
            onClick={onClickExport}
          />
        </Grid.Column>
      </Grid>
      <Segment
        className="contentSegment"
        textAlign="left"
        style={{ minHeight: "50vh", padding: "20px" }}
      >
        <Form>
          <Grid columns="6">
            <Grid.Column>
              <Form.Input
                width="16"
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={handleInputChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                width="16"
                icon={null}
                fluid
                floating
                name="type"
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Type : <b>{values.type}</b>
                  </Button>
                }
                options={[
                  { key: " ", text: "All", value: " " },
                  ...config.options_bookType,
                ]}
                defaultValue={values.type}
                selectOnBlur={false}
                onChange={handleSelectChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                width="16"
                scrolling="true"
                icon={null}
                fluid
                floating
                name="category"
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Category : <b>{values.category}</b>
                  </Button>
                }
                options={[
                  { key: " ", text: "All", value: " " },
                  ...config.options_category,
                ]}
                defaultValue={values.category}
                selectOnBlur={false}
                onChange={handleSelectChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                width="16"
                icon={null}
                fluid
                floating
                name="status"
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status Item : <b>{values.status}</b>
                  </Button>
                }
                options={[
                  { key: " ", text: "All", value: " " },
                  ...config.options_status_item,
                ]}
                defaultValue={values.status}
                selectOnBlur={false}
                onChange={handleSelectChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                width="16"
                icon={null}
                fluid
                floating
                name="borrow_status"
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status Stock : <b>{values.borrow_status}</b>
                  </Button>
                }
                options={[
                  { key: " ", text: "All", value: " " },
                  ...config.options_status_stock,
                ]}
                defaultValue={values.borrow_status}
                selectOnBlur={false}
                onChange={handleSelectChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                color="green"
                content="Add Item"
                floated="right"
                onClick={() => setShowPopupBooks(true)}
              />
            </Grid.Column>
          </Grid>
        </Form>
        <br></br>

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
        />
      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Waiting Download File
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      <ModalBasic
        size="large"
        open={showPopupBooks}
        content="Item"
        close={() => setShowPopupBooks(false)}
      >
        <FormBooks
          onSubmit={insertBook}
          onClose={() => setShowPopupBooks(false)}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic>
    </Segment>
  );
};
export default Books;
