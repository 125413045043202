import React, { useState, useEffect } from "react";
import { Button, Form, Message, Table, Divider, Icon, Header, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import Wrap from "../../components/wrap";
import * as config from '../../config';
import { getCourseList } from "./service";


let PromoteForm = (props) => {
    const { loading, course, onSubmit,
        onClose, term_option, errormsg, student } = props;
    const [studentRegist, setStudentRegist] = useState(student);
    const [studentSelected, setStudentSelected] = useState([]);
    const [term, setTerm] = useState("All");
    const [promoteTerm, setPromoteTerm] = useState();
    const [promoteCourse, setPromoteCourse] = useState(course && course.id);
    const [course_option, setCourse_option] = useState([]);
    const [promote_option, setPromote_option] = useState([]);

    useEffect(() => {
        const result = [];
        student.map(item => {
            item.checked = null;
            if (term && term.id) {
                if (item.term_id === term.id) {
                    result.push(item);
                }
            } else result.push(item)
        });
        setStudentRegist(result);
        setStudentSelected([]);
    }, [term]);

    useEffect(() => {
        setPromote_option(term_option);
        if (promoteCourse === course.id) {
            const result = [];
            studentSelected.map(item => {
                result.push(item.name); // term name 
            });
            const option = [];
            term_option && term_option.map(item => {
                if (result.indexOf(item.text) === -1)
                    option.push(item)
            })

            setPromote_option(option)
        }
    }, [studentSelected, promoteCourse]);


    useEffect(() => {
        getCourseList("?status=Active&master").then(res => {
            let option = [];
            res.data.map(item => {
                option.push({
                    key: item.id,
                    text: `${item.campus_name} : ${item.name}`,
                    value: item.id
                })
            })
            setCourse_option(option)
        })
    }, []);
    const handleChange = (e, { id, checked }) => {
        let data = studentRegist;
        data[id]['checked'] = checked;

        const result = [];
        data.map(item => {
            if (item.checked)
                result.push(item)
        });
        setStudentRegist(data);
        setStudentSelected(result);
    }

    return (
        <Form   >
            {
                errormsg && <Message negative>
                    <Message.Content>{errormsg}</Message.Content>
                </Message>
            }

            <Form.Dropdown
                width="4"
                icon={null}
                fluid
                floating
                style={{ width: "100%", marginRight: "30px" }}
                name='term'
                trigger={
                    <Button
                        basic
                        icon
                        fluid
                        size="small"
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                    >
                        <Icon name="angle down" />
                        Filter by Term : <b>{term && term.name ? term.name : term}</b>
                    </Button>
                }
                defaultValue={term && term.name ? term.name : term}
                options={term_option && [{ key: "all", text: "All", value: "All" }, ...term_option]}
                onChange={(e, data) => setTerm(data.value)}
                selectOnBlur={false}

            />
            <Table celled size='small' compact padded style={{ borderRadius: 0, fontSize: '95%' }} >
                <Table.Header style={{
                    boxShadow: '0 0.5px 5px 0 rgb(0 0 0 / 15%)',
                    textAlign: 'center'
                }}>
                    <Table.Row>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}></Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Name</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Term</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Start</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>End</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Registered date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {studentRegist.map((item, index) =>
                        <Table.Row>
                            <Table.Cell textAlign='center'>
                                <Form.Checkbox
                                    id={index}
                                    checked={item.checked}

                                    onChange={handleChange} />
                            </Table.Cell>
                            <Table.Cell >
                                {item.studentID} {item.title} {item.firstName} {item.lastName}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {item.name}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {item.start}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {item.end}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {item.registdate}
                            </Table.Cell>
                        </Table.Row>
                    )}

                </Table.Body>
            </Table>
            <br />
            <Form style={{ textAlign: 'center' }}>
                {studentSelected && studentSelected.length != 0
                    && <Wrap >
                        <Divider horizontal>
                            <Header as='h4'>
                                Promotion details
                            </Header>
                        </Divider>
                        <br /><br />
                        <Grid style={{ padding: '40px' }} >
                            <Grid.Row>
                                <Grid.Column textAlign='right' width='6'>
                                    Promote Course :
                                </Grid.Column>
                                <Grid.Column width='10'>
                                    <Form.Select
                                        options={course_option}
                                        inline
                                        scrolling
                                        defaultValue={promoteCourse}
                                        fluid
                                        onChange={(e, data) => setPromoteCourse(data.value)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign='right' width='6'>
                                    Term :
                                </Grid.Column>
                                <Grid.Column width='6'>
                                    <Form.Select
                                        options={promote_option}
                                        fluid
                                        inline
                                        scrolling
                                        onChange={(e, data) => setPromoteTerm(data.value)}
                                    />
                                </Grid.Column>
                            </Grid.Row>


                        </Grid>
                        <br /><br />
                    </Wrap>
                }
                <div>
                    <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
                    {studentSelected && studentSelected.length != 0 && <Button floated='left' content={`Promotion ${studentSelected.length != 0 ? '( ' + studentSelected.length + ' )' : ''}`}
                        style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                        loading={loading}
                        disabled={studentSelected.length === 0}
                        onClick={() => onSubmit({ student: studentSelected, term: promoteTerm, course: promoteCourse })}
                    />
                    }
                </div>
            </Form>
            <br /><br />
        </Form>
    );

}


export default PromoteForm;
