import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../components/renderField";
import renderSelectField from "../components/renderSelectField";
import renderFileField from "../components/renderFileField";
import renderDateTimeField2 from "../components/renderDateTimeField2";
import renderDateOnlyField from "../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../components/secondbutton";
import * as config from '../config';
import {
    Header,
    Segment,
} from 'semantic-ui-react';
import renderDateField from "../components/renderDateOnlyField";
import ModalBasic from "../components/modal";


const validate = values => {
    const errors = {}
    if (!values.id_campus) errors.id_campus = true
    if (!values.id_program) errors.id_program = true
    if (!values.parent_firstName) errors.parent_firstName = true
    if (!values.parent_lastName) errors.parent_lastName = true
    if (!values.number) errors.number = true
    if (!values.child_firstname) errors.child_firstname = true
    if (!values.child_lastname) errors.child_lastname = true
    if (!values.nickname) errors.nickname = true
    if (!values.dateOfBirth) errors.dateOfBirth = true
    if (!values.gender) errors.gender = true
    if (!values.dateTimeOfVisit) errors.dateTimeOfVisit = true
    return errors;
};

let FormVisitRequest = (props) => {
    const { loading, pristine, submitting, handleSubmit, onSubmit, start, end, onClose, errormsg } = props;
    const [open, setOpen] = useState(false);

    const showPopup = () => {
        setOpen(true);
    };
    const popUpClose = () => {
        setOpen(false);
    };
    console.log(props);

    return (
        <Form style={{ width: '50%', marginLeft: '25%' }} onSubmit={handleSubmit(onSubmit)}>
            <Header as='h2' style={{ backgroundColor: '#80561B', color: '#fff', padding: '1%' }}>School Visit Requested</Header>
            <br></br>
            <Segment>
                <Header as='h4' style={{ paddingLeft: "10px", textAlign: 'left' }}>Number of visitor : V000001</Header>
                <div style={{ paddingLeft: "20px", textAlign: 'left' }}>
                    <Grid divided stackable>
                        <Grid.Row>
                            <Grid.Column>
                                {
                                    errormsg && <Message negative>
                                        <Message.Content>{errormsg}</Message.Content>
                                    </Message>
                                }
                                <br></br>
                                <u><b>Parent's Information</b></u>
                                <Form.Group unstackable>
                                    <Field
                                        width="8"
                                        name="parent_firstName"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="firstname"
                                    />
                                    <Field
                                        width="8"
                                        name="parent_lastName"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="lastname"
                                    />
                                </Form.Group>
                                <Form.Group unstackable>
                                    <Field
                                        width="8"
                                        name="mobile"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="Mobile Phone"
                                    />
                                    <Field
                                        width="8"
                                        name="parent_nationality"
                                        component={renderSelectField}
                                        label="parent_nationality"
                                        required={false}
                                        options={config.options_nationality}
                                    />
                                </Form.Group>
                                <Form.Group unstackable>
                                    <Field
                                        width="16"
                                        name="email"
                                        type="email"
                                        required={false}
                                        component={renderField}
                                        label="E-mail"
                                    />
                                </Form.Group>
                                <br></br>
                                <u><b>Child's Information</b></u>
                                <Form.Group unstackable>
                                    <Field
                                        required={true}
                                        width="8"
                                        name="id_campus"
                                        component={renderSelectField}
                                        label="Campus"
                                        options={config.options_campus}
                                    />
                                    <Field
                                        required={true}
                                        width="8"
                                        name="id_program"
                                        component={renderSelectField}
                                        label="Program"
                                        options={config.options_program}
                                    />
                                </Form.Group>
                                <Form.Group unstackable>
                                    <Field
                                        width="8"
                                        name="child_firstname"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="firstname"
                                    />
                                    <Field
                                        width="8"
                                        name="child_lastname"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="lastname"
                                    />
                                </Form.Group>
                                <Form.Group unstackable>
                                    <Field
                                        width="8"
                                        name="nickname"
                                        type="text"
                                        required={true}
                                        component={renderField}
                                        label="Nickname"
                                    />
                                    <Field
                                        width="8"
                                        name="dateOfBirth"
                                        required={true}
                                        component={renderDateOnlyField}
                                        label="Date Of Birth"
                                    />
                                    <Field
                                        width="8"
                                        name="gender"
                                        component={renderSelectField}
                                        label="Gender"
                                        required={true}
                                        options={config.options_gender}
                                    />
                                </Form.Group>
                                <b>Appointment to visit <span style={{color:'red'}}>*</span></b>
                                <Form.Group unstackable>
                                    <Field
                                        width="8"
                                        name="dateTimeOfVisit"
                                        required={true}
                                        component={renderDateTimeField2}
                                    />
                                </Form.Group>
                                <p style={{ color: 'red' }}>Convenient day and time for visited Monday- Friday, 09:00 - 16.00.</p>
                                <b>Which channel do you hear about us?</b>
                                <Form.Group unstackable>
                                    <Field
                                        width="8"
                                        name="channel"
                                        type="text"
                                        component={renderField}
                                    />
                                </Form.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Button floated='left' content='Confirm'
                        style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                        onClick={() => showPopup()}
                    />
                    {/* <SecondButton floated='right' content='Cancel' color='grey' /> */}
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </Segment>

            <ModalBasic open={open} size='big' close={popUpClose}>
                <Header as='h2' style={{textAlign:'center'}}>ทำการลงทะเบียนเสร็จเรียบร้อย</Header>
                <center><Button content='OK'
                    style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '5%' }}
                    onClick={() => popUpClose()}
                /></center>
            </ModalBasic>
        </Form>
    );

}




FormVisitRequest = reduxForm({
    form: "visit",
    validate,
    enableReinitialize: true
})(FormVisitRequest);

export default FormVisitRequest;
