import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import * as config from '../../config';
import {
  Button,
  Modal,
  Header,
  Icon
} from 'semantic-ui-react';

const StatusModal = (props) => {

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      size='mini'
      centered={false}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <Header as='h2' icon textAlign='center'>
          <Icon name='warning circle' color='red' />
          <Header.Content>{props.error}</Header.Content>
          <Header.Subheader>
            Something went wrong. Please try again
          </Header.Subheader>
        </Header>
        <br /><br />
        <Button fluid color='red' onClick={props.onClose}>Try again</Button>
      </Modal.Content>
    </Modal>

  )
}

export default StatusModal;
