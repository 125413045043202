import React, { useState, useEffect } from "react";
import * as config from '../../config';

import {
  Header,
  Icon,
  Segment,
  Card
} from 'semantic-ui-react';

const MainLibraryMenu = (props) => {

  const [role, setRole] = useState(JSON.parse(sessionStorage.getItem("kidz_roles")));
  const [allPermistion, setAllPermistion] = useState(JSON.parse(sessionStorage.getItem("kidz_permission")));


  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (userpermissionGroup && userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  return (
    <Segment basic textAlign='left'>
      <Header as='h2' >Library</Header>
      <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>

        {checkPermissionMenu("Library", [
          "book",
        ]) &&
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/library/books")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="book"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Books
              </Header>
            </Card.Content>
          </Card>
        }
        {/* {checkPermissionMenu("Library", [
          "booking-list",
        ]) &&
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/library/booking_list")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="list alternate"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Booking List
              </Header>
            </Card.Content>
          </Card>
        } */}
        {checkPermissionMenu("Library", [
          "history",
        ]) &&
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/library/history")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="history"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                History
              </Header>
            </Card.Content>
          </Card>
        }

      </Card.Group>
      <br />
    </Segment>
  );
}
export default MainLibraryMenu;
