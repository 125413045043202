import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Label,
  Dimmer,
  Loader,
  Grid,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import {
  getClassAttendance,
  exportClassAttendExcel,
  getCourse,
  getClassInCourse,
  getCampus,
  getProgram,
  getTeacher,
} from "./service";
import TableList from "../../components/table";
// import { DatesRangeInput } from "semantic-ui-calendar-react";
import { DateInput } from "semantic-ui-calendar-react";
//Todo
// import { Component } from "react";
// import { render } from "react-dom";
// import ReactTable from "react-table";
// import "react-table/react-table.css";

const Attendance = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    status: "All",
    classattstatus: "All",
    course: "All",
    class: "All",
    campus: "All", //Todo
    program: "All",
    teacher: "All",
  });
  const [data, setData] = useState([]);
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [course_option, setCourse_option] = useState(null);
  const [class_option, setClass_option] = useState(null);
  const [datacampus, setDataCampus] = useState(null); //Todo
  const [dataprogram, setDataProgram] = useState(null); //Todo
  const [datateacher, setDataTeacher] = useState(null); //Todo

  // const [date, setDate] = useState(
  //   moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  // );
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  const [column, setColumn] = useState([
    // {
    //   Header: "No.",
    //   accessor: "",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) =>
    //     cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
    //   width: 50,
    // },
    {
      Header: "Program",
      accessor: "program_name",
      // style: { backgroundColor: "rgba(244, 204, 204,1)" },
      sortable: false,
      // Cell: (cellInfo) => {
      //   return (
      //     <p
      //       style={{
      //         backgroundColor: programColorMap[cellInfo.original.program_id],
      //       }}
      //     >
      //       {cellInfo.original.program_name}
      //     </p>
      //   );
      // },
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        return cellInfo.original.program_name;
      },
    },
    {
      Header: "Course",
      accessor: "course_name",
      sortable: false,
      // width: 200,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     <span className="bold">
      //       {/* {cellInfo.original.title} {cellInfo.original.firstName}{" "}
      //       {cellInfo.original.lastName} */}
      //       {cellInfo.original.course_name}
      //     </span>
      //   </Wrap>
      // ),
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        return cellInfo.original.course_name;
      },
    },
    {
      Header: "Class",
      accessor: "class_name",
      sortable: false,
      // width: 80,
      // Cell: (cellInfo) => {
      //   return (
      //     <p
      //       style={{
      //         backgroundColor: programColorMap[cellInfo.original.program_id],
      //       }}
      //     >
      //       {cellInfo.original.class_name}
      //     </p>
      //   );
      // },
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        return cellInfo.original.class_name;
      },
    },
    {
      Header: "Term",
      accessor: "term",
      style: { textAlign: "left" },
      sortable: false,
      // Cell: (cellInfo) => {
      //   return (
      //     <p
      //       style={{
      //         backgroundColor: programColorMap[cellInfo.original.program_id],
      //       }}
      //     >
      //       {cellInfo.original.term}
      //     </p>
      //   );
      // },
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        return cellInfo.original.term;
      },
    },
    {
      Header: "Teacher",
      accessor: "teacher_name",
      sortable: false,
      // Cell: (cellInfo) => {
      //   return (
      //     <p
      //       style={{
      //         backgroundColor: programColorMap[cellInfo.original.program_id],
      //       }}
      //     >
      //       {cellInfo.original.teacher_name}
      //     </p>
      //   );
      // },
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        return cellInfo.original.teacher_name;
      },
    },
    {
      Header: "T.A.",
      accessor: "ta_name",
      style: { textAlign: "center" },
      // Cell: (cellInfo) => (cellInfo.original.extended === "true" ? "✓" : "-"),
      // Cell: (cellInfo) => {
      //   return (
      //     <p
      //       style={{
      //         backgroundColor: programColorMap[cellInfo.original.program_id],
      //       }}
      //     >
      //       {cellInfo.original.ta_name}
      //     </p>
      //   );
      // },
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        return cellInfo.original.ta_name;
      },
    },
    {
      Header: "Register",
      accessor: "cnt_regis",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        // return cellInfo.original.cnt_regis ? cellInfo.original.cnt_regis : "0";
        return cellInfo.original.cnt_regis;
      },
    },
    {
      Header: "School Check-in",
      accessor: "cnt_checkin",
      // style: { textAlign: "center", color: "rgba(106,168,79, 1)" },
      style: { textAlign: "center", color: "blue" },
      sortable: false,
      width: 100,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        // return cellInfo.original.cnt_checkin ? cellInfo.original.cnt_checkin: "0";
        return cellInfo.original.cnt_checkin;
      },
    },
    {
      Header: "Class Check-in",
      accessor: "cnt_attend",
      style: { textAlign: "center", color: "rgb(55,55,255)" },
      grow: 5,
      sortable: false,
      width: 100,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        // return cellInfo.original.cnt_attend? cellInfo.original.cnt_attend: "0";
        return cellInfo.original.cnt_attend;
      },
    },
    {
      Header: "Absent",
      accessor: "cnt_absent",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        // return cellInfo.original.cnt_absent? cellInfo.original.cnt_absent: "0";
        return cellInfo.original.cnt_absent;
      },
    },
    {
      Header: "Late",
      accessor: "cnt_late",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        // return cellInfo.original.cnt_late ? cellInfo.original.cnt_late : "0";
        return cellInfo.original.cnt_late;
      },
    },
    {
      Header: "Leave",
      accessor: "cnt_leave",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        // return cellInfo.original.cnt_leave ? cellInfo.original.cnt_leave : "0";
        return cellInfo.original.cnt_leave;
      },
    },
    // {
    //   Header: "Class Check-out",
    //   // accessor: "cnt_checkout",
    //   style: { textAlign: "center", color: "rgba(106,168,79, 1)" },
    //   sortable: false,
    //   width: 70,
    //   // Cell: (cellInfo) => (
    //   //   <Wrap>
    //   //     {cellInfo.original.cnt_checkout
    //   //       ? cellInfo.original.cnt_checkout
    //   //       : "0"}
    //   //   </Wrap>
    //   // ),
    //   Cell: (cellInfo) => {
    //     cellInfo.styles["backgroundColor"] =
    //       programColorMap[cellInfo.original.program_id];
    //     // return cellInfo.original.cnt_checkout
    //     //   ? cellInfo.original.cnt_checkout
    //     //   : "0";
    //   },
    // },
    {
      Header: "School Check-out",
      accessor: "cnt_checkout",
      // style: { textAlign: "center", color: "rgba(31,90,0, 1)" },
      style: { textAlign: "center", color: "green" },
      sortable: false,
      width: 100,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        // return cellInfo.original.cnt_checkout ? cellInfo.original.cnt_checkout: "0";
        return cellInfo.original.cnt_checkout;
      },
    },
    {
      Header: "Early check-out",
      accessor: "cnt_early_checkout",
      style: { textAlign: "center", color: "rgba(180,95,6, 1)" },
      sortable: false,
      width: 100,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        // return cellInfo.original.cnt_early_checkout ? cellInfo.original.cnt_early_checkout : "0";
        return cellInfo.original.cnt_early_checkout;
      },
    },
    {
      Header: "Status",
      accessor: "att_status",
      style: { textAlign: "center" },
      sortable: false,
      style: { textAlign: "center" },
      // Cell: (cellInfo) => <Wrap>{cellInfo.original.att_status} </Wrap>,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        return cellInfo.original.att_status;
      },
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => {
        cellInfo.styles["backgroundColor"] =
          programColorMap[cellInfo.original.program_id];
        return (
          <Wrap>
            <Icon
              link
              bordered
              // color="green"
              style={{
                color: config.colorTheme,
                boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
              }}
              name="book"
              onClick={() =>
                props.history.push({
                  pathname: "/report/classattreportby",
                  // search: '?query=abc',
                  state: {
                    id: cellInfo.original.class_id,
                    // campus: cellInfo.original.campus_name,
                    // course: cellInfo.original.course_name,
                    // term: cellInfo.original.term,
                    // program: cellInfo.original.program_name,
                    // class: cellInfo.original.class_name,
                    // teacher: cellInfo.original.teacher_name,
                    date: date,
                    page: "report",
                  },
                })
              }
            />
          </Wrap>
        );
      },
      width: 100,
    },
    // {
    //   Header: "Class Attendance",
    //   accessor: "checkin_at",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) =>
    //     cellInfo.original.checkin_at
    //       ? moment(cellInfo.original.checkin_at).format("YYYY-MM-DD HH:mm")
    //       : "-",
    // },
    // {
    //   Header: "Class Status",
    //   accessor: "status",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   // Cell: (cellInfo) => attendees,
    // },
    // {
    //   Header: "Check In",
    //   accessor: "checkin_at",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) =>
    //     cellInfo.original.school_attendance &&
    //     cellInfo.original.school_attendance.checkin_at
    //       ? moment(cellInfo.original.school_attendance.checkin_at).format(
    //           "YYYY-MM-DD HH:mm"
    //         )
    //       : "-",
    // },
    // {
    //   Header: "Check In Guardian",
    //   accessor: "checkin_by",
    //   sortable: false,
    //   Cell: (cellInfo) =>
    //     cellInfo.original.school_attendance &&
    //     cellInfo.original.school_attendance.checkin_by
    //       ? cellInfo.original.school_attendance.checkin_by
    //       : cellInfo.original.school_attendance &&
    //         cellInfo.original.school_attendance.checkin_other && (
    //           <p>
    //             {cellInfo.original.school_attendance.checkin_other.name} (
    //             {cellInfo.original.school_attendance.checkin_other.relationship}
    //             )<br />
    //             {cellInfo.original.school_attendance.checkin_other.phone}
    //             <br />
    //             {cellInfo.original.school_attendance.checkin_other.photo && (
    //               <a
    //                 href={
    //                   cellInfo.original.school_attendance.checkin_other.photo
    //                 }
    //                 target="_blank"
    //               >
    //                 Click
    //               </a>
    //             )}
    //             <br />
    //             {cellInfo.original.school_attendance.checkin_other
    //               .photo_other && (
    //               <a
    //                 href={
    //                   cellInfo.original.school_attendance.checkin_other
    //                     .photo_other
    //                 }
    //                 target="_blank"
    //               >
    //                 Click
    //               </a>
    //             )}
    //           </p>
    //         ),
    // },
    // {
    //   Header: "Check Out",
    //   accessor: "checkout",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) =>
    //     cellInfo.original.school_attendance &&
    //     cellInfo.original.school_attendance.checkout_at
    //       ? moment(cellInfo.original.school_attendance.checkout_at).format(
    //           "YYYY-MM-DD HH:mm"
    //         )
    //       : "-",
    // },
    // {
    //   Header: "Check Out Guardian",
    //   accessor: "checkout_by",
    //   sortable: false,
    //   Cell: (cellInfo) =>
    //     cellInfo.original.school_attendance &&
    //     cellInfo.original.school_attendance.checkout_by
    //       ? cellInfo.original.school_attendance.checkout_by
    //       : cellInfo.original.school_attendance &&
    //         cellInfo.original.school_attendance.checkout_other && (
    //           <p>
    //             {cellInfo.original.school_attendance.checkout_other.name} (
    //             {
    //               cellInfo.original.school_attendance.checkout_other
    //                 .relationship
    //             }
    //             )<br />
    //             {cellInfo.original.school_attendance.checkout_other.phone}
    //             <br />
    //             {cellInfo.original.school_attendance.checkout_other.photo && (
    //               <a
    //                 href={
    //                   cellInfo.original.school_attendance.checkout_other.photo
    //                 }
    //                 target="_blank"
    //               >
    //                 Click
    //               </a>
    //             )}
    //             <br />
    //             {cellInfo.original.school_attendance.checkout_other
    //               .photo_other && (
    //               <a
    //                 href={
    //                   cellInfo.original.school_attendance.checkout_other
    //                     .photo_other
    //                 }
    //                 target="_blank"
    //               >
    //                 Click
    //               </a>
    //             )}
    //           </p>
    //         ),
    // },
    // {
    //   Header: "School Status",
    //   accessor: "attendees",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) =>
    //     cellInfo.original.school_attendance &&
    //     cellInfo.original.school_attendance.checkin_at
    //       ? "Present"
    //       : "Absent",
    // },
    // {
    //   Header: "Action",
    //   accessor: "id",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) => (
    //     <Wrap>
    //       <Icon
    //         link
    //         bordered
    //         // color="green"
    //         style={{ color: config.colorTheme, boxShadow: '0 0 0 0.1em ' + config.colorTheme + ' inset' }}
    //         name="user"
    //         onClick={() => props.history.push({
    //           pathname: '/student/profile',
    //           // search: '?query=abc',
    //           state: { id: cellInfo.original.id, page: 'student' }
    //         })
    //         }
    //       />
    //     </Wrap>
    //   ),
    //   width: 100,
    // },
  ]);

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  const programColorMap = {
    1: "rgba(244, 204, 204,1)",
    2: "rgba(252, 229, 205)",
    3: "rgba(255,242,204)",
    4: "rgba(217, 234, 211)",
    5: "rgba(208, 224, 227)",
    6: "rgba(201, 218, 248)",
    7: "rgba(217,210,233)",
    // etc
  };

  useEffect(() => {
    setCourse_option(null);
    let option = [];
    getCourse().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: { id: item.id, name: item.name },
          });
        });
        setCourse_option(option);
      }
    });
  }, []);

  useEffect(() => {
    getDataCampus();
    getDataProgram();
    // getDataTeacher();
  }, []);

  useEffect(() => {
    if (date) getData();
  }, [date, values.campus, , values.program]);

  // useEffect(() => {
  //   if (values.status) getData();
  //   if (values.course !== "All") {
  //     setClass_option(null);
  //     let option = [];
  //     getClassInCourse(values.course.id).then((res) => {
  //       if (res && res.status === 200) {
  //         let newcourse = true;
  //         res.data.class_schedule &&
  //           res.data.class_schedule.map((item) => {
  //             option.push({
  //               key: item.id,
  //               text: item.name,
  //               value: { id: item.id, name: item.name },
  //             });
  //             if (values.class !== "All" && values.class.id === item.id)
  //               newcourse = false;
  //           });
  //         if (newcourse) {
  //           values.class = "All";
  //           setValues(values);
  //         }
  //         setClass_option(option);
  //       }
  //     });
  //   } else if (values.course === "All") {
  //     values.class = "All";
  //     setValues(values);
  //     setClass_option(null);
  //   }
  // }, [values]);

  const getData = () => {
    setLoading(true);
    // const val = date.split(" - ");
    // let start = val[0];
    // let end = val[1];
    // let qString = "";
    // if (start && end) {
    //   qString = "?start=" + start + "&end=" + end;
    // }

    //Date 2022.10.21
    let qString = "";
    if (date) qString = "?date=" + date;
    if (values.campus && values.campus != "All")
      qString = qString + "&campus_name=" + values.campus.name;
    if (values.program && values.program != "All")
      qString = qString + "&program_name=" + values.program.name;

    // console.log(`getClassAttendance`, qString);

    // if (values.status !== "All") qString = qString + "&status=" + values.status;
    // if (values.classattstatus !== "All")
    //   qString = qString + "&school" + values.classattstatus;
    // if (values.course !== "All")
    //   qString = qString + "&course=" + values.course.id;
    // if (values.class !== "All") qString = qString + "&class=" + values.class.id;

    // getSchoolAttendance(qString + "&attendance").then((res) => {
    getClassAttendance(qString).then((res) => {
      if (res && res.status === 200) {
        // console.log(`getClassAttendance`, res);
        setColumn(column);
        setData(res.data);
      }
      setLoading(false);
    });
  };

  //Todo
  const getDataCampus = () => {
    setDataCampus(null);
    let option = [];
    getCampus().then((res) => {
      // console.log(res); //Test
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: { id: item.id, name: item.name },
          });
        });
        setDataCampus(option);
      }
      // setLoading(false);
    });
  };

  //Todo
  const getDataProgram = () => {
    setDataProgram(null);
    let option = [];
    getProgram().then((res) => {
      // console.log(res); //Test
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: { id: item.id, name: item.name },
          });
        });
        setDataProgram(option);
      }
      // setLoading(false);
    });
  };

  //Todo
  const getDataTeacher = () => {
    setDataTeacher(null);
    let option = [];
    getTeacher().then((res) => {
      // console.log(res); //Test
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.firstName,
            value: { id: item.id, name: item.firstName },
          });
        });
        setDataTeacher(option);
      }
      // setLoading(false);
    });
  };

  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };
  // const onClickExport = () => {
  //   setLoadPopup(true);
  //   setErrormsg(null);
  //   const val = date.split(" - ");
  //   let start = val[0];
  //   let end = val[1];
  //   let qString = "";
  //   if (start && end) {
  //     qString = "?start=" + start + "&end=" + end;
  //   }
  //   if (values.status !== "All") qString = qString + "&status=" + values.status;
  //   if (values.classattstatus !== "All")
  //     qString = qString + "&school" + values.classattstatus;
  //   if (values.course !== "All")
  //     qString = qString + "&course=" + values.course.id;
  //   if (values.class !== "All") qString = qString + "&class=" + values.class.id;
  //   exportClassAttendance(qString).then((res) => {
  //     if (res && res.status === 200) {
  //       setLoadPopup(false);
  //       var newBlob = new Blob([res.data]);
  //       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //         var newBlobIE = new Blob([res.data], {
  //           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //         });
  //         return navigator.msSaveOrOpenBlob(
  //           newBlobIE,
  //           "AttendanceReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
  //         );
  //       }
  //       const data = window.URL.createObjectURL(newBlob);
  //       var link = document.createElement("a");
  //       link.href = data;
  //       // this.setState({ link: link.href });
  //       link.download =
  //         "AttendanceReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       setTimeout(function () {
  //         // For Firefox it is necessary to delay revoking the ObjectURL

  //         window.URL.revokeObjectURL(data);
  //       }, 100);
  //     } else {
  //       setErrormsg("The request is taking too long. Please try again");
  //     }
  //   });
  // };

  const onClickExport = () => {
    setLoadPopup(true);
    setErrormsg(null);
    //Date  2022.11.07
    let qString = "";
    if (date) qString = "?date=" + date;
    if (values.campus && values.campus != "All")
      qString = qString + "&campus_name=" + values.campus.name;
    if (values.program && values.program != "All")
      qString = qString + "&program_name=" + values.program.name;

    exportClassAttendExcel(qString).then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false);
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "ClassAttendanceReport_" +
              moment().format("YYYYMMDDHHmmss") +
              ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        // this.setState({ link: link.href });
        link.download =
          "ClassAttendanceReport_" +
          moment().format("YYYYMMDDHHmmss") +
          ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Class Attendance Report</Header>

      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/report")}
              style={{ color: "grey" }}
            >
              Report
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>
              Class Attendance Report
            </Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="green"
            content="Export Excel"
            floated="right"
            style={{ marginBottom: "14px" }}
            onClick={onClickExport}
          />
        </Grid.Column>
      </Grid>

      <Segment
        className="contentSegment"
        textAlign="left"
        style={{ minHeight: "50vh", padding: "20px" }}
      >
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              {/* <DatesRangeInput */}
              <DateInput
                name="date"
                // placeholder="Start - End"
                placeholder="Date"
                dateFormat="YYYY-MM-DD"
                iconPosition="left"
                value={date}
                // allowSameEndDate
                onChange={handleChangeDate}
                // clearable
              />
            </Form.Field>
            {/* <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="course"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Course :{" "}
                  <b>
                    {values.course && values.course.name
                      ? values.course.name
                      : values.course}
                  </b>
                </Button>
              }
              options={
                course_option && [
                  { key: "all", text: "All", value: "All" },
                  ...course_option,
                ]
              }
              defaultValue={
                values.course && values.course.name
                  ? values.course.name
                  : values.course
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            /> */}
            {/* <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="class"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Class :{" "}
                  <b>
                    {values.class && values.class.name
                      ? values.class.name
                      : values.class}
                  </b>
                </Button>
              }
              options={
                class_option && [
                  { key: "all", text: "All", value: "All" },
                  ...class_option,
                ]
              }
              defaultValue={
                values.class && values.class.name
                  ? values.class.name
                  : values.class
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            /> */}
          </Form.Group>
          <Form.Group>
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="campus"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  {/* Class Status : <b>{values.status}</b> */}
                  {/* Todo */}
                  Campus :{" "}
                  <b>
                    {values.campus && values.campus.name
                      ? values.campus.name
                      : values.campus}
                  </b>
                </Button>
              }
              // options={config.options_campus}
              options={
                datacampus && [
                  { key: "all", text: "All", value: "All" },
                  ...datacampus,
                ]
              }
              defaultValue={
                values.campus && values.campus.name
                  ? values.campus.name
                  : values.campus
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="program"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  {/* Class Status : <b>{values.status}</b> */}
                  {/* Todo */}
                  Program :{" "}
                  <b>
                    {values.program && values.program.name
                      ? values.program.name
                      : values.program}
                  </b>
                </Button>
              }
              // options={config.options_campus}
              options={
                dataprogram && [
                  { key: "all", text: "All", value: "All" },
                  ...dataprogram,
                ]
              }
              defaultValue={
                values.program && values.program.name
                  ? values.program.name
                  : values.program
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            {/* Enable later */}
            {/* <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="course"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Course :{" "}
                  <b>
                    {values.course && values.course.name
                      ? values.course.name
                      : values.course}
                  </b>
                </Button>
              }
              options={
                course_option && [
                  { key: "all", text: "All", value: "All" },
                  ...course_option,
                ]
              }
              defaultValue={
                values.course && values.course.name
                  ? values.course.name
                  : values.course
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="class"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Class :{" "}
                  <b>
                    {values.class && values.class.name
                      ? values.class.name
                      : values.class}
                  </b>
                </Button>
              }
              options={
                class_option && [
                  { key: "all", text: "All", value: "All" },
                  ...class_option,
                ]
              }
              defaultValue={
                values.class && values.class.name
                  ? values.class.name
                  : values.class
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="6"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="class"
              scrolling
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Teacher :{" "}
                  <b>
                    {values.teacher && values.teacher.name
                      ? values.teacher.name
                      : values.teacher}
                  </b>
                </Button>
              }
              options={
                datateacher && [
                  { key: "all", text: "All", value: "All" },
                  ...datateacher,
                ]
              }
              defaultValue={
                values.teacher && values.teacher.name
                  ? values.teacher.name
                  : values.teacher
              }
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="classattstatus"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status : <b>{values.classattstatus}</b>
                </Button>
              }
              options={config.options_classattstatus}
              defaultValue={values.classattstatus}
              selectOnBlur={false}
              onChange={handleSelectChange}
            /> */}
          </Form.Group>
        </Form>
        <TableList loading={loading} data={data} columns={column} />
        {/* <TableList
          loading={loading}
          data={data}
          columns={column}
          getRowProps={(row) => ({
            style: {
              background: row.index % 2 === 0 ? "rgba(128,128,128)" : "green",
            },
          })}
        /> */}
        {/* <ReactTable
          data={data}
          columns={column}
          className="-striped -highlight"
        /> */}
      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Waiting Download File
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>
    </Segment>
  );
};
export default Attendance;
