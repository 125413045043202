import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Grid,
  Image,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import { DateInput, DatesRangeInput } from "semantic-ui-calendar-react";
import {
  getHistoryBookID,
  getBookHistoryByItem,
  getBorrowHistoryBook,
} from "./service";
import ModalBasic from "../../components/modal";
import FormDefectBook from "./formDefectBook";

const BookHistory = (props) => {
  const [item, setItem] = useState(props.bookProfiles);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [per_page, setPerPage] = useState(5);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [DefectImageUrl, setDefectImageUrl] = useState(null);
  const [defectData, setDefectData] = useState(null);
  const [showPopupDefect, setShowPopupDefect] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const [errormsg, setErrormsg] = useState(null);

  //   const [values, setValues] = useState({
  //     class: "",
  //     term: "",
  //     status_visit: "",
  //     status_interview: "",
  //   });
  //   const [date, setDate] = useState(
  //     moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  //   );
  //   const [visit_date, setvisit_date] = useState(
  //     moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  //   );
  const [column, setColumn] = useState([
    {
      Header: "Booking ID",
      accessor: "booking_id",
      style: { textAlign: "center" },
      sortable: false,
      // Cell: (cellInfo) => cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
    },
    {
      Header: "Borrowed date",
      accessor: "borrow_date",
      sortable: false,
      style: { textAlign: "center" },
    },
    {
      Header: "Borrower",
      accessor: "fullname",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Due",
      accessor: "return_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Date",
      accessor: "return_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Defective",
      accessor: "defect_id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.defect_id === null ? (
          ""
        ) : (
          <Wrap>
            <Icon
              link
              bordered
              style={{
                color: "red",
                boxShadow: "0 0 0 0.1em " + "red" + " inset",
              }}
              name="exclamation"
              onClick={() => onItemDefect(cellInfo.original.booking_id)}
            />
          </Wrap>
        ),
    },
  ]);

  useEffect(() => {
    setLoading(true);
    if (item) {
      getData();
    }
  }, [per_page, current_page, item]);

  const getData = () => {
    setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    qString = qString + "&book_id=" + item;

    // getHistoryBookID(props.bookProfiles).then((res) => {
    getBookHistoryByItem(qString).then((res) => {
      if (res && res.status === 200) {
        // setColumn(column);
        // setData(res.data);
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
  };
  // console.log(data)
  // console.log(props.bookProfiles)

  // Get defect detail
  const onItemDefect = (id) => {
    let qString = "?booking_id=" + id + "&book_id=" + item;

    console.log(`onItemDefect`, qString);

    getBorrowHistoryBook(qString).then((res) => {
      console.log(`getBorrowHistoryBook`, res);
      if (res && res.status === 200) {
        // setDefectData(res.data[0]);
        // setDefectImageUrl(res.data[0].imageURL);
        // // setDefectImageUrl(res.data[0].authimgurl);   //cannot sent to formdefect.js (undefine)
        setDefectData(res.data);
        setDefectImageUrl(res.data.imageURL);

        setShowPopupDefect(true);
      }
      // setLoading(false);
    });
  };

  return (
    <Segment basic textAlign="left">
      {/* <TableList loading={loading} data={data} columns={column} /> */}
      <TableList
        loading={loading}
        data={data}
        columns={column}
        pageSize={per_page}
        page={current_page}
        pages={last_page}
        onPageChange={(page) => setCurrentPage(page)}
        onPageSizeChange={(value) => setPerPage(value)}
        // onSortedChange={(value) => setSortPage(value)}
      />

      {/* Defect form */}
      <ModalBasic
        size="big"
        open={showPopupDefect}
        content="Book Damaged"
        close={() => setShowPopupDefect(false)}
      >
        <FormDefectBook
          onSubmit={() => setShowPopupDefect(false)}
          onClose={() => setShowPopupDefect(false)}
          loading={loading}
          initialValues={defectData}
          errormsg={errormsg}
          imageURL={DefectImageUrl}
          // data={defectData}
          viewOnly={viewOnly}
        />
      </ModalBasic>
    </Segment>
  );
};
export default BookHistory;
