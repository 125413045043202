import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

export default class ControlledEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty()
        };
        // this.props.onChange(
        //   draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        // );
    }

    componentDidMount() {
        console.log(this.props);
        if (this.props.value) {
            if (this.props.value === '<p></p>') {
                this.setState({ editorState: EditorState.createEmpty() })
            } else {
                this.setState({
                    editorState: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(this.props.value)
                        )
                    )
                })
                const { onChange, value } = this.props;
                onChange(value)
            }
        }
    }

    onEditorStateChange = (editorState) => {
        const { onChange, value } = this.props;
        const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        if (value !== newValue) {
            onChange(newValue);
        }

        this.setState({
            editorState
        });
    };
    //   onEditorStateChange = (editorState) => {
    //         this.setState({
    //             editorState,
    //         });
    //     };

    render() {
        const { editorState } = this.state;
        return (
            <div>
                <Editor
                    editorState={editorState}
                    toolbarHidden="true"
                    defaultEditorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    stripPastedStyles={true}
                />
            </div>
        );
    }
}
