import React, { useState, useEffect } from "react";
import { Button, Form, Message, Table, Grid, Divider, Icon, Header } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from '../../config';
import Wrap from "../../components/wrap";


let GraduateForm = (props) => {
    const { loading, course, onSubmit,
        onClose, term_option, errormsg, student } = props;
    const [studentRegist, setStudentRegist] = useState(student);
    const [studentSelected, setStudentSelected] = useState([]);
    const [status, setStatus] = useState('Graduate');
    const [term, setTerm] = useState("All");

    useEffect(() => {
        const result = [];
        student.map(item => {
            item.checked = null;
            if (term && term.id) {
                if (item.term_id === term.id) {
                    result.push(item);
                }
            } else result.push(item)
        });
        setStudentRegist(result);
        setStudentSelected([]);
    }, [term]);


    const handleChange = (e, { id, checked }) => {
        let data = studentRegist;
        data[id]['checked'] = checked;

        const result = [];
        data.map(item => {
            if (item.checked)
                result.push(item)
        });
        setStudentRegist(data);
        setStudentSelected(result);
    }

    return (
        <Form   >
            {
                errormsg && <Message negative>
                    <Message.Content>{errormsg}</Message.Content>
                </Message>
            }
            <Form.Dropdown
                width="4"
                icon={null}
                fluid
                floating
                style={{ width: "100%", marginRight: "30px" }}
                name='term'
                trigger={
                    <Button
                        basic
                        icon
                        fluid
                        size="small"
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                    >
                        <Icon name="angle down" />
                        Filter by Term : <b>{term && term.name ? term.name : term}</b>
                    </Button>
                }
                defaultValue={term && term.name ? term.name : term}
                options={term_option && [{ key: "all", text: "All", value: "All" }, ...term_option]}
                onChange={(e, data) => setTerm(data.value)}
                selectOnBlur={false}

            />
            <Table celled size='small' compact padded style={{ borderRadius: 0, fontSize: '95%' }} >
                <Table.Header style={{
                    boxShadow: '0 0.5px 5px 0 rgb(0 0 0 / 15%)',
                    textAlign: 'center'
                }}>
                    <Table.Row>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}></Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Name</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Term</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Start</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>End</Table.HeaderCell>
                        <Table.HeaderCell style={{ borderRadius: 0, backgroundColor: '#80561B', color: '#fff', paddingTop: '5px', paddingBottom: '5px' }}>Registered date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {studentRegist.map((item, index) =>
                        <Table.Row>
                            <Table.Cell textAlign='center'>
                                <Form.Checkbox
                                    id={index}
                                    checked={item.checked}

                                    onChange={handleChange} />
                            </Table.Cell>
                            <Table.Cell >
                                {item.studentID} {item.title} {item.firstName} {item.lastName}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {item.name}
                            </Table.Cell>
                            {item.dateOfRegist ? <Wrap>
                                <Table.Cell textAlign='center'>
                                    {item.dateOfRegist}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    -
                                </Table.Cell>
                            </Wrap>
                                : <Wrap>
                                    <Table.Cell textAlign='center'>
                                        {item.start}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {item.end}
                                    </Table.Cell>
                                </Wrap>
                            }

                            <Table.Cell textAlign='center' >
                                {item.registdate}
                            </Table.Cell>
                        </Table.Row>
                    )}

                </Table.Body>
            </Table>
            <br />
            <Form style={{ textAlign: 'center' }}>
                {studentSelected && studentSelected.length != 0
                    && <Wrap >
                        <Divider horizontal>
                            <Header as='h4'>
                                Registration Status
                            </Header>
                        </Divider>
                        <br /><br />
                        <Form.Select
                            label='Status : '
                            defaultValue={status}
                            inline
                            scrolling
                            options={[
                                { key: 'm', text: 'Unregister', value: 'Unregister' },
                                { key: 'f', text: 'Terminate', value: 'Terminate' },
                                { key: 'o', text: 'Graduate', value: 'Graduate' },
                            ]}
                            onChange={(e, data) => setStatus(data.value)}

                        />

                        <br /><br />
                    </Wrap>
                }
                <div>
                    <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
                    {studentSelected && studentSelected.length != 0 && <Button floated='left' content={`${status} ${studentSelected.length != 0 ? '( ' + studentSelected.length + ' )' : ''}`}
                        style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
                        loading={loading}
                        disabled={studentSelected.length === 0}
                        onClick={() => onSubmit({ status: status, student: studentSelected })}
                    />
                    }
                </div>
            </Form>
            <br /><br />
        </Form>
    );

}


export default GraduateForm;
