import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import ApproveFormRegistation from "./approveformregistation";
import {
  getApplicationProfile,
  getSibling2bystudent_id,
  guardian1bystudent_id,
  guardian2bystudent_id,
  getimmunization_gethealthCheckStudents_healthbystudent_id,
  getdocumentbyvisit_idbystudent_id,
  submitApproveRegistation,
  updateapplicationstudent,
  updateapplicationfather,
  updateapplicationmother,
  updatesibling,
  updateemergency2,
  updateapplicationguardian1,
  updateapplicationguardian2,
  updatehealthform,
  updateImageProfile,
  updateImageProfile2,
} from "../service";
import FormStudent from "./formstudent";
// import FormImg from "./formimg";
import FormImgParent from "./formimgparent";
import FormParent from "./formparent";
import FormImgGuardian from "./formimgguardian";
import FormEditSibling from "./formsibling";
import FormEmergency from "./formemergency";
import FormHealth from "../Student/healthform";
import FormDocument from "../Student/document/formdocument";
import { useReactToPrint } from "react-to-print";

let RegistationDetail = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "RegistrationForm",
    // onAfterPrint: () => alert("Print success"),
  });

  const [loading, setLoading] = useState(true);
  const [approveLoading, setApproveLoading] = useState(false);
  const [interviewProfiles] = useState(
    props && props.location.state && props.location.state.id
  );
  const [formType] = useState(
    props && props.location.state && props.location.state.formtype
  );
  const [data, setData] = useState([]);
  const [dataFather, setDataFather] = useState([]);
  const [dataMother, setDataMother] = useState([]);
  const [datagetSibling2bystudent_id, setDatagetSibling2bystudent_id] =
    useState([]);
  const [dataguardian1bystudent_id, setDataguardian1bystudent_id] = useState(
    []
  );
  const [dataguardian2bystudent_id, setDataguardian2bystudent_id] = useState(
    []
  );
  const [
    immunization_gethealthCheckStudents_health,
    setDatagetimmunization_gethealthCheckStudents_healthbystudent_id,
  ] = useState([]);
  const [document, setDatagetdocumentbyvisit_idbystudent_id] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [open_student, setOpen_student] = useState(false);
  const [open_father, setOpen_father] = useState(false);
  const [open_mother, setOpen_mother] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [openImgFather, setOpenImgFather] = useState(false);
  const [openImgMother, setOpenImgMother] = useState(false);
  const [open_sibling, setOpen_sibling] = useState(false);
  const [open_emergency, setOpen_emergency] = useState(false);
  const [open_guardian1, setOpen_guardian1] = useState(false);
  const [open_guardian2, setOpen_guardian2] = useState(false);
  const [openImgGuardian1, setOpenImgGuardian1] = useState(false);
  const [openImgGuardian2, setOpenImgGuardian2] = useState(false);
  const [open_health, setOpen_health] = useState(false);
  const [formTitle, setFormTitle] = useState(null);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    if (!interviewProfiles) props.history.push("/application/registerdetail");

    setLoading(true);
    if (interviewProfiles) {
      getData();
    }
  }, [interviewProfiles]);

  const getData = () => {
    getApplicationProfile(props.location.state.id).then((res) => {
      if (res && res.status === 200) {
        setData(res.data.student);
        setDataFather(res.data.parent[0]);
        setDataMother(res.data.parent[1]);
      }
      setLoading(false);
    });

    if (formType == 1) {
      getSibling2bystudent_id(props.location.state.id).then((res) => {
        if (res && res.status === 200) {
          setDatagetSibling2bystudent_id(res.data);
        }
        setLoading(false);
      });

      guardian1bystudent_id(props.location.state.id).then((res) => {
        if (res && res.status === 200) {
          setDataguardian1bystudent_id(res.data);
        }
        setLoading(false);
      });

      guardian2bystudent_id(props.location.state.id).then((res) => {
        if (res && res.status === 200) {
          setDataguardian2bystudent_id(res.data);
        }
        setLoading(false);
      });

      getimmunization_gethealthCheckStudents_healthbystudent_id(
        props.location.state.id
      ).then((res) => {
        if (res && res.status === 200) {
          setDatagetimmunization_gethealthCheckStudents_healthbystudent_id(
            res.data[0]
          );
        }
        setLoading(false);
      });
    }

    if (formType == 1 || formType == 2) {
      getdocumentbyvisit_idbystudent_id(props.location.state.id).then((res) => {
        if (res && res.status === 200) {
          setDatagetdocumentbyvisit_idbystudent_id(res.data);
        }
        setLoading(false);
      });
    }
  };

  // Todo
  const updateImage = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageProfile(formData, data.id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setPhoto(null);
        setOpenImg(false);
      }
      setLoading(false);
    });
  };

  // student
  const onEditImg = (values) => {
    setOpenImg(true);
    setCurrentData(values);
  };
  const onCloseImg = () => {
    setOpenImg(false);
    setCurrentData(null);
  };

  const updateDataImg = (values) => {
    setLoading(true);
    let data = values;
  };
  const onEdit_student = (values) => {
    setOpen_student(true);
    setCurrentData(values);
  };
  const onClose_student = () => {
    setOpen_student(false);
    setCurrentData(null);
  };
  const updateData_student = (values) => {
    setLoading(true);
    let data = values;
    updateapplicationstudent(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_student(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // Todo
  const updateImageFather = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageProfile2(formData, dataFather.guardian_id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setPhoto(null);
        setOpenImgFather(false);
      }
      setLoading(false);
    });
  };

  // father
  const onEditImgFather = (values) => {
    setOpenImgFather(true);
    setCurrentData(values);
  };
  const onCloseImgFather = () => {
    setOpenImgFather(false);
    setCurrentData(null);
  };

  const onEdit_father = (values) => {
    setOpen_father(true);
    setCurrentData(values);
    setFormTitle("FATHER (บิดา)");
  };
  const onClose_father = () => {
    setOpen_father(false);
    setCurrentData(null);
  };
  const updateDataImgFather = (values) => {
    setLoading(true);
    let dataFather = values;
  };
  const updateData_father = (values) => {
    setLoading(true);
    let dataFather = values;
    updateapplicationfather(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_father(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // Todo
  const updateImageMother = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageProfile2(formData, dataMother.guardian_id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setPhoto(null);
        setOpenImgMother(false);
      }
      setLoading(false);
    });
  };

  // mother
  const onEditImgMother = (values) => {
    setOpenImgMother(true);
    setCurrentData(values);
  };
  const onCloseImgMother = () => {
    setOpenImgMother(false);
    setCurrentData(null);
  };

  const onEdit_mother = (values) => {
    setOpen_mother(true);
    setCurrentData(values);
    setFormTitle("MOTHER (มารดา)");
  };
  const onClose_mother = () => {
    setOpen_mother(false);
    setCurrentData(null);
  };
  const updateDataImgMother = (values) => {
    setLoading(true);
    let dataMother = values;
  };
  const updateData_mother = (values) => {
    setLoading(true);
    let dataMother = values;
    updateapplicationmother(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_mother(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // sibling
  const onEdit_sibling = (values) => {
    setOpen_sibling(true);
    setCurrentData(values);
  };
  const onClose_sibling = () => {
    setOpen_sibling(false);
    setCurrentData(null);
  };
  const updateData_sibling = (values) => {
    setLoading(true);
    let immunization_gethealthCheckStudents_health = values;
    updatesibling(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_sibling(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // emergency
  const onEdit_emergency = (values) => {
    setOpen_emergency(true);
    setCurrentData(values);
  };
  const onClose_emergency = () => {
    setOpen_emergency(false);
    setCurrentData(null);
  };
  const updateData_emergency = (values) => {
    setLoading(true);
    let data = values;
    updateemergency2(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_emergency(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // guardian1
  const onEditImgGuardian1 = (values) => {
    setOpenImgGuardian1(true);
    setCurrentData(values);
  };
  const onCloseImgGuardian1 = () => {
    setOpenImgGuardian1(false);
    setCurrentData(null);
  };

  const onEdit_guardian1 = (values) => {
    setOpen_guardian1(true);
    setCurrentData(values);
    setFormTitle("GUARDIAN1 (ผู้ปกครอง1)");
  };
  const onClose_guardian1 = () => {
    setOpen_guardian1(false);
    setCurrentData(null);
  };
  const updateDataImgGuardian1 = (values) => {
    setLoading(true);
    let dataguardian1bystudent_id = values;
  };
  const updateData_guardian1 = (values) => {
    setLoading(true);
    let dataguardian1bystudent_id = values;
    updateapplicationguardian1(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_guardian1(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // guardian2
  const onEditImgGuardian2 = (values) => {
    setOpenImgGuardian2(true);
    setCurrentData(values);
  };
  const onCloseImgGuardian2 = () => {
    setOpenImgGuardian2(false);
    setCurrentData(null);
  };

  const onEdit_guardian2 = (values) => {
    setOpen_guardian2(true);
    setCurrentData(values);
    setFormTitle("GUARDIAN2 (ผู้ปกครอง2)");
  };
  const onClose_guardian2 = () => {
    setOpen_guardian2(false);
    setCurrentData(null);
  };
  const updateDataImgGuardian2 = (values) => {
    setLoading(true);
    let dataguardian2bystudent_id = values;
  };
  const updateData_guardian2 = (values) => {
    setLoading(true);
    let dataguardian2bystudent_id = values;
    updateapplicationguardian2(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_guardian2(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // health
  const onEdit_health = (values) => {
    setOpen_health(true);
    setCurrentData(values);
  };
  const onClose_health = () => {
    setOpen_health(false);
    setCurrentData(null);
  };
  const updateData_health = (values) => {
    setLoading(true);
    let immunization_gethealthCheckStudents_health = values;
    updatehealthform(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_health(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  //Approve Registration
  const updateApprove = () => {
    setApproveLoading(true);

    submitApproveRegistation(data).then((res) => {
      if (res && res.status === 200) {
        setApproveLoading(false);
        window.location.reload();

        // console.log(`submit`, res);
      }
    });
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "end", paddingRight: "20px" }}
      >
        <button onClick={handlePrint}>Print this out</button>
      </div>
      <div ref={componentRef}>
        <Segment basic textAlign="left">
          <Header as="h2">Registration Form</Header>
          <Grid columns="2">
            <Grid.Column>
              <Breadcrumb>
                <Breadcrumb.Section
                  onClick={() => props.history.push("/application/registation")}
                  style={{ color: "grey" }}
                >
                  Registration
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section active>
                  Registration Form
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid>

          <Segment>
            <div style={{ paddingLeft: "20px" }}>
              <Header as="h4">Student</Header>
              <Grid divided stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Segment style={{ margin: "40px" }}>
                      <Grid columns="2">
                        {data.imageURL && (
                          <>
                            <Grid.Column style={{ paddingLeft: "40px" }}>
                              <Image
                                width={"25%"}
                                verticalAlign="middle"
                                src={
                                  data.imageURL
                                    ? data.imageURL + "?time=" + new Date()
                                    : "../storage/imgs/dfkid.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../storage/imgs/dfkid.png";
                                }}
                              />

                              <Button
                                content="Image"
                                // onClick={() => onEditImg(data)}
                                onClick={() => setOpenImg(true)}
                                style={{
                                  backgroundColor: config.colorTheme,
                                  color: "#ffffff",
                                  minWidth: "10%",
                                  marginLeft: "10%",
                                }}
                              ></Button>
                            </Grid.Column>
                          </>
                        )}
                        {!data.imageURL && (
                          <>
                            <Grid.Column style={{ paddingLeft: "40px" }}>
                              <Image
                                width={"25%"}
                                verticalAlign="middle"
                                src={
                                  data.imageURL
                                    ? data.imageURL + "?time=" + new Date()
                                    : "../storage/imgs/dfkid.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../storage/imgs/dfkid.png";
                                }}
                              />
                              <Button
                                content="Image"
                                // onClick={() => onEditImg(data)}
                                onClick={() => setOpenImg(true)}
                                style={{
                                  backgroundColor: config.colorTheme,
                                  color: "#ffffff",
                                  minWidth: "10%",
                                  marginLeft: "10%",
                                }}
                              ></Button>
                            </Grid.Column>
                            <Grid.Column>
                              <p style={{ color: "red", marginTop: "8%" }}>
                                Please Upload Image
                              </p>
                            </Grid.Column>
                          </>
                        )}
                      </Grid>
                    </Segment>
                    <Grid columns="3">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Passport or ID No.:<b> {data.idnumber}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Date Of Birth:<b> {data.dateOfBirth}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="3">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        firstname:<b> {data.firstName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        lastname:<b> {data.lastName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Nickname:<b> {data.nickname}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="3">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        ชื่อ:<b> {data.firstName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        นามสกุล:<b> {data.lastName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        ชื่อเล่น:<b> {data.nickname_th}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="3">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Gender:<b> {data.gender}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Nationality:<b> {data.nationality}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Native Language:<b> {data.nativelanguage}</b>
                      </Grid.Column>
                    </Grid>
                    {/* <Grid columns="4" style={{ width: "80%" }}> */}
                    <Grid columns="3">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Race:<b> {data.race}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Native Language: (Use at home):
                        <b> {data.nativelanguageAtHome}</b>
                      </Grid.Column>
                      <Grid.Column>
                        BloodType:<b> {data.bloodtype}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="3">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        โรคประจำตัว: <b>{data.allergy}</b>
                      </Grid.Column>
                      <Grid.Column style={{ width: "15%" }}>
                        แพ้อาหาร : <b>{data.food_allergy}</b>
                      </Grid.Column>
                    </Grid>
                    <br />
                    <br />
                    <Button
                      content="Edit"
                      onClick={() => onEdit_student(data)}
                      style={{
                        backgroundColor: config.colorTheme,
                        color: "#ffffff",
                      }}
                    ></Button>
                    <hr />
                    <Header as="h4">FATHER</Header>
                    <Segment style={{ margin: "40px" }}>
                      <Grid columns="2">
                        {dataFather.GimageURL && (
                          <>
                            <Grid.Column style={{ paddingLeft: "40px" }}>
                              <Image
                                width={"25%"}
                                verticalAlign="middle"
                                src={
                                  dataFather.GimageURL
                                    ? dataFather.GimageURL +
                                      "?time=" +
                                      new Date()
                                    : "../storage/imgs/dfkid.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../storage/imgs/dfkid.png";
                                }}
                              />
                              <Button
                                content="Image"
                                // onClick={() => onEditImgFather(dataFather)}
                                onClick={() => setOpenImgFather(true)}
                                style={{
                                  backgroundColor: config.colorTheme,
                                  color: "#ffffff",
                                  minWidth: "10%",
                                  marginLeft: "10%",
                                }}
                              ></Button>
                            </Grid.Column>
                          </>
                        )}
                        {!dataFather.GimageURL && (
                          <>
                            <Grid.Column style={{ paddingLeft: "40px" }}>
                              <Image
                                width={"25%"}
                                verticalAlign="middle"
                                src={
                                  dataFather.GimageURL
                                    ? dataFather.GimageURL +
                                      "?time=" +
                                      new Date()
                                    : "../storage/imgs/dfkid.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../storage/imgs/dfkid.png";
                                }}
                              />
                              <Button
                                content="Image"
                                // onClick={() => onEditImgFather(dataFather)}
                                onClick={() => setOpenImgFather(true)}
                                style={{
                                  backgroundColor: config.colorTheme,
                                  color: "#ffffff",
                                  minWidth: "10%",
                                  marginLeft: "10%",
                                }}
                              ></Button>
                            </Grid.Column>
                            <Grid.Column>
                              <p style={{ color: "red", marginTop: "8%" }}>
                                Please Upload Image
                              </p>
                            </Grid.Column>
                          </>
                        )}
                      </Grid>
                    </Segment>
                    <Grid columns="2">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Passport or ID No.:<b> {dataFather.idnumber}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Date Of Birth:<b> {dataFather.dateOfBirth}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        firstname:<b> {dataFather.firstName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        lastname:<b> {dataFather.lastName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Nationality: <b> {dataFather.nationality}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Native Language:<b> {dataFather.nativelanguage}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        ชื่อ:<b> {dataFather.firstName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        นามสกุล:<b> {dataFather.lastName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Mobile:<b> {dataFather.phone}</b>
                      </Grid.Column>
                      <Grid.Column>
                        E-mail:<b> {dataFather.email}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Place of work:<b> {dataFather.placeOfWork}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Position:<b> {dataFather.position}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Office Tel:<b> {dataFather.officePhone}</b>
                      </Grid.Column>
                    </Grid>
                    <br />
                    <br />
                    <Button
                      content="Edit"
                      onClick={() => onEdit_father(dataFather)}
                      style={{
                        backgroundColor: config.colorTheme,
                        color: "#ffffff",
                      }}
                    ></Button>
                    <hr />

                    <Header as="h4">MOTHER</Header>
                    <Segment style={{ margin: "40px" }}>
                      <Grid columns="2">
                        {dataMother.GimageURL && (
                          <>
                            <Grid.Column style={{ paddingLeft: "40px" }}>
                              <Image
                                width={"25%"}
                                verticalAlign="middle"
                                src={
                                  dataMother.GimageURL
                                    ? dataMother.GimageURL +
                                      "?time=" +
                                      new Date()
                                    : "../storage/imgs/dfkid.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../storage/imgs/dfkid.png";
                                }}
                              />
                              <Button
                                content="Image"
                                // onClick={() => onEditImgMother(dataMother)}
                                onClick={() => setOpenImgMother(true)}
                                style={{
                                  backgroundColor: config.colorTheme,
                                  color: "#ffffff",
                                  minWidth: "10%",
                                  marginLeft: "10%",
                                }}
                              ></Button>
                            </Grid.Column>
                          </>
                        )}
                        {!dataMother.GimageURL && (
                          <>
                            <Grid.Column style={{ paddingLeft: "40px" }}>
                              <Image
                                width={"25%"}
                                verticalAlign="middle"
                                src={
                                  dataMother.GimageURL
                                    ? dataMother.GimageURL +
                                      "?time=" +
                                      new Date()
                                    : "../storage/imgs/dfkid.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../storage/imgs/dfkid.png";
                                }}
                              />
                              <Button
                                content="Image"
                                // onClick={() => onEditImgMother(dataMother)}
                                onClick={() => setOpenImgMother(true)}
                                style={{
                                  backgroundColor: config.colorTheme,
                                  color: "#ffffff",
                                  minWidth: "10%",
                                  marginLeft: "10%",
                                }}
                              ></Button>
                            </Grid.Column>
                            <Grid.Column>
                              <p style={{ color: "red", marginTop: "8%" }}>
                                Please Upload Image
                              </p>
                            </Grid.Column>
                          </>
                        )}
                      </Grid>
                    </Segment>
                    <Grid columns="2">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Passport or ID No.:<b> {dataMother.idnumber}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Date Of Birth:<b> {dataMother.dateOfBirth}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        firstname:<b> {dataMother.firstName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        lastname:<b> {dataMother.lastName}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Nationality:<b> {dataMother.nationality}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Native Language:<b> {dataMother.nativelanguage}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        ชื่อ:<b> {dataMother.firstName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        นามสกุล:<b> {dataMother.lastName_th}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Mobile:<b> {dataMother.phone}</b>
                      </Grid.Column>
                      <Grid.Column>
                        E-mail:<b> {dataMother.email}</b>
                      </Grid.Column>
                    </Grid>
                    <Grid columns="4">
                      <Grid.Column style={{ paddingLeft: "40px" }}>
                        Place of work:<b> {dataMother.placeOfWork}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Position:<b> {dataMother.position}</b>
                      </Grid.Column>
                      <Grid.Column>
                        Office Tel:<b> {dataMother.officePhone}</b>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              <br />
              <Button
                content="Edit"
                onClick={() => onEdit_mother(dataMother)}
                style={{
                  backgroundColor: config.colorTheme,
                  color: "#ffffff",
                }}
              ></Button>
              <hr />

              {formType == 1 ? (
                <>
                  <Header as="h4">
                    <u>Part II </u>: Student(Cont.)
                  </Header>
                  <Grid divided stackable>
                    <br></br>
                    <Header as="h4">Name and ages of student sibling.</Header>
                    <Grid.Row>
                      <Grid.Column>
                        {formType == 1 &&
                          datagetSibling2bystudent_id.map != null && (
                            <>
                              {datagetSibling2bystudent_id.map(
                                (item, index) => (
                                  <Grid
                                    key={index}
                                    columns="1"
                                    style={{ width: "70%" }}
                                  >
                                    <Grid.Column
                                      style={{ paddingLeft: "40px" }}
                                    >
                                      {item.name1 != null && (
                                        <>
                                          <b>Name:</b> {item.name1}
                                        </>
                                      )}
                                      {item.age1 != null && (
                                        <>
                                          <b> age:</b> {item.age1}
                                        </>
                                      )}
                                      <br />
                                      <br />
                                      {item.name2 != null && (
                                        <>
                                          <b>Name:</b> {item.name2}
                                        </>
                                      )}
                                      {item.age2 != null && (
                                        <>
                                          <b> age:</b> {item.age2}
                                        </>
                                      )}
                                      <br />
                                      <br />
                                      {item.name3 != null && (
                                        <>
                                          <b>Name:</b> {item.name3}
                                        </>
                                      )}
                                      {item.age3 != null && (
                                        <>
                                          <b> age:</b> {item.age3}
                                        </>
                                      )}
                                      <br />
                                      <br />
                                      {item.name4 != null && (
                                        <>
                                          <b>Name:</b> {item.name4}
                                        </>
                                      )}
                                      {item.age4 != null && (
                                        <>
                                          <b> age:</b> {item.age4}
                                        </>
                                      )}
                                    </Grid.Column>
                                    <Button
                                      content="Edit"
                                      onClick={() => onEdit_sibling(item)}
                                      style={{
                                        backgroundColor: config.colorTheme,
                                        color: "#ffffff",
                                        minWidth: "10%",
                                        marginLeft: "10%",
                                      }}
                                    ></Button>
                                  </Grid>
                                )
                              )}
                            </>
                          )}

                        <Header as="h4">Emergency contact person.</Header>
                        <Card
                          style={{
                            width: "50%",
                            backgroundColor: "#efefef",
                          }}
                        >
                          <Card.Content>
                            <Grid columns="1" style={{ width: "70%" }}>
                              <Grid.Column>
                                Emergency person:
                                <b> {data.emergency_name}</b>
                              </Grid.Column>
                            </Grid>
                            <Grid columns="2" style={{ width: "70%" }}>
                              <Grid.Column>
                                Contact no.:<b> {data.emergency_phone}</b>
                              </Grid.Column>
                              <Grid.Column>
                                Relationship:
                                <b> {data.emergency_relation}</b>
                              </Grid.Column>
                            </Grid>
                          </Card.Content>
                        </Card>
                        <Button
                          content="Edit"
                          onClick={() => onEdit_emergency(data)}
                          style={{
                            backgroundColor: config.colorTheme,
                            color: "#ffffff",
                          }}
                        ></Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <br />
                  <br />
                  <hr />
                  <Header as="h4">
                    <u>Part III</u> : Guardian
                  </Header>
                  <div style={{ paddingLeft: "20px" }}>
                    <Segment style={{ margin: "40px" }}>
                      <Grid columns="2">
                        {dataguardian1bystudent_id.imageURL && (
                          <>
                            <Grid.Column style={{ paddingLeft: "40px" }}>
                              <Image
                                width={"25%"}
                                verticalAlign="middle"
                                src={
                                  dataguardian1bystudent_id.imageURL
                                    ? dataguardian1bystudent_id.imageURL +
                                      "?time=" +
                                      new Date()
                                    : "../storage/imgs/dfkid.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../storage/imgs/dfkid.png";
                                }}
                              />
                              <Button
                                content="Image"
                                onClick={() =>
                                  onEditImgGuardian1(dataguardian1bystudent_id)
                                }
                                style={{
                                  backgroundColor: config.colorTheme,
                                  color: "#ffffff",
                                  minWidth: "10%",
                                  marginLeft: "10%",
                                }}
                              ></Button>
                            </Grid.Column>
                          </>
                        )}
                        {!dataguardian1bystudent_id.imageURL && (
                          <>
                            <Grid.Column style={{ paddingLeft: "40px" }}>
                              <Image
                                width={"25%"}
                                verticalAlign="middle"
                                src={
                                  dataguardian1bystudent_id.imageURL
                                    ? dataguardian1bystudent_id.imageURL +
                                      "?time=" +
                                      new Date()
                                    : "../storage/imgs/dfkid.png"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "../storage/imgs/dfkid.png";
                                }}
                              />
                              <Button
                                content="Image"
                                onClick={() =>
                                  onEditImgGuardian1(dataguardian1bystudent_id)
                                }
                                style={{
                                  backgroundColor: config.colorTheme,
                                  color: "#ffffff",
                                  minWidth: "10%",
                                  marginLeft: "10%",
                                }}
                              ></Button>
                            </Grid.Column>
                            <Grid.Column>
                              <p style={{ color: "red", marginTop: "8%" }}>
                                Please Upload Image
                              </p>
                            </Grid.Column>
                          </>
                        )}
                      </Grid>
                    </Segment>
                    {dataguardian1bystudent_id && (
                      <>
                        <Header as="h4">Guardian 1 : </Header>
                        <Grid divided stackable>
                          <Grid.Row>
                            <Grid.Column>
                              <Grid columns="2">
                                <Grid.Column style={{ paddingLeft: "40px" }}>
                                  Passport or ID No.:
                                  <b> {dataguardian1bystudent_id.idnumber}</b>
                                </Grid.Column>
                                <Grid.Column>
                                  Date Of Birth:
                                  <b>
                                    {" "}
                                    {dataguardian1bystudent_id.dateOfBirth}
                                  </b>
                                </Grid.Column>
                              </Grid>
                              <Grid columns="4">
                                <Grid.Column style={{ paddingLeft: "40px" }}>
                                  firstname:
                                  <b> {dataguardian1bystudent_id.firstName}</b>
                                </Grid.Column>
                                <Grid.Column>
                                  lastname:
                                  <b> {dataguardian1bystudent_id.lastName}</b>
                                </Grid.Column>
                                <Grid.Column>
                                  Nationality:
                                  <b>
                                    {" "}
                                    {dataguardian1bystudent_id.nationality}
                                  </b>
                                </Grid.Column>
                                <Grid.Column>
                                  Native Language:
                                  <b>
                                    {" "}
                                    {dataguardian1bystudent_id.nativelanguage}
                                  </b>
                                </Grid.Column>
                              </Grid>
                              <Grid columns="4">
                                <Grid.Column style={{ paddingLeft: "40px" }}>
                                  ชื่อ:
                                  <b>
                                    {" "}
                                    {dataguardian1bystudent_id.firstName_th}
                                  </b>
                                </Grid.Column>
                                <Grid.Column>
                                  นามสกุล:
                                  <b>
                                    {" "}
                                    {dataguardian1bystudent_id.lastName_th}
                                  </b>
                                </Grid.Column>
                                <Grid.Column>
                                  Mobile:
                                  <b> {dataguardian1bystudent_id.phone}</b>
                                </Grid.Column>
                                <Grid.Column>
                                  Email:
                                  <b> {dataguardian1bystudent_id.email}</b>
                                </Grid.Column>
                              </Grid>
                              <Grid columns="4">
                                <Grid.Column style={{ paddingLeft: "40px" }}>
                                  Place of work:
                                  <b>
                                    {" "}
                                    {dataguardian1bystudent_id.placeOfWork}
                                  </b>
                                </Grid.Column>
                                <Grid.Column>
                                  Position:
                                  <b> {dataguardian1bystudent_id.position}</b>
                                </Grid.Column>
                                <Grid.Column>
                                  Office Tel:
                                  <b>
                                    {" "}
                                    {dataguardian1bystudent_id.officePhone}
                                  </b>
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </>
                    )}
                  </div>
                  <br />
                  <br />
                  <Button
                    content="Edit"
                    onClick={() => onEdit_guardian1(dataguardian1bystudent_id)}
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                    }}
                  ></Button>
                  <hr />
                  <Segment style={{ margin: "40px" }}>
                    <Grid columns="2">
                      {formType == 1 && dataguardian2bystudent_id.imageURL && (
                        <>
                          <Grid.Column style={{ paddingLeft: "40px" }}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                dataguardian2bystudent_id.imageURL
                                  ? dataguardian2bystudent_id.imageURL +
                                    "?time=" +
                                    new Date()
                                  : "../storage/imgs/dfkid.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/dfkid.png";
                              }}
                            />
                            <Button
                              content="Image"
                              onClick={() =>
                                onEditImgGuardian2(dataguardian2bystudent_id)
                              }
                              style={{
                                backgroundColor: config.colorTheme,
                                color: "#ffffff",
                                minWidth: "10%",
                                marginLeft: "10%",
                              }}
                            ></Button>
                          </Grid.Column>
                        </>
                      )}
                      {!dataguardian2bystudent_id.imageURL && (
                        <>
                          <Grid.Column style={{ paddingLeft: "40px" }}>
                            <Image
                              width={"25%"}
                              verticalAlign="middle"
                              src={
                                dataguardian2bystudent_id.imageURL
                                  ? dataguardian2bystudent_id.imageURL +
                                    "?time=" +
                                    new Date()
                                  : "../storage/imgs/dfkid.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "../storage/imgs/dfkid.png";
                              }}
                            />
                            <Button
                              content="Image"
                              onClick={() =>
                                onEditImgGuardian2(dataguardian2bystudent_id)
                              }
                              style={{
                                backgroundColor: config.colorTheme,
                                color: "#ffffff",
                                minWidth: "10%",
                                marginLeft: "10%",
                              }}
                            ></Button>
                          </Grid.Column>
                          <Grid.Column>
                            <p style={{ color: "red", marginTop: "8%" }}>
                              Please Upload Image
                            </p>
                          </Grid.Column>
                        </>
                      )}
                    </Grid>
                  </Segment>
                  {dataguardian2bystudent_id && (
                    <>
                      <Header as="h4">Guardian 2 : </Header>
                      <Grid divided stackable>
                        <Grid.Row>
                          <Grid.Column>
                            <Grid columns="2">
                              <Grid.Column style={{ paddingLeft: "40px" }}>
                                Passport or ID No.:
                                <b> {dataguardian2bystudent_id.idnumber}</b>
                              </Grid.Column>
                              <Grid.Column>
                                Date Of Birth:
                                <b> {dataguardian2bystudent_id.dateOfBirth}</b>
                              </Grid.Column>
                            </Grid>
                            <Grid columns="4">
                              <Grid.Column style={{ paddingLeft: "40px" }}>
                                firstname:
                                <b> {dataguardian2bystudent_id.firstName}</b>
                              </Grid.Column>
                              <Grid.Column>
                                lastname:
                                <b> {dataguardian2bystudent_id.lastName}</b>
                              </Grid.Column>
                              <Grid.Column>
                                Nationality:
                                <b> {dataguardian2bystudent_id.nationality}</b>
                              </Grid.Column>
                              <Grid.Column>
                                Native Language:
                                <b>
                                  {" "}
                                  {dataguardian2bystudent_id.nativelanguage}
                                </b>
                              </Grid.Column>
                            </Grid>
                            <Grid columns="4">
                              <Grid.Column style={{ paddingLeft: "40px" }}>
                                ชื่อ:
                                <b> {dataguardian2bystudent_id.firstName_th}</b>
                              </Grid.Column>
                              <Grid.Column>
                                นามสกุล:
                                <b> {dataguardian2bystudent_id.lastName_th}</b>
                              </Grid.Column>
                              <Grid.Column>
                                Mobile:
                                <b> {dataguardian2bystudent_id.phone}</b>
                              </Grid.Column>
                              <Grid.Column>
                                Email:
                                <b> {dataguardian2bystudent_id.email}</b>
                              </Grid.Column>
                            </Grid>
                            <Grid columns="4">
                              <Grid.Column style={{ paddingLeft: "40px" }}>
                                Place of work:
                                <b> {dataguardian2bystudent_id.placeOfWork}</b>
                              </Grid.Column>
                              <Grid.Column>
                                Position:
                                <b> {dataguardian2bystudent_id.position}</b>
                              </Grid.Column>
                              <Grid.Column>
                                Office Tel:
                                <b> {dataguardian2bystudent_id.officePhone}</b>
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </>
                  )}
                  <br />
                  <br />
                  <Button
                    content="Edit"
                    onClick={() => onEdit_guardian2(dataguardian2bystudent_id)}
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                    }}
                  ></Button>
                  <hr />
                  <Header as="h4">
                    <u>Part IV</u> : Health And Medical
                  </Header>
                  Health information and special medical care:{" "}
                  <b>
                    {immunization_gethealthCheckStudents_health &&
                      immunization_gethealthCheckStudents_health.information_care}
                  </b>
                  <br />
                  <br />
                  <div style={{ paddingLeft: "10px" }}>
                    <Header as="h4">
                      ประวัติการรับวัคซีน (CERTIFICATE of IMMUIZATION)
                    </Header>
                    <Table
                      celled
                      padded
                      style={{
                        borderRadius: 0,
                        fontSize: "95%",
                        width: "95%",
                      }}
                    >
                      <Table.Header
                        style={{
                          boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
                          textAlign: "center",
                        }}
                      >
                        <Table.Row>
                          <Table.HeaderCell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#ddd",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            IMMUIZATION
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#ddd",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "70%",
                            }}
                          >
                            RECECORD COMPLETE DATES OF VACCINE DOSES GIVEN
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{ textAlign: "center" }}>
                          <Table.HeaderCell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#ddd",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          ></Table.HeaderCell>
                          <Table.HeaderCell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#ddd",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          >
                            <Table style={{ textAlign: "center" }}>
                              <Table.HeaderCell
                                style={{
                                  borderRadius: 0,
                                  backgroundColor: "#ddd",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  width: "20%",
                                }}
                              >
                                1
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                style={{
                                  borderRadius: 0,
                                  backgroundColor: "#ddd",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  width: "20%",
                                }}
                              >
                                2
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                style={{
                                  borderRadius: 0,
                                  backgroundColor: "#ddd",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  width: "20%",
                                }}
                              >
                                3
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                style={{
                                  borderRadius: 0,
                                  backgroundColor: "#ddd",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  width: "20%",
                                }}
                              >
                                4
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                style={{
                                  borderRadius: 0,
                                  backgroundColor: "#ddd",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  width: "20%",
                                }}
                              >
                                5
                              </Table.HeaderCell>
                            </Table>
                          </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#eee",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "20%",
                            }}
                          >
                            1.Diphtheria Tetanus Pertussis(DTP)
                          </Table.Cell>
                          <Table.Cell>
                            <Table style={{ textAlign: "center" }}>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.DTP1}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.DTP2}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.DTP3}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.DTP4}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.DTP5}
                              </Table.Cell>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#eee",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "20%",
                            }}
                          >
                            2.Diphtheria Tetanus(Td)
                          </Table.Cell>
                          <Table.Cell>
                            <Table style={{ textAlign: "center" }}>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.Td1}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.Td2}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.Td3}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.Td4}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.Td5}
                              </Table.Cell>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#eee",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "20%",
                            }}
                          >
                            3.Poliomyelitis(OPV)
                          </Table.Cell>
                          <Table.Cell>
                            <Table style={{ textAlign: "center" }}>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.OPV1}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.OPV2}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.OPV3}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.OPV4}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.OPV5}
                              </Table.Cell>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#eee",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "20%",
                            }}
                          >
                            4.Measles Booster
                          </Table.Cell>
                          <Table.Cell>
                            <Table style={{ textAlign: "center" }}>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.Measles_Booster1}
                              </Table.Cell>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.Measles_Booster2}
                              </Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#eee",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "20%",
                            }}
                          >
                            5.Rubella
                          </Table.Cell>
                          <Table.Cell>
                            <Table style={{ textAlign: "center" }}>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.Rubella}
                              </Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#eee",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "20%",
                            }}
                          >
                            6.Mumps
                          </Table.Cell>
                          <Table.Cell>
                            <Table style={{ textAlign: "center" }}>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.vaccineMumps}
                              </Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#eee",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "20%",
                            }}
                          >
                            7.Measles,Mumps,Rubella(MMR)
                          </Table.Cell>
                          <Table.Cell>
                            <Table style={{ textAlign: "center" }}>
                              <Table.Cell style={{ width: "20%" }}>
                                {immunization_gethealthCheckStudents_health &&
                                  immunization_gethealthCheckStudents_health.MMR}
                              </Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                              <Table.Cell
                                style={{
                                  width: "20%",
                                  backgroundColor: "#eee",
                                }}
                              ></Table.Cell>
                            </Table>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                    <u>ข้อมูลเกี่ยวกับสุขภาพ</u>
                    <p>Please check / any that this child has had</p>
                    <Table
                      celled
                      padded
                      style={{
                        borderRadius: 0,
                        fontSize: "90%",
                        width: "80%",
                      }}
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#ddd",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          >
                            Conditions
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#ddd",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              textAlign: "center",
                            }}
                          >
                            Yes/No
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#ddd",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          >
                            Conditions
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#ddd",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              textAlign: "center",
                            }}
                          >
                            Yes/No
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            1.Abnormal spinal curvature (scoliosis, etc.)
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Abnormal_spinal ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Abnormal_spinal ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Abnormal_spinal ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            19.Hepatitis
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Hepatitis ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Hepatitis ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Hepatitis ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            2.Anemia
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Anemia ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Anemia ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Anemia ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            20.Measles (old fashioned or "ten day")
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Measles ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Measles ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Measles ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            3.Asthma or wheezing
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Asthma_or_wheezing ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Asthma_or_wheezing ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Asthma_or_wheezing ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            21.Meninggitis or encephalitis
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Meninggitis ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Meninggitis ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Meninggitis ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            4.Bedwetting at night
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Bedwetting_at_night ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Bedwetting_at_night ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Bedwetting_at_night ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            22.Multiple ear infections (3 or more)
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Multiple_ear ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Multiple_ear ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Multiple_ear ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            5.Behavior problem
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Behavior_problem ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Behavior_problem ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Behavior_problem ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            23.Wetting during day
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Wetting_during_day ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Wetting_during_day ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Wetting_during_day ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            6.Near-drowning or near-suffocation
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Neardrowning ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Neardrowning ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Neardrowning ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            24.Urinary tract infections
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Urinary_tract_infections ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Urinary_tract_infections ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Urinary_tract_infections ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            7.Nervous twitches or tics
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Nervous_twitches ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Nervous_twitches ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Nervous_twitches ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            25.Birth or congenital malformation
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Birth_or_congenital ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Birth_or_congenital ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Birth_or_congenital ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            8.Chronic diarrhea or constipation
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Chronic_diarrhea ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Chronic_diarrhea ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Chronic_diarrhea ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            26.Chicken pox
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Chicken_pox ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Chicken_pox ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Chicken_pox ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            9.Concern about relationship with sibling or friends
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Concern_about_relationship ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Concern_about_relationship ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Concern_about_relationship ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            27.Diabetes
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Diabetes ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Diabetes ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Diabetes ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            10.Cystic fibrosis
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Cystic_fibrosis ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Cystic_fibrosis ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Cystic_fibrosis ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            28.Eczema
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Eczema ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Eczema ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Eczema ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            11.Eye problems, poor vision
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Eye_problems ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Eye_problems ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Eye_problems ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            29.Emotional Problems
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Emotional_Problems ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Emotional_Problems ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Emotional_Problems ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            12.Frequent skin infections
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Frequent_skin ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Frequent_skin ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Frequent_skin ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            30.Frequent headaches
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Frequent_headaches ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Frequent_headaches ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Frequent_headaches ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            13.Heart disease, type
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Heart_disease ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Heart_disease ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Heart_disease ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            31.Frequent sore throat infections
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Frequent_sore_throat ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Frequent_sore_throat ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Frequent_sore_throat ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            14.Poisoning
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Poisoning ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Poisoning ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Poisoning ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            32.Mumps
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Mumps ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Mumps ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Mumps ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            15.Rheumatic fever
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Rheumatic_fever ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Rheumatic_fever ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Rheumatic_fever ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            33.Poor hearing
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Poor_hearing ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Poor_hearing ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Poor_hearing ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            16.Sickle cell disease
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Sickle_cell ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Sickle_cell ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Sickle_cell ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            34.Seizures or epilepsy
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Seizures ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Seizures ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Seizures ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            17.Kidney disease, type
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Kidney_disease ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Kidney_disease ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Kidney_disease ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            35.Stool soiling
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Stool ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Stool ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Stool ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            18.Allergies or hay fever
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Allergies ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Allergies ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Allergies ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "30%",
                            }}
                          >
                            36.Toothaches or dental infections
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              borderRadius: 0,
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              width: "10%",
                              textAlign: "center",
                            }}
                          >
                            {immunization_gethealthCheckStudents_health && (
                              <>
                                {immunization_gethealthCheckStudents_health.Toothaches ==
                                  null && "No"}
                                {immunization_gethealthCheckStudents_health.Toothaches ==
                                  0 && "No"}
                                {immunization_gethealthCheckStudents_health.Toothaches ==
                                  1 && "Yes"}
                              </>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                    <b>What medication are grien frequently/daily</b>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width="4">
                          ข้อมูลการใช้ยาเป็นประจำ :{" "}
                          <b>
                            {immunization_gethealthCheckStudents_health &&
                              immunization_gethealthCheckStudents_health.medication}
                          </b>
                        </Grid.Column>
                        <Grid.Column width="4">
                          Allergy information :{" "}
                          <b>
                            {immunization_gethealthCheckStudents_health &&
                              immunization_gethealthCheckStudents_health.allergy}
                          </b>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width="4">
                          Allergy reaction :{" "}
                          <b>
                            {immunization_gethealthCheckStudents_health &&
                              immunization_gethealthCheckStudents_health.reaction}
                          </b>
                        </Grid.Column>
                        <Grid.Column width="4">
                          Allergy treatment :{" "}
                          <b>
                            {immunization_gethealthCheckStudents_health &&
                              immunization_gethealthCheckStudents_health.treatment}
                          </b>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br></br>
                    <b>ข้อมูลโรงพยาบาลและแพทย์ที่ดูแลเป็นประจำ</b>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width="4">
                          Hospital Name :{" "}
                          <b>
                            {immunization_gethealthCheckStudents_health &&
                              immunization_gethealthCheckStudents_health.hospital_name}
                          </b>
                        </Grid.Column>
                        <Grid.Column width="4">
                          H/N No. :{" "}
                          <b>
                            {immunization_gethealthCheckStudents_health &&
                              immunization_gethealthCheckStudents_health.h_n_no}
                          </b>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width="4">
                          Doctor's name :{" "}
                          <b>
                            {immunization_gethealthCheckStudents_health &&
                              immunization_gethealthCheckStudents_health.doctor_name}
                          </b>
                        </Grid.Column>
                        <Grid.Column width="4">
                          Doctor's Phone :{" "}
                          <b>
                            {immunization_gethealthCheckStudents_health &&
                              immunization_gethealthCheckStudents_health.doctor_phone}
                          </b>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                  <br />
                  <br />
                  <Button
                    content="Edit"
                    onClick={() =>
                      onEdit_health(immunization_gethealthCheckStudents_health)
                    }
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                    }}
                  ></Button>
                  <hr />
                </>
              ) : (
                ""
              )}
            </div>

            {formType == 1 || formType == 2 ? (
              <>
                {/* <Header as="h4">
              <u>Part V : Sumitted Document (Digital File)</u>
            </Header> */}
                <FormDocument
                  loading={loading}
                  initialValues={document.student_id}
                  onClose={() => onCloseImg()}
                  onSubmit={updateDataImg}
                />
              </>
            ) : (
              ""
            )}

            {/* <hr /> */}
            <br></br>
            <br></br>
            {data.approve_registation != "yes" && (
              <ApproveFormRegistation
                onSubmit={updateApprove}
                initialValues={data}
                loading={approveLoading}
              />
            )}
            {data.approve_registation == "yes" && (
              <p style={{ color: "#33CC33", textAlign: "center" }}>
                ทำการ Approve Registration แล้ว
              </p>
            )}
            <br></br>
            <br></br>
          </Segment>
          {/* End capture area */}
          <ModalBasic
            closeIcon={true}
            open={open_student}
            size="large"
            close={() => onClose_student()}
          >
            <FormStudent
              loading={loading}
              initialValues={currentData}
              onClose={() => onClose_student()}
              onSubmit={updateData_student}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={openImg}
            // size="large"
            // close={() => onCloseImg()}
            close={() => setOpenImg(false)}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Form.Input
                    type="file"
                    name="photo"
                    onChange={(e) => setPhoto(e.target.files)}
                  />
                  <br />
                  <br />
                  <Button
                    floated="left"
                    content="Upload"
                    fluid
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                      minWidth: "30%",
                    }}
                    loading={loading}
                    onClick={updateImage}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <br />
            {/* <FormImg
              loading={loading}
              initialValues={currentData}
              onClose={() => onCloseImg()}
              onSubmit={updateDataImg}
            /> */}
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={openImgFather}
            // size="big"
            // close={() => onCloseImgFather()}
            close={() => setOpenImgFather(false)}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Form.Input
                    type="file"
                    name="photo"
                    onChange={(e) => setPhoto(e.target.files)}
                  />
                  <br />
                  <br />
                  <Button
                    floated="left"
                    content="Upload"
                    fluid
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                      minWidth: "30%",
                    }}
                    loading={loading}
                    onClick={updateImageFather}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <br />
            {/* <FormImgParent
              loading={loading}
              initialValues={currentData}
              onClose={() => onCloseImgFather()}
              onSubmit={updateDataImgFather}
            /> */}
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={openImgMother}
            // size="big"
            // close={() => onCloseImgMother()}
            close={() => setOpenImgMother(false)}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  <Form.Input
                    type="file"
                    name="photo"
                    onChange={(e) => setPhoto(e.target.files)}
                  />
                  <br />
                  <br />
                  <Button
                    floated="left"
                    content="Upload"
                    fluid
                    style={{
                      backgroundColor: config.colorTheme,
                      color: "#ffffff",
                      minWidth: "30%",
                    }}
                    loading={loading}
                    onClick={updateImageMother}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <br />
            {/* <FormImgParent
              loading={loading}
              initialValues={currentData}
              onClose={() => onCloseImgMother()}
              onSubmit={updateDataImgMother}
            /> */}
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={openImgGuardian1}
            size="big"
            close={() => onCloseImgGuardian1()}
          >
            <FormImgGuardian
              loading={loading}
              initialValues={currentData}
              onClose={() => onCloseImgGuardian1()}
              onSubmit={updateDataImgGuardian1}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={openImgGuardian2}
            size="big"
            close={() => onCloseImgGuardian2()}
          >
            <FormImgGuardian
              loading={loading}
              initialValues={currentData}
              onClose={() => onCloseImgGuardian2()}
              onSubmit={updateDataImgGuardian2}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={open_father}
            size="large"
            close={() => onClose_father()}
          >
            <FormParent
              loading={loading}
              initialValues={currentData}
              onClose={() => onClose_father()}
              onSubmit={updateData_father}
              formTitle={formTitle}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={open_mother}
            size="large"
            close={() => onClose_mother()}
          >
            <FormParent
              loading={loading}
              initialValues={currentData}
              onClose={() => onClose_mother()}
              onSubmit={updateData_mother}
              formTitle={formTitle}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={open_sibling}
            size="large"
            close={() => onClose_sibling()}
          >
            <FormEditSibling
              loading={loading}
              initialValues={currentData}
              onClose={() => onClose_sibling()}
              onSubmit={updateData_sibling}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={open_emergency}
            size="large"
            close={() => onClose_emergency()}
          >
            <FormEmergency
              loading={loading}
              initialValues={currentData}
              onClose={() => onClose_emergency()}
              onSubmit={updateData_emergency}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={open_guardian1}
            size="large"
            close={() => onClose_guardian1()}
          >
            <FormParent
              loading={loading}
              initialValues={currentData}
              onClose={() => onClose_guardian1()}
              onSubmit={updateData_guardian1}
              formTitle={formTitle}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={open_guardian2}
            size="large"
            close={() => onClose_guardian2()}
          >
            <FormParent
              loading={loading}
              initialValues={currentData}
              onClose={() => onClose_guardian2()}
              onSubmit={updateData_guardian2}
              formTitle={formTitle}
            />
          </ModalBasic>

          <ModalBasic
            closeIcon={true}
            open={open_health}
            size="large"
            close={() => onClose_health()}
          >
            <FormHealth
              loading={loading}
              initialValues={currentData}
              onClose={() => onClose_health()}
              onSubmit={updateData_health}
            />
          </ModalBasic>
        </Segment>
      </div>
    </>
  );
};

RegistationDetail = reduxForm({
  // a unique name for the form
  form: "RegistationDetail",
  enableReinitialize: true,
})(RegistationDetail);

const selector = formValueSelector("class");
RegistationDetail = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(RegistationDetail);

export default withRouter(RegistationDetail);
