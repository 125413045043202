import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
} from "semantic-ui-react";
import {
  getParentProfile,
  updateGuardian,
  updateImageProfile,
  // getAuthenImage,
  // getAuthenImageGaurdian,
} from "./service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import ParentForm from "../Parent/form";
import QRCode from "qrcode.react";

const ParentProfile = (props) => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [Parent, setParent] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [lastpage, setLastpage] = useState(
    props && props.location.state && props.location.state.page
  );
  const [showPopupImage, setShowPopupImage] = useState(false);
  const [photo, setPhoto] = useState(null);

  const [role, setRole] = useState(
    JSON.parse(sessionStorage.getItem("kidz_roles"))
  );
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );
  // const [authimgurl, setImage] = useState(null); //authen image view
  // const [authimgurlGuardian, setImageGuardian] = useState(null); //authen image view for Guardian

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  useEffect(() => {
    if (props.location && !props.location.state) props.history.push("/parent");

    setLoading(true);
    if (Parent) {
      getData();
    }
  }, [Parent]);

  const getData = () => {
    getParentProfile(props.location.state.id).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        // console.log(`getParentProfile`, res.data); //Test

        //Cancel: Move to use inside getParentProfile query --------------------
        // //Todo: get auth child's image
        // let option = [];
        // if (res.data.childs.length > 0) {
        //   const result = res.data.childs.map((e) => {
        //     getAuthenImage(e.student_id).then((res) => {
        //       if (res && res.status === 200) {
        //         e["child"]["s_auth_url"] = res.data;
        //         option.push(e);

        //         // console.log(`Student url response=`, res.data); //Test
        //       } else {
        //         console.log(`Error get auth child's image`, e.student_id);
        //       }
        //     });
        //   });
        // }
        //---------------------------------------------------------------------
      }
      setLoading(false);
    });

    //Cancel: Move to use inside getParentProfile query --------------------
    // //Todo: get auth image view for guardian
    // getAuthenImageGaurdian(props.location.state.id).then((res) => {
    //   if (res && res.status === 200) {
    //     // console.log(res.data); //Test
    //     setImageGuardian(res.data);
    //   }
    // });
    //---------------------------------------------------------------------
  };

  const updateData = (value) => {
    setLoading(true);
    setErrormsg(null);
    updateGuardian(data.id, value).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopup(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  const updateImage = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageProfile(formData, data.id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopupImage(false);
      }
      setLoading(false);
    });
  };

  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">Guardian Profile</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              {lastpage === "parent" ? (
                <Breadcrumb.Section
                  onClick={() => props.history.push("/parent")}
                  style={{ color: "grey" }}
                >
                  Guardian
                </Breadcrumb.Section>
              ) : (
                <Breadcrumb.Section
                  onClick={() => props.history.push("/dashboard")}
                  style={{ color: "grey" }}
                >
                  Dashboard
                </Breadcrumb.Section>
              )}
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Guardian Profile</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column>
            {checkPermissionMenu("Personnel", ["manage-parent"]) && (
              <Button
                basic
                color="green"
                content="Upload Image"
                floated="right"
                style={{ marginBottom: "14px" }}
                onClick={() => setShowPopupImage(true)}
              />
            )}
            {checkPermissionMenu("Personnel", ["manage-parent"]) && (
              <Button
                basic
                color="green"
                content="Edit profile"
                floated="right"
                style={{ marginBottom: "14px" }}
                onClick={() => setShowPopup(true)}
              />
            )}
            {/* <Button basic color='green'
              content='Upload Image' floated='right' style={{ marginBottom: '14px' }}
              onClick={() => setShowPopupImage(true)} />
            <Button basic color='green'
              content='Edit profile' floated='right' style={{ marginBottom: '14px' }}
              onClick={() => setShowPopup(true)} /> */}
          </Grid.Column>
        </Grid>
        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "100vh", padding: "20px" }}
        >
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Wrap>
              <Grid columns="2" doubling>
                <Grid.Column width="4">
                  <Image
                    verticalAlign="middle"
                    src={
                      data && data.imageURL
                        ? data.imageURL + "?time=" + new Date()
                        : "../storage/imgs/dfparent.png"
                    }
                    // src={
                    //   data && data.g_auth_url
                    //     ? data.g_auth_url
                    //     : "../storage/imgs/dfparent.png"
                    // }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/dfparent.png";
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  columns="2"
                  width="12"
                  style={{ minHeight: "30vh" }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h2" style={{ color: config.colorTheme2 }}>
                          <Header.Content>
                            {data.title && data.title}{" "}
                            {data.firstName && data.firstName}&nbsp;&nbsp;
                            {data.lastName && data.lastName}
                            <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.title_th && data.title_th}{" "}
                              {data.firstName_th && data.firstName_th}
                              &nbsp;&nbsp;{data.lastName_th && data.lastName_th}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2">
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Passport or ID No.
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.idnumber}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Nationality
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.nationality}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Native Language
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.nativelanguage}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Mobile Phone
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.phone}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            E-mail
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {" "}
                            {data.email}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Address
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.address}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Date of birth
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.dateOfBirth}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Age
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {moment().diff(
                              moment(data.dateOfBirth, "DD-MM-YYYY"),
                              "years"
                            )}{" "}
                            Year old
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Gender
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.gender}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Occupation
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.occupation}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Place of work
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.placeOfWork}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Position
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.position}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Office Phone
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.officePhone}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Start Date
                          </Grid.Column>
                          <Grid.Column>{data.start_card}</Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            End Date
                          </Grid.Column>
                          <Grid.Column>{data.end_card}</Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
              <br />
              <br />
              <Tab
                menu={{ color: "brown", secondary: true, pointing: true }}
                renderActiveOnly={true}
                panes={[
                  {
                    menuItem: "Childs",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        <Table singleLine>
                          <Table.Body>
                            {data &&
                              data.childs &&
                              data.childs.map((item) => (
                                <Table.Row>
                                  <Table.Cell width="4" textAlign="center">
                                    <Image
                                      spaced
                                      verticalAlign="middle"
                                      style={{
                                        maxHeight: "100px",
                                        width: "auto",
                                      }}
                                      src={
                                        item.child.imageURL
                                          ? item.child.imageURL +
                                            "?time=" +
                                            new Date()
                                          : "../storage/imgs/dfkid.png"
                                      }
                                      // src={
                                      //   item.child.authimgurl
                                      //     ? item.child.authimgurl
                                      //     : "../storage/imgs/dfkid.png"
                                      // }
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "../storage/imgs/dfkid.png";
                                      }}
                                    />
                                  </Table.Cell>
                                  <Table.Cell
                                    verticalAlign="middle"
                                    textAlign="left"
                                  >
                                    <p
                                      style={{
                                        color: config.colorTheme2,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Name
                                    </p>
                                    <p>
                                      {item.child.firstName &&
                                        item.child.firstName}
                                      &nbsp;&nbsp;
                                      {item.child.lastName &&
                                        item.child.lastName}
                                    </p>
                                  </Table.Cell>
                                  <Table.Cell
                                    verticalAlign="middle"
                                    textAlign="left"
                                  >
                                    <p
                                      style={{
                                        color: config.colorTheme2,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Phone no.
                                    </p>
                                    <p>{item.child.phone || "-"}</p>
                                  </Table.Cell>
                                  <Table.Cell
                                    verticalAlign="middle"
                                    textAlign="left"
                                  >
                                    {checkPermissionMenu("Personnel", [
                                      "view-student",
                                    ]) &&
                                      data &&
                                      data.guardians &&
                                      data.guardians.length < 4 && (
                                        <Icon
                                          link
                                          bordered
                                          // color="green"
                                          style={{
                                            color: config.colorTheme,
                                            boxShadow:
                                              "0 0 0 0.1em " +
                                              config.colorTheme +
                                              " inset",
                                          }}
                                          name="user"
                                          onClick={() =>
                                            props.history.push({
                                              pathname: "/student/profile",
                                              state: {
                                                id: item.child.id,
                                                page: "student",
                                              },
                                            })
                                          }
                                        />
                                      )}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                          </Table.Body>
                        </Table>
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "QR Code",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        {data && data.secret_key && (
                          <div>
                            {/* <QRCode
                            id={data.id}
                            value={'?guardian=' + data.secret_key}
                            size={290}
                            level={"H"}
                            includeMargin={true}
                          /> */}
                            {/* <Image
                            centered
                            bordered
                            src={"https://kidz.loxleyorbit.com:8080/api/v1/web/parentcard/" + data.id} /> */}
                            <Image
                              centered
                              bordered
                              src={config.urlAPI + "/parentcard/" + data.id}
                            />
                            <br />
                          </div>
                        )}
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Wrap>
          )}
        </Segment>
      </Segment>
      <ModalBasic
        open={showPopup}
        content="Add Parent"
        close={() => setShowPopup(false)}
      >
        <ParentForm
          onSubmit={updateData}
          onClose={() => setShowPopup(false)}
          loading={loading}
          initialValues={data}
          errormsg={errormsg}
        />
      </ModalBasic>
      <ModalBasic
        closeIcon={true}
        open={showPopupImage}
        content="Profile Image"
        close={() => setShowPopupImage(false)}
      >
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              {data && data.imageURL && (
                <Image
                  size="medium"
                  name="photo"
                  src={data.imageURL}
                  centered
                />
              )}
              {/* {data && data.g_auth_url && (
                <Image
                  size="medium"
                  name="photo"
                  src={data.g_auth_url}
                  centered
                />
              )} */}
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Form.Input
                type="file"
                name="photo"
                onChange={(e) => setPhoto(e.target.files)}
              />
              <br />
              <br />
              <Button
                floated="left"
                content="Upload"
                fluid
                style={{
                  backgroundColor: config.colorTheme,
                  color: "#ffffff",
                  minWidth: "30%",
                }}
                loading={loading}
                onClick={updateImage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </ModalBasic>
    </Wrap>
  );
};

export default withRouter(ParentProfile);
