import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../../config";
import { Button, Header, Icon, Segment, Form } from "semantic-ui-react";
import ModalBasic from "../../components/modal";

import TableList from "../../components/tablePerPage";
import Wrap from "../../components/wrap";
import {
  getUser,
  postUser,
  getRole,
  updateUser,
  deleteUser,
  resetpassUser,
} from "./service";
import Confirmmodal from "../../components/confirmmodal";
import UserRoleForm from "./userRoleForm";
import UserForm from "./userForm";

const UserList = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    class: "",
    course: "",
    status: "Active",
    type: "All",
  });
  const [search, setSearch] = useState({ name: null });
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [errormsg, setErrormsg] = useState(null);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [last_page, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [role, setRole] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmReset, setOpenConfirmReset] = useState(false);

  const [currentData, setCurrentData] = useState(null);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: false,
    },
    {
      Header: "Username",
      accessor: "email",
      sortable: false,
    },
    {
      Header: "Employee type",
      accessor: "employee_type",
      sortable: false,
      style: { textAlign: "center" },
    },
    {
      Header: "Role",
      accessor: "role",
      sortable: false,
      style: { textAlign: "center" },
      Cell: (cellInfo) => cellInfo.original.rolesName.toString(),
    },
    {
      Header: "Phone",
      accessor: "phone",
      sortable: false,
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.isActive === "false" ? (
          <Wrap>
            <Icon
              link
              name="key"
              onClick={() => onClickResetPassword(cellInfo.original)}
            />{" "}
            &nbsp;&nbsp;
            <Icon
              link
              color="green"
              name="user check"
              onClick={() => onClickDelete(cellInfo.original)}
            />
          </Wrap>
        ) : (
          <Wrap>
            <Icon
              link
              name="key"
              onClick={() => onClickResetPassword(cellInfo.original)}
            />{" "}
            &nbsp;&nbsp;
            <Icon
              link
              name="eye slash outline icon"
              onClick={() => onClickEditRole(cellInfo.original)}
            />{" "}
            &nbsp;&nbsp;
            <Icon
              link
              color="red"
              name="user close"
              onClick={() => onClickDelete(cellInfo.original)}
            />
          </Wrap>
        ),
      width: 100,
    },
  ]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, values]);

  useEffect(() => {
    if (search) {
      setLoading(true);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        getData();
      }, 2000);
    }
  }, [search]);
  const getData = () => {
    setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    if (values.type && values.type !== "All")
      qString = qString + "&type=" + values.type;
    if (values.status) {
      if (values.status === "Active") qString = qString + "&status=true";
      else qString = qString + "&status=false";
    }
    if (search.name) qString = qString + "&name=" + search.name;

    getUser(qString).then((res) => {
      if (res && res.status === 200) {
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      // console.log(res.data.data);
      setLoading(false);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };
  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  const addUser = () => {
    setOpen(true);
    getRole().then((res) => {
      let option = [];
      res.data.map((item) => {
        option.push({
          key: item.name,
          text: item.name,
          value: item.name,
        });
      });
      setRole(option);
    });
  };
  const insertUser = (values) => {
    setErrormsg(null);
    setLoading(true);
    postUser(values).then((res) => {
      if (res && res.status === 201) {
        getData();
        setOpen(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };
  const updateRole = (values) => {
    setLoading(true);
    if (currentData && currentData.id)
      updateUser({ id: currentData.id, role: values }).then((res) => {
        if (res && res.status === 200) {
          getData();
          setOpenEdit(false);
        } else {
          setErrormsg(res.data.message);
        }
        setLoading(false);
      });
  };
  const onClickDelete = (values) => {
    setOpenConfirmDelete(true);
    setCurrentData(values);
  };
  const onClickResetPassword = (values) => {
    setOpenConfirmReset(true);
    setCurrentData(values);
  };

  const deleteData = () => {
    // let { currentData } = this.state;
    setLoading(true);
    if (currentData && currentData.id)
      deleteUser(currentData.id).then((res) => {
        if (res && res.status === 200) {
          getData();
          setOpenConfirmDelete(false);
        }
        setLoading(false);
      });
  };

  const onClickEditRole = (values) => {
    setOpenEdit(true);
    getRole().then((res) => {
      let option = [];
      res.data.map((item) => {
        option.push({
          key: item.name,
          text: item.name,
          value: item.name,
        });
      });
      setRole(option);
    });
    setCurrentData(values);
  };
  const resetPassword = () => {
    if (currentData && currentData.id)
      resetpassUser(currentData.id).then((res) => {
        if (res && res.status === 200) {
          getData();
          setOpenConfirmReset(false);
        }
        setLoading(false);
      });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">User</Header>
      <Segment className="contentSegment" textAlign="left">
        <Form>
          <Form.Group>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={handleInputChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status : <b>{values.status}</b>
                </Button>
              }
              options={config.options_active}
              defaultValue={values.status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="type"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Employee type : <b>{values.type}</b>
                </Button>
              }
              options={[
                { key: "a", text: "All", value: "All" },
                { key: "t", text: "Teacher", value: "Teacher" },
                { key: "ta", text: "TA", value: "TA" },
                { key: "n", text: "Nanny", value: "Nanny" },
                { key: "s", text: "Staff", value: "Staff" },
              ]}
              defaultValue={values.type}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Field width="6" />
            <Button
              color="green"
              content="Add User"
              floated="right"
              onClick={addUser}
            />
          </Form.Group>
        </Form>
        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
        />
      </Segment>
      <Confirmmodal
        loading={loading}
        open={openConfirmDelete}
        title="User"
        actionText={values.status === "Active" ? "Inactive" : "Active"}
        onClose={() => setOpenConfirmDelete(false)}
        onComfirm={deleteData}
      />
      <Confirmmodal
        loading={loading}
        open={openConfirmReset}
        actionText="Reset password"
        title="User"
        onClose={() => setOpenConfirmReset(false)}
        onComfirm={resetPassword}
      />

      <ModalBasic open={open} content="User" close={() => setOpen(false)}>
        <UserForm
          onSubmit={insertUser}
          options_roles={role}
          onClose={() => setOpen(false)}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic>
      <ModalBasic
        open={openEdit}
        content="Update Role"
        close={() => setOpenEdit(false)}
      >
        <UserRoleForm
          onSubmit={updateRole}
          options_roles={role}
          onClose={() => setOpenEdit(false)}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic>
    </Segment>
  );
};
export default withRouter(UserList);
