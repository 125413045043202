import React, { Component } from "react";
import { Form, Button, Divider, Message } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import SecondButton from "../../components/secondbutton";
import { connect } from "react-redux";
import * as config from '../../config';

const validate = values => {
  const errors = {};
  if (!values.idnumber) errors.idnumber = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.usertype) errors.usertype = true;
  // if (!values.roles) errors.roles = true;
  // if (values.roles && values.roles.length === 0) errors.roles = true;
  if (!values.phone) errors.phone = true;

  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.username) errors.username = true;
  return errors;
};

class FromUser extends Component {

  render() {
    const state = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      loading,
      options_roles,
      errormsg,
      onClose
    } = this.props;
    return (
      <div>
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Field
              name="role"
              required={true}
              multiple={true}
              component={renderSelectField}
              label="Role"
              options={options_roles}
            />
          </Form.Group>
          {errormsg && (
            <Message negative>
              <p>{errormsg}</p>
            </Message>
          )}

          <br /><br /><br />
          <div>
            <SecondButton floated='right' content='Cancel' color='grey'
              onClick={onClose}
            />
            <Button floated='left' content='Save'
              style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
              loading={loading}
              disabled={pristine || submitting}
            />
          </div>
          <br /><br />
        </Form>
      </div>
    );
  }
}


FromUser = reduxForm({
  // a unique name for the form
  form: "role",
  validate,
  enableReinitialize: true,
})(FromUser);

export default FromUser;