import React from "react";
import { Form, Checkbox } from "semantic-ui-react";
import Wrap from "./wrap";
const renderField = ({
    input,
    label,
    width,
    required,
    readOnly,
    meta: { touched, error, warning }
}) => (
    <Wrap>
        {/* <Form.Group unstackable> */}
        {/* <Form.Field width='4'/> */}
        {/* <Form.Field
      width={width || 12}
      required={required}
      style={{ marginBottom: "0px", textAlign: "left" }}
    > */}
        <Form.Checkbox
            // readOnly={}
            width={width || 12}
            required={required}
            style={{ marginBottom: "0px", textAlign: "left" }}
            readOnly={readOnly}
            label={label}
            defaultChecked={input.value || false}
            checked={input.value}
            onChange={(param, data) => input.onChange(data.checked)}
        />
        {/* </Form.Group> */}
    </Wrap>
);

export default renderField;
