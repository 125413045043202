import React, { useState, useEffect } from "react";

import {
  Button,
  Form,
  Message,
  Icon,
  Grid,
  Table,
  Checkbox,
} from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";

import * as config from "../../config";
import { getstudentByStatus } from "./service";

let GraduateForm = (props) => {
  const { loading, onSubmit, course, onClose, errormsg, onDelete, delLoading } =
    props;
  const [studentRegist, setStudentRegist] = useState([]);
  const [studentSelected, setStudentSelected] = useState([]);

  useEffect(() => {
    getstudentByStatus(course.id, "?status=Pre-register").then((res) => {
      if (res && res.status === 200) {
        setStudentRegist(res.data);
      }
    });
  }, []);

  const handleChange = (e, { id, checked }) => {
    let data = studentRegist;
    data[id]["checked"] = checked;

    const result = [];
    data.map((item) => {
      if (item.checked) result.push(item);
    });
    setStudentRegist(data);
    setStudentSelected(result);
  };

  return (
    <Form>
      {errormsg && (
        <Message negative>
          <Message.Content>{errormsg}</Message.Content>
        </Message>
      )}

      <Table
        celled
        size="small"
        compact
        padded
        style={{ borderRadius: 0, fontSize: "95%" }}
      >
        <Table.Header
          style={{
            boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
            textAlign: "center",
          }}
        >
          <Table.Row>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            ></Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Term
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Start
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              End
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                borderRadius: 0,
                backgroundColor: "#80561B",
                color: "#fff",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Registered date
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {studentRegist.map((item, index) => (
            <Table.Row>
              <Table.Cell textAlign="center">
                <Form.Checkbox
                  id={index}
                  checked={item.checked}
                  onChange={handleChange}
                />
              </Table.Cell>
              <Table.Cell>
                {item.studentID} {item.title} {item.firstName} {item.lastName}
              </Table.Cell>
              <Table.Cell textAlign="center">{item.name}</Table.Cell>
              <Table.Cell textAlign="center">{item.start}</Table.Cell>
              <Table.Cell textAlign="center">{item.end}</Table.Cell>
              <Table.Cell textAlign="center">{item.registdate}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <br />
      <br />
      <br />
      <div>
        <SecondButton
          floated="right"
          content="Cancel"
          color="grey"
          onClick={onClose}
        />
        <Button
          floated="left"
          content={`Approve ${
            studentSelected.length != 0
              ? "( " + studentSelected.length + " )"
              : ""
          }`}
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffffff",
            minWidth: "30%",
          }}
          loading={loading}
          disabled={studentSelected.length === 0}
          onClick={() => onSubmit({ student: studentSelected })}
        />
        <Button
          floated="right"
          content={`Delete ${
            studentSelected.length != 0
              ? "( " + studentSelected.length + " )"
              : ""
          }`}
          style={{
            backgroundColor: "#fc8c14",
            color: "#ffffff",
            minWidth: "30%",
          }}
          loading={delLoading}
          disabled={studentSelected.length === 0}
          onClick={() => onDelete({ student: studentSelected })}
        />
      </div>
      <br />
      <br />
    </Form>
  );
};

export default GraduateForm;
