import React, { Component } from "react";
import { Form, Button, Divider, Message } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import SecondButton from "../../components/secondbutton";
import { connect } from "react-redux";
import * as config from '../../config';

const validate = values => {
  const errors = {};
  if (!values.idnumber) errors.idnumber = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.usertype) errors.usertype = true;
  // if (!values.roles) errors.roles = true;
  // if (values.roles && values.roles.length === 0) errors.roles = true;
  if (!values.phone) errors.phone = true;
  //let regex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})(\.[a-z]{2,8})?$/;
  //if (values.email && !regex.test(values.email)) errors.email = 'Invalid email format';
  if (/@kidz-village.ac.th\s*$/.test(values.email) === false) {
    errors.email = "Invalid email format";
  }
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.username) errors.username = true;
  return errors;
};

class FromUser extends Component {
  state = {
    optionsUserType: [
      { key: "r", text: "Teacher", value: "Teacher" },
      { key: "t", text: "TA", value: "TA" },
      { key: "n", text: "Nanny", value: "Nanny" },
      { key: "u", text: "Staff", value: "Staff" }
    ]
  };

  handleChange = (e) => {
    this.props.dispatch(change("rolesuser", "username", e.target.value))
  }
  render() {
    const state = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      loading,
      options_roles,
      errormsg,
      isEdit,
      onClose
    } = this.props;
    return (
      <div>
        <Form onSubmit={handleSubmit}>
          <Form.Group unstackable>
            <Field
              name="idnumber"
              type="text"
              required={true}
              width="10"
              component={renderField}
              label="Passport or ID No."
            />
          </Form.Group>
          <Form.Group unstackable>
            <Field
              name="title"
              width="4"
              component={renderSelectField}
              label="Title"
              options={config.options_title_eng}
            />
            <Field
              name="firstName"
              type="text"
              required={true}
              width="6"
              component={renderField}
              label="firstName"
            />
            <Field
              name="lastName"
              type="text"
              required={true}
              width="6"
              component={renderField}
              label="lastName"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Field
              name="email"
              type="text"
              required={true}
              component={renderField}
              label="Email"
              onChange={(e => this.handleChange(e))}
            />
            <Field
              name="phone"
              type="text"
              required={true}
              component={renderField}
              label="Phone"
            />
          </Form.Group>
          <Form.Group widths="equal">

            <Field
              name="employee_type"
              required={true}
              component={renderSelectField}
              label="Employee type"
              options={state.optionsUserType}
            />
            <Field
              name="role"
              required={true}
              multiple={true}
              component={renderSelectField}
              label="Role"
              options={options_roles}
            />
            <Field
              name="dateOfBirth"
              required={true}
              component={renderDateOnlyField}
              label="Date of Birth"
            />
          </Form.Group>
          <br />

          <div style={{ textAlign: 'center' }}>
            <Divider horizontal>Default Username & Password</Divider>
            <p><b>Username : </b>{this.props.setUsername}</p>
            <p><b>Password : </b>{this.props.setPassword && this.props.setPassword.replace(/-/g, '')}</p>
          </div>


          {errormsg && (
            <Message negative>
              <p>{errormsg}</p>
            </Message>
          )}

          <br /><br /><br />
          <div>
            <SecondButton floated='right' content='Cancel' color='grey'
              onClick={onClose}
            />
            <Button floated='left' content='Save'
              style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%' }}
              loading={loading}
              disabled={pristine || submitting}
            />
          </div>
          <br /><br />
        </Form>
      </div>
    );
  }
}


FromUser = reduxForm({
  // a unique name for the form
  form: "rolesuser",
  validate,
  enableReinitialize: true,
})(FromUser);

const selector = formValueSelector("rolesuser");
FromUser = connect(state => {
  const setUsername = selector(state, "email");
  const setPassword = selector(state, "dateOfBirth");
  return {
    setUsername: setUsername,
    setPassword: setPassword
  };
})(FromUser);

export default FromUser;
