import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import * as config from '../../config'
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Label,
  Dimmer,
  Loader,
  Grid,
  Breadcrumb
} from 'semantic-ui-react';
import Wrap from "../../components/wrap";
import { getLogLogin, exportLogin } from "./service";
import TableList from "../../components/table";
import {
  DateInput
} from 'semantic-ui-calendar-react';


const Login = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [errormsg, setErrormsg] = useState(null)
  const [loadPopup, setLoadPopup] = useState(null)
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    }, {
      Header: "Name",
      accessor: "name",
      sortable: false,
      width: 200,
    }, {
      Header: "Username",
      accessor: "email",
      sortable: false,
      width: 200,
    }, {
      Header: "Employee Type",
      accessor: "employee_type",
      sortable: false,
      width: 200,
    },
    {
      Header: "Date",
      accessor: "login_at",
      sortable: false,
      width: 200,
    },
    {
      Header: "User-Agent",
      accessor: "user_agent",
      sortable: false,
      width: 200,
    },
    {
      Header: "IP",
      accessor: "ip_address",
      sortable: false,
      width: 200,
    }
  ]);


  useEffect(() => {
    if (date)
      getData();
  }, [date])

  const getData = () => {
    setLoading(true)
    getLogLogin('?date=' + date).then(res => {
      if (res && res.status === 200) {
        setColumn(column)
        setData(res.data)
      }
      setLoading(false)
    })
  }

  const handleChange = (event, { value }) => {
    setDate(value);
  }
  const onClickExport = () => {
    setLoadPopup(true)
    setErrormsg(null)
    exportLogin('?date=' + date).then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false)
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "LoginReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        // this.setState({ link: link.href });
        link.download =
          "LoginReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });

  }

  return (
    <Segment basic textAlign='left'>
      <Header as='h2' >Login Report</Header>

      <Grid columns='2' >
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/report")}
              style={{ color: "grey" }}
            >
              Report
          </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Login Report</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column>
          <Button basic color='green'
            content='Export Excel' floated='right' style={{ marginBottom: '14px' }}
            onClick={onClickExport} />
        </Grid.Column>
      </Grid>
      <Segment className="contentSegment" textAlign="left" style={{ minHeight: '50vh', padding: '20px' }}>
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <DateInput
                name="date"
                placeholder="Date"
                value={date}
                iconPosition="left"
                dateFormat="YYYY-MM-DD"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <TableList
          loading={loading}
          data={data}
          columns={column}
        />

      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() =>
          setLoadPopup(false)
        }
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
                </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                  Waiting Download File
                </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

    </Segment>
  );
}
export default Login;
