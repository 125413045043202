import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderDateTimeField4 from "../../components/renderDateTimeField4.js";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import ModalBasic from "../../components/modal";

const validate = (values) => {
  const errors = {};
  if (!values.idnumber) errors.idnumber = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.nickname) errors.nickname = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.bloodtype) errors.bloodtype = true;
  if (!values.race) errors.race = true;
  if (!values.allergy) errors.allergy = true;
  if (!values.food_allergy) errors.food_allergy = true;
  return errors;
};

let FormStudent = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    onClose,
  } = props;
  //   const [open, setOpen] = useState(false);

  //   const showPopup = () => {
  //     setOpen(true);
  //   };
  //   const popUpClose = () => {
  //     setOpen(false);
  //     window.location.reload();
  //     window.close();
  //   };

  return (
    <Segment basic textAlign="left">
      <Segment>
        <div style={{ paddingLeft: "20px" }}>
          <Header as="h4">
            <u>Part I</u> : Bio Data (ข้อมูลส่วนบุคคล)
          </Header>
          <Header as="h4">Student(นักเรียน)</Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid divided stackable>
              <Grid.Row>
                <Grid.Column>
                  <Field
                    width="5"
                    name="idnumber"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Passport or ID No. (เลขประจำตัวประชาชน)"
                  />
                  <Form.Group unstackable>
                    <Field
                      width="3"
                      name="firstName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="firstname"
                    />
                    <Field
                      width="3"
                      name="lastName"
                      type="text"
                      required={true}
                      component={renderField}
                      label="lastname"
                    />
                    <Field
                      width="3"
                      name="nickname"
                      type="text"
                      required={true}
                      component={renderField}
                      label="Nickname"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      width="3"
                      name="firstName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="ชื่อ"
                    />
                    <Field
                      width="3"
                      name="lastName_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="นามสกุล"
                    />
                    <Field
                      width="3"
                      name="nickname_th"
                      type="text"
                      required={false}
                      component={renderField}
                      label="ชื่อเล่น"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      name="gender"
                      width="2"
                      component={renderSelectField}
                      label="Gender (เพศ)"
                      options={config.options_gender}
                    />
                    <Field
                      width="3"
                      name="dateOfBirth"
                      required={true}
                      component={renderDateOnlyField}
                      label="Date Of Birth (ว/ด/ป เกิด)"
                    />
                    <Field
                      width="2"
                      name="nationality"
                      type="text"
                      required={false}
                      component={renderSelectField}
                      options={config.options_nationality}
                      label="nationality (สัญชาติ)"
                    />
                    <Field
                      width="2"
                      name="nativelanguage"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Native Language (ภาษาประจำชาติ)"
                    />
                  </Form.Group>
                  <Form.Group unstackable>
                    <Field
                      width="3"
                      name="nativelanguageAtHome"
                      type="text"
                      required={false}
                      component={renderField}
                      label="Native Language use at home (ภาษาใช้ที่บ้าน)"
                    />
                    <Field
                      name="bloodtype"
                      width="2"
                      component={renderSelectField}
                      required={true}
                      label="BloodType (หมู่เลือด)"
                      options={[
                        { key: "A", text: "A (เอ)", value: "A" },
                        { key: "B", text: "B (บี)", value: "B" },
                        { key: "AB", text: "AB (เอบี)", value: "AB" },
                        { key: "O", text: "O (โอ)", value: "O" },
                      ]}
                    />
                    <Field
                      width="2"
                      name="race"
                      type="text"
                      required={true}
                      component={renderSelectField}
                      options={config.options_nationality}
                      label="Race (สัญชาติ)"
                    />
                    <Field
                      width="3"
                      name="allergy"
                      type="text"
                      required={true}
                      component={renderField}
                      options={config.options_nationality}
                      label="Medical Condition (โรคประจำตัว)"
                    />
                    <Field
                      width="3"
                      name="food_allergy"
                      type="text"
                      required={true}
                      component={renderField}
                      options={config.options_nationality}
                      label="Food Allergy (แพ้อาหาร)"
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <br />
            {/* <b>Appointment to Interview (นัดหมายสัมภาษณ์)<span style={{color:'red'}}>*</span></b>
                        <Segment>
                        <b>interview date<span style={{color:'red'}}> *</span></b>
                        <b style={{paddingLeft:'45%'}}>interview time<span style={{color:'red'}}> *</span></b> 
                            <Form.Group unstackable>
                                <Field
                                    width="14"
                                    name="interview_date"
                                    required={true}
                                    component={renderDateTimeField4}
                                />
                            </Form.Group>
                        </Segment> */}
            <Button
              floated="right"
              content="Save"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "20%",
                marginRight: "10%",
              }}
              //   onClick={() => showPopup()}
              loading={loading}
              disabled={pristine || submitting}
            />
            <br></br>
            <br></br>
            <br></br>
          </Form>
        </div>
      </Segment>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการบันทึกข้อมูลแล้ว
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </Segment>
  );
};

FormStudent = reduxForm({
  // a unique name for the form
  form: "FormStudent",
  validate,
  enableReinitialize: true,
})(FormStudent);

// const selector = formValueSelector("class");
// FormStudent = connect((state) => {
//   const start_card = selector(state, "start_card");
//   const end_card = selector(state, "end_card");
//   return {
//     start_card: start_card,
//     end_card: end_card,
//   };
// })(FormStudent);

export default withRouter(FormStudent);
