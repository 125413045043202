import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as config from "./../config";
import Alert from "./alert";
import AlertApplication from "./alertapplication";
import {
  Button,
  Form,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  Card,
} from "semantic-ui-react";
import Alertlibrary from "./alertlibrary";

const Mainmenu = (props) => {
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState(
    JSON.parse(sessionStorage.getItem("kidz_roles"))
  );
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Dashboard</Header>
      <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>
        {checkPermissionMenu("Attendance", ["scan-school"]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/schoolattendance")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="qrcode"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                School Attendance
              </Header>
            </Card.Content>
          </Card>
        )}

        {checkPermissionMenu("Report", ["attendance-report"]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/report/attendance")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="list alternate outline"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                School Attendance Report
              </Header>
            </Card.Content>
          </Card>
        )}

        {checkPermissionMenu("Report", ["absent-report"]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/report/absent")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="user x"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Absent Report
              </Header>
            </Card.Content>
          </Card>
        )}

        {checkPermissionMenu("Personnel", [
          "view-student",
          "manage-student",
        ]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/student")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="id card outline"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Student
              </Header>
            </Card.Content>
          </Card>
        )}

        {checkPermissionMenu("Personnel", ["view-parent", "manage-parent"]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/parent")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="user outline"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Guardian
              </Header>
            </Card.Content>
          </Card>
        )}
        {checkPermissionMenu("Attendance", ["scan-qr"]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/scanqr")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="qrcode"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Scan QR Code
              </Header>
            </Card.Content>
          </Card>
        )}
        {/* {checkPermissionMenu("Attendance", [
          "scan-school",
        ]) && <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/qrbook")}
        >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="book"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Library
              </Header>
            </Card.Content>
          </Card>
        } */}
        <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/qrbook")}
        >
          <Card.Content style={{ textAlign: "center" }}>
            <Header as="h5" icon style={{ marginBottom: "0px" }}>
              <Icon
                name="book"
                style={{ color: config.colorTheme, textAlign: "center" }}
              />
              Library
            </Header>
          </Card.Content>
        </Card>

        <Card
          raised
          style={{ background: "#ffffff" }}
          onClick={() => props.history.push("/qrasset")}
        >
          <Card.Content style={{ textAlign: "center" }}>
            <Header as="h5" icon style={{ marginBottom: "0px" }}>
              <Icon
                name="box"
                style={{ color: config.colorTheme, textAlign: "center" }}
              />
              Asset
            </Header>
          </Card.Content>
        </Card>
      </Card.Group>
      <br />

      <Alert />
      <AlertApplication />
      {/* <Alertlibrary /> */}
    </Segment>
  );
};
export default Mainmenu;
