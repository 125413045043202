import React, { useState, useEffect } from "react";
import * as config from "../../config";

import { Header, Icon, Segment, Card } from "semantic-ui-react";

const MainAsset = (props) => {
  const [role, setRole] = useState(
    JSON.parse(sessionStorage.getItem("kidz_roles"))
  );
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };
  return (
    <Segment basic textAlign="left">
      <Header as="h2">Asset</Header>
      <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>
        {checkPermissionMenu("Asset", ["item"]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/asset/items")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="box"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Item
              </Header>
            </Card.Content>
          </Card>
        )}
        {checkPermissionMenu("Asset", ["booking-item"]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/asset/booking_item")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="list alternate"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                Booking Item
              </Header>
            </Card.Content>
          </Card>
        )}
        {checkPermissionMenu("Asset", ["asset-history"]) && (
          <Card
            raised
            style={{ background: "#ffffff" }}
            onClick={() => props.history.push("/asset/history")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon
                  name="history"
                  style={{ color: config.colorTheme, textAlign: "center" }}
                />
                History
              </Header>
            </Card.Content>
          </Card>
        )}
      </Card.Group>
      <br />
    </Segment>
  );
};
export default MainAsset;
