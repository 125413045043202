import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import * as config from '../../config';
import {
  Button,
  Modal,
  Header,
  Icon,
  Image,
  Grid,
  Table,
  Divider
} from 'semantic-ui-react';
import Wrap from "../../components/wrap";

const DetailModal = (props) => {

  const [data, setData] = useState(props.data);
  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      size='small'
      centered={false}
      closeIcon={true}
    >
      <Modal.Content>
        <Image src='../storage/imgs/logo.png' centered style={{ padding: '4px', height: '100%' }} />
        <br /><br />
        <Grid columns='2' doubling>
          <Grid.Column width='4'>
            <Image
              verticalAlign="middle"
              src={
                data && data.imageURL ?
                  data.imageURL + "?time=" + new Date()
                  : '../storage/imgs/dfkid.png'
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  '../storage/imgs/dfkid.png';
              }}
            />

          </Grid.Column>
          <Grid.Column width='12'>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h2" style={{ color: config.colorTheme2 }}>
                    <Header.Content>
                      {data.firstName && data.firstName}&nbsp;&nbsp;{data.lastName && data.lastName}
                      <Header.Subheader style={{ color: config.colorTheme2 }}>
                        {data.firstName_th && data.firstName_th}&nbsp;&nbsp;{data.lastName_th && data.lastName_th}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='2'>
                <Grid.Column columns='2'>
                  <Grid columns='2'>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Child's name (nick name)</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} >{data.nickname}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Gender</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} >{data.gender}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Date of birth</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} >{data.dateOfBirth}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Age</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} >{moment().diff(data.dateOfBirth, 'years')} Year old</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>Emergency contact no.</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} > {data.emergency_phone}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} className='bold'>E-mail</Grid.Column>
                    <Grid.Column style={{ paddingBottom: 0 }} >{data.email}</Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column columns='2'>
                  <Grid columns='2'>
                    <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>Status</Grid.Column>
                    <Grid.Column>{data.status}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>Start Date</Grid.Column>
                    <Grid.Column>{data.start_card}</Grid.Column>
                    <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>End Date</Grid.Column>
                    <Grid.Column>{data.end_card}</Grid.Column>
                    {/* <Grid.Column style={{ paddingBottom: '20px' }} className='bold'>Course</Grid.Column>
                    <Grid.Column>{data.regist_course && data.regist_course.map(item =>
                      <p>{item.course_name} ({item.class_name})<br />
                        <div  >{item.term_name}</div> </p>
                    )}</Grid.Column> */}
                  </Grid>
                </Grid.Column>

              </Grid.Row>
              <Grid.Row>
                <Grid.Column width='4' style={{ paddingBottom: '20px' }} className='bold'>Course</Grid.Column>
                <Grid.Column width='12'>
                  {data.regist_course && data.regist_course.map(item =>
                    <p>{item.course_name} : {item.class_name} : {item.teacher}<br />
                      {item.term_name}
                    </p>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
        <Divider />
        <h3 style={{ color: config.colorTheme2 }}>Health and Medical Records</h3>
        <Grid  >
          <Grid.Row columns='2'>
            <Grid.Column style={{ paddingBottom: '20px' }} className='bold' width='4'>
              Health Conditions
                          </Grid.Column>
            <Grid.Column>{data.health && data.health.healthConditions}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns='2'>
            <Grid.Column style={{ paddingBottom: '20px' }} className='bold' width='4'>
              Allergy information
                          </Grid.Column>
            <Grid.Column>{data.health && data.health.allergy ? data.health.allergy : ''}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns='2'>
            <Grid.Column style={{ paddingBottom: '20px' }} className='bold' width='4'>
              Medical Service
                        </Grid.Column>
            <Grid.Column>
              Hospital : {data.health && data.health.hospital ? data.health.hospital : '-'}<br />
              Name of Doctor : {data.health && data.health.doctor_name ? data.health.doctor_name : '-'}<br />
              Phone : {data.health && data.health.doctor_phone ? data.health.doctor_phone : '-'}<br />
            </Grid.Column>
          </Grid.Row>
          <Grid.Column></Grid.Column>
        </Grid>

        <Divider />
        <h3 style={{ color: config.colorTheme2 }}>Guardian</h3>
        <Table singleLine>
          <Table.Body>
            {data && data.guardians && data.guardians.map(item =>
              <Table.Row>
                <Table.Cell width='4' textAlign='center'>
                  <Image spaced
                    verticalAlign="middle"
                    style={{
                      maxHeight: '100px',
                      width: 'auto'
                    }}
                    src={
                      item.guardian.imageURL ?
                        item.guardian.imageURL + "?time=" + new Date()
                        : '../storage/imgs/dfparent.png'
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        '../storage/imgs/dfparent.png';
                    }}
                  />
                </Table.Cell>
                <Table.Cell verticalAlign='middle' textAlign='left'>
                  <p style={{ color: config.colorTheme2, fontWeight: 'bold' }}>Name</p>
                  <p>{item.guardian.firstName && item.guardian.firstName}&nbsp;&nbsp;{item.guardian.lastName && item.guardian.lastName}</p>
                </Table.Cell>
                <Table.Cell verticalAlign='middle' textAlign='left'>
                  <p style={{ color: config.colorTheme2, fontWeight: 'bold' }}>Relationship</p>
                  <p>{item.relationship}</p>
                </Table.Cell>
                <Table.Cell verticalAlign='middle' textAlign='left'>
                  <p style={{ color: config.colorTheme2, fontWeight: 'bold' }}>Phone no.</p>
                  <p>{item.guardian.phone || '-'}</p>
                </Table.Cell>
              </Table.Row>
            )}

          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>

  )
}

export default DetailModal;
