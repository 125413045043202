import axios from "axios";
import * as config from "../../config";

export function getParentList(qString) {
  return axios
    .get(
      config.urlAPI + "/guardianlist/perpage" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getParentProfile(id) {
  return axios
    .get(config.urlAPI + "/guardian/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postGuardian(data) {
  return axios
    .post(config.urlAPI + "/guardian", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateGuardian(id, data) {
  return axios
    .put(config.urlAPI + "/guardian/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateImageProfile(data, id) {
  return axios
    .post(config.urlAPI + "/guardian/upload/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Todo : Authen image view for student
// export function getAuthenImage(data) {
export function getAuthenImage(id) {
  return (
    axios
      // .post(config.urlAPI + "/viewimage", data, config.headerCallAPI)
      .get(config.urlAPI + "/viewimage/" + id, config.headerCallAPI)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}
//Todo : Authen image view for guardian
export function getAuthenImageGaurdian(id) {
  return axios
    .get(config.urlAPI + "/parentviewimage/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
