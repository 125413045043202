import React, { useState, useEffect } from "react";
import { withRouter, useNavigate, useHistory } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
} from "semantic-ui-react";
import {
  getClassAttendanceByPeriod,
  exportClassAttendByPeriodExcel,
} from "./service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
// import Stock from "./stock";
// import BookHistiry from "./bookhistory";
// import FormBooks from "./formbook";
// import FormImgBook from "./formimgbook.js";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import TableList from "../../components/table";

//========================================================================================
const Clattreportbyperiod = (props) => {
  //   const navigate = useNavigate();
  //   let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [bookProfiles] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState([]);
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  const [period, setPeriod] = useState(null);

  //   const [currentData, setCurrentData] = useState(null);
  //   const [showPopup, setShowPopup] = useState(false);
  //   const [openImgBook, setOpenImgBook] = useState(false);

  //   useEffect(() => {
  //     if (!bookProfiles) props.history.push("/library/bookprofile");

  //     setLoading(true);
  //     if (bookProfiles) {
  //       getData();
  //     }
  //   }, [bookProfiles]);

  //   const onEditProfile = (values) => {
  //     setShowPopup(true);
  //     setCurrentData(values);
  //   };
  //   const onClose = () => {
  //     setShowPopup(false);
  //     setCurrentData(null);
  //   };
  //   const updateData = (values) => {
  //     setLoading(true);
  //     setErrormsg(null);
  //     let data = values;
  //     updateBookProfile(values).then((res) => {
  //       if (res && res.status === 200) {
  //         getData();
  //         setShowPopup(false);
  //       }
  //       setCurrentData(null);
  //       setLoading(false);
  //     });
  //   };

  //   const onEditImgBook = (values) => {
  //     setOpenImgBook(true);
  //     setCurrentData(values);
  //   };
  //   const onCloseImgBook = () => {
  //     setOpenImgBook(false);
  //     setCurrentData(null);
  //   };
  //   const updateDataImgBook = (values) => {
  //     setLoading(true);
  //     let data = values;
  //   };

  const [column, setColumn] = useState([
    {
      Header: "Student",
      accessor: "student_name",
      sortable: false,
    },
    {
      Header: "Date",
      accessor: "checkin_date",
      sortable: false,
      // width: 80,
    },
    {
      Header: "Check-in",
      accessor: "checkin_time",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     {cellInfo.original.cnt_checkin ? cellInfo.original.cnt_checkin : "0"}
      //   </Wrap>
      // ),
    },
    {
      Header: "User/Operation",
      accessor: "checkin_by",
      sortable: false,
    },
    {
      Header: "Attendance",
      accessor: "on_attend",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     {cellInfo.original.cnt_attend ? cellInfo.original.cnt_attend : "0"}
      //   </Wrap>
      // ),
    },
    {
      Header: "Absent",
      accessor: "on_absent",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     {cellInfo.original.cnt_absent ? cellInfo.original.cnt_absent : "0"}
      //   </Wrap>
      // ),
    },
    {
      Header: "Late",
      accessor: "on_late",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     {cellInfo.original.cnt_late ? cellInfo.original.cnt_late : "0"}
      //   </Wrap>
      // ),
    },
    {
      Header: "Leave",
      accessor: "on_leave",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     {cellInfo.original.cnt_leave ? cellInfo.original.cnt_leave : "0"}
      //   </Wrap>
      // ),
    },
    {
      Header: "User/Operation",
      accessor: "checkclass_by",
      sortable: false,
    },
    {
      Header: "Check-out",
      accessor: "checkout_time",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     {cellInfo.original.cnt_checkout
      //       ? cellInfo.original.cnt_checkout
      //       : "0"}
      //   </Wrap>
      // ),
    },
    {
      Header: "Early check-out",
      accessor: "early_checkout",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      // Cell: (cellInfo) => (
      //   <Wrap>
      //     {cellInfo.original.cnt_early_checkout
      //       ? cellInfo.original.cnt_early_checkout
      //       : "0"}
      //   </Wrap>
      // ),
    },
    {
      Header: "User/Operation",
      accessor: "checkout_by",
      sortable: false,
    },

    {
      Header: "After School",
      // accessor: "after_name",
      style: { textAlign: "center" },
      // Cell: (cellInfo) => (cellInfo.original.extended === "true" ? "✓" : "-"),
    },

    // {
    //   Header: "Action",
    //   accessor: "id",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) => (
    //     <Wrap>
    //       <Icon
    //         link
    //         bordered
    //         // color="green"
    //         style={{
    //           color: config.colorTheme,
    //           boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
    //         }}
    //         name="user"
    //         onClick={() =>
    //           props.history.push({
    //             pathname: "/report/classattreportby",
    //             // search: '?query=abc',
    //             state: {
    //               id: cellInfo.original.class_id,
    //               campus: cellInfo.original.campus_name,
    //               course: cellInfo.original.course_name,
    //               term: cellInfo.original.term,
    //               program: cellInfo.original.program_name,
    //               class: cellInfo.original.class_name,
    //               teacher: cellInfo.original.teacher_name,
    //               date: date,
    //               page: "report",
    //             },
    //           })
    //         }
    //       />
    //     </Wrap>
    //   ),
    //   width: 100,
    // },
  ]);

  // useEffect(() => {
  //   if (date) getData();
  // }, [date]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    let qString = "";
    // if (props.location.state.id) {
    qString = "?classid=" + props.location.state.id;
    // }

    getClassAttendanceByPeriod(qString).then((res) => {
      if (res && res.status === 200) {
        console.log(`getClassAttendanceByPeriod`, res);
        setColumn(column);
        setData(res.data);
        if (res.data.length != 0) {
          let periodform = res.data[0].start + " to " + res.data[0].end;
          setPeriod(periodform);
        }
      }
      setLoading(false);
    });
  };

  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };

  const onClickExport = () => {
    setLoadPopup(true);
    setErrormsg(null);

    let qString = "";
    // if (props.location.state.id) {
    qString = "?classid=" + props.location.state.id;
    // }
    if (period) qString = qString + "&period=" + period;

    exportClassAttendByPeriodExcel(qString).then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false);
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "ClassAttendanceByPeriodReport_" +
              moment().format("YYYYMMDDHHmmss") +
              ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        // this.setState({ link: link.href });
        link.download =
          "ClassAttendanceByPeriodReport_" +
          moment().format("YYYYMMDDHHmmss") +
          ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });
  };

  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">Class Attendance</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => props.history.push("/report")}
                style={{ color: "grey" }}
              >
                Report
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section
                onClick={() => props.history.push("/report/classattendance")}
                style={{ color: "grey" }}
              >
                Class Attendance Report
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section
                // onClick={() => props.history.push("/report/classattreportby")}
                onClick={() =>
                  props.history.push({
                    pathname: "/report/classattreportby",
                    // search: '?query=abc',
                    state: {
                      id: props.location.state.id,
                      campus: props.location.state.campus,
                      course: props.location.state.course,
                      term: props.location.state.term,
                      program: props.location.state.program,
                      class: props.location.state.class,
                      teacher: props.location.state.teacher,
                      date: date,
                    },
                  })
                }
                style={{ color: "grey" }}
              >
                Show Report By
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Period</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column>
            <Button
              basic
              color="green"
              content="Export Excel"
              floated="right"
              style={{ marginBottom: "14px" }}
              onClick={onClickExport}
            />
          </Grid.Column>
          {/* <Grid.Column>
            <Button
              basic
              color="green"
              content="By date"
              floated="right"
              style={{ marginBottom: "14px" }}
              //   onClick={() => onEditProfile(data)}
            />
            <Button
              basic
              color="green"
              content="By period"
              floated="right"
              style={{ marginBottom: "14px" }}
              //   onClick={() => onEditImgBook(data)}
            />
          </Grid.Column> */}
        </Grid>
        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "50vh", padding: "20px" }}
        >
          {/* {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : ( */}
          <Wrap>
            <Grid columns="2" doubling>
              {/* <Grid.Column width="4">
                  <Image
                    verticalAlign="middle"
                    src={
                      data && data.imageURL
                        ? data.imageURL + "?time=" + new Date()
                        : "../storage/imgs/book.png"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/book.png";
                    }}
                  />
                </Grid.Column> */}
              <Grid.Column columns="2" width="12" style={{ minHeight: "30vh" }}>
                <Grid>
                  {/* <Grid.Row>
                      <Grid.Column>
                        <Header as="h2" style={{ color: config.colorTheme2 }}>
                          <Header.Content>
                            {data.name_en}
                            <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.name_th}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row> */}
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3" style={{ color: config.colorTheme2 }}>
                        <Header.Content>
                          Show report by: Period
                          {/* <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.name_th}
                            </Header.Subheader> */}
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  {/* <Grid.Row> */}
                  <Grid.Row columns="2">
                    <Grid.Column columns="2">
                      <Grid columns="2">
                        <Grid.Column
                          style={{ paddingBottom: 0 }}
                          className="bold"
                          align="right"
                        >
                          Date :
                        </Grid.Column>
                        <Grid.Column
                          style={{ paddingBottom: 0, paddingRight: 0 }}
                        >
                          {period}
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    {/* <Form>
                        <Form.Group>
                          <Form.Field width={6}>
                            <DatesRangeInput
                              name="date"
                              placeholder="Start - End"
                              // placeholder="Date"
                              dateFormat="YYYY-MM-DD"
                              iconPosition="right"
                              value={date}
                              allowSameEndDate
                              onChange={handleChangeDate}
                              clearable
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form> */}
                    {/* </Grid.Column> */}
                    {/* </Grid.Column> */}
                  </Grid.Row>
                  {/* </Grid.Row> */}
                  <Grid.Row columns="2">
                    <Grid.Column columns="2">
                      <Grid columns="2">
                        <Grid.Column
                          style={{ paddingBottom: 0 }}
                          className="bold"
                          align="right"
                        >
                          {/* Type : */}
                          Campus :
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: 0 }}>
                          {props.location.state.campus}
                          {/* Kidz-Village */}
                        </Grid.Column>
                        <Grid.Column
                          style={{ paddingBottom: 0 }}
                          className="bold"
                          align="right"
                        >
                          Course :
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: 0 }}>
                          {props.location.state.course}
                          {/* Kindergarten */}
                        </Grid.Column>
                        <Grid.Column
                          style={{ paddingBottom: 0 }}
                          className="bold"
                          align="right"
                        >
                          Term :
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: 0 }}>
                          {props.location.state.term}
                          {/* 3/2023 */}
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column columns="2">
                      <Grid columns="2">
                        <Grid.Column
                          style={{ paddingBottom: 0 }}
                          className="bold"
                          align="right"
                        >
                          Program :
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: 0 }}>
                          {props.location.state.program}
                          {/* International */}
                        </Grid.Column>
                        <Grid.Column
                          style={{ paddingBottom: 0 }}
                          className="bold"
                          align="right"
                        >
                          Class :
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: 0 }}>
                          {props.location.state.class}
                          {/* K-1A */}
                        </Grid.Column>
                        <Grid.Column
                          style={{ paddingBottom: 0 }}
                          className="bold"
                          align="right"
                        >
                          Teacher :
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: 0 }}>
                          {props.location.state.teacher}
                          {/* Shannon */}
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="2">
                    <Grid.Column columns="2"></Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid>
            {/* <br /> */}
            {/* <Form>
              <Form.Group>
                <Form.Field width={4}>
                  <DateInput
                    name="date"
                    placeholder="Date"
                    dateFormat="YYYY-MM-DD"
                    iconPosition="left"
                    value={date}
                    onChange={handleChangeDate}
                  />
                </Form.Field>
              </Form.Group>
            </Form> */}
          </Wrap>
          <TableList loading={loading} data={data} columns={column} />
          {/* <TableList loading={loading} columns={column} /> */}

          {/* )} */}
        </Segment>

        {/* <ModalBasic open={showPopup} size="large" close={() => onClose()}>
          <FormBooks
            loading={loading}
            initialValues={currentData}
            onClose={() => onClose()}
            onSubmit={updateData}
          />
        </ModalBasic> */}

        {/* <ModalBasic
          open={openImgBook}
          size="big"
          close={() => onCloseImgBook()}
        >
          <FormImgBook
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseImgBook()}
            onSubmit={updateDataImgBook}
          />
        </ModalBasic> */}
      </Segment>
    </Wrap>
  );
};

export default withRouter(Clattreportbyperiod);
