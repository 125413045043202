import axios from "axios";
import * as config from "../../config";

// // item
// export function getAssetsList(qString) {
//   return axios
//     .get(config.urlAPI + "/report/assets" + qString, config.headerCallAPI)
//     .then((res) => {
//       return res;
//     })
//     .catch((err) => {
//       return err.response;
//     });
// }

// item
export function getAssetsList(qString) {
  return axios
    .get(config.urlAPI + "/assetlist/perpage" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportAssetsList(qString) {
  return axios
    .get(config.urlAPI + "/export/assets" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportAssetHistoryList(qString) {
  return axios
    .get(config.urlAPI + "/export/assetshistory" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportItem(qString) {
  return axios
    .get(config.urlAPI + "/export/item" + qString, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("kidz_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo
export function postAsset(data) {
  return axios
    .post(config.urlAPI + "/asset", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo
export function getAssetProfile(id) {
  return axios
    .get(config.urlAPI + "/asset/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: Edit data
export function updateAsset(data) {
  return axios
    .put(config.urlAPI + "/asset/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: Update manage stock
export function updateAssetStock(data) {
  return axios
    .post(config.urlAPI + "/asset/updatestock", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo
export function updateImageAssetProfile(data, id) {
  return axios
    .post(config.urlAPI + "/asset/upload/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo
export function requestAsset(data) {
  return axios
    .post(config.urlAPI + "/asset/request", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo
//Get Booking list of user
export function getBookingList(user_id) {
  return axios
    .get(config.urlAPI + "/assetbooking" + user_id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Todo
//Delete from Booking list
export function deleteBooking(borrow_id) {
  return axios
    .post(
      config.urlAPI + "/assetbooking/delete",
      borrow_id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: Edit data
export function confirmBooking(data) {
  return axios
    .post(config.urlAPI + "/assetbooking/confirm", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: my asset history
export function getAssetsMyHistory(qString) {
  return axios
    .get(
      config.urlAPI + "/assethistory/perpage" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: my asset history
export function getAssetsAllHistory(qString) {
  return axios
    .get(config.urlAPI + "/assethisall/perpage" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Get asset history detail
export function getAssetHistoryDetail(reqno) {
  return axios
    .get(config.urlAPI + "/assethistory/detail" + reqno, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: update scan borrow data
export function updScanBorrow(data) {
  return axios
    .post(config.urlAPI + "/assetborrow/scan", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: confirm borrow data
export function confirmBorrow(data) {
  return axios
    .post(config.urlAPI + "/assetborrow/confirm" + data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: cancel borrow data
export function cancelBorrow(data) {
  return axios
    .post(config.urlAPI + "/assetborrow/cancel" + data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Todo: update scan return data
export function updScanReturn(data) {
  return axios
    .post(config.urlAPI + "/assetreturn/scan", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Get asset borrow data row
export function getBorrowItem(borrowid) {
  return axios
    .get(config.urlAPI + "/assetreturn/row" + borrowid, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// Get asset borrow history data row
export function getBorrowHistoryItem(borrowid) {
  return axios
    .get(config.urlAPI + "/assethistory/row" + borrowid, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// Todo: confirm borrow data
export function confirmReturn(data) {
  return axios
    .post(config.urlAPI + "/assetreturn/confirm" + data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// insert defect data
export function insertDefect(data) {
  return axios
    .post(config.urlAPI + "/assetreturn/defect", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// update defect image
export function updateDefectImage(data, id) {
  return axios
    .post(
      config.urlAPI + "/assetreturn/upload/" + id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// Get asset history tab
export function getAssetHistoryByItem(id) {
  return axios
    .get(config.urlAPI + "/assethisbyitem/perpage" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// profile
export function getItemProfile(itemlist_id) {
  return axios
    .get(config.urlAPI + "/itemprofile/" + itemlist_id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateItemProfile(data) {
  return axios
    .post(config.urlAPI + "/updatebookprofile", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateStatus(data) {
  return axios
    .post(config.urlAPI + "/updatestatusitem", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
