import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { connect } from "react-redux";

const validate = (values) => {
  const errors = {};

  // if (!values.id) errors.id = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.studenttype) errors.studenttype = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.nickname) errors.nickname = true;

  if (!values.gender) errors.gender = true;
  if (!values.relationship) errors.relationship = true;
  if (!values.phone) errors.phone = true;
  if (!values.allergy) errors.allergy = true;
  if (!values.healthConditions) errors.healthConditions = true;
  if (!values.emergency_phone) errors.emergency_phone = true;
  // if (!values.bloodType) errors.bloodType = true;
  //  if (!values.race) errors.race = true;
  if (!values.start_card) errors.start_card = true;
  if (!values.end_card) errors.end_card = true;
  if (!values.status) errors.status = true;
  return errors;
};

let StudentForm = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    start_card,
    end_card,
  } = props;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid divided columns="2" stackable>
        <Grid.Row>
          <Grid.Column width="10">
            <Form.Group unstackable>
              <Field
                name="idnumber"
                type="text"
                required={true}
                component={renderField}
                placeholder="type here to search"
                label="Passport or ID No."
              />
              <Field
                required={true}
                width="6"
                name="studenttype"
                component={renderSelectField}
                label="Student Type"
                options={config.options_studenttype}
              />
              <Field
                width="6"
                name="studentID"
                component={renderField}
                label="studentID"
              />
            </Form.Group>
            {/* {
              errormsg && <Message negative>
                <Message.Content>{errormsg}</Message.Content>
              </Message>
            } */}

            <Form.Group unstackable>
              <Field
                name="title"
                width="4"
                component={renderSelectField}
                label="Title"
                options={config.options_title_eng}
              />
              <Field
                name="firstName"
                type="text"
                required={true}
                width="6"
                component={renderField}
                label="firstName"
              />
              <Field
                name="lastName"
                type="text"
                required={true}
                width="6"
                component={renderField}
                label="lastName"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="title_th"
                width="4"
                component={renderSelectField}
                label="คำนำหน้า"
                options={config.options_title_th}
              />
              <Field
                name="firstName_th"
                type="text"
                required={false}
                width="6"
                component={renderField}
                label="ชื่อ"
              />
              <Field
                name="lastName_th"
                type="text"
                required={false}
                width="6"
                component={renderField}
                label="นามสกุล"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="nickname"
                type="text"
                required={true}
                component={renderField}
                label="Nickname"
              />
              <Field
                name="dateOfBirth"
                required={true}
                component={renderDateOnlyField}
                label="Date Of Birth"
              />
              <Field
                name="gender"
                component={renderSelectField}
                label="Gender"
                required={true}
                options={config.options_gender}
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="email"
                type="text"
                component={renderField}
                label="E-mail"
              />
            </Form.Group>
            <Form.Group unstackable>
              <Field
                name="nationality"
                type="text"
                required={false}
                component={renderField}
                label="Nationality"
              />
              <Field
                name="nativelanguage"
                type="text"
                required={false}
                component={renderField}
                label="Native Language"
              />
              <Field
                name="bloodType"
                type="text"
                //    required={true}
                component={renderField}
                label="BloodType"
              />
              <Field
                name="race"
                type="text"
                //    required={true}
                component={renderField}
                label="Race"
              />
            </Form.Group>
            <Form.Group>
              <Field
                name="start_card"
                required={true}
                maxDate={end_card}
                width="8"
                label="Start Date"
                component={renderDateOnlyField}
              />
              <Field
                name="end_card"
                minDate={start_card}
                width="8"
                label="End Date"
                required={true}
                component={renderDateOnlyField}
              />
            </Form.Group>

            <Form.Group>
              <Field
                name="status"
                width="8"
                component={renderSelectField}
                label="Status"
                required={true}
                options={config.options_studentstatus}
              />
            </Form.Group>
          </Grid.Column>
          <Grid.Column width="6">
            <Message>
              <Field
                name="healthConditions"
                type="text"
                required={true}
                component={renderField}
                label="Health Conditions"
              />
              <Field
                name="allergy"
                type="text"
                required={true}
                component={renderField}
                label="Allergy information"
              />
              <Field
                name="reaction"
                type="text"
                component={renderField}
                label="Reaction"
              />
              <Field
                name="treatment"
                type="text"
                component={renderField}
                label="Treatment"
              />
              <Field
                name="medication"
                type="text"
                component={renderField}
                label="Medical condition"
              />
              <Field
                name="food_allergy"
                type="text"
                component={renderField}
                label="Food allergy"
              />
            </Message>
            <Message>
              <Field
                name="emergency_name"
                type="text"
                component={renderField}
                label="Emergency person"
              />
              <Form.Group unstackable>
                <Field
                  name="emergency_phone"
                  type="text"
                  required={true}
                  component={renderField}
                  label="Contact no."
                />
                <Field
                  name="emergency_relation"
                  type="text"
                  component={renderField}
                  label="Relationship"
                />
              </Form.Group>
              <Field
                name="emergency_name2"
                type="text"
                component={renderField}
                label="Emergency person (2)"
              />
              <Form.Group unstackable>
                <Field
                  name="emergency_phone2"
                  type="text"
                  component={renderField}
                  label="Contact no."
                />
                <Field
                  name="emergency_relation2"
                  type="text"
                  component={renderField}
                  label="Relationship"
                />
              </Form.Group>
            </Message>
            <Message>
              <Field
                name="hospital_name"
                type="text"
                component={renderField}
                label="Hospital"
              />
              <Field
                name="h_n_no"
                type="text"
                component={renderField}
                label="H/N No."
              />
              <Form.Group unstackable>
                <Field
                  name="doctor_name"
                  type="text"
                  component={renderField}
                  label="Name of Doctor"
                />
                <Field
                  name="doctor_phone"
                  type="text"
                  component={renderField}
                  label="Phone of Doctor"
                />
              </Form.Group>
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <br />
      <br />
      <br />
      {errormsg && (
        <Message negative>
          <Message.Content>{errormsg}</Message.Content>
        </Message>
      )}

      <div>
        <SecondButton
          floated="right"
          content="Cancel"
          color="grey"
          onClick={onClose}
        />
        <Button
          floated="left"
          content="Save"
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffffff",
            minWidth: "30%",
          }}
          loading={loading}
          disabled={pristine || submitting}
        />
      </div>
      <br />
      <br />
    </Form>
  );
};

StudentForm = reduxForm({
  // a unique name for the form
  form: "student",
  validate,
  enableReinitialize: true,
})(StudentForm);

const selector = formValueSelector("class");
StudentForm = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(StudentForm);

export default StudentForm;
