import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Grid,
  Image,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../../components/wrap";
import TableList from "../../../components/tablePerPage";
import ModalBasic from "../../../components/modal";
import HistoryDetailForm from "./../formhistorydetail";
import ItemDefectForm from "./../formdefect";
import { DateInput, DatesRangeInput } from "semantic-ui-calendar-react";
import {
  getAssetsAllHistory,
  exportAssetHistoryList,
  getAssetHistoryDetail,
  getBorrowHistoryItem,
} from "./../service";
import "./../Book.css";

const HistoryAsset = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  ); //Todo
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataReqNo, setDataReqNo] = useState([]);
  // const [mstockdata, setMStockData] = useState([]);
  // const [returnRow, setReturnRow] = useState(null);
  const [defectData, setDefectData] = useState(null);
  const [DefectImageUrl, setDefectImageUrl] = useState(null);
  const [viewOnly, setViewOnly] = useState(true);
  const [loading, setLoading] = useState(true);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [values, setValues] = useState({
    maintype: "",
    type: "",
    category: "",
    borrow_status: "",
    // status: "Active",
    status: "",
  });
  // const [search, setSearch] = useState({ name: "" });
  const [search, setSearch] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [loadMsgPopup, setLoadMsgPopup] = useState(null); //for Manage stock
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  const [showAssetHistoryDetail, setShowAssetHistoryDetail] = useState(false);
  const [showPopupDefect, setShowPopupDefect] = useState(false);
  // const [date, setDate] = useState(
  //   moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  // );
  const [date, setDate] = useState("");

  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Request No.",
      accessor: "request_no",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Request Date",
      accessor: "confirm_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Requester",
      accessor: "fullname",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Date of Use",
      accessor: "date_of_use",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Use for",
      accessor: "use_detail",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Borrowed Date",
      accessor: "borrow_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Due Date",
      accessor: "return_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Date",
      accessor: "return_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.status === "Complete" ? (
          <div className="complete">{cellInfo.original.status} </div>
        ) : cellInfo.original.status === "Borrowing" ? (
          <div className="borrow">{cellInfo.original.status}</div>
        ) : (
          <div className="request">{cellInfo.original.status}</div>
        ),
    },
    {
      Header: "Dispose",
      accessor: "isDispose",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.isDispose === "Yes" ? (
          <div className="dispose">{cellInfo.original.isDispose}</div>
        ) : (
          <div>{cellInfo.original.isDispose}</div>
        ),
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            // onClick={() =>
            //   props.history.push({
            //     pathname: "/asset/myhistory/requestdetail",
            //     state: { id: cellInfo.original.request_no },
            //   })
            // }
            onClick={() => loadHistoryDetail(cellInfo.original.request_no)}
          />
        </Wrap>
      ),
    },
  ]);

  //Todo
  // set colum for bookinglist
  // const [columnbookinglist, setColumnbookinglist] = useState([
  //   {
  //     Header: "No.",
  //     accessor: "",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 35,
  //     Cell: (cellInfo) =>
  //       cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
  //   },
  //   {
  //     Header: "Image",
  //     accessor: "id",
  //     sortable: false,
  //     width: 70,
  //     Cell: (cellInfo) => (
  //       <Image
  //         // size="mini"
  //         width="50"
  //         height="50"
  //         verticalAlign="middle"
  //         src={
  //           cellInfo.original.imageURL
  //             ? cellInfo.original.imageURL + "?time=" + new Date()
  //             : "../../storage/imgs/no-image.jpg"
  //         }
  //         // src={
  //         //   cellInfo.original.authimgurl
  //         //     ? cellInfo.original.authimgurl
  //         //     : "../../storage/imgs/no-image.jpg"
  //         // }
  //         onError={(e) => {
  //           e.target.onerror = null;
  //           e.target.src = "../../storage/imgs/no-image.jpg";
  //         }}
  //       />
  //     ),
  //     style: { textAlign: "center" },
  //   },
  //   {
  //     Header: "Item Code",
  //     accessor: "item_code",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Type",
  //     accessor: "type",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Category",
  //     accessor: "category",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Brand",
  //     accessor: "brand",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Model",
  //     accessor: "model",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [EN]",
  //     accessor: "name_en",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Item Name [TH]",
  //     accessor: "name_th",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Supplier",
  //     accessor: "supplier",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //   },
  //   {
  //     Header: "Quantity",
  //     accessor: "borqty",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 60,
  //   },
  //   {
  //     Header: "Unit",
  //     accessor: "unit",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 50,
  //   },
  //   {
  //     Header: "Defect",
  //     accessor: "defect",
  //     style: { textAlign: "center" },
  //     sortable: false,
  //     width: 55,
  //     Cell: (cellInfo) =>
  //       cellInfo.original.defect === null ? (
  //         <Wrap>
  //           <Icon
  //             link
  //             bordered
  //             style={{
  //               color: "green",
  //               boxShadow: "0 0 0 0.1em " + "green" + " inset",
  //             }}
  //             name="exclamation"
  //             // onClick={() => onItemDefect(cellInfo.original.borrow_id)}
  //           />
  //         </Wrap>
  //       ) : (
  //         <Wrap>
  //           <Icon
  //             link
  //             bordered
  //             style={{
  //               color: "red",
  //               boxShadow: "0 0 0 0.1em " + "red" + " inset",
  //             }}
  //             name="exclamation"
  //             onClick={() => onItemDefect(cellInfo.original.borrow_id)}
  //           />
  //         </Wrap>
  //       ),
  //   },
  //   // {
  //   //   Header: "Remove",
  //   //   accessor: "id",
  //   //   style: { textAlign: "center" },
  //   //   sortable: false,
  //   //   width: 55,
  //   //   Cell: (cellInfo) => (
  //   //     <Wrap>
  //   //       <Icon
  //   //         link
  //   //         bordered
  //   //         style={{
  //   //           color: "red",
  //   //           boxShadow: "0 0 0 0.1em " + "red" + " inset",
  //   //         }}
  //   //         name="trash alternate outline" //bin icon
  //   //         onClick={() =>
  //   //           onDeleteBooking(
  //   //             cellInfo.original.id,
  //   //             cellInfo.original.asset_id,
  //   //             cellInfo.original.qty
  //   //           )
  //   //         }
  //   //         // onClick={() =>
  //   //         //   props.history.push({
  //   //         //     pathname: "/booking/bookprofile",
  //   //         //     state: { id: cellInfo.original.id },
  //   //         //   })
  //   //         // }
  //   //       />
  //   //     </Wrap>
  //   //   ),
  //   // },
  // ]);

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (date && date.length !== 23) return;

    getData();
  }, [date, per_page, current_page, values.status]);

  useEffect(() => {
    if (search) {
      setLoading(true);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        getData();
      }, 2000);
    }
  }, [search]);

  const getData = () => {
    setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;

    // qString = qString + "&uid=" + userData.id;

    if (date) {
      const val = date.split(" - ");
      let start = val[0];
      let end = val[1];
      if (start && end) {
        qString = qString + "&start=" + start + "&end=" + end;
      }
    }
    if (search) {
      if (search.reqno) qString = qString + "&request_no=" + search.reqno;
      // if (search.requester)
      //   qString = qString + "&requester=" + search.requester;
    }
    if (values.status && values.status != "" && values.status != "All") {
      qString = qString + "&status=" + values.status;
    }
    // console.log(`search`, qString, search);

    getAssetsAllHistory(qString).then((res) => {
      if (res && res.status === 200) {
        // console.log(`getAssetsAllHistory`, res);
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
  };

  //Todo
  const loadHistoryDetail = (reqno) => {
    //get history detail
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    qString = qString + "&request_no=" + reqno;

    getAssetHistoryDetail(qString).then((res) => {
      // console.log(`getAssetHistoryDetail`, res); //Test

      if (res && res.status === 200) {
        // setColumnbookinglist(columnbookinglist);
        setDataDetail(res.data.data);
        setDataReqNo(res.data.data[0].request_no);
        setShowAssetHistoryDetail(true);
      }
      // setLoading(false);
    });
  };

  // const onItemDefect = (id) => {
  //   let qString = "?borrow_id=" + id;

  //   console.log(`onItemDefect`, id);

  //   getBorrowHistoryItem(qString).then((res) => {
  //     console.log(`getBorrowHistoryItem`, res);
  //     if (res && res.status === 200) {
  //       // setReturnRow(res.data[0]);
  //       setDefectData(res.data[0]);
  //       setDefectImageUrl(res.data[0].imageURL);

  //       setShowPopupDefect(true);
  //     }
  //     // setLoading(false);
  //   });
  // };

  const onClickExport = () => {
    setLoadPopup(true);
    // let page = current_page + 1;
    // let qString = "?_perpage=" + per_page + "&page=" + page;

    // qString = qString + "&uid=" + userData.id;

    let qString = "?";
    if (date) {
      const val = date.split(" - ");
      let start = val[0];
      let end = val[1];
      if (start && end) {
        qString = qString + "&start=" + start + "&end=" + end;
      }
    }
    if (search) {
      if (search.reqno) qString = qString + "&request_no=" + search.reqno;
      // if (search.requester)
      //   qString = qString + "&requester=" + search.requester;
    }
    if (values.status && values.status != "" && values.status != "All") {
      qString = qString + "&status=" + values.status;
    }
    // console.log(`search`, qString, search);

    exportAssetHistoryList(qString).then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false);
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "AssetHistoryReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        // this.setState({ link: link.href });
        link.download =
          "AssetHistoryReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });
  };

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">History Stock Request</Header>

      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/asset")}
              style={{ color: "grey" }}
            >
              Asset
            </Breadcrumb.Section>
            {/* <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section
              onClick={() => props.history.push("/asset/booking_item")}
              style={{ color: "grey" }}
            >
              Request Stock
            </Breadcrumb.Section> */}
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>
              History Stock Request
            </Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="green"
            content="Export Excel"
            floated="right"
            style={{ marginBottom: "14px" }}
            onClick={onClickExport}
          />
        </Grid.Column>
      </Grid>
      <Segment
        className="contentSegment"
        textAlign="left"
        // style={{ minHeight: "50vh", padding: "20px" }}
      >
        <Form>
          <Form.Group>
            <Form.Field>
              <DatesRangeInput
                name="date"
                placeholder="Start - End"
                dateFormat="YYYY-MM-DD"
                iconPosition="right"
                value={date}
                allowSameEndDate
                onChange={handleChangeDate}
                clearable
                closable
              />
            </Form.Field>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Request No."
              name="reqno"
              onChange={handleInputChange}
            />
            {/* <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Requester"
              name="requester"
              onChange={handleInputChange}
            /> */}

            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status: <b>{values.status}</b>
                </Button>
              }
              options={[
                { key: "all", text: "All", value: "All" },
                ...config.options_asset_status_history,
              ]}
              defaultValue={values.status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Field width="1" />
          </Form.Group>
        </Form>
        <br></br>

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          // onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Waiting Download File
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      {/* Msg for Manage Stock */}
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadMsgPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadMsgPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Updating stock...
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      {/* History detail form */}
      <ModalBasic
        size="large"
        open={showAssetHistoryDetail}
        content="History detail"
        close={() => setShowAssetHistoryDetail(false)}
      >
        <HistoryDetailForm
          //   onSubmit={onBookingConfirm}
          onClose={() => setShowAssetHistoryDetail(false)}
          loading={loading}
          initialValues={dataDetail}
          errormsg={errormsg}
          // data={dataDetail}
          // columnbookinglist={columnbookinglist}
          dataReqNo={dataReqNo}
          viewOnly={viewOnly}
        />
      </ModalBasic>

      {/* Defect form */}
      {/* <ModalBasic
        size="big"
        open={showPopupDefect}
        content="Item Damaged"
        close={() => setShowPopupDefect(false)}
      >
        <ItemDefectForm
          onSubmit={() => setShowPopupDefect(false)}
          onClose={() => setShowPopupDefect(false)}
          loading={loading}
          initialValues={defectData}
          errormsg={errormsg}
          imageURL={DefectImageUrl}
          // data={defectData}
          viewOnly={viewOnly}
        />
      </ModalBasic> */}
    </Segment>
  );
};
export default withRouter(HistoryAsset);
