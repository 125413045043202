import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
  // FormField, //Todo
} from "semantic-ui-react";

const validate = (values) => {
  const errors = {};
  if (!values.item_code) errors.item_code = true;
  if (!values.main_type) errors.main_type = true;
  if (!values.type) errors.type = true;
  if (!values.name_en) errors.name_en = true;
  if (!values.name_th) errors.name_th = true;
  if (!values.category) errors.category = true;
  if (!values.sub_category1) errors.sub_category1 = true;
  if (!values.sub_category2) errors.sub_category2 = true;
  if (!values.initial_stock) errors.initial_stock = true;
  if (!values.borrow_status) errors.borrow_status = true;
  if (!values.status) errors.status = true;
  return errors;
};

let FormItem = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    // start_card,
    // end_card,
    // data,
    // minInitQty,
  } = props;

  // const [open, setOpen] = useState(false);

  // const showPopup = () => {
  //   setOpen(true);
  // };
  // const popUpClose = () => {
  //   setOpen(false);
  //   window.location.reload();
  //   window.close();
  // };

  return (
    <Segment basic textAlign="left">
      <Segment>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column>
                <Form.Group unstackable>
                  <Field
                    width="6"
                    name="item_code"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Item code"
                  />
                  <Field
                    width="6"
                    name="main_type"
                    component={renderSelectField}
                    label="Main Type"
                    required={true}
                    options={config.options_maintype}
                  />
                  <Field
                    width="6"
                    name="type"
                    component={renderSelectField}
                    label="Type"
                    required={true}
                    options={config.options_type}
                  />
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    width="9"
                    name="name_en"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Item name (EN)"
                  />
                  <Field
                    width="9"
                    name="name_th"
                    type="text"
                    required={true}
                    component={renderField}
                    label="Item name (TH)"
                  />
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    width="6"
                    name="category"
                    component={renderSelectField}
                    label="Category"
                    required={true}
                    options={config.options_assetcategory}
                  />
                  <Field
                    width="6"
                    name="sub_category1"
                    component={renderSelectField}
                    label="Sub category1"
                    required={true}
                    options={config.options_asset_subcategory1}
                  />
                  <Field
                    width="6"
                    name="sub_category2"
                    component={renderSelectField}
                    label="Sub category2"
                    required={true}
                    options={config.options_asset_subcategory2}
                  />
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    width="6"
                    name="model"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Model"
                  />
                  <Field
                    width="6"
                    name="brand"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Brand"
                  />
                  <Field
                    width="6"
                    name="unit"
                    type="text"
                    component={renderField}
                    label="Unit"
                    required={false}
                  />
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    width="16"
                    name="description"
                    type="textarea"
                    required={false}
                    component={renderField}
                    label="Detail"
                    row="3"
                  />
                  {/* <Form.Field
                    width="16"
                    name="description"
                    label="Detail"
                    control="textarea"
                    component={renderField}
                    rows="3"
                  /> */}
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    width="6"
                    name="supplier"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Supplier"
                  />
                  <Field
                    width="6"
                    name="price"
                    type="number"
                    required={false}
                    component={renderField}
                    label="Price"
                  />
                  <Field
                    width="6"
                    name="initial_stock"
                    type="number"
                    // min={minInitQty}
                    required={true}
                    component={renderField}
                    label="Initial Qty"
                  />
                </Form.Group>
                <Form.Group unstackable>
                  <Field
                    width="6"
                    name="borrow_status"
                    component={renderSelectField}
                    label="Status stock"
                    required={true}
                    options={config.options_status_stock}
                  />
                  <Field
                    width="6"
                    name="status"
                    component={renderSelectField}
                    label="Status item"
                    required={true}
                    options={config.options_status_item}
                  />
                </Form.Group>
                {/* <Form.Group unstackable>
                  <Field
                    width="6"
                    name="available_quantity"
                    type="text"
                    required={false}
                    component={renderField}
                    label="Available Quantity"
                  />
                </Form.Group> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          {/* <Button
            floated="left"
            content="Save"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "30%",
              marginRight: "10%",
            }}
            onClick={() => showPopup()}
          />

          <SecondButton
            floated="right"
            content="Cancel"
            color="grey"
            onClick={onClose}
          /> */}
          <div>
            <SecondButton
              floated="right"
              content="Cancel"
              color="grey"
              onClick={onClose}
            />
            <Button
              floated="left"
              content="Save"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "30%",
              }}
              loading={loading}
              disabled={pristine || submitting}
            />
          </div>

          <br />
          <br />
          <br />
          <br />
        </Form>
      </Segment>

      {/* <ModalBasic open={open} size="big" close={popUpClose}>
        <Header as="h2" style={{ textAlign: "center" }}>
          ทำการบันทึกข้อมูลแล้ว
        </Header>
        <center>
          <Button
            content="OK"
            style={{
              backgroundColor: config.colorTheme,
              color: "#ffffff",
              minWidth: "5%",
            }}
            onClick={() => popUpClose()}
          />
        </center>
      </ModalBasic> */}
    </Segment>
  );
};

FormItem = reduxForm({
  // a unique name for the form
  form: "editItem",
  validate,
  enableReinitialize: true,
})(FormItem);

// const selector = formValueSelector("class");
// FormItem = connect((state) => {
//   const minInitQty = selector(state, "minInitQty");
//   // const end_card = selector(state, "end_card");
//   return {
//     minInitQty: minInitQty,
//     // end_card: end_card,
//   };
// })(FormItem);

export default FormItem;
