import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../../components/renderField";
import renderSelectField from "../../../components/renderSelectField";
import renderFileField from "../../../components/renderFileField";
import renderDateTimeField from "../../../components/renderDateTimeField";
import renderDateOnlyField from "../../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../../components/secondbutton";
import * as config from '../../../config';
import { connect } from "react-redux";
import {
    Header,
    Image,
    Segment,
    Breadcrumb,
    Loader,
    Dimmer,
    Tab,
    Table,
    Icon,
    Label,
    FormGroup
} from 'semantic-ui-react';
import renderDateField from "../../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import ModalBasic from "../../../components/modal";
import { updatedoctype7 } from "./service";

let FileHealth = (props) => {

    const { loading, pristine, submitting, onSubmit, handleSubmit, initialValue, onClose } = props;
    const [photo, setPhoto] = useState(null);
    const [open, setOpen] = useState(false);


    const popUpClose = () => {
        setOpen(false);
        window.location.reload();
        window.close();

    };

    const updatefile = () => {
        setOpen(true);
        var formData = new FormData();
        if (photo) {
            if (typeof photo === "object")
                formData.append("photo", photo[0]);
            else formData.append("photo", photo);
        }
        updatedoctype7(formData, props.initialValues.student_id).then(res => {
            if (res && res.status === 200) {
            }
        });
    }

    return (

        <Segment basic textAlign='left'>
            <Segment>
                <div style={{ paddingLeft: "20px" }}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Grid divided stackable>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Group unstackable>
                                        {
                                            props.initialValues.docType7 == null &&
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <p style={{ color: "red" }}>ยังไม่ทำการอัพโหลดไฟล์</p>
                                                    <Form.Input
                                                        type="file"
                                                        name="file"
                                                        onChange={(e) => setPhoto(e.target.files)}
                                                    />
                                                    <br></br><br></br>
                                                    <Button floated='left' content='Save Image'
                                                        fluid
                                                        style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '1%' }}
                                                        loading={loading}
                                                        onClick={updatefile}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                        {
                                            props.initialValues.docType7 != null &&
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <a href={props.initialValues.docType7 + "?time=" + new Date()} style={{ paddingRight: '10%' }}>Download: Health Report Signed</a>
                                                    <Form.Input
                                                        type="file"
                                                        name="file"
                                                        onChange={(e) => setPhoto(e.target.files)}
                                                    />
                                                    <br></br><br></br>
                                                    <Button floated='left' content='Save Image'
                                                        fluid
                                                        style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '1%' }}
                                                        loading={loading}
                                                        onClick={updatefile}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            </Segment>

            <ModalBasic open={open} size='big' close={popUpClose}>
                <Header as='h2' style={{textAlign:'center'}}>ทำการบันทึกข้อมูลแล้ว</Header>
                <center><Button content='OK'
                    style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '5%' }}
                    onClick={() => popUpClose()}
                /></center>
            </ModalBasic>

        </Segment>



    );

}




FileHealth = reduxForm({
    // a unique name for the form
    form: "FileHealth",
    FileHealth: true,
})(FileHealth);

const selector = formValueSelector("class");
FileHealth = connect(state => {
    const start_card = selector(state, "start_card");
    const end_card = selector(state, "end_card");
    return {
        start_card: start_card,
        end_card: end_card
    };
})(FileHealth);

export default withRouter(FileHealth);
