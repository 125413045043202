import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
} from "semantic-ui-react";
import { getClassAttendanceDetail } from "./service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
// import Stock from "./stock";
// import BookHistiry from "./bookhistory";
// import FormBooks from "./formbook";
// import FormImgBook from "./formimgbook.js";

const Clattreportby = (props) => {
  const [loading, setLoading] = useState(true);
  const [bookProfiles] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState([]);
  const [errormsg, setErrormsg] = useState(null);
  //   const [currentData, setCurrentData] = useState(null);
  //   const [showPopup, setShowPopup] = useState(false);
  //   const [openImgBook, setOpenImgBook] = useState(false);
  // const prevCount = usePrevious(props.location.state.id);

  const [values, setValues] = useState({
    // type: "Book",
    // type: "All",
    // category: "All",
    // statstock: "All",
    // statitem: "All",
  });

  //   useEffect(() => {
  //     if (!bookProfiles) props.history.push("/library/bookprofile");

  //     setLoading(true);
  //     if (bookProfiles) {
  //       getData();
  //     }
  //   }, [bookProfiles]);

  //   console.log(props);

  //   const getData = () => {
  //     getBookProfile(props.location.state.id).then((res) => {
  //       if (res && res.status === 200) {
  //         setData(res.data);
  //       }
  //       setLoading(false);
  //     });
  //   };
  //   // console.log(data);

  //   const onEditProfile = (values) => {
  //     setShowPopup(true);
  //     setCurrentData(values);
  //   };
  //   const onClose = () => {
  //     setShowPopup(false);
  //     setCurrentData(null);
  //   };
  //   const updateData = (values) => {
  //     setLoading(true);
  //     setErrormsg(null);
  //     let data = values;
  //     updateBookProfile(values).then((res) => {
  //       if (res && res.status === 200) {
  //         getData();
  //         setShowPopup(false);
  //       }
  //       setCurrentData(null);
  //       setLoading(false);
  //     });
  //   };

  //   const onEditImgBook = (values) => {
  //     setOpenImgBook(true);
  //     setCurrentData(values);
  //   };
  //   const onCloseImgBook = () => {
  //     setOpenImgBook(false);
  //     setCurrentData(null);
  //   };
  //   const updateDataImgBook = (values) => {
  //     setLoading(true);
  //     let data = values;
  //   };

  useEffect(() => {
    if (props.location.state.date) {
      getData(values);
    }
  }, [props.location.state.date]);

  const getData = (values) => {
    setLoading(true);

    values["date"] = props.location.state.date;

    getClassAttendanceDetail(values, props.location.state.id).then((res) => {
      if (res && res.status === 200) {
        console.log(`getClassAttendanceDetail`, res);
        // setColumn(column);
        setData(res.data[0]);
      }
      setLoading(false);
    });
  };

  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">Class Attendance</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => props.history.push("/report")}
                style={{ color: "grey" }}
              >
                Report
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section
                onClick={() => props.history.push("/report/classattendance")}
                style={{ color: "grey" }}
              >
                Class Attendance Report
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Show Report By</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          {/* <Grid.Column>
            <Button
              basic
              color="green"
              content="By date"
              floated="right"
              style={{ marginBottom: "14px" }}
              //   onClick={() => onEditProfile(data)}
            />
            <Button
              basic
              color="green"
              content="By period"
              floated="right"
              style={{ marginBottom: "14px" }}
              //   onClick={() => onEditImgBook(data)}
            />
          </Grid.Column> */}
        </Grid>
        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "50vh", padding: "20px" }}
        >
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Wrap>
              <Grid columns="2" doubling>
                {/* <Grid.Column width="4">
                  <Image
                    verticalAlign="middle"
                    src={
                      data && data.imageURL
                        ? data.imageURL + "?time=" + new Date()
                        : "../storage/imgs/book.png"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/book.png";
                    }}
                  />
                </Grid.Column> */}
                <Grid.Column
                  columns="2"
                  width="12"
                  style={{ minHeight: "30vh" }}
                >
                  <Grid>
                    {/* <Grid.Row>
                      <Grid.Column>
                        <Header as="h2" style={{ color: config.colorTheme2 }}>
                          <Header.Content>
                            {data.name_en}
                            <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.name_th}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row> */}
                    <Grid.Row columns="2">
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            {/* Type : */}
                            Campus :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {/* {props.location.state.campus} */}
                            {data.campus_name}
                            {/* Kidz-Village */}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Course :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.course_name}
                            {/* Kindergarten */}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Term :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {/* {props.location.state.term} */}
                            {data.term}
                            {/* 3/2023 */}
                          </Grid.Column>

                          {/* <Grid.Column
                          style={{ paddingBottom: 0 }}
                          className="bold"
                        >
                          Show report by :
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: 0 }}>
                          button
                        </Grid.Column> */}
                        </Grid>
                      </Grid.Column>
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Program :
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0, paddingRight: 5 }}
                          >
                            {/* {props.location.state.program} */}
                            {data.program_name}
                            {/* International */}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Class :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {/* {props.location.state.class} */}
                            {data.class_name}
                            {/* K-1A */}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Teacher :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {/* {props.location.state.teacher} */}
                            {data.teacher_name}
                            {/* Shannon */}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2">
                      <Grid.Column columns="2">
                        <Grid.Column
                          style={{ paddingBottom: 0, paddingTop: 15 }}
                          className="bold"
                        >
                          Show report by :
                        </Grid.Column>
                        <Grid.Column
                          style={{
                            paddingBottom: 0,
                            paddingLeft: 20,
                            paddingTop: 10,
                          }}
                        >
                          {/* Added */}
                          <Grid.Column>
                            <Button
                              basic
                              color="green"
                              content="By date"
                              // floated="right"
                              style={{ marginBottom: "14px", width: "30%" }}
                              onClick={() =>
                                props.history.push({
                                  pathname: "/report/classattreportbydate",
                                  state: {
                                    id: data.class_id,
                                    campus: data.campus_name,
                                    course: data.course_name,
                                    term: data.term,
                                    program: data.program_name,
                                    class: data.class_name,
                                    teacher: data.teacher_name,
                                    // date: props.location.state.date,
                                  },
                                })
                              }
                            />
                            <Button
                              basic
                              color="green"
                              content="By period"
                              // floated="right"
                              style={{ marginBottom: "14px" }}
                              onClick={() =>
                                props.history.push({
                                  pathname: "/report/classattreportbyperiod",
                                  state: {
                                    id: data.class_id,
                                    campus: data.campus_name,
                                    course: data.course_name,
                                    term: data.term,
                                    program: data.program_name,
                                    class: data.class_name,
                                    teacher: data.teacher_name,
                                    // page: "report",
                                  },
                                })
                              }
                            />
                            <Button
                              basic
                              color="green"
                              content="By student"
                              // floated="right"
                              style={{ marginBottom: "14px" }}
                              onClick={() =>
                                props.history.push({
                                  pathname: "/report/classattreportbystudent",
                                  state: {
                                    id: data.class_id,
                                    campus: data.campus_name,
                                    course: data.course_name,
                                    term: data.term,
                                    program: data.program_name,
                                    class: data.class_name,
                                    teacher: data.teacher_name,
                                    // page: "report",
                                  },
                                })
                              }
                            />
                          </Grid.Column>
                        </Grid.Column>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
              {/* <br /> */}
            </Wrap>
          )}
        </Segment>

        {/* <ModalBasic open={showPopup} size="large" close={() => onClose()}>
          <FormBooks
            loading={loading}
            initialValues={currentData}
            onClose={() => onClose()}
            onSubmit={updateData}
          />
        </ModalBasic> */}

        {/* <ModalBasic
          open={openImgBook}
          size="big"
          close={() => onCloseImgBook()}
        >
          <FormImgBook
            loading={loading}
            initialValues={currentData}
            onClose={() => onCloseImgBook()}
            onSubmit={updateDataImgBook}
          />
        </ModalBasic> */}
      </Segment>
    </Wrap>
  );
};

export default withRouter(Clattreportby);
