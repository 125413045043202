import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderSelectField from "../../components/renderSelectField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderCheckboxGroupField from "../../components/renderCheckboxGroupField";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Form, Message, Icon, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { getstudentForRegistCourse } from "./service";
const validate = (values) => {
  const errors = {};
  // if (!values.term) errors.term = true
  if (!values.start) errors.term = true;
  if (!values.end) errors.term = true;
  if (!values.student) errors.student = true;
  if (!values.day) errors.day = true;
  if (values.day && values.dow) {
    let now = 0;
    if (values.dow) {
      Object.keys(values.dow).map((key) => {
        if (values.dow[key]) now++;
      });
      if (now != values.day) {
        errors.day = true;
      }
    }
  }
  return errors;
};

let RegistForm = (props) => {
  const {
    loading,
    pristine,
    submitting,
    handleSubmit,
    onSubmit,
    course,
    onClose,
    term_option,
    errormsg,
    term,
    day,
    dow,
    start,
    end,
  } = props;
  const [student, setStudent] = useState(null);

  useEffect(() => {
    let now = 0;
    if (dow) {
      Object.keys(dow).map((key) => {
        if (dow[key]) now++;
      });
      if (now > day) {
        props.dispatch(change("regist", "dow", null));
      }
    }
  }, [dow]);

  useEffect(() => {
    if (term) {
      props.dispatch(change("regist", "start", term.start));
      props.dispatch(change("regist", "end", term.end));
      props.dispatch(change("regist", "student", null));
      getstudentForRegistCourse(
        "?course=" + course.id + "&term=" + term.id
      ).then((res) => {
        if (res && res.status === 200) {
          let option = [];
          res.data.map((item) => {
            option.push({
              key: item.id,
              text: `${item.firstName} ${item.lastName} `,
              value: item.id,
            });
          });
          setStudent(option);
        }
      });
    }
  }, [term]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {errormsg && (
        <Message negative>
          <Message.Content>{errormsg}</Message.Content>
        </Message>
      )}

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width="6" textAlign="right" className="bold">
            Course
          </Grid.Column>
          <Grid.Column>
            {course && `${course.name} (${course.program_name})`}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width="6" textAlign="right" className="bold">
            Term
          </Grid.Column>
          <Grid.Column>
            <Field
              name="term"
              component={renderSelectField}
              label=""
              disableclear={true}
              options={term_option}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width="6" textAlign="right" className="bold">
            Period
          </Grid.Column>
          <Grid.Column>
            <Form.Group>
              <Field
                name="start"
                minDate={term && term.start}
                maxDate={end}
                width="8"
                label=""
                component={renderDateOnlyField}
              />
              <Field
                name="end"
                minDate={start}
                maxDate={term && term.end}
                width="8"
                label=""
                component={renderDateOnlyField}
              />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width="6" textAlign="right" className="bold">
            Student <span style={{ color: "red" }}>*</span>
          </Grid.Column>
          <Grid.Column>
            <Field
              name="student"
              component={renderSelectField}
              label=""
              multiple
              search
              options={student}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="6" textAlign="right" className="bold">
            Period (Time)
          </Grid.Column>
          <Grid.Column>
            <Field
              style={{ paddingLeft: "34px" }}
              name="time"
              component={renderCheckboxGroupField}
              inline
              label=""
              options={[
                {
                  key: "full",
                  name: "full",
                  value: "full",
                  label: "Full Day ",
                },
                {
                  key: "half",
                  name: "half",
                  value: "half",
                  label: "Half Day ",
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width="6" textAlign="right" className="bold">
            Period (Day) <span style={{ color: "red" }}>*</span>
          </Grid.Column>
          <Grid.Column width="10">
            <Field
              style={{ paddingLeft: "34px" }}
              name="day"
              component={renderCheckboxGroupField}
              inline
              label=""
              options={[
                {
                  key: 1,
                  name: 1,
                  value: 1,
                  label: "1 Days",
                },
                {
                  key: 3,
                  name: 3,
                  value: 3,
                  label: "3 Days",
                },
                {
                  key: 4,
                  name: 4,
                  value: 4,
                  label: "4 Days",
                },
                {
                  key: 5,
                  name: 5,
                  value: 5,
                  label: "5 Days",
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        {day && (
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column width="6" textAlign="right" className="bold">
              Select {day} Days
            </Grid.Column>
            <Grid.Column width="10">
              <Field
                name="dow.sun"
                label={<label>Sunday</label>}
                component={renderCheckboxField}
              />
              <Field
                name="dow.mon"
                label={<label>Monday</label>}
                component={renderCheckboxField}
              />
              <Field
                name="dow.tue"
                label={<label>Tuesday</label>}
                component={renderCheckboxField}
              />
              <Field
                name="dow.wed"
                label={<label>Wednesday</label>}
                component={renderCheckboxField}
              />
              <Field
                name="dow.thu"
                label={<label>Thursday</label>}
                component={renderCheckboxField}
              />{" "}
              <Field
                name="dow.fri"
                label={<label>Friday</label>}
                component={renderCheckboxField}
              />
              <Field
                name="dow.sat"
                label={<label>saturday</label>}
                component={renderCheckboxField}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <br />
      <br />
      <br />
      <div>
        <SecondButton
          floated="right"
          content="Cancel"
          color="grey"
          onClick={onClose}
        />
        <Button
          floated="left"
          content="Save"
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffffff",
            minWidth: "30%",
          }}
          loading={loading}
          disabled={pristine || submitting}
        />
      </div>
      <br />
      <br />
    </Form>
  );
};

RegistForm = reduxForm({
  // a unique name for the form
  form: "regist",
  validate,
  enableReinitialize: true,
})(RegistForm);

const selector = formValueSelector("regist");
RegistForm = connect((state) => {
  const term = selector(state, "term");
  const day = selector(state, "day");
  const dow = selector(state, "dow");
  const start = selector(state, "start");
  const end = selector(state, "end");
  return {
    term: term,
    day: day,
    dow: dow,
    start: start,
    end: end,
  };
})(RegistForm);

export default RegistForm;
