import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Image,
  Segment,
  Breadcrumb,
  Grid,
  Form,
} from "semantic-ui-react";

import moment from "moment";
import TableList from "../../components/tablePerPage";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import { DatesRangeInput } from "semantic-ui-calendar-react";
import TemplateSendURL from "./templatesendurl";
import { getdatavisitrequest } from "../service";
import { set } from "core-js/core/dict";

const Visit = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({ program: "", campus: "", status: "" });
  const [search, setSearch] = useState({ name: null });
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [url, seturl] = useState([]);
  const [Id, setId] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loadPopup, setLoadPopup] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  const [open, setOpen] = useState(false);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Date",
      accessor: "created_at",
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.created_at.split(" ") &&
        cellInfo.original.created_at.split(" ")[0].split("-")[2] +
          "-" +
          cellInfo.original.created_at.split(" ")[0].split("-")[1] +
          "-" +
          cellInfo.original.created_at.split(" ")[0].split("-")[0] +
          " " +
          cellInfo.original.created_at.split(" ")[1],
      width: 130,
    },
    {
      Header: "Visit No.",
      accessor: "Id",
      sortable: false,
      width: 85,
      Cell: (cellInfo) => "V0000" + cellInfo.original.Id,
    },
    {
      Header: "Campus",
      accessor: "campusname",
      style: { textAlign: "left" },
      sortable: false,
    },
    {
      Header: "Program",
      accessor: "programsname",
      sortable: false,
      style: { textAlign: "left" },
    },
    {
      Header: "Student",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.firstName +
        " " +
        cellInfo.original.lastName +
        " (" +
        cellInfo.original.nickname +
        ")",
    },
    {
      Header: "Birthdate",
      accessor: "dateOfBirth",
      sortable: false,
    },
    {
      Header: "Year old",
      accessor: "dateOfBirth",
      style: { textAlign: "center" },
      sortable: false,
      width: 60,
      Cell: (cellInfo) => (
        <Wrap>
          {moment().diff(
            moment(cellInfo.original.dateOfBirth, "DD-MM-YYYY"),
            "years"
          )}
        </Wrap>
      ),
    },
    {
      Header: "Parent",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.parent_firstName +
        " " +
        cellInfo.original.parent_lastName,
    },
    {
      Header: "e-mail",
      accessor: "email",
      sortable: false,
    },
    {
      Header: "Mobile",
      accessor: "mobile",
      sortable: false,
    },
    {
      Header: "Appointment",
      accessor: "dateTimeOfVisit",
      sortable: false,
      width: 100,
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: false,
      width: 60,
    },
    {
      Header: "Action",
      accessor: "Id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            // color="green"
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              props.history.push({
                pathname: "/application/visitprofile",
                state: { Id: cellInfo.original.Id },
              })
            }
          />
        </Wrap>
      ),
      width: 50,
    },
    {
      Header: "URL Application Form",
      accessor: "Id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.status === "Visited" && (
          <Wrap>
            <Icon
              link
              bordered
              style={{
                color: config.colorTheme,
                boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
              }}
              name="linkify"
              onClick={() =>
                // onOpen(config.urlApplicationform + cellInfo.original.Id)
                onOpen(
                  cellInfo.original.Id,
                  cellInfo.original.id_campus,
                  cellInfo.original.id_program
                )
              }
            />
          </Wrap>
        ),
      width: 140,
    },
  ]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [per_page, current_page, sort_page, values]);

  useEffect(() => {
    setLoading(true);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getData();
    }, 2000);
  }, [search]);

  useEffect(() => {
    if (date) getData();
  }, [date]);

  const getData = () => {
    setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    if (values.status) qString = qString + "&status=" + values.status;
    if (values.program && values.program !== "All")
      qString = qString + "&program=" + values.program;
    if (values.campus && values.campus !== "All")
      qString = qString + "&campus=" + values.campus;
    if (search.name) qString = qString + "&name=" + search.name;

    let sort = sort_page;
    if (sort !== null) {
      var sortName = sort[0].id;
      var sortType = sort[0].desc === false ? "asc" : "desc";
      if (sortName === "name") {
        sortName = "firstName";
      }
      qString = qString + "&sortName=" + sortName + "&sortType=" + sortType;
    }

    getdatavisitrequest(qString).then((res) => {
      if (res && res.status === 200) {
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
  };

  const onOpen = (visit_id, id_campus, id_program) => {
    // Verify application form type (3 types)
    if (id_campus == 1 && id_program == 1) {
      //application form type 1
      seturl(config.urlRegisterform + visit_id);
    } else if (id_campus == 2 && id_program == 5) {
      //application form type 2
      seturl(config.urlApplicationform + visit_id);
    } else {
      //application form type 3
      seturl(config.urlApplicationformOther + visit_id);
    }

    setOpen(true);
    // seturl(url);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getData();
    }, 2000);
  };

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Visit Form</Header>
      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/application")}
              style={{ color: "grey" }}
            >
              Application
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Visit Form</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid>

      <Segment className="contentSegment" textAlign="left">
        <Form>
          <Form.Group>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={handleInputChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="campus"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Campus :{" "}
                  <b>
                    {(values.campus &&
                      values.campus == "1" &&
                      "Kidz Village (KV)") ||
                      (values.campus == "2" && "Kidz Learning Centre (KLC)")}
                  </b>
                </Button>
              }
              options={[
                { key: " ", text: "All", value: " " },
                ...config.options_campus,
              ]}
              defaultValue={values.campus && values.campus.name}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="program"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Program :{" "}
                  <b>
                    {(values.program &&
                      values.program == "1" &&
                      "International Program") ||
                      (values.program == "2" && "After School") ||
                      (values.program == "3" && "Special class") ||
                      (values.program == "4" && "Summer Program") ||
                      (values.program == "5" &&
                        "Regular Program (Per Month)") ||
                      (values.program == "6" && "Playgroup") ||
                      (values.program == "7" && "Day care")}
                  </b>
                </Button>
              }
              options={[
                { key: " ", text: "All", value: " " },
                ...config.options_program,
              ]}
              defaultValue={values.program && values.program.name}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
            <Form.Dropdown
              width="4"
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              name="status"
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status : <b>{values.status}</b>
                </Button>
              }
              options={[
                { key: " ", text: "All", value: " " },
                ...config.options_status_visit,
              ]}
              defaultValue={values.status}
              selectOnBlur={false}
              onChange={handleSelectChange}
            />
          </Form.Group>
        </Form>

        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
          onSortedChange={(value) => setSortPage(value)}
        />
      </Segment>

      <ModalBasic open={open} size="big">
        <Grid columns="2">
          <Grid.Column>
            <Header as="h3">URL for send.</Header>
          </Grid.Column>
          <Grid.Column>
            <Header
              as="h3"
              floated="right"
              style={{ cursor: "pointer" }}
              onClick={() => onClose()}
            >
              X
            </Header>
          </Grid.Column>
        </Grid>
        <TemplateSendURL initialValues={url} />
      </ModalBasic>
    </Segment>
  );
};
export default withRouter(Visit);
