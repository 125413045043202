import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../config";
import {
  Header,
  Image,
  Segment,
  Grid,
  Breadcrumb,
  Form,
  Loader,
  Dimmer,
  Tab,
  Table,
  Button,
  Icon,
  Modal,
} from "semantic-ui-react";
import {
  getAssetProfile,
  updateAsset,
  updateImageAssetProfile,
} from "./service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import TableStock from "./stock";
import TableHistoryTab from "./historytab";
import ItemForm from "./formitem";

const ItemProfile = (props) => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("kidzUserData"))
  );
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [asset, setAsset] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [openImgBook, setOpenImgBook] = useState(false);
  const [showPopupProfile, setShowPopupProfile] = useState(false);
  const [minInitQty, setMinInitQty] = useState(0);
  const [curInitQty, setCurInitQty] = useState(0);
  const [loadPopup, setLoadPopup] = useState(null);

  // Upload Image
  const [showPopupImage, setShowPopupImage] = useState(false);
  const [photo, setPhoto] = useState(null);

  // // Complete popup
  // const [openComplete, setOpenComplete] = useState(false);
  // const showCompletePopup = () => {
  //   setOpenComplete(true);
  // };
  // const closeCompletePopup = () => {
  //   setOpenComplete(false);
  //   // window.location.reload();
  //   // window.close();
  // };

  // useEffect(() => {
  //     if (!itemProfiles)
  //         props.history.push('/asset/itemprofile')

  //     setLoading(true);
  //     if (itemProfiles) {
  //         getData();
  //     }
  // }, [itemProfiles]);

  useEffect(() => {
    if (props.location && !props.location.state)
      props.history.push("/asset/items");

    setLoadingPage(true);
    if (asset) {
      getData();
    }
  }, [asset]);

  const getData = () => {
    setLoadingPage(true);
    getAssetProfile(props.location.state.id).then((res) => {
      if (res && res.status === 200) {
        // console.log(`getAssetProfile`, res);
        setData(res.data);

        // data for verify
        let qty =
          res.data.defect_qty + res.data.reject_qty + res.data.borrow_qty;
        setMinInitQty(qty);
        setCurInitQty(res.data.initial_stock);

        // console.log(`getAssetProfile qty`, qty);
      }
      setLoading(false);
    });
  };

  // Todo
  const onEditProfile = () => {
    // setData(data);
    setShowPopupProfile(true);
  };

  // Todo
  const updateProfile = (values) => {
    setErrormsg(null);
    setLoading(true); //save button
    setLoadPopup(true); //warning msg

    //Verify initial stock
    if (values.initial_stock >= minInitQty) {
      //re-cal qty
      if (values.initial_stock != curInitQty) {
        values["qty"] = values.initial_stock - minInitQty;
      }

      values["updated_by"] = userData.id;

      // console.log(`updateValue`, values);

      updateAsset(values).then((res) => {
        if (res && res.status === 200) {
          getData();
          setLoadPopup(false);
          setShowPopupProfile(false);
          // showCompletePopup(); //for show complete text
        } else {
          setErrormsg(res.data.message);
        }
        setLoading(false);
      });
    } else {
      setErrormsg("Wrong initial stock (Min. " + minInitQty + ")");
      setLoading(false);
    }
  };

  // Todo
  const updateImage = () => {
    setLoading(true);
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    updateImageAssetProfile(formData, data.id).then((res) => {
      if (res && res.status === 200) {
        getData();
        setShowPopupImage(false);
      }
      setLoading(false);
    });
  };
  const onClose = () => {
    setShowPopup(false);
    setCurrentData(null);
  };

  // const updateData = (values) => {
  //   setLoading(true);
  //   setErrormsg(null);
  //   let data = values;
  //   updateItemProfile(values).then((res) => {
  //     if (res && res.status === 200) {
  //       getData();
  //       setShowPopup(false);
  //     }
  //     setCurrentData(null);
  //     setLoading(false);
  //   });
  // };

  // const onEditImgBook = (values) => {
  //   setOpenImgBook(true);
  //   setCurrentData(values);
  // };
  // const onCloseImgBook = () => {
  //   setOpenImgBook(false);
  //   setCurrentData(null);
  // };
  // const updateDataImgBook = (values) => {
  //   setLoading(true);
  //   let data = values;
  // };

  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">Items Profile</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => props.history.push("/asset")}
                style={{ color: "grey" }}
              >
                Asset
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section
                onClick={() => props.history.push("/asset/items")}
                style={{ color: "grey" }}
              >
                Items
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>Item Profile</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column>
            <Button
              basic
              color="green"
              content="Edit Item"
              floated="right"
              style={{ marginBottom: "14px" }}
              onClick={onEditProfile}
            />
            <Button
              basic
              color="green"
              content="Upload Image"
              floated="right"
              style={{ marginBottom: "14px" }}
              // onClick={() => onEditImgBook(data)}
              onClick={() => setShowPopupImage(true)}
            />
          </Grid.Column>
        </Grid>

        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "100vh", padding: "20px" }}
        >
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Wrap>
              <Grid columns="2" doubling>
                <Grid.Column width="4">
                  <Image
                    verticalAlign="middle"
                    src={
                      data && data.imageURL
                        ? data.imageURL + "?time=" + new Date()
                        : "../storage/imgs/book.png"
                    }
                    // src={
                    //   data && data.authimgurl
                    //     ? data.authimgurl
                    //     : "../storage/imgs/no-image.jpg"
                    // }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../storage/imgs/no-image.jpg";
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  columns="2"
                  width="12"
                  style={{ minHeight: "30vh" }}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h2" style={{ color: config.colorTheme2 }}>
                          <Header.Content>
                            {data.name_en != null && data.name_en}
                            <Header.Subheader
                              style={{ color: config.colorTheme2 }}
                            >
                              {data.name_th}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="2">
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Type :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.type}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Category :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.category}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Item code :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.item_code}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Brand :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.brand}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Model :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.model}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Detail:{" "}
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.description}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column columns="2">
                        <Grid columns="2">
                          {/* <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Borrowing Period(days) :
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.borrowing_period} days
                          </Grid.Column> */}
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Initial Stock
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.initial_stock}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Available Quantity
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.qty}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Unit
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.unit}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Status Stock
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.borrow_status}
                          </Grid.Column>
                          <Grid.Column
                            style={{ paddingBottom: 0 }}
                            className="bold"
                          >
                            Status Item
                          </Grid.Column>
                          <Grid.Column style={{ paddingBottom: 0 }}>
                            {data.status}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
              <br />
              <br />
              <Tab
                menu={{ color: "brown", secondary: true, pointing: true }}
                renderActiveOnly={true}
                panes={[
                  {
                    menuItem: "Stock",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        <TableStock itemId={data.id} />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: "History",
                    render: () => (
                      <Tab.Pane attached={false} basic>
                        <TableHistoryTab itemId={data.id} />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Wrap>
          )}
        </Segment>

        <ModalBasic
          size="large"
          open={showPopupProfile}
          content="Edit Item"
          close={() => setShowPopupProfile(false)}
        >
          <ItemForm
            onSubmit={updateProfile}
            onClose={() => setShowPopupProfile(false)}
            loading={loading}
            initialValues={data}
            errormsg={errormsg}
            data={data}
          />
        </ModalBasic>
        <ModalBasic
          closeIcon={true}
          open={showPopupImage}
          content="Item Image"
          close={() => setShowPopupImage(false)}
        >
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                {data && data.imageURL && (
                  <Image
                    size="medium"
                    name="photo"
                    src={data.imageURL}
                    centered
                  />
                )}
              </Grid.Column>
              <Grid.Column verticalAlign="middle">
                <Form.Input
                  type="file"
                  name="photo"
                  onChange={(e) => setPhoto(e.target.files)}
                />
                <br />
                <br />
                <Button
                  floated="left"
                  content="Upload"
                  fluid
                  style={{
                    backgroundColor: config.colorTheme,
                    color: "#ffffff",
                    minWidth: "30%",
                  }}
                  loading={loading}
                  onClick={updateImage}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <br />
        </ModalBasic>

        <Modal
          size={errormsg ? "tiny" : "mini"}
          open={loadPopup}
          closeIcon={errormsg ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() => setLoadPopup(false)}
        >
          <Wrap>
            {errormsg ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{errormsg}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Updating...
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>

        {/* <ModalBasic open={openComplete} size="small" close={closeCompletePopup}>
          <Header as="h2" style={{ textAlign: "center" }}>
            บันทึกข้อมูลสำเร็จ
          </Header>
          <center>
            <Button
              content="OK"
              style={{
                backgroundColor: config.colorTheme,
                color: "#ffffff",
                minWidth: "5%",
              }}
              onClick={() => closeCompletePopup()}
            />
          </center>
        </ModalBasic> */}
      </Segment>
    </Wrap>
  );
};

export default withRouter(ItemProfile);
