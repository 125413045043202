import axios from "axios";
import * as config from "../../config";

export function getStudentList(qString) {
  return axios
    .get(config.urlAPI + "/studentlist/perpage" + qString, config.headerCallAPI)
    .then((res) => {
      // console.log(res);
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getStudentProfile(id) {
  return axios
    .get(config.urlAPI + "/student/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postStudent(data) {
  return axios
    .post(config.urlAPI + "/student", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateStudent(data) {
  return axios
    .put(config.urlAPI + "/student/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateImageProfile(data, id) {
  return axios
    .post(config.urlAPI + "/student/upload/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo for test
export function updateQR(data, id) {
  return axios
    .post(config.urlAPI + "/student/qr/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//Todo : Authen image view for student
// export function getAuthenImage(data) {
export function getAuthenImage(id) {
  return (
    axios
      // .post(config.urlAPI + "/viewimage", data, config.headerCallAPI)
      .get(config.urlAPI + "/viewimage/" + id, config.headerCallAPI)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}
//Todo : Authen image view for guardian
export function getAuthenImageGaurdian(id) {
  return axios
    .get(config.urlAPI + "/parentviewimage/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postGuardian(data) {
  return axios
    .post(config.urlAPI + "/guardian", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateGuardian(data) {
  return axios
    .post(config.urlAPI + "/relationship", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getParent(qString) {
  return axios
    .get(config.urlAPI + "/guardian" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function removeGuardian(studentId, guardianId) {
  return axios
    .get(
      config.urlAPI + "/removeGuardian/" + studentId + "/" + guardianId,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function ImportStudent(data) {
  return axios
    .post(config.urlAPI + "/student/import", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function ImportStudentConfirm(data) {
  return axios
    .post(config.urlAPI + "/student/confirm", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSchoolAttendance(id) {
  return axios
    .get(config.urlAPI + "/schoolAttendance/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getClassAttendance(id) {
  return axios
    .get(config.urlAPI + "/class/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getimmunizationbystudent_id(id) {
  return axios
    .get(config.urlAPI + "/getimmunizationbystudent_id/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function gethealthCheck_studentsbystudent_id(id) {
  return axios
    .get(config.urlAPI + "/gethealthCheck_studentsbystudent_id/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
