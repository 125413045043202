import React, { useState, useEffect } from "react";
import {
  Button,
  Header,
  Icon,
  Segment,
  Form,
  Grid,
  Dimmer,
  Loader,
  Table,
  List,
  Checkbox,
  Label,
  Dropdown,
  Image,
  TableBody,
} from "semantic-ui-react";
import * as config from "../../config";
import moment from "moment";
import { getAssetHistoryByItem, getBorrowHistoryItem } from "./service";
import Wrap from "../../components/wrap";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import ModalBasic from "../../components/modal";
import ItemDefectForm from "./formdefect";

function exampleReducer(state, action) {
  switch (action.type) {
    case "CHANGE_DIMMED":
      return { ...state, dimmed: action.dimmed };
    default:
      throw new Error();
  }
}

let HistoryTabTable = (props) => {
  //   const [loadingPage, setLoadingPage] = useState(true);
  const [item, setItem] = useState(props.itemId);
  const [data, setData] = useState([]);
  const [DefectImageUrl, setDefectImageUrl] = useState(null);
  const [defectData, setDefectData] = useState(null);
  const [showPopupDefect, setShowPopupDefect] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const [errormsg, setErrormsg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [per_page, setPerPage] = useState(5);
  const [current_page, setCurrentPage] = useState(0);
  const [sort_page, setSortPage] = useState(null);
  const [last_page, setLastPage] = useState(1);
  const [currentData, setCurrentData] = useState(null);
  const [showPopupDefective, setShowPopupDefective] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showdamage, setShowdamage] = useState(true);
  //toggle button
  const { dimmed } = useState(false);
  const [state, dispatch] = React.useReducer(exampleReducer, {
    dimmed: false,
  });

  const [column, setColumn] = useState([
    {
      Header: "Request No.",
      accessor: "request_no",
      style: { textAlign: "center" },
      sortable: false,
      // Cell: (cellInfo) => cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
    },
    {
      Header: "Borrowed date",
      accessor: "borrow_date",
      sortable: false,
      style: { textAlign: "center" },
    },
    {
      Header: "Borrower",
      accessor: "fullname",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Due",
      accessor: "return_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Date",
      accessor: "return_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Defective",
      accessor: "defect_id",
      style: { textAlign: "center" },
      sortable: false,
      // width: 55,
      Cell: (cellInfo) =>
        cellInfo.original.defect_id === null ? (
          ""
        ) : (
          <Wrap>
            <Icon
              link
              bordered
              style={{
                color: "red",
                boxShadow: "0 0 0 0.1em " + "red" + " inset",
              }}
              name="exclamation"
              onClick={() => onItemDefect(cellInfo.original.borrow_id)}
            />
          </Wrap>
        ),
    },
  ]);

  useEffect(() => {
    setLoading(true);
    if (item) {
      getData();
    }
  }, [per_page, current_page, item]);

  const getData = () => {
    // setLoadingPage(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    qString = qString + "&asset_id=" + item;

    getAssetHistoryByItem(qString).then((res) => {
      if (res && res.status === 200) {
        console.log(`getAssetHistoryByItem`, res);
        // setColumn(column);
        // setData(res.data);
        setPerPage(per_page);
        setColumn(column);
        setData(res.data.data);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
  };

  // Get defect detail
  const onItemDefect = (id) => {
    let qString = "?borrow_id=" + id;

    console.log(`onItemDefect`, id);

    getBorrowHistoryItem(qString).then((res) => {
      console.log(`getBorrowHistoryItem`, res);
      if (res && res.status === 200) {
        // setDefectData(res.data[0]);
        // setDefectImageUrl(res.data[0].imageURL);
        // // setDefectImageUrl(res.data[0].authimgurl);   //cannot sent to formdefect.js (undefine)
        setDefectData(res.data);
        setDefectImageUrl(res.data.imageURL);

        setShowPopupDefect(true);
      }
      // setLoading(false);
    });
  };

  return (
    <Segment basic textAlign="left">
      <TableList
        loading={loading}
        data={data}
        columns={column}
        pageSize={per_page}
        page={current_page}
        pages={last_page}
        onPageChange={(page) => setCurrentPage(page)}
        onPageSizeChange={(value) => setPerPage(value)}
        // onSortedChange={(value) => setSortPage(value)}
      />

      {/* Defect form */}
      <ModalBasic
        size="big"
        open={showPopupDefect}
        content="Item Damaged"
        close={() => setShowPopupDefect(false)}
      >
        <ItemDefectForm
          onSubmit={() => setShowPopupDefect(false)}
          onClose={() => setShowPopupDefect(false)}
          loading={loading}
          initialValues={defectData}
          errormsg={errormsg}
          imageURL={DefectImageUrl}
          // data={defectData}
          viewOnly={viewOnly}
        />
      </ModalBasic>
    </Segment>
  );
};

export default HistoryTabTable;
