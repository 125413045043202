import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import * as config from "../../config";
import {
  Button,
  Header,
  Icon,
  Container,
  Segment,
  Form,
  Table,
} from "semantic-ui-react";
import ModalBasic from "../../components/modal";
import TableList from "../../components/table";
import Headerwithmenu from "../../components/headerwithmenu";
import Confirmmodal from "../../components/confirmmodal";

import Wrap from "../../components/wrap";
import CourseForm from "./form";

import { getCourseList, postCourse, getProgram, getCampus } from "./service";

const CourseList = (props) => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    class: "",
    course: "",
    status: "Active",
  });
  const [search, setSearch] = useState({ name: null });
  const [open, setOpen] = useState(false);
  // const [openConfirmExpired, setOpenConfirmExpired] = useState(false);
  const [errormsg, setErrormsg] = useState(null);
  const [campus, setCampus] = useState(null);
  const [program, setProgram] = useState(null);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([
    {
      Header: "Campus",
      accessor: "campus_name",
      sortable: false,
    },
    {
      Header: "Program",
      accessor: "program_name",
      sortable: false,
    },
    {
      Header: "Sub Program",
      accessor: "name",
      sortable: false,
    },
    {
      Header: "Age",
      accessor: "desc",
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.desc && (
          <div dangerouslySetInnerHTML={{ __html: cellInfo.original.desc }} />
        ),
    },
    {
      Header: "Period",
      accessor: "period",
      sortable: false,
      Cell: (cellInfo) => (
        <div>
          {cellInfo.original.period && (
            <div
              dangerouslySetInnerHTML={{ __html: cellInfo.original.period }}
            />
          )}
        </div>
      ),
    },
    {
      Header: "Remark",
      accessor: "remark",
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.remark && (
          <div dangerouslySetInnerHTML={{ __html: cellInfo.original.remark }} />
        ),
    },
    {
      Header: "Food: Lunch/Snack ",
      accessor: "food",
      sortable: false,
      style: { textAlign: "center" },
      Cell: (cellInfo) =>
        cellInfo.original.food === "false" ||
        cellInfo.original.food === null ? (
          <div>
            <Icon name="x" color="red" /> Lunch/Snack
          </div>
        ) : (
          <div>
            <Icon name="check circle" color="green" /> {cellInfo.original.food}
          </div>
        ),
      width: 100,
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            // color="green"
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="book"
            onClick={() =>
              props.history.push({
                pathname: "/course/profile",
                // search: '?query=abc',
                state: { id: cellInfo.original.id, page: "course" },
              })
            }
          />
        </Wrap>
      ),
      width: 100,
    },
  ]);
  const [role, setRole] = useState(
    JSON.parse(sessionStorage.getItem("kidz_roles"))
  );
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getData();
    }, 2000);
  }, [search]);

  const getData = () => {
    let qString = "";
    if (values.status) qString = "?status=" + values.status;
    if (search.name) qString = qString + "&name=" + search.name;

    getCourseList(qString).then((res) => {
      if (res && res.status === 200) {
        setColumn(column);
        let course = [];
        let dataCampus = groupBy(res.data, "campus_name");
        Object.keys(dataCampus).map((key) => {
          course[key] = groupBy(dataCampus[key], "program_name");
          // console.log(groupBy(dataCampus[key], 'program_name'));
        });

        setData(course);
      }
      setLoading(false);
    });
  };

  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };
  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };

  const onAddCourse = () => {
    getProgram().then((res) => {
      if (res && res.status === 200) {
        let option = [];
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: item,
          });
        });
        setProgram(option);
      }
    });
    getCampus().then((res) => {
      if (res && res.status === 200) {
        let option = [];
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: item.id,
          });
        });
        setCampus(option);
      }
    });
    setOpen(true);
  };

  const insertCourse = (values) => {
    setErrormsg(null);
    setLoading(true);
    if (!values["food"]) values["food"] = "false";
    if (values["master"]) values["master"] = "true";
    else values["master"] = "false";

    values["program"] = values["program"]["id"];

    postCourse(values).then((res) => {
      if (res && res.status === 201) {
        getData();
        setOpen(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  return (
    <Segment basic textAlign="left">
      <Headerwithmenu name="Course">
        {checkPermissionMenu("Academic", ["manage-course"]) && (
          <Button
            color="green"
            content="Add Course"
            floated="right"
            onClick={onAddCourse}
          />
        )}
        <Button
          color="green"
          content="Course History"
          floated="right"
          onClick={() => props.history.push("/management/coursehistory")}
        />
      </Headerwithmenu>
      <Segment className="contentSegment" textAlign="left">
        <Form>
          <Form.Group>
            <Form.Input
              width="4"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={handleInputChange}
            />

            {/* <Form.Dropdown
                            width="4"
                            icon={null}
                            fluid
                            floating
                            style={{ width: "100%", marginRight: "30px" }}
                            name='status'
                            trigger={
                                <Button
                                    basic
                                    icon
                                    fluid
                                    size="small"
                                    labelPosition="right"
                                    style={{ textAlign: "left" }}
                                >
                                    <Icon name="angle down" />
                            Status : <b>{values.status}</b>
                                </Button>
                            }
                            options={config.options_studentstatus}
                            defaultValue={values.status}
                            selectOnBlur={false}
                            onChange={handleSelectChange}
                        /> */}
          </Form.Group>
        </Form>
        <br />

        <Table
          celled
          striped
          padded
          style={{ borderRadius: 0, fontSize: "95%" }}
        >
          <Table.Header
            style={{
              boxShadow: "0 0.5px 5px 0 rgb(0 0 0 / 15%)",
              textAlign: "center",
            }}
          >
            <Table.Row>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Program
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Sub Program
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Age
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Period
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Remark
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Food: Lunch/Snack
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  borderRadius: 0,
                  backgroundColor: "#80561B",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Action
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? (
              <Table.Row>
                <Table.Cell colSpan="7">Loading...</Table.Cell>
              </Table.Row>
            ) : (
              Object.keys(data).map((campus) => (
                <Wrap>
                  <Table.Row>
                    <Table.HeaderCell
                      colSpan="7"
                      style={{ backgroundColor: "#e8e8e8" }}
                    >
                      {campus}
                    </Table.HeaderCell>
                  </Table.Row>
                  {Object.keys(data[campus]).map((program) => {
                    return data[campus][program].map((item, index) =>
                      index === 0 ? (
                        <Table.Row>
                          <Table.Cell
                            rowSpan={data[campus][program].length}
                            textAlign="center"
                            className="bold"
                          >
                            {item.program_name}
                          </Table.Cell>
                          <Table.Cell>{item.name}</Table.Cell>
                          <Table.Cell>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.desc }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.period }}
                            />
                            {item.halfStart &&
                              item.halfEnd &&
                              `Half Day [${item.halfStart} - ${item.halfEnd}]`}
                            <br />
                            {item.fullStart &&
                              item.fullEnd &&
                              `Full Day [${item.fullStart} - ${item.fullEnd}]`}
                          </Table.Cell>
                          <Table.Cell>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.remark }}
                            />
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {item.food === "false" || item.food === null ? (
                              <div>
                                <Icon name="x" color="red" /> Lunch/Snack
                              </div>
                            ) : (
                              <div>
                                <Icon name="check circle" color="green" />{" "}
                                {item.food}
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <Wrap>
                              <Icon
                                link
                                bordered
                                // color="green"
                                style={{
                                  color: config.colorTheme,
                                  boxShadow:
                                    "0 0 0 0.1em " +
                                    config.colorTheme +
                                    " inset",
                                }}
                                name="book"
                                onClick={() =>
                                  props.history.push({
                                    pathname: "/course/profile",
                                    // search: '?query=abc',
                                    state: { id: item.id, page: "course" },
                                  })
                                }
                              />
                            </Wrap>
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        <Table.Row>
                          <Table.Cell>{item.name}</Table.Cell>
                          <Table.Cell>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.desc }}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.period }}
                            />
                            {item.halfStart &&
                              item.halfEnd &&
                              `Half Day [${item.halfStart} - ${item.halfEnd}]`}
                            <br />
                            {item.fullStart &&
                              item.fullEnd &&
                              `Full Day [${item.fullStart} - ${item.fullEnd}]`}
                          </Table.Cell>
                          <Table.Cell>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.remark }}
                            />
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {item.food === "false" || item.food === null ? (
                              <div>
                                <Icon name="x" color="red" /> Lunch/Snack
                              </div>
                            ) : (
                              <div>
                                <Icon name="check circle" color="green" />{" "}
                                {item.food}
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <Wrap>
                              <Icon
                                link
                                bordered
                                // color="green"
                                style={{
                                  color: config.colorTheme,
                                  boxShadow:
                                    "0 0 0 0.1em " +
                                    config.colorTheme +
                                    " inset",
                                }}
                                name="book"
                                onClick={() =>
                                  props.history.push({
                                    pathname: "/course/profile",
                                    // search: '?query=abc',
                                    state: { id: item.id, page: "course" },
                                  })
                                }
                              />
                            </Wrap>
                          </Table.Cell>
                        </Table.Row>
                      )
                    );
                  })}
                </Wrap>
              ))
            )}
          </Table.Body>
        </Table>

        {/* <TableList
                    loading={loading}
                    data={data}
                    columns={column}
                /> */}
      </Segment>
      <ModalBasic open={open} content="Course" close={() => setOpen(false)}>
        <CourseForm
          onSubmit={insertCourse}
          onClose={() => setOpen(false)}
          campus_option={campus}
          program_option={program}
          loading={loading}
          errormsg={errormsg}
        />
      </ModalBasic>

      {/* <Confirmmodal
        loading={loading}
        open={openConfirmExpired}
        title="Course Expired"
        actionText={values.status === "Active" ? "Expired" : "Active"}
        onClose={() => setOpenConfirmExpired(false)}
        onComfirm={expiredData}
      /> */}
    </Segment>
  );
};
export default withRouter(CourseList);
