import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../components/renderField";
import renderSelectField from "../components/renderSelectField";
import renderFileField from "../components/renderFileField";
import renderDateTimeField from "../components/renderDateTimeField";
import renderDateOnlyField from "../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../components/secondbutton";
import * as config from "../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateTimeField2 from "../components/renderDateTimeField2";

const validate = (values) => {
  const errors = {};
  if (!values.parent_firstName) errors.parent_firstName = true;
  if (!values.parent_lastName) errors.parent_lastName = true;
  if (!values.mobile) errors.mobile = true;
  if (!values.id_campus) errors.id_campus = true;
  if (!values.id_program) errors.id_program = true;
  if (!values.firstName) errors.firstName = true;
  if (!values.lastName) errors.lastName = true;
  if (!values.nickname) errors.nickname = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.gender) errors.gender = true;
  if (!values.dateTimeOfVisit) errors.dateTimeOfVisit = true;
  if (!values.status) errors.status = true;
  return errors;
};

let FormAlert = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    initialValue,
    onClose,
    errormsg,
    handleSubmit,
  } = props;
  // console.log(props);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h2">School Visit Requested</Header>
      <Header as="h4" style={{ paddingLeft: "10px" }}>
        Number of visitor : V0000{props.initialValues.Id}
      </Header>
      <div style={{ paddingLeft: "20px" }}>
        <Grid divided stackable>
          <Grid.Row>
            <Grid.Column>
              {errormsg && (
                <Message negative>
                  <Message.Content>{errormsg}</Message.Content>
                </Message>
              )}
              <u>
                <b>Parent's Information</b>
              </u>
              <Form.Group unstackable>
                <Field
                  width="8"
                  name="parent_firstName"
                  type="text"
                  required={true}
                  component={renderField}
                  label="firstname"
                />
                <Field
                  width="8"
                  name="parent_lastName"
                  type="text"
                  required={true}
                  component={renderField}
                  label="lastname"
                />
              </Form.Group>
              <Form.Group unstackable>
                <Field
                  width="8"
                  name="mobile"
                  type="number"
                  required={true}
                  component={renderField}
                  label="Mobile Phone"
                />
                <Field
                  width="8"
                  name="parent_nationality"
                  component={renderSelectField}
                  label="nationality"
                  required={false}
                  options={config.options_nationality}
                />
              </Form.Group>
              <Form.Group unstackable>
                <Field
                  width="16"
                  name="email"
                  type="text"
                  required={false}
                  component={renderField}
                  label="E-mail"
                />
              </Form.Group>
              <u>
                <b>Child's Information</b>
              </u>
              <Form.Group unstackable>
                <Field
                  required={true}
                  width="8"
                  name="id_campus"
                  component={renderSelectField}
                  label="Campus"
                  options={config.options_campus}
                />
                <Field
                  required={true}
                  width="8"
                  name="id_program"
                  component={renderSelectField}
                  label="Program"
                  options={config.options_program}
                />
              </Form.Group>
              <Form.Group unstackable>
                <Field
                  width="8"
                  name="firstName"
                  type="text"
                  required={true}
                  component={renderField}
                  label="firstname"
                />
                <Field
                  width="8"
                  name="lastName"
                  type="text"
                  required={true}
                  component={renderField}
                  label="lastname"
                />
              </Form.Group>
              <Form.Group unstackable>
                <Field
                  width="8"
                  name="nickname"
                  type="text"
                  required={true}
                  component={renderField}
                  label="Nickname"
                />
                <Field
                  width="8"
                  name="dateOfBirth"
                  required={true}
                  component={renderDateOnlyField}
                  label="Date Of Birth"
                />
                <Field
                  width="8"
                  name="gender"
                  component={renderSelectField}
                  label="Gender"
                  required={true}
                  options={config.options_gender}
                />
              </Form.Group>
              <b>Appointment to visit</b>
              <Form.Group unstackable>
                <Field
                  width="8"
                  name="dateTimeOfVisit"
                  required={true}
                  component={renderDateTimeField2}
                />
              </Form.Group>
              <b>Which channel do you hear about us?</b>
              <Form.Group unstackable>
                <Field
                  width="8"
                  name="channel"
                  type="text"
                  component={renderField}
                />
              </Form.Group>
              <Form.Group unstackable>
                <Field
                  width="16"
                  name="comment"
                  label="Comment"
                  type="text"
                  component={renderField}
                />
              </Form.Group>
              <Form.Group unstackable>
                <Field
                  width="8"
                  name="status"
                  component={renderSelectField}
                  label="Status"
                  required={true}
                  options={config.options_status_visit}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Button
          floated="left"
          content="Confirm"
          loading={loading}
          style={{
            backgroundColor: config.colorTheme,
            color: "#ffffff",
            minWidth: "30%",
          }}
        />
        <SecondButton
          floated="right"
          content="Cancel"
          color="grey"
          onClick={onClose}
        />
        <br></br>
        <br></br>
      </div>
    </Form>
  );
};

FormAlert = reduxForm({
  // a unique name for the form
  form: "FormAlert",
  validate,
  enableReinitialize: true,
})(FormAlert);

const selector = formValueSelector("class");
FormAlert = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(FormAlert);

export default FormAlert;
