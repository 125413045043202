import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid, Card } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import MakeAppointmentInterview from "./makeappointmentinterview";
import Evaluation from "./evaluation";
import {
  getApplicationProfile,
  updateinerviewprofile,
  updateinerviewevaluationprofile,
  updateapplicationstudent,
  updateapplicationfather,
  updateapplicationmother,
} from "../service";
import FormStudent from "./formstudent";
import FormImg from "./formimg";
import FormImgParent from "./formimgparent";
import FormParent from "./formparent";

let ApplicationFormDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [interviewProfiles] = useState(
    props && props.location.state && props.location.state.id
  );
  const [data, setData] = useState([]);
  const [dataFather, setDataFather] = useState([]);
  const [dataMother, setDataMother] = useState([]);
  const [open_student, setOpen_student] = useState(false);
  const [open_father, setOpen_father] = useState(false);
  const [open_mother, setOpen_mother] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [openImg, setOpenImg] = useState(false);
  const [openImgFather, setOpenImgFather] = useState(false);
  const [openImgMother, setOpenImgMother] = useState(false);
  const [visitDataTime, setVisitDataTime] = useState([]);
  const [visitData, setVisitData] = useState([]);
  const [errormsg, setErrormsg] = useState(null);
  const [OpenPopUpMakeInterview, setOpenPopUpMakeInterview] = useState(false);
  const [OpenPopUpEvaluation, setOpenPopUpEvaluation] = useState(false);
  const [editTitle, setEditTitle] = useState("");

  useEffect(() => {
    if (!interviewProfiles)
      props.history.push("/application/applicationformdetail");

    setLoading(true);
    if (interviewProfiles) {
      getData();
    }
  }, [interviewProfiles]);

  const getData = () => {
    getApplicationProfile(props.location.state.id).then((res) => {
      if (res && res.status === 200) {
        setData(res.data.student);
        setDataFather(res.data.parent[0]);
        setDataMother(res.data.parent[1]);
        if (res.data.student.interview_date != null) {
          console.log(`getDate !=null`);
          setVisitDataTime(res.data.student.interview_date.split(" ")[1]);
        }
      }
      setLoading(false);
    });
  };

  const funcOpenPopUpMakeInterview = () => {
    setOpenPopUpMakeInterview(true);
  };
  const funcClosePopUpMakeInterview = () => {
    setOpenPopUpMakeInterview(false);
  };
  const funcOpenPopUpEvaluation = () => {
    setOpenPopUpEvaluation(true);
  };
  const funcClosePopUpEvaluation = () => {
    setOpenPopUpEvaluation(false);
  };

  const updateData = (value) => {
    setLoading(true);
    setErrormsg(null);

    console.log(`updateData1`, value);

    // Protect human error
    // >> Verify interview date is empty
    if (value.interview_date.length < 16) {
      setErrormsg("Please fill in the Interview date and time");
      setLoading(false);
      return;
    }

    updateinerviewprofile(value).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpenPopUpMakeInterview(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  const updateData2 = (value) => {
    setLoading(true);
    setErrormsg(null);

    console.log(`updateData2`, value);

    // Protect human error
    // >> Verify interview date is empty
    if (value.interview_date.length < 16) {
      setErrormsg("Please fill in the Interview date and time");
      setLoading(false);
      return;
    }

    updateinerviewevaluationprofile(value).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpenPopUpEvaluation(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  // student
  const onEditImg = (values) => {
    setOpenImg(true);
    setCurrentData(values);
  };
  const onCloseImg = () => {
    setOpenImg(false);
    setCurrentData(null);
  };

  const updateDataImg = (values) => {
    setLoading(true);
    let data = values;
  };
  const onEdit_student = (values) => {
    setOpen_student(true);
    setCurrentData(values);
  };
  const onClose_student = () => {
    setOpen_student(false);
    setCurrentData(null);
  };
  const updateData_student = (values) => {
    setLoading(true);
    let data = values;
    updateapplicationstudent(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_student(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // father
  const onEditImgFather = (values) => {
    setOpenImgFather(true);
    setCurrentData(values);
  };
  const onCloseImgFather = () => {
    setOpenImgFather(false);
    setCurrentData(null);
  };

  const onEdit_father = (values) => {
    setOpen_father(true);
    setCurrentData(values);
    setEditTitle("FATHER (บิดา)"); //Add title for edit box
  };
  const onClose_father = () => {
    setOpen_father(false);
    setCurrentData(null);
  };
  const updateDataImgFather = (values) => {
    setLoading(true);
    let dataFather = values;
  };
  const updateData_father = (values) => {
    setLoading(true);
    setErrormsg(null);
    let dataFather = values;

    // Protect human error
    // >> Verify phone No. must be 10 digits
    if (values.phone.length != 10) {
      setErrormsg("Invalid phone number, must be 10 digits");
      setLoading(false);
      return;
    }
    // >> Verify not allow (-) at first digit
    if (parseInt(values.phone) < 0) {
      setErrormsg("Invalid phone number, do not allow (-) at first digit.");
      setLoading(false);
      return;
    }

    updateapplicationfather(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_father(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  // mother
  const onEditImgMother = (values) => {
    setOpenImgMother(true);
    setCurrentData(values);
  };
  const onCloseImgMother = () => {
    setOpenImgMother(false);
    setCurrentData(null);
  };

  const onEdit_mother = (values) => {
    setOpen_mother(true);
    setCurrentData(values);
    setEditTitle("Mother (มารดา)"); //Add title for edit box
  };
  const onClose_mother = () => {
    setOpen_mother(false);
    setCurrentData(null);
  };
  const updateDataImgMother = (values) => {
    setLoading(true);
    let dataMother = values;
  };
  const updateData_mother = (values) => {
    setLoading(true);
    let dataMother = values;
    setErrormsg(null);

    // Protect human error
    // >> Verify phone No. must be 10 digits
    if (values.phone.length != 10) {
      setErrormsg("Invalid phone number, must be 10 digits");
      setLoading(false);
      return;
    }
    // >> Verify not allow (-) at first digit
    if (parseInt(values.phone) < 0) {
      setErrormsg("Invalid phone number, do not allow (-) at first digit.");
      setLoading(false);
      return;
    }

    updateapplicationmother(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen_mother(false);
      }
      setCurrentData(null);
      setLoading(false);
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">Application Form</Header>

      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/application/application")}
              style={{ color: "grey" }}
            >
              Application
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Application Form</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column>
          <Grid.Column>
            <Button
              basic
              color="green"
              content="Interview Evaluate"
              floated="right"
              style={{ marginBottom: "14px" }}
              onClick={() => funcOpenPopUpEvaluation()}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              basic
              color="green"
              content="Interview Appointment"
              floated="right"
              style={{ marginBottom: "14px" }}
              onClick={() => funcOpenPopUpMakeInterview()}
            />
          </Grid.Column>
        </Grid.Column>
      </Grid>

      <Segment>
        <div style={{ paddingLeft: "20px" }}>
          <Header as="h4">Student</Header>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column>
                <Segment style={{ margin: "40px" }}>
                  <Grid columns="2" style={{ width: "70%" }}>
                    {data.imageURL && (
                      <>
                        <Grid.Column style={{ paddingLeft: "40px" }}>
                          <Image
                            width={"25%"}
                            verticalAlign="middle"
                            src={
                              data.imageURL
                                ? data.imageURL + "?time=" + new Date()
                                : "../storage/imgs/dfkid.png"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "../storage/imgs/dfkid.png";
                            }}
                          />
                          <Button
                            content="Image"
                            onClick={() => onEditImg(data)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </Grid.Column>
                      </>
                    )}
                    {!data.imageURL && (
                      <>
                        <Grid.Column style={{ paddingLeft: "40px" }}>
                          <Image
                            width={"25%"}
                            verticalAlign="middle"
                            src={
                              data.imageURL
                                ? data.imageURL + "?time=" + new Date()
                                : "../storage/imgs/dfkid.png"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "../storage/imgs/dfkid.png";
                            }}
                          />
                          <Button
                            content="Image"
                            onClick={() => onEditImg(data)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </Grid.Column>
                        <Grid.Column>
                          <p style={{ color: "red", marginTop: "8%" }}>
                            Please Upload Image
                          </p>
                        </Grid.Column>
                      </>
                    )}
                  </Grid>
                </Segment>
                <Grid columns="2" style={{ width: "50%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    Passport or ID No.:<b> {data.idnumber}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Date Of Birth:<b> {data.dateOfBirth}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="3" style={{ width: "70%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    firstname:<b> {data.firstName}</b>
                  </Grid.Column>
                  <Grid.Column>
                    lastname:<b> {data.lastName}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Nickname:<b> {data.nickname}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="3" style={{ width: "70%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    ชื่อ:<b> {data.firstName_th}</b>
                  </Grid.Column>
                  <Grid.Column>
                    นามสกุล:<b> {data.lastName_th}</b>
                  </Grid.Column>
                  <Grid.Column>
                    ชื่อเล่น:<b> {data.nickname_th}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="4" style={{ width: "80%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    Gender:<b> {data.gender}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Nationality:<b> {data.nationality}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Native Language:<b> {data.nativelanguage}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Race:<b> {data.race}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="4" style={{ width: "80%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    Native Language: (Use at home):
                    <b> {data.nativelanguageAtHome}</b>
                  </Grid.Column>
                  <Grid.Column>
                    BloodType:<b> {data.bloodtype}</b>
                  </Grid.Column>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    โรคประจำตัว: <b>{data.allergy}</b>
                  </Grid.Column>
                  <Grid.Column>
                    แพ้อาหาร : <b>{data.food_allergy}</b>
                  </Grid.Column>
                </Grid>
                <Button
                  content="Edit"
                  onClick={() => onEdit_student(data)}
                  style={{
                    backgroundColor: config.colorTheme,
                    color: "#ffffff",
                  }}
                ></Button>
                <hr />

                <Header as="h4">FATHER</Header>
                <Segment style={{ margin: "40px" }}>
                  <Grid columns="2" style={{ width: "70%" }}>
                    {dataFather.GimageURL && (
                      <>
                        <Grid.Column style={{ paddingLeft: "40px" }}>
                          <Image
                            width={"25%"}
                            verticalAlign="middle"
                            src={
                              dataFather.GimageURL
                                ? dataFather.GimageURL + "?time=" + new Date()
                                : "../storage/imgs/dfkid.png"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "../storage/imgs/dfkid.png";
                            }}
                          />
                          <Button
                            content="Image"
                            onClick={() => onEditImgFather(dataFather)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </Grid.Column>
                      </>
                    )}
                    {!dataFather.GimageURL && (
                      <>
                        <Grid.Column style={{ paddingLeft: "40px" }}>
                          <Image
                            width={"25%"}
                            verticalAlign="middle"
                            src={
                              dataFather.GimageURL
                                ? dataFather.GimageURL + "?time=" + new Date()
                                : "../storage/imgs/dfkid.png"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "../storage/imgs/dfkid.png";
                            }}
                          />
                          <Button
                            content="Image"
                            onClick={() => onEditImgFather(dataFather)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </Grid.Column>
                        <Grid.Column>
                          <p style={{ color: "red", marginTop: "8%" }}>
                            Please Upload Image
                          </p>
                        </Grid.Column>
                      </>
                    )}
                  </Grid>
                </Segment>
                <Grid columns="2" style={{ width: "50%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    Passport or ID No.:<b> {dataFather.idnumber}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Date Of Birth:<b> {dataFather.dateOfBirth}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="4" style={{ width: "80%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    firstname:<b> {dataFather.firstName}</b>
                  </Grid.Column>
                  <Grid.Column>
                    lastname:<b> {dataFather.lastName}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Nationality: <b> {dataFather.nationality}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Native Language:<b> {dataFather.nativelanguage}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="4" style={{ width: "80%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    ชื่อ:<b> {dataFather.firstName_th}</b>
                  </Grid.Column>
                  <Grid.Column>
                    นามสกุล:<b> {dataFather.lastName_th}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Mobile:<b> {dataFather.phone}</b>
                  </Grid.Column>
                  <Grid.Column>
                    E-mail:<b> {dataFather.email}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="3" style={{ width: "70%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    Place of work:<b> {dataFather.placeOfWork}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Position:<b> {dataFather.position}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Office Tel:<b> {dataFather.officePhone}</b>
                  </Grid.Column>
                </Grid>
                <Button
                  content="Edit"
                  onClick={() => onEdit_father(dataFather)}
                  style={{
                    backgroundColor: config.colorTheme,
                    color: "#ffffff",
                  }}
                ></Button>
                <hr />

                <Header as="h4">MOTHER</Header>
                <Segment style={{ margin: "40px" }}>
                  <Grid columns="2" style={{ width: "70%" }}>
                    {dataMother.GimageURL && (
                      <>
                        <Grid.Column style={{ paddingLeft: "40px" }}>
                          <Image
                            width={"25%"}
                            verticalAlign="middle"
                            src={
                              dataMother.GimageURL
                                ? dataMother.GimageURL + "?time=" + new Date()
                                : "../storage/imgs/dfkid.png"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "../storage/imgs/dfkid.png";
                            }}
                          />
                          <Button
                            content="Image"
                            onClick={() => onEditImgMother(dataMother)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </Grid.Column>
                      </>
                    )}
                    {!dataMother.GimageURL && (
                      <>
                        <Grid.Column style={{ paddingLeft: "40px" }}>
                          <Image
                            width={"25%"}
                            verticalAlign="middle"
                            src={
                              dataMother.GimageURL
                                ? dataMother.GimageURL + "?time=" + new Date()
                                : "../storage/imgs/dfkid.png"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "../storage/imgs/dfkid.png";
                            }}
                          />
                          <Button
                            content="Image"
                            onClick={() => onEditImgMother(dataMother)}
                            style={{
                              backgroundColor: config.colorTheme,
                              color: "#ffffff",
                              minWidth: "10%",
                              marginLeft: "10%",
                            }}
                          ></Button>
                        </Grid.Column>
                        <Grid.Column>
                          <p style={{ color: "red", marginTop: "8%" }}>
                            Please Upload Image
                          </p>
                        </Grid.Column>
                      </>
                    )}
                  </Grid>
                </Segment>
                <Grid columns="2" style={{ width: "50%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    Passport or ID No.:<b> {dataMother.idnumber}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Date Of Birth:<b> {dataMother.dateOfBirth}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="4" style={{ width: "80%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    firstname:<b> {dataMother.firstName}</b>
                  </Grid.Column>
                  <Grid.Column>
                    lastname:<b> {dataMother.lastName}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Nationality:<b> {dataMother.nationality}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Native Language:<b> {dataMother.nativelanguage}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="4" style={{ width: "80%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    ชื่อ:<b> {dataMother.firstName_th}</b>
                  </Grid.Column>
                  <Grid.Column>
                    นามสกุล:<b> {dataMother.lastName_th}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Mobile:<b> {dataMother.phone}</b>
                  </Grid.Column>
                  <Grid.Column>
                    E-mail:<b> {dataMother.email}</b>
                  </Grid.Column>
                </Grid>
                <Grid columns="3" style={{ width: "70%" }}>
                  <Grid.Column style={{ paddingLeft: "40px" }}>
                    Place of work:<b> {dataMother.placeOfWork}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Position:<b> {dataMother.position}</b>
                  </Grid.Column>
                  <Grid.Column>
                    Office Tel:<b> {dataMother.officePhone}</b>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Button
            content="Edit"
            onClick={() => onEdit_mother(dataMother)}
            style={{ backgroundColor: config.colorTheme, color: "#ffffff" }}
          ></Button>
          <hr />
        </div>

        <br></br>
        <br></br>
        <Segment>
          <Grid columns="4" style={{ width: "80%" }}>
            <Grid.Column width="8">
              Date/Time Interview : <b>{data.interview_date}</b>
            </Grid.Column>
          </Grid>
        </Segment>
        {/* <Segment>
                    <Grid columns='4' style={{ width: "80%" }}>
                        <Grid.Column width='8'>
                            {visitDataTime == '09:00' && <>Date/Time Interview : <b>{visitData.interview_date} - 10:00</b></>}
                            {visitDataTime == '10:00' && <>Date/Time Interview : <b>{visitData.interview_date} - 11:00</b></>}
                            {visitDataTime == '11:00' && <>Date/Time Interview : <b>{visitData.interview_date} - 12:00</b></>}
                            {visitDataTime == '13:00' && <>Date/Time Interview : <b>{visitData.interview_date} - 14:00</b></>}
                            {visitDataTime == '14:00' && <>Date/Time Interview : <b>{visitData.interview_date} - 15:00</b></>}
                        </Grid.Column>
                    </Grid>
                </Segment> */}

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <ModalBasic
          open={OpenPopUpMakeInterview}
          close={() => setOpenPopUpMakeInterview(false)}
        >
          <Grid columns="2">
            <Grid.Column>
              <Header as="h2">Interview Appointment</Header>
            </Grid.Column>
            <Grid.Column>
              <Header
                as="h3"
                floated="right"
                style={{ cursor: "pointer" }}
                onClick={() => funcClosePopUpMakeInterview()}
              >
                X
              </Header>
            </Grid.Column>
          </Grid>
          <MakeAppointmentInterview
            onSubmit={updateData}
            onClose={() => funcClosePopUpMakeInterview()}
            loading={loading}
            initialValues={data}
            errormsg={errormsg}
          />
        </ModalBasic>

        <ModalBasic
          open={OpenPopUpEvaluation}
          close={() => setOpenPopUpMakeInterview(false)}
        >
          <Grid columns="2">
            <Grid.Column>
              <Header as="h2">Interview Evaluate</Header>
            </Grid.Column>
            <Grid.Column>
              <Header
                as="h3"
                floated="right"
                style={{ cursor: "pointer" }}
                onClick={() => funcClosePopUpEvaluation()}
              >
                X
              </Header>
            </Grid.Column>
          </Grid>
          <Evaluation
            onSubmit={updateData2}
            onClose={() => funcClosePopUpEvaluation(false)}
            loading={loading}
            initialValues={data}
            errormsg={errormsg}
          />
        </ModalBasic>
      </Segment>

      <ModalBasic
        closeIcon={true}
        open={open_student}
        size="100%"
        close={() => onClose_student()}
      >
        <FormStudent
          loading={loading}
          initialValues={currentData}
          onClose={() => onClose_student()}
          onSubmit={updateData_student}
        />
      </ModalBasic>

      <ModalBasic
        closeIcon={true}
        open={openImg}
        size="big"
        close={() => onCloseImg()}
      >
        <FormImg
          loading={loading}
          initialValues={currentData}
          onClose={() => onCloseImg()}
          onSubmit={updateDataImg}
        />
      </ModalBasic>

      <ModalBasic
        closeIcon={true}
        open={openImgFather}
        size="big"
        close={() => onCloseImgFather()}
      >
        <FormImgParent
          loading={loading}
          initialValues={currentData}
          onClose={() => onCloseImgFather()}
          onSubmit={updateDataImgFather}
        />
      </ModalBasic>

      <ModalBasic
        closeIcon={true}
        open={openImgMother}
        size="big"
        close={() => onCloseImgMother()}
      >
        <FormImgParent
          loading={loading}
          initialValues={currentData}
          onClose={() => onCloseImgMother()}
          onSubmit={updateDataImgMother}
        />
      </ModalBasic>

      <ModalBasic
        closeIcon={true}
        open={open_father}
        size="100%"
        close={() => onClose_father()}
      >
        <FormParent
          loading={loading}
          initialValues={currentData}
          onClose={() => onClose_father()}
          onSubmit={updateData_father}
          editTitle={editTitle}
          errormsg={errormsg}
        />
      </ModalBasic>

      <ModalBasic
        closeIcon={true}
        open={open_mother}
        size="100%"
        close={() => onClose_mother()}
      >
        <FormParent
          loading={loading}
          initialValues={currentData}
          onClose={() => onClose_mother()}
          onSubmit={updateData_mother}
          editTitle={editTitle}
          errormsg={errormsg}
        />
      </ModalBasic>
    </Segment>
  );
};

ApplicationFormDetail = reduxForm({
  // a unique name for the form
  form: "ApplicationFormDetail",
  enableReinitialize: true,
})(ApplicationFormDetail);

const selector = formValueSelector("class");
ApplicationFormDetail = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(ApplicationFormDetail);

export default withRouter(ApplicationFormDetail);
