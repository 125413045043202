import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import * as config from "../../../config";
import {
  Button,
  Header,
  Icon,
  Modal,
  Segment,
  Form,
  Grid,
  Image,
  Breadcrumb,
} from "semantic-ui-react";
import Wrap from "../../../components/wrap";
// import TableList from "../../../components/table";
import TableList from "../../../components/tablePerPage";
import ModalBasic from "../../../components/modal";
// import FormBooks from "../formbook";
import HistoryDetailForm from "./../formhistorydetail";
import ItemDefectForm from "./../formDefectBook";
import { DateInput, DatesRangeInput } from "semantic-ui-calendar-react";
import {
  getHistoryBook,
  exportHistoryBook,
  postBook,
  getBookHistoryDetail,
  getBorrowHistoryBook,
} from "../service";

const History = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [per_page, setPerPage] = useState(10);
  const [current_page, setCurrentPage] = useState(0);
  const [last_page, setLastPage] = useState(1);
  const [values, setValues] = useState({
    status: "",
    cetegory: "",
    status_visit: "",
    status_interview: "",
  });
  const [search, setSearch] = useState({ fullname: "" });
  const [errormsg, setErrormsg] = useState(null);
  const [loadPopup, setLoadPopup] = useState(null);
  const [showAssetHistoryDetail, setShowAssetHistoryDetail] = useState(false);
  const [showPopupDefect, setShowPopupDefect] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [viewOnly, setViewOnly] = useState(true);
  const [bookingID, setBookingID] = useState(null);
  const [bookStatus, setBookStatus] = useState(null);
  const [pickupDueDate, setPickupDueDate] = useState(null);
  const [bookingDate, setBookingDate] = useState(null);
  const [borrowDate, setBorrowDate] = useState(null);
  const [mainData, setMainData] = useState(null);
  const [defectData, setDefectData] = useState(null);
  const [DefectImageUrl, setDefectImageUrl] = useState(null);
  //   const [date, setDate] = useState(
  //     moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  //   );
  const [date, setDate] = useState("");
  const [visit_date, setvisit_date] = useState(
    moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD")
  );
  const [showPopupBooks, setShowPopupBooks] = useState(false);
  const [column, setColumn] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Channel",
      accessor: "channel",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Booking ID",
      accessor: "booking_id",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Borrower",
      accessor: "fullname",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Booking Date",
      accessor: "booking_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Pickup Due Date",
      accessor: "pickup_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Borrowed Date",
      accessor: "borrow_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Due Date",
      accessor: "return_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Date",
      accessor: "return_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.status === "Complete" ? (
          <div className="complete">{cellInfo.original.status} </div>
        ) : cellInfo.original.status === "Borrowing" ? (
          <div className="borrow">{cellInfo.original.status}</div>
        ) : (
          <div className="request">{cellInfo.original.status}</div>
        ),
    },
    {
      Header: "Dispose",
      accessor: "isDispose",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.isDispose === "Yes" ? (
          <div className="dispose">{cellInfo.original.isDispose}</div>
        ) : (
          <div>{cellInfo.original.isDispose}</div>
        ),
    },
    {
      Header: "Action",
      accessor: "id",
      style: { textAlign: "center" },
      sortable: false,
      width: 70,
      Cell: (cellInfo) => (
        <Wrap>
          <Icon
            link
            bordered
            style={{
              color: config.colorTheme,
              boxShadow: "0 0 0 0.1em " + config.colorTheme + " inset",
            }}
            name="user"
            onClick={() =>
              loadHistoryDetail(
                cellInfo.original.booking_id,
                cellInfo.original.status
              )
            }
          />
        </Wrap>
      ),
    },
  ]);

  // For detail page
  const [columnhistorylist, setColumnhistorylist] = useState([
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "id",
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/book.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/book.png";
          }}
        />
      ),
      style: { textAlign: "center" },
      width: 100,
    },
    {
      Header: "Category",
      accessor: "category",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Code",
      accessor: "book_code",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Brand",
      accessor: "brand",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Model",
      accessor: "model",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "ISBN",
      accessor: "isbn",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [EN]",
      accessor: "name_en",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Item Name [TH]",
      accessor: "name_th",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Age range",
      accessor: "age_range",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Author",
      accessor: "author",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Return Due Date",
      accessor: "return_due_date",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Unit",
      accessor: "unit",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Defect",
      accessor: "defect",
      style: { textAlign: "center" },
      sortable: false,
      width: 55,
      Cell: (cellInfo) =>
        cellInfo.original.defect === null ? (
          <Wrap>
            <Icon
              // link
              bordered
              style={{
                color: "green",
                boxShadow: "0 0 0 0.1em " + "green" + " inset",
              }}
              name="exclamation"
            />
          </Wrap>
        ) : (
          <Wrap>
            <Icon
              link
              bordered
              style={{
                color: "red",
                boxShadow: "0 0 0 0.1em " + "red" + " inset",
              }}
              name="exclamation"
              onClick={() =>
                onItemDefect(
                  cellInfo.original.book_id,
                  cellInfo.original.booking_id
                )
              }
            />
          </Wrap>
        ),
    },
  ]);

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (date && date.length !== 23) return;

    getData();
  }, [date, per_page, current_page, values.status]);

  useEffect(() => {
    setLoading(true);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getData();
    }, 2000);
  }, [search]);

  const getData = () => {
    setLoading(true);
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;

    // if (values.status && values.status !== "")
    //   qString = qString + "&status=" + values.status;
    // if (search.fullname) qString = qString + "&fullname=" + search.fullname;
    // let qString = "?";

    if (date) {
      const val = date.split(" - ");
      let start = val[0];
      let end = val[1];
      if (start && end) {
        qString = qString + "&start=" + start + "&end=" + end;
      }
    }
    if (search) {
      if (search.fullname) qString = qString + "&fullname=" + search.fullname;
    }
    if (values.status && values.status != "" && values.status != "All") {
      qString = qString + "&status=" + values.status;
    }

    getHistoryBook(qString).then((res) => {
      // console.log(`Test-getdata:`, res);
      if (res && res.status === 200) {
        setColumn(column);
        setData(res.data.data);
        setPerPage(per_page);
        setCurrentPage(res.data.current_page - 1);
        setLastPage(res.data.last_page);
      }
      setLoading(false);
    });
  };

  const handleSelectChange = (e, data) => {
    const { name, value } = data;
    setValues({ ...values, [name]: value });
  };
  const handleChangeDate = (event, { name, value }) => {
    setDate(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch({ ...values, [name]: value });
  };

  const onClickExport = () => {
    setLoadPopup(true);

    let qString = "?";

    if (date) {
      const val = date.split(" - ");
      let start = val[0];
      let end = val[1];
      if (start && end) {
        qString = qString + "&start=" + start + "&end=" + end;
      }
    }
    if (search) {
      if (search.fullname) qString = qString + "&fullname=" + search.fullname;
    }
    if (values.status && values.status != "" && values.status != "All") {
      qString = qString + "&status=" + values.status;
    }

    exportHistoryBook(qString).then((res) => {
      if (res && res.status === 200) {
        setLoadPopup(false);
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type: "history/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "BookHistoryReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download =
          "BookHistoryReport_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        setErrormsg("The request is taking too long. Please try again");
      }
    });
  };

  const loadHistoryDetail = (booking_id, status) => {
    //get history detail
    let page = current_page + 1;
    let qString = "?_perpage=" + per_page + "&page=" + page;
    qString = qString + "&booking_id=" + booking_id + "&status=" + status;
    // console.log(`TEST-loadHistoryDetail`, qString);

    getBookHistoryDetail(qString).then((res) => {
      // console.log(`getBookHistoryDetail`, res); //Test
      if (res && res.status === 200) {
        // setColumnbookinglist(columnbookinglist);
        setColumnhistorylist(columnhistorylist);
        setDataDetail(res.data.data);
        setMainData(res.data.data[0]);
        setBookingID(res.data.data[0].booking_id);
        setBookStatus(res.data.data[0].status);
        setPickupDueDate(res.data.data[0].pickup_due_date);
        setBookingDate(res.data.data[0].booking_date);
        setBorrowDate(res.data.data[0].borrow_date);
        setShowAssetHistoryDetail(true);
      }
      // setLoading(false);
    });
  };

  const onItemDefect = (book_id, booking_id) => {
    let qString = "?booking_id=" + booking_id + "&book_id=" + book_id;

    // console.log(`onItemDefect`, qString);

    getBorrowHistoryBook(qString).then((res) => {
      // console.log(`getBorrowHistoryBook`, res);
      if (res && res.status === 200) {
        // setReturnRow(res.data[0]);
        setDefectData(res.data);
        setDefectImageUrl(res.data.imageURL);

        setShowPopupDefect(true);
      }
      // setLoading(false);
    });
  };

  return (
    <Segment basic textAlign="left">
      <Header as="h2">History</Header>

      <Grid columns="2">
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => props.history.push("/library")}
              style={{ color: "grey" }}
            >
              Library
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>History</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column>
          <Button
            basic
            color="green"
            content="Export Excel"
            floated="right"
            style={{ marginBottom: "14px" }}
            onClick={onClickExport}
          />
        </Grid.Column>
      </Grid>
      <Segment
        className="contentSegment"
        textAlign="left"
        style={{ minHeight: "50vh", padding: "20px" }}
      >
        <Form>
          <Grid columns="3">
            <Grid.Column>
              <DatesRangeInput
                name="date"
                placeholder="Borroewd Date"
                hideMobileKeyboard
                dateFormat="YYYY-MM-DD"
                iconPosition="right"
                value={date}
                allowSameEndDate
                onChange={handleChangeDate}
                clearable
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                width="16"
                icon="search"
                size="small"
                placeholder="Borrower"
                name="fullname"
                onChange={handleInputChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Dropdown
                width="16"
                icon={null}
                fluid
                floating
                name="status"
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status : <b>{values.status}</b>
                  </Button>
                }
                options={[
                  { key: "all", text: "All", value: "All" },
                  ...config.options_status_history,
                ]}
                defaultValue={values.status}
                selectOnBlur={false}
                onChange={handleSelectChange}
              />
            </Grid.Column>
          </Grid>
        </Form>
        <br></br>

        {/* <TableList loading={loading} data={data} columns={column} /> */}
        <TableList
          loading={loading}
          data={data}
          columns={column}
          pageSize={per_page}
          page={current_page}
          pages={last_page}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={(value) => setPerPage(value)}
        />
      </Segment>
      <Modal
        size={errormsg ? "tiny" : "mini"}
        open={loadPopup}
        closeIcon={errormsg ? true : false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        onClose={() => setLoadPopup(false)}
      >
        <Wrap>
          {errormsg ? (
            <Wrap>
              <Modal.Header>
                <Icon name="warning sign" /> Warning
              </Modal.Header>
              <Modal.Content>{errormsg}</Modal.Content>
            </Wrap>
          ) : (
            <Modal.Content>
              <div style={{ textAlign: "center" }}>
                <Icon loading name="circle notch" />
                <br />
                Waiting Download File
              </div>
            </Modal.Content>
          )}
        </Wrap>
      </Modal>

      {/* History detail form */}
      <ModalBasic
        size="large"
        open={showAssetHistoryDetail}
        content="History detail"
        close={() => setShowAssetHistoryDetail(false)}
      >
        <HistoryDetailForm
          onClose={() => setShowAssetHistoryDetail(false)}
          loading={loading}
          initialValues={dataDetail}
          mainData={mainData}
          errormsg={errormsg}
          bookingID={bookingID}
          viewOnly={viewOnly}
          bookStatus={bookStatus}
          pickupDueDate={pickupDueDate}
          bookingDate={bookingDate}
          borrowDate={borrowDate}
          columnhistorylist={columnhistorylist}
        />
      </ModalBasic>

      {/* Defect form */}
      <ModalBasic
        size="large"
        open={showPopupDefect}
        content="Book Damaged"
        close={() => setShowPopupDefect(false)}
      >
        <ItemDefectForm
          onSubmit={() => setShowPopupDefect(false)}
          onClose={() => setShowPopupDefect(false)}
          loading={loading}
          initialValues={defectData}
          errormsg={errormsg}
          imageURL={DefectImageUrl}
          // data={defectData}
          viewOnly={viewOnly}
        />
      </ModalBasic>
    </Segment>
  );
};
export default History;
