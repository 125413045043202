import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import ModalBasic from "../../components/modal";
import * as config from '../../config';
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup
} from 'semantic-ui-react';

const validate = values => {
  const errors = {}
  if (!values.isbn) errors.isbn = true;
  if (!values.status) errors.status = true;
  return errors;
};


let FormActive = (props) => {
  const { loading, pristine, submitting, handleSubmit, onSubmit, initialValue, onClose, errormsg, start_card, end_card } = props;
  const [open, setOpen] = useState(false);

  const showPopup = () => {
    setOpen(true);
  };
  const popUpClose = () => {
    setOpen(false);
    window.location.reload();
    window.close();

  };

  return (

    <Segment basic textAlign='left'>
      <Segment>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid divided stackable>
            <Grid.Row>
              <Grid.Column>
                <Form.Group unstackable>
                  <Field
                    width="6"
                    name="status"
                    component={renderSelectField}
                    label="Status"
                    required={true}
                    options={config.options_status_item}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br /><br /><br /><br />
          <Button floated='left' content='Save'
            style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '30%', marginRight: '10%' }}
            onClick={() => showPopup()}
          />
          <SecondButton floated='right' content='Cancel' color='grey' onClick={onClose} />
          <br /><br /><br /><br />
        </Form>
      </Segment>

      <ModalBasic open={open} size='big' close={popUpClose}>
        <Header as='h2' style={{ textAlign: 'center' }}>ทำการบันทึกข้อมูลแล้ว</Header>
        <center><Button content='OK'
          style={{ backgroundColor: config.colorTheme, color: "#ffffff", minWidth: '5%' }}
          onClick={() => popUpClose()}
        /></center>
      </ModalBasic>

    </Segment>

  );

}




FormActive = reduxForm({
  // a unique name for the form
  form: "FormActive",
  validate,
  enableReinitialize: true,
})(FormActive);

const selector = formValueSelector("class");
FormActive = connect(state => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card
  };
})(FormActive);

export default FormActive;