import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import * as config from '../../config'

import {
    Button,
    Checkbox,
    Grid,
    Header,
    Icon,
    Image,
    Segment,
    Card,
    List
} from 'semantic-ui-react';
import { getRole, postRole, updateRole, getRolesWithPermission, postRolesWithPermission, deleteRole } from "./service";
import Headerwithmenu from "../../components/headerwithmenu";
import FormRoles from "./form";
import ModalForm from "../../components/modal";
import Confirmmodal from "../../components/confirmmodal";



const Role = (props) => {
    const [loading, setLoading] = useState(true);
    const [errormsg, setErrormsg] = useState(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [permission, setPermission] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    useEffect(() => {
        setLoading(true);
        getData();
    }, []);

    const getData = () => {
        getRole().then(res => {
            if (res && res.status === 200) {
                setData(res.data);
            }
            setLoading(false);
        });
        getRolesWithPermission().then(res => {
            if (res && res.status === 200) {
                setPermission(res.data);
            }
            setLoading(false);
        });
    };
    const insertData = (data) => {
        setLoading(true);
        setErrormsg(null);

        if (!data.permission)
            data.permission = [];
        let permission = [];
        Object.keys(data.permission).map(group => {
            let tmp = [];
            Object.keys(data.permission[group]).map(key => {
                if (data.permission[group][key]) tmp.push(key);
            });
            permission.push({
                group: group,
                permission: tmp
            });
        });

        postRole({ 'name': data.name }).then(res => {
            if (res && res.status === 201) {
                let permissionData = {
                    id: res.data.id,
                    name: data.name,
                    permission: permission
                };
                postRolesWithPermission(permissionData).then(res => {
                    if (res && res.status === 200) {
                        getData();
                        onClose();
                    }
                });
            } else {
                setErrormsg(res.data.message);
            }
            setLoading(false);
        });
    };
    const updateData = (data) => {
        setLoading(true);
        setErrormsg(null);

        if (!data.permission)
            data.permission = [];
        let permission = [];
        Object.keys(data.permission).map(group => {
            let tmp = [];
            Object.keys(data.permission[group]).map(key => {
                if (data.permission[group][key]) tmp.push(key);
            });
            permission.push({
                group: group,
                permission: tmp
            });
        });
        updateRole({ 'id': data.id, 'name': data.name }).then(res => {
            if (res && res.status === 200) {
                let permissionData = {
                    id: data.id,
                    name: data.name,
                    permission: permission
                };
                postRolesWithPermission(permissionData).then(res => {
                    if (res && res.status === 200) {
                        getData();
                        onClose();
                    }
                });
            } else {
                setErrormsg(res.data.message);
            }
            setLoading(false);
        });

    };
    const onEdit = (values) => {

        console.log(values);
        const group = permission.find(item => {
            return item.id === values.id;
        });
        if (!group.permission) group.permission = [];
        const currentPermission = {};
        group.permission.map(item => {
            currentPermission[item.group] = {};
            item.permission.map(per => {
                currentPermission[item.group][per] = true;
            });
        });
        values.permission = currentPermission;
        setOpen(true);
        setCurrentData(values);
    };
    const onClose = () => {
        setOpen(false);
        setCurrentData(null);
    };

    const onClickDelete = (values) => {
        setOpenConfirmDelete(true)
        setCurrentData(values)
    }

    const deleteData = () => {
        setLoading(true)
        if (currentData && currentData.id)
            deleteRole(currentData.id).then(res => {
                if (res && res.status === 200) {
                    getData();
                    setOpenConfirmDelete(false)
                }
                setLoading(false)
            });
    }
    return (
        <Segment basic textAlign='left'>
            <Headerwithmenu name='Role & Permission' >
                <Button color='green'
                    content='Add Role' floated='right'
                    onClick={() => setOpen(true)} />
            </Headerwithmenu>
            <Segment className="contentSegment" textAlign="left">
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Icon loading name="circle notch" />
                        <br />
              Loading..
                    </div>
                ) :
                    <List verticalAlign="middle" divided relaxed style={{ padding: '20px' }}>
                        {data && data.map((item, index) =>
                            <List.Item style={{ padding: '14px' }}>
                                <List.Content floated="left">
                                </List.Content>
                                <List.Content floated="right" textAlign="left">
                                    <Icon
                                        link
                                        name="edit"
                                        onClick={() => onEdit(item)}
                                    />
                                    &nbsp; &nbsp;
                                        <Icon
                                        link
                                        color="red"
                                        name="trash alternate"
                                        onClick={() => onClickDelete(item)}
                                    />

                                </List.Content>
                                <List.Content>
                                    <span
                                        className="bold"
                                        style={{ cursor: "pointer" }}
                                    // onClick={() => this.onEdit(item)}
                                    >
                                        {item.name}
                                    </span>
                                </List.Content>
                            </List.Item>
                        )}
                    </List>}
            </Segment>
            <ModalForm
                open={open}
                icon="key"
                content="Role & Permission"
                close={onClose}
            >
                <FormRoles
                    loading={loading}
                    errormsg={errormsg}
                    // readOnly={state.readOnly}
                    initialValues={currentData ? currentData : null}
                    onSubmit={currentData ? updateData : insertData}
                    onClose={onClose}
                />
            </ModalForm>
            <Confirmmodal
                loading={loading}
                open={openConfirmDelete}
                title="Role"
                onClose={() => setOpenConfirmDelete(false)}
                onComfirm={deleteData}
            />
        </Segment>
    );
}
export default Role;
