import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";
import renderDateTimeField from "../../components/renderDateTimeField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import SecondButton from "../../components/secondbutton";
import * as config from "../../config";
import { connect } from "react-redux";
import {
  Header,
  Image,
  Segment,
  Breadcrumb,
  Loader,
  Dimmer,
  Tab,
  Table,
  Icon,
  Label,
  FormGroup,
} from "semantic-ui-react";
import renderDateField from "../../components/renderDateOnlyField";
import { withRouter } from "react-router-dom";
import ModalBasic from "../../components/modal";
import { updateImageProfile2 } from "../service";

let FormImgParent = (props) => {
  const {
    loading,
    pristine,
    submitting,
    onSubmit,
    handleSubmit,
    initialValue,
    onClose,
  } = props;
  const [photo, setPhoto] = useState(null);

  const updateImage = () => {
    var formData = new FormData();
    if (photo) {
      if (typeof photo === "object") formData.append("photo", photo[0]);
      else formData.append("photo", photo);
    }
    // updateImageProfile2(formData, props.initialValues.Id).then(res => {
    updateImageProfile2(formData, props.initialValues.guardian_id).then(
      (res) => {
        if (res && res.status === 200) {
          window.location.reload();
          window.close();
        }
      }
    );
  };
  // console.log(props);

  return (
    <Segment basic textAlign="left">
      <Segment>
        <div style={{ paddingLeft: "20px" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid divided stackable>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group unstackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Input
                          type="file"
                          name="photo"
                          onChange={(e) => setPhoto(e.target.files)}
                        />
                        <br></br>
                        <br></br>
                        <Button
                          floated="left"
                          content="Save Image"
                          fluid
                          style={{
                            backgroundColor: config.colorTheme,
                            color: "#ffffff",
                            minWidth: "1%",
                          }}
                          loading={loading}
                          onClick={updateImage}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </Segment>
    </Segment>
  );
};

FormImgParent = reduxForm({
  // a unique name for the form
  form: "FormStudent",
  enableReinitialize: true,
})(FormImgParent);

const selector = formValueSelector("class");
FormImgParent = connect((state) => {
  const start_card = selector(state, "start_card");
  const end_card = selector(state, "end_card");
  return {
    start_card: start_card,
    end_card: end_card,
  };
})(FormImgParent);

export default withRouter(FormImgParent);
