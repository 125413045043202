import React, { useState, useEffect } from "react";
import { Dimmer, Loader, Label } from "semantic-ui-react";
import * as config from '../../config';
import moment from 'moment';
import { getClassAttendance } from "./service";
import Wrap from "../../components/wrap";
import TableList from "../../components/table";


let AttendanceTable = (props) => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [student, setStudent] = useState(props.studentId);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([
    {
      Header: "Date Time",
      accessor: "checkin_at",
      sortable: false,


    }, {
      Header: "Class",
      accessor: "name",
      sortable: false,

    }, {
      Header: "Course",
      accessor: "course_name",
      sortable: false,
    }, {
      Header: "Teacher",
      accessor: "teacher",
      sortable: false,
    },
    //  {
    //   Header: "Status",
    //   accessor: "status",
    //   sortable: false,
    //   style: { textAlign: "center" },
    //   Cell: (cellInfo) => (
    //     cellInfo.original.attendees
    //       ? <Label as='a' color='green' >
    //         Attended
    //         </Label> : <Label as='a' color='orange' >
    //         Absent
    //         </Label>
    //   )
    // }
  ]);

  useEffect(() => {
    setLoadingPage(true);
    if (student) {
      getData();
    }
  }, [student]);

  const getData = () => {
    setLoadingPage(true);
    getClassAttendance(student).then(res => {
      if (res && res.status === 200) {
        setData(res.data);
        // console.log(res);
      }
      setLoadingPage(false);
    });
  };

  return (
    <Wrap>
      {loadingPage
        ? <Dimmer active inverted >
          <Loader inverted>Loading</Loader>
        </Dimmer>
        : <Wrap>
          <TableList
            data={data}
            columns={column}
          />
        </Wrap>
      }
    </Wrap>
  )
}


export default AttendanceTable;
