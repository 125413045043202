import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../../config";
import {
  Header,
  Segment,
  Grid,
  Breadcrumb,
  Loader,
  Dimmer,
  Button,
  Icon,
  Tab,
  Image,
  Card,
  Popup,
  List,
} from "semantic-ui-react";
import {
  getCourseHistoryProfile,
  getProgram,
  getCampus,
  updateCourse,
  deleteCourse,
  getNanny,
  getTeacher,
  getStudent,
  postClass,
  updateClass,
  deleteClass,
  getTerm,
  getRegisHistory,
  postRegist,
  postRegistSpecial,
  postGraduate,
  postApprove,
  postPromote,
  updateRegist,
} from "./service";
import Wrap from "../../components/wrap";
import ModalBasic from "../../components/modal";
import TableList from "../../components/table";
import CourseForm from "./form";
import ClassForm from "./classForm";
import Confirmmodal from "../../components/confirmmodal";
import RegisterForm from "./formRegist";
import RegisterSpecialForm from "./formRegistSpe";
import RegisterEditForm from "./formEditRegist";

import GraduateForm from "./formGraduate";
import ApproveForm from "./formApprove";
import PromoteForm from "./formPromote";

const CourseHistoryProfile = (props) => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState(
    props && props.location.state && props.location.state.id
  );
  const [open, setOpen] = useState(false);
  const [errormsg, setErrormsg] = useState(null);
  const [campus, setCampus] = useState(null);
  const [program, setProgram] = useState(null);
  const [nanny, setNanny] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [ta, setTA] = useState(null);
  const [student, setStudent] = useState(null);
  const [term, setTerm] = useState(null);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [registData, setRegistData] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmDeleteCourse, setOpenConfirmDeleteCourse] = useState(false);
  const [allPermistion, setAllPermistion] = useState(
    JSON.parse(sessionStorage.getItem("kidz_permission"))
  );
  const [showClass, setShowClass] = useState(true);

  const columnRegist = [
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "imageURL",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/dfkid.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/dfkid.png";
          }}
        />
      ),
      width: 60,
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: false,
      width: 200,
      Cell: (cellInfo) => (
        <Wrap>
          <span
            className="bold"
            style={{ cursor: "pointer" }}
            onClick={() =>
              props.history.push({
                pathname: "/student/profile",
                // search: '?query=abc',
                state: { id: cellInfo.original.student_id, page: "student" },
              })
            }
          >
            {cellInfo.original.studentID} {cellInfo.original.title}{" "}
            {cellInfo.original.firstName} {cellInfo.original.lastName}
          </span>
        </Wrap>
      ),
    },
    {
      Header: "Term",
      accessor: "name",
      style: { textAlign: "center" },
      width: 100,
      sortable: false,
    },
    {
      Header: "Start",
      accessor: "start",
      style: { textAlign: "center" },
      sortable: false,
      width: 100,
    },
    {
      Header: "End",
      accessor: "end",
      style: { textAlign: "center" },
      sortable: false,
      width: 100,
    },
    {
      Header: "D/W",
      accessor: "d/w",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Full/Half",
      accessor: "time",
      style: { textAlign: "center" },
      sortable: false,
      width: 100,
      Cell: (cellInfo) =>
        cellInfo.original.time === "full"
          ? "Full Day"
          : cellInfo.original.time === "half"
          ? "Half Day"
          : "-",
    },
    {
      Header: "Sun",
      accessor: "sun",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    {
      Header: "Mon",
      accessor: "mon",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    {
      Header: "Tue",
      accessor: "tue",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    {
      Header: "Wed",
      accessor: "wed",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    {
      Header: "Thu",
      accessor: "thu",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    {
      Header: "Fri",
      accessor: "fri",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    {
      Header: "Sat",
      accessor: "sat",
      style: { textAlign: "center" },
      sortable: false,
      width: 50,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { textAlign: "center" },
      sortable: false,
    },
    {
      Header: "Registered date",
      accessor: "registdate",
      style: { textAlign: "center" },
      sortable: false,
    },
    // {
    //   Header: "Action",
    //   accessor: "id",
    //   style: { textAlign: "center" },
    //   sortable: false,
    //   Cell: (cellInfo) => {
    //     return (
    //       checkPermissionMenu("Academic", ["manage-registration"]) && (
    //         <Wrap>
    //           <Icon
    //             link
    //             name="edit"
    //             onClick={() => onClickEditRegist(cellInfo.original)}
    //           />
    //         </Wrap>
    //       )
    //     );
    //   },
    //   width: 100,
    // },
  ];
  const columnSpeRegist = [
    {
      Header: "No.",
      accessor: "",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.page * cellInfo.pageSize + (cellInfo.index + 1),
      width: 50,
    },
    {
      Header: "Image",
      accessor: "imageURL",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => (
        <Image
          size="mini"
          verticalAlign="middle"
          src={
            cellInfo.original.imageURL
              ? cellInfo.original.imageURL + "?time=" + new Date()
              : "../storage/imgs/dfkid.png"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "../storage/imgs/dfkid.png";
          }}
        />
      ),
      width: 60,
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: false,
      width: 200,
      Cell: (cellInfo) => (
        <Wrap>
          <span
            className="bold"
            style={{ cursor: "pointer" }}
            onClick={() =>
              props.history.push({
                pathname: "/student/profile",
                // search: '?query=abc',
                state: { id: cellInfo.original.student_id, page: "student" },
              })
            }
          >
            {cellInfo.original.studentID} {cellInfo.original.title}{" "}
            {cellInfo.original.firstName} {cellInfo.original.lastName}
          </span>
        </Wrap>
      ),
    },
    {
      Header: "Period (Day)",
      accessor: "numOfRegist",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) => <p> {cellInfo.original.numOfRegist} Day </p>,
    },
    {
      Header: "",
      accessor: "dateOfRegist",
      style: { textAlign: "center" },
      sortable: false,
      Cell: (cellInfo) =>
        cellInfo.original.dateOfRegist.split(",").map((item) => <p>{item}</p>),
    },
    {
      Header: "Registered date",
      accessor: "registdate",
      style: { textAlign: "center" },
      sortable: false,
    },
  ];

  const checkPermissionMenu = (group, permission) => {
    let userpermissionGroup = allPermistion.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };
  useEffect(() => {
    if (props.location && !props.location.state)
      props.history.push("/management/course");

    setLoadingPage(true);
    if (course) {
      getData();
    }
  }, [course]);

  const clickShowHideClass = () => {
    var d = new Date();
    // console.log( "วันที่ปัจจุบัน คือ "+d.getDate()+" เดือน "+(d.getMonth()+1)+" ปี "+d.getFullYear() );
    for (let i = 0; i < data.class_schedule.length; i++) {
      let endDate = data.class_schedule[i].end;
      const endDateSplit = endDate.split("-");
      if (Number(endDateSplit[2]) < d.getFullYear()) {
        setShowClass(!showClass);
      }
      if (Number(endDateSplit[2]) === d.getFullYear()) {
        setShowClass(!showClass);
      }
    }
  };

  const getData = () => {
    setLoadingPage(true);
    getCourseHistoryProfile(course).then((res) => {
      if (res && res.status === 200) {
        setData(res.data);
        //เรียงข้อมูล
        res.data.class_schedule.reverse();
      }
      setLoadingPage(false);
    });
    getRegisHistory(course).then((res) => {
      if (res && res.status === 200) {
        let newdata = [];
        res.data.map((item) => {
          let days = [];
          if (item.sun) days.push("S");
          if (item.mon) days.push("M");
          if (item.tue) days.push("Tu");
          if (item.wed) days.push("W");
          if (item.thu) days.push("Th");
          if (item.fri) days.push("F");
          if (item.sat) days.push("S");
          item["d/w"] = days.join();
          if (item.time === "full") item["d/w"] = item["d/w"] + " (Full Day)";
          newdata.push(item);
        });
        setRegistData(newdata);
      }
    });
  };

  const updateProfile = (values) => {
    setErrormsg(null);
    setLoading(true);

    if (!values["food"]) values["food"] = "false";

    updateCourse(values).then((res) => {
      if (res && res.status === 200) {
        getData();
        setOpen(false);
      } else {
        setErrormsg(res.data.message);
      }
      setLoading(false);
    });
  };

  const onEditProfile = () => {
    getProgram().then((res) => {
      if (res && res.status === 200) {
        let option = [];
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: item.id,
          });
        });
        setProgram(option);
      }
    });
    getCampus().then((res) => {
      if (res && res.status === 200) {
        let option = [];
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: item.name,
            value: item.id,
          });
        });
        setCampus(option);
      }
    });
    setCurrentData({
      id: data.id,
      name: data.name,
      desc: data.desc,
      period: data.period,
      remark: data.remark,
      campus: data.campus_id,
      program: data.program_id,
      food: data.food,
      master: data.master,
      ...data,
    });
    setOpen("Course");
  };

  const onAddClass = () => {
    getNanny("").then((res) => {
      if (res && res.status === 200) {
        let option = [];
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: `${item.firstName} ${item.lastName} `,
            value: item.id,
          });
        });
        setNanny(option);
      }
    });
    getTeacher().then((res) => {
      if (res && res.status === 200) {
        let option = [];
        let option_ta = [];
        res.data.map((item) => {
          if (item.employee_type === "TA") {
            option_ta.push({
              key: item.id,
              text: `${item.firstName} ${item.lastName} `,
              value: item.id,
            });
          } else {
            option.push({
              key: item.id,
              text: `${item.firstName} ${item.lastName} `,
              value: item.id,
            });
          }
        });
        setTeacher(option);
        setTA(option_ta);
      }
    });

    getTerm().then((res) => {
      let option = [];
      res.data.map((item) => {
        option.push({
          key: item.id,
          text: item.name,
          value: item,
        });
      });
      setTerm(option);
    });
    setOpen("Class");
  };

  const onClickEditRegist = (values) => {
    getTerm().then((res) => {
      let option = [];
      res.data.map((item) => {
        if (values.term_id && values.term_id == item.id) {
          values["term"] = item;
        }
        option.push({
          key: item.id,
          text: item.name,
          value: item,
        });
      });
      setTerm(option);
    });

    values["day"] = values.dayOfWeek;
    setCurrentData(values);
    setOpen("EditRegister");
  };
  const onClickEdit = (values) => {
    values["teacher"] = values.teacher_id;
    values["ta"] = values.ta_id;
    values["nanny"] = values.nanny_id;
    values["term"] = values.term;

    if (values["extended"] === "true") values["extended"] = true;
    else values["extended"] = false;

    let attendees = values.attendees.map((item) => item.id);
    values["attendees"] = attendees;
    setCurrentData(values);
    onAddClass();
  };
  const submitClass = (values) => {
    setErrormsg(null);
    setLoading(true);

    if (values["extended"]) values["extended"] = "true";
    else values["extended"] = "false";

    if (values["term"]) values["term_id"] = values["term"]["id"];
    else values["term_id"] = null;

    if (values.id) {
      values["course"] = data.course_id;
      updateClass(values).then((res) => {
        if (res && res.status === 200) {
          getData();
          setOpen(false);
        } else {
          setErrormsg(res.data.message);
        }
        setLoading(false);
        setCurrentData(null);
      });
    } else {
      values["course"] = data.id;
      postClass(values).then((res) => {
        if (res && res.status === 201) {
          getData();
          setOpen(false);
        } else {
          setErrormsg(res.data.message);
        }
        setLoading(false);
        setCurrentData(null);
      });
    }
  };
  const onAddRegister = () => {
    setOpen("Register");
    getStudent().then((res) => {
      if (res && res.status === 200) {
        let option = [];
        res.data.map((item) => {
          option.push({
            key: item.id,
            text: `${item.firstName} ${item.lastName} `,
            value: item.id,
          });
        });
        setStudent(option);
      }
    });
    getTerm().then((res) => {
      let option = [];
      res.data.map((item) => {
        option.push({
          key: item.id,
          text: item.name,
          value: item,
        });
      });
      setTerm(option);
    });
  };

  const onPromote = () => {
    setOpen("Promote");
    getTerm().then((res) => {
      let option = [];
      res.data.map((item) => {
        option.push({
          key: item.id,
          text: item.name,
          value: item,
        });
      });
      setTerm(option);
    });
  };
  const updatePromote = (values) => {
    setLoading(true);
    postPromote({
      course_id: data.id,
      course: values.course,
      term: values.term && values.term.id,
      student: values.student,
    }).then((res) => {
      if (res && res.status === 201) {
        setOpen(false);
        getData();
      }
      setLoading(false);
    });
  };
  const onGraduate = () => {
    setOpen("Graduate");
    getTerm().then((res) => {
      let option = [];
      res.data.map((item) => {
        option.push({
          key: item.id,
          text: item.name,
          value: item,
        });
      });
      setTerm(option);
    });
  };

  const updateGraduate = (values) => {
    setLoading(true);
    postGraduate({
      course_id: data.id,
      status: values.status,
      student: values.student,
      special: data.master ? false : true,
    }).then((res) => {
      if (res && res.status === 201) {
        setOpen(false);
        getData();
      }
      setLoading(false);
    });
  };
  const updateApprove = (values) => {
    setLoading(true);
    postApprove({
      course_id: data.id,
      student: values.student,
    }).then((res) => {
      if (res && res.status === 201) {
        setOpen(false);
        getData();
      }
      setLoading(false);
    });
  };

  const postRegister = (values) => {
    setLoading(true);
    values["course_id"] = data.id;

    if (values["term"]) values["term_id"] = values["term"]["id"];
    else values["term_id"] = null;
    values["dayOfWeek"] = values["day"];
    values["time"] = values["time"];
    values["sun"] =
      values["dow"] && values["dow"]["sun"] ? values["dow"]["sun"] : false;
    values["mon"] =
      values["dow"] && values["dow"]["mon"] ? values["dow"]["mon"] : false;
    values["tue"] =
      values["dow"] && values["dow"]["tue"] ? values["dow"]["tue"] : false;
    values["wed"] =
      values["dow"] && values["dow"]["wed"] ? values["dow"]["wed"] : false;
    values["thu"] =
      values["dow"] && values["dow"]["thu"] ? values["dow"]["thu"] : false;
    values["fri"] =
      values["dow"] && values["dow"]["fri"] ? values["dow"]["fri"] : false;
    values["sat"] =
      values["dow"] && values["dow"]["sat"] ? values["dow"]["sat"] : false;

    postRegist(values).then((res) => {
      if (res && res.status === 201) {
        setOpen(false);
        getData();
      }
      setLoading(false);
    });
  };
  const updateRegister = (values) => {
    setLoading(true);
    values["course_id"] = data.id;

    if (values["term"]) values["term_id"] = values["term"]["id"];
    else values["term_id"] = null;
    values["dayOfWeek"] = values["day"];
    values["time"] = values["time"];

    updateRegist(values).then((res) => {
      if (res && res.status === 200) {
        setOpen(false);
        getData();
      }
      setLoading(false);
    });
  };
  const postRegisterSpe = (values) => {
    setLoading(true);
    values["course_id"] = data.id;
    postRegistSpecial(values).then((res) => {
      if (res && res.status === 201) {
        setOpen(false);
        getData();
      }
      setLoading(false);
    });
  };

  const onClickDelete = (values) => {
    setOpenConfirmDelete(true);
    setCurrentData(values);
  };

  const deleteData = () => {
    setLoading(true);
    if (currentData && currentData.id)
      deleteClass(currentData.id).then((res) => {
        if (res && res.status === 200) {
          getData();
          setOpenConfirmDelete(false);
        }
        setLoading(false);
      });
  };

  const onClickDeleteCourse = (values) => {
    setOpenConfirmDeleteCourse(true);
    setCurrentData(values);
  };

  const deleteCourseData = () => {
    setLoading(true);
    if (data && data.id)
      deleteCourse(data.id).then((res) => {
        if (res && res.status === 200) {
          props.history.push("/management/course");
        }
        setLoading(false);
      });
  };
  const onClickClose = () => {
    setCurrentData(null);
    setOpen(false);
    getData();
  };
  return (
    <Wrap>
      <Segment basic textAlign="left">
        <Header as="h2">{data && data.name}</Header>
        <Grid columns="2">
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => props.history.push("/management/coursehistory")}
                style={{ color: "grey" }}
              >
                Course History
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>
                {data && data.name}
              </Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
          <Grid.Column>
            {/* {!loadingPage &&
              checkPermissionMenu("Academic", ["manage-course"]) && (
                <Wrap>
                  <Button
                    basic
                    color="green"
                    content="Edit Course"
                    floated="right"
                    style={{ marginBottom: "14px" }}
                    onClick={onEditProfile}
                  />
                  {registData && registData.length === 0 && (
                    <Button
                      basic
                      color="red"
                      content="Delete Course"
                      floated="right"
                      style={{ marginBottom: "14px" }}
                      onClick={onClickDeleteCourse}
                    />
                  )}
                </Wrap>
              )} */}
          </Grid.Column>
        </Grid>
        <Segment
          className="contentSegment"
          textAlign="left"
          style={{ minHeight: "50vh", padding: "20px" }}
        >
          {loadingPage ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Wrap>
              <Grid columns={2} divided>
                <Grid.Row>
                  <Grid.Column width="6">
                    <Header as="h2" icon textAlign="center">
                      <Icon
                        name="graduation cap"
                        circular
                        style={{ color: config.colorTheme }}
                      />
                      <Header.Content>
                        {data.name}
                        <Header.Subheader>
                          {data.program_name}
                          {data.master ? <p>Master</p> : ""}
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width="10" style={{ paddingTop: "30px" }}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width="6" className="bold">
                          Campus
                        </Grid.Column>
                        <Grid.Column width="10">{data.campus_name}</Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width="6" className="bold">
                          Age
                        </Grid.Column>
                        <Grid.Column width="10">
                          <div
                            dangerouslySetInnerHTML={{ __html: data.desc }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width="6" className="bold">
                          Period
                        </Grid.Column>
                        <Grid.Column width="10">
                          <div
                            dangerouslySetInnerHTML={{ __html: data.period }}
                          />
                          {data.halfStart &&
                            data.halfEnd &&
                            `Half Day [${data.halfStart} - ${data.halfEnd}]`}
                          <br />
                          {data.fullStart &&
                            data.fullEnd &&
                            `Full Day [${data.fullStart} - ${data.fullEnd}]`}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width="6" className="bold">
                          Remark
                        </Grid.Column>
                        <Grid.Column width="10">
                          <div
                            dangerouslySetInnerHTML={{ __html: data.remark }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width="6" className="bold">
                          Food
                        </Grid.Column>
                        <Grid.Column width="10">
                          {data.food === "false" || data.food === null ? (
                            <div>
                              <Icon name="x" color="red" /> Lunch/Snack
                            </div>
                          ) : (
                            <div>
                              <Icon name="check circle" color="green" />{" "}
                              {data.food}
                            </div>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Wrap>
          )}

          <br />
          <br />
          <Tab
            menu={{ color: "brown", secondary: true, pointing: true }}
            renderActiveOnly={true}
            panes={[
              {
                menuItem: "Registration",
                render: () => (
                  <Tab.Pane attached={false} basic>
                    <Grid columns={1}>
                      {/* {checkPermissionMenu("Academic", [
                        "manage-registration",
                      ]) && (
                        <Grid.Row>
                          <Grid.Column>
                            <span>
                              {data.master && (
                                <Button
                                  basic
                                  color="green"
                                  content="Promotion"
                                  floated="right"
                                  style={{ marginBottom: "14px" }}
                                  onClick={onPromote}
                                />
                              )}

                              <Button
                                basic
                                color="purple"
                                content="Unregister/Terminate/Graduate"
                                floated="right"
                                style={{ marginBottom: "14px" }}
                                onClick={onGraduate}
                              />
                              {checkPermissionMenu("Academic", [
                                "approve-registration",
                              ]) && (
                                <Button
                                  color="green"
                                  content="Approve Registration "
                                  floated="right"
                                  style={{ marginBottom: "14px" }}
                                  onClick={() => setOpen("ApproveRegister")}
                                />
                              )}
                              <Button
                                color="blue"
                                content="Register "
                                floated="right"
                                style={{ marginBottom: "14px" }}
                                onClick={onAddRegister}
                              />
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      )} */}
                      <Grid.Row>
                        <Grid.Column>
                          <TableList
                            loading={loading}
                            data={registData}
                            columns={
                              data && data.master
                                ? columnRegist
                                : columnSpeRegist
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Tab.Pane>
                ),
              },
              {
                menuItem: "Class",
                render: () => (
                  <Tab.Pane attached={false} basic>
                    {/* <Wrap>
                      <input
                        type="checkbox"
                        onChange={() => clickShowHideClass()}
                      />{" "}
                      Show All
                      <br />
                      <br />
                      <br />
                    </Wrap> */}

                    <Wrap>
                      {showClass ? (
                        <Card.Group itemsPerRow={3}>
                          {data &&
                            data.class_schedule &&
                            data.class_schedule.map((item) => (
                              <Card>
                                <Card.Content>
                                  <Card.Header>{item.name}</Card.Header>
                                  <Card.Meta>
                                    {item.term ? (
                                      <p>
                                        {item.term.name} {item.term.desc}
                                      </p>
                                    ) : (
                                      <p>
                                        {item.start} to {item.end}
                                      </p>
                                    )}
                                  </Card.Meta>
                                  <Card.Description>
                                    <br />
                                    <p>Teacher : {item.teacher}</p>
                                    <Card.Meta>
                                      {item.ta && <p>T.A : {item.ta}</p>}
                                      {item.nanny && (
                                        <p>Nanny : {item.nanny}</p>
                                      )}
                                    </Card.Meta>
                                  </Card.Description>
                                </Card.Content>
                                {checkPermissionMenu("Academic", [
                                  "manage-class",
                                ]) && (
                                  <Card.Content extra textAlign="right">
                                    <Icon
                                      link
                                      name="edit"
                                      onClick={() => onClickEdit(item)}
                                    />
                                  </Card.Content>
                                )}
                              </Card>
                            ))}
                        </Card.Group>
                      ) : (
                        <Card.Group itemsPerRow={3}>
                          {data &&
                            data.class_schedule &&
                            data.class_schedule.map((item) => (
                              <>
                                {Number(item.end.split("-")[2]) >
                                  new Date().getFullYear() && (
                                  <Card>
                                    <Card.Content>
                                      <Card.Header>{item.name}</Card.Header>
                                      <Card.Meta>
                                        {item.term ? (
                                          <p>
                                            {item.term.name} {item.term.desc}
                                          </p>
                                        ) : (
                                          <p>
                                            {item.start} to {item.end}
                                          </p>
                                        )}
                                      </Card.Meta>
                                      <Card.Description>
                                        <br />
                                        <p>Teacher : {item.teacher}</p>
                                        <Card.Meta>
                                          {item.ta && <p>T.A : {item.ta}</p>}
                                          {item.nanny && (
                                            <p>Nanny : {item.nanny}</p>
                                          )}
                                        </Card.Meta>
                                      </Card.Description>
                                    </Card.Content>
                                    {checkPermissionMenu("Academic", [
                                      "manage-class",
                                    ]) && (
                                      <Card.Content extra textAlign="right">
                                        <Icon
                                          link
                                          name="edit"
                                          onClick={() => onClickEdit(item)}
                                        />
                                        {/* <Icon
                                        link
                                        color="red"
                                        name="trash"
                                        onClick={() => onClickDelete(item)}
                                      /> */}
                                      </Card.Content>
                                    )}
                                  </Card>
                                )}
                                {Number(item.end.split("-")[2]) ===
                                  new Date().getFullYear() &&
                                  Number(item.end.split("-")[1]) >
                                    new Date().getMonth() + 1 && (
                                    <Card>
                                      <Card.Content>
                                        <Card.Header>{item.name}</Card.Header>
                                        <Card.Meta>
                                          {item.term ? (
                                            <p>
                                              {item.term.name} {item.term.desc}
                                            </p>
                                          ) : (
                                            <p>
                                              {item.start} to {item.end}
                                            </p>
                                          )}
                                        </Card.Meta>
                                        <Card.Description>
                                          <br />
                                          <p>Teacher : {item.teacher}</p>
                                          <Card.Meta>
                                            {item.ta && <p>T.A : {item.ta}</p>}
                                            {item.nanny && (
                                              <p>Nanny : {item.nanny}</p>
                                            )}
                                          </Card.Meta>
                                        </Card.Description>
                                      </Card.Content>
                                      {checkPermissionMenu("Academic", [
                                        "manage-class",
                                      ]) && (
                                        <Card.Content extra textAlign="right">
                                          <Icon
                                            link
                                            name="edit"
                                            onClick={() => onClickEdit(item)}
                                          />
                                          {/* <Icon
                                        link
                                        color="red"
                                        name="trash"
                                        onClick={() => onClickDelete(item)}
                                      /> */}
                                        </Card.Content>
                                      )}
                                    </Card>
                                  )}
                                {Number(item.end.split("-")[2]) ===
                                  new Date().getFullYear() &&
                                  Number(item.end.split("-")[1]) ===
                                    new Date().getMonth() + 1 &&
                                  Number(item.end.split("-")[0]) >=
                                    new Date().getDate() && (
                                    <Card>
                                      <Card.Content>
                                        <Card.Header>{item.name}</Card.Header>
                                        <Card.Meta>
                                          {item.term ? (
                                            <p>
                                              {item.term.name} {item.term.desc}
                                            </p>
                                          ) : (
                                            <p>
                                              {item.start} to {item.end}
                                            </p>
                                          )}
                                        </Card.Meta>
                                        <Card.Description>
                                          <br />
                                          <p>Teacher : {item.teacher}</p>
                                          <Card.Meta>
                                            {item.ta && <p>T.A : {item.ta}</p>}
                                            {item.nanny && (
                                              <p>Nanny : {item.nanny}</p>
                                            )}
                                          </Card.Meta>
                                        </Card.Description>
                                      </Card.Content>
                                      {checkPermissionMenu("Academic", [
                                        "manage-class",
                                      ]) && (
                                        <Card.Content extra textAlign="right">
                                          <Icon
                                            link
                                            name="edit"
                                            onClick={() => onClickEdit(item)}
                                          />
                                          {/* <Icon
                                        link
                                        color="red"
                                        name="trash"
                                        onClick={() => onClickDelete(item)}
                                      /> */}
                                        </Card.Content>
                                      )}
                                    </Card>
                                  )}
                              </>
                            ))}
                        </Card.Group>
                      )}
                    </Wrap>
                  </Tab.Pane>
                ),
              },
            ]}
          />
        </Segment>
        <ModalBasic
          open={open === "Course"}
          content="Course"
          close={onClickClose}
        >
          <CourseForm
            onSubmit={updateProfile}
            onClose={onClickClose}
            initialValues={currentData}
            campus_option={campus}
            program_option={program}
            loading={loading}
            errormsg={errormsg}
          />
        </ModalBasic>
        <ModalBasic
          open={open === "Class"}
          content="Class"
          close={onClickClose}
        >
          <ClassForm
            onSubmit={submitClass}
            onClose={onClickClose}
            initialValues={currentData}
            master={data && data.master}
            course={data}
            teacher_option={teacher}
            term_option={term}
            ta_option={ta}
            nanny_option={nanny}
            // student_option={student}
            loading={loading}
            errormsg={errormsg}
            viewOnly={true} //hide "Save" button
          />
        </ModalBasic>
        <ModalBasic
          open={open === "Register"}
          content="Register"
          close={onClickClose}
        >
          {data && data.master ? (
            <RegisterForm
              onSubmit={postRegister}
              onClose={onClickClose}
              course={data}
              term_option={term}
              loading={loading}
              errormsg={errormsg}
            />
          ) : (
            <RegisterSpecialForm
              onSubmit={postRegisterSpe}
              onClose={onClickClose}
              course={data}
              term_option={term}
              student_option={student}
              loading={loading}
              errormsg={errormsg}
            />
          )}
        </ModalBasic>
        <ModalBasic
          open={open === "Graduate"}
          size="large"
          content={
            <Header as="h2" textAlign="center">
              <Icon name="graduation cap" />
              <Header.Content>
                {data && `${data.name} (${data.program_name})`}
              </Header.Content>
            </Header>
          }
          close={onClickClose}
        >
          <GraduateForm
            onSubmit={updateGraduate}
            onClose={onClickClose}
            student={registData}
            course={data}
            term_option={term}
            loading={loading}
            errormsg={errormsg}
          />
        </ModalBasic>
        <ModalBasic
          open={open === "Promote"}
          size="large"
          content={
            <Header as="h2" textAlign="center">
              <Icon name="graduation cap" />
              <Header.Content>
                Promotion : {data && `${data.name} (${data.program_name})`}
              </Header.Content>
            </Header>
          }
          close={onClickClose}
        >
          <PromoteForm
            onSubmit={updatePromote}
            onClose={onClickClose}
            student={registData}
            course={data}
            term_option={term}
            loading={loading}
            errormsg={errormsg}
          />
        </ModalBasic>
        <ModalBasic
          open={open === "ApproveRegister"}
          size="large"
          content={
            <Header as="h2" textAlign="center">
              <Icon name="check square outline" />
              <Header.Content>
                {data && `${data.name} (${data.program_name})`}
              </Header.Content>
            </Header>
          }
          close={onClickClose}
        >
          <ApproveForm
            onSubmit={updateApprove}
            onClose={onClickClose}
            student={registData}
            course={data}
            loading={loading}
            errormsg={errormsg}
          />
        </ModalBasic>
        <ModalBasic
          open={open === "EditRegister"}
          content="Register"
          close={onClickClose}
        >
          <RegisterEditForm
            onSubmit={updateRegister}
            onClose={onClickClose}
            course={data}
            initialValues={currentData}
            term_option={term}
            loading={loading}
            errormsg={errormsg}
          />
        </ModalBasic>
      </Segment>
      <Confirmmodal
        loading={loading}
        open={openConfirmDelete}
        title="Class"
        onClose={() => setOpenConfirmDelete(false)}
        onComfirm={deleteData}
      />
      <Confirmmodal
        loading={loading}
        open={openConfirmDeleteCourse}
        title="Course"
        onClose={() => setOpenConfirmDeleteCourse(false)}
        onComfirm={deleteCourseData}
      />
    </Wrap>
  );
};

export default withRouter(CourseHistoryProfile);
